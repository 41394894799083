// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// const ProtectedRoute = (props) => {
//   const { Component } = props;
//   const navigate = useNavigate();
//   useEffect(() => {
//     // let isAuthenticated = localStorage.getItem("token");
//     let isAuthenticated = localStorage.getItem("token") ;

//     console.log("isAuthenticated" , isAuthenticated)
//     if (!isAuthenticated) {
//       navigate("/admin");
//     }
//   }, []);

//   return (
//     <>
//       <Component />
//     </>
//   );
// };

// export default ProtectedRoute;




import React from "react";
import axios from "axios";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
    const { Component } = props;

  const navigate = useNavigate();

  const verifytokenexistss = async () => {
    
    try {
        // console.log("I am in verifytokenexists Try_sam");    
      const tokenfromls = localStorage.getItem("token");
      // console.log("tokenfromls" , tokenfromls)

      const id = localStorage.getItem("adminregisterId");
      const h = { authorization: tokenfromls };
      // console.log(h);
      if (tokenfromls) {
        const res = await axios.get(`
          ${process.env.REACT_APP_URL}/protect/protectedroute`,
          { headers: h }
        );
        // console.log("my resp", res);
       
        if (res) {
          // console.log(res.data , "jloashissh")
          if (res.data.m != id) {
            navigate("/admin");
            localStorage.removeItem("token")
            localStorage.removeItem("adminregisterId")
          }
        }
      } 
      else{
        navigate("/admin");
      }
     
    } catch (e) {
      console.log("I am in verifytokenexists catch_sam");
    console.log("Error -> ",e)
    if(e.response&&e.response.status===401||e.response&&e.response.status===400){
      navigate("/admin");
      localStorage.removeItem("token")
      localStorage.removeItem("adminregisterId")
    }
  
  }
  };

  useEffect(() => {
    // console.log("I am in useeffect_sam")
    verifytokenexistss();
  }, []);

  return <div> <Component /></div>; 
{/* <Component /> */}
};

export default ProtectedRoute; 
























// const calculate_currencyaverage = () => {
//   const filteredEventHistory = eventHistory.filter(
//     (item) => item.estimate !== null && item.estimate !== ""
//   );
//   const a_minus_evalue = filteredEventHistory.map(
//     (item) => item.actual - item.estimate
//   );
//   const a_minus_evalue1 = eventHistory.map(
//     (item) => item.actual - item.estimate
//   );
//   // <---here we r matching date time , bcz. length r different --->
//   const eventavgtime = eventHistory.map((item) => item.time);
//   const eventavgestimate = eventHistory.map((item) => item.estimate);
//   const eventavgdate = eventHistory.map((item) =>
//     item.date.split(".").reverse().join(".")
//   );

//   const calculateSumAndAverage = (data, differences) => {
//     // console.log("usddata" , usddata)
//     const filledData = eventavgdate.map((date, index) => {
//       const time = eventavgtime[index];
//       const entry = data.find(
//         (item) => item.date === date && item.time === time
//       );
//     // <---open close ki empty value add kr rhe hum , for same length---->
//       return entry || { date: date, time: time, Open: "", Close: "" };
//     });
//     // console.log("filledData" , filledData)
//     const filteredData = filledData.filter((entry, index) => {

//       return (
//         eventavgestimate[index] !== undefined &&
//         eventavgestimate[index] !== null
//       );
//     });
//     // console.log("filteredData" , filteredData)

//     if (
//       filteredData.some((entry) => entry.Open !== "" && entry.Close !== "")
//     ) {
//       const sum = filteredData.reduce((total, item, index) => {
//         if (differences[index] !== 0.0 && differences[index] !== -0.0) {
//           if (item.Close === "" || item.Open === "") {
//             return total;
//           }
//           const differenceCO = item.Close - item.Open;
        
//           // console.log("differenceCO -->", differenceCO.toFixed(4));
//           const modulusCO = Math.abs(differenceCO.toFixed(4));
//           // console.log("modulusCO" , modulusCO)
//           return total + modulusCO;
//         }
//         return total;
//       }, 0);

     
//       const validEntriesCount = filteredData.filter(
//         (item, index) =>

//          { 
//           const opencloseNotEmpty = item.Open !== "" && item.Close !== "";

//           // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
//           return opencloseNotEmpty && differences[index] !== 0.0 && differences[index] !== -0.0;
//         }).length;



// // console.log("validEntriesCount" , validEntriesCount)
//       const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
//       // console.log("average" , average)
//       return average;
//     } else {
//       return null;
//     }
//   };

//   // Calculate averages for different data sets
//   const averageOC1 = calculateSumAndAverage(usddata, a_minus_evalue);
//   setAverageDiff(averageOC1);
//   const averageOC2 = calculateSumAndAverage(jpydata, a_minus_evalue);
//   setavgjpydiff(averageOC2);
//   const averageOC3 = calculateSumAndAverage(gbpnzddata, a_minus_evalue);
//   setaveragegbpnzddiff1(averageOC3);
//   const averageOC4 = calculateSumAndAverage(eurgbpdata, a_minus_evalue);
//   setaveragegbpeurdiff1(averageOC4);
//   const averageOC5 = calculateSumAndAverage(gbpchfdata, a_minus_evalue);
//   setaveragegbpchfdiff1(averageOC5);
//   const averageOC6 = calculateSumAndAverage(gbpauddata, a_minus_evalue);
//   setaveragegbpauddiff1(averageOC6);
//   const averageOC7 = calculateSumAndAverage(gbpcaddata, a_minus_evalue);
//   setaveragegbpcaddiff1(averageOC7);

  