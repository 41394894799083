import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LineWave } from "react-loader-spinner";
// importUserTest from "../components/admin/adminTest"
import UserNavbar from "../../components/user/userNavbar";


const Userbuysell = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSell, setLoadingSell] = useState(false);

  const [iframeHeight1, setIframeHeight1] = useState("38vh");
  const [iframeHeight2, setIframeHeight2] = useState("38vh");
  const [iframeHeight3, setIframeHeight3] = useState("38vh");
  const [latestevent , setLatestevent] = useState(null)
//   const handleClick = async (value) => {
//     try {
//       if (value === "buy") {
//         setLoading(true);
//       } else {
//         setLoadingSell(true);
//       }
//       const userid = localStorage.getItem("adminregisterId");
//       const response = await axios.post(
//         `${process.env.REACT_APP_URL}/postbuysell`,
//         {
//           userid: userid,
//           value: value,
//         }
//       );

//       console.log(response);
//       if (value === "buy") {
//         setLoading(false);
//       } else {
//         setLoadingSell(false);
//       }
//       toast.success(`${value} command send successfully!`, {
//         position: "top-right",
//       });
//     } catch (error) {
//       console.error("Error posting buy/sell data:", error);
//       toast.error(`Error in sending ${value} command!`, {
//         position: "top-right",
//       });
//       if (value === "buy") {
//         setLoading(false);
//       } else {
//         setLoadingSell(false);
//       }
//     }
//   };
const fetchlatest_event = async () => {
  try {
    const userid = localStorage.getItem("userregisterId");
    const response = await axios.get(`${process.env.REACT_APP_URL}/user/getlivetrade_upcomingdata/${userid}`);
    if (response.data.status === 200) {
     
      // setErrorMessage('');
      console.log("response.data" ,response.data)
      const future_data = response.data?.nextEvent?.trades?.[0]
      // console.log("future_data" , future_data)
      if (future_data) {
        setLatestevent(future_data);
      } else {
        console.log("No future events found");
      }
    } else {
      setLatestevent(null);
      // setErrorMessage('No future trades available');
    }
   
    
  } catch (error) {
    console.error("Error fetching messages:", error);
  }
};
useEffect(()=>{
  fetchlatest_event();
},[])


return (
    <>
      {/* <div className="flex justify-center items-center mt-2">
        <button
          className={`px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 ${
            loading ? "opacity-50 cursor-not-allowed disabled" : ""
          }`}
          onClick={() => handleClick("buy")}
        >
          Buy
        </button>
        <button
          className={`px-4 py-1 bg-red-500 text-white rounded hover:bg-red-600 m-2 ${
            loadingSell ? "opacity-50 cursor-not-allowed disabled" : ""
          }`}
          onClick={() => handleClick("sell")}
        >
          Sell
        </button>
      </div> */}


        <div>
          <UserNavbar />
        </div>
        <h1 className="font-bold text-[18px] mt-[100px]"> Future Trade Event: - {latestevent ? `${latestevent.event} on ${latestevent.date} at ${latestevent.time}` : "Loading..."}</h1>
      <div className="flex justify-between items-center w-full ">
      <div>
      <div className="flex space-x-4 chart_button mt-[23%] md:mt-[6%] justify-center items-center">
                <button
                  onClick={() => setIframeHeight1("13vh")}
                  className="px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Reduce Height
                </button>
                <button
                  onClick={() => setIframeHeight1("38vh")}
                  className="px-4 py-1 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                  Reset Height
                </button>
              </div>

            <div className="flex justify-center items-center mt-1">
              <iframe
              
                title="Trading economics"
                src="https://tradingeconomics.com/calendar"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className=" block    border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[45vw] rounded-2xl"
                style={{ height: iframeHeight1 }}
              ></iframe>
            </div>
      </div>


          <div>
          <div className="flex space-x-4 chart_button  mt-[23%] md:mt-[6%] justify-center items-center">
                <button
                  onClick={() => setIframeHeight2("13vh")}
                  className="px-4 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Reduce Height
                </button>
                <button
                  onClick={() => setIframeHeight2("38vh")}
                  className="px-4 py-1 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                  Reset Height
                </button>
              </div>
            <div className="flex justify-center items-center mt-1">
              <iframe
                title="Uk investing"
                src="https://uk.investing.com/economic-calendar/"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className=" block   border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[45vw] rounded-2xl"
                // style={{border:2px solid #4A4A4A}}
                style={{ height: iframeHeight2 }}
              ></iframe>
              {/* <div>Hello World</div> */}
        {/* <AdminTest/> */}
            </div>
          </div>

      </div>

      <div className="flex space-x-4 chart_button mt-3 justify-center items-center">
          <button
            onClick={() => setIframeHeight3("13vh")}
            className="px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Reduce Height
          </button>
          <button
            onClick={() => setIframeHeight3("38vh")}
            className="px-4 py-1 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Reset Height
          </button>
      </div>
      <div className="flex justify-center items-center mt-1">
        <iframe
          title="Ig page"
          src="https://www.ig.com/uk/welcome-page"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          className=" block   border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[60vw] rounded-2xl"
          // style={{border:2px solid #4A4A4A}}
          style={{ height: iframeHeight3 }}
        ></iframe>
        {/* <div>Hello World</div> */}
  {/* <AdminTest/> */}
      </div>
      
    </>
  );
};

export default Userbuysell;
