// import React, { useState } from "react";

// const CommunityChat = () => {
//   const [messages, setMessages] = useState([]); // To store all messages
//   const [newMessage, setNewMessage] = useState(""); // For input box

//   // Add a new message
//   const sendMessage = () => {
//     if (newMessage.trim()) {
//       const newEntry = { id: Date.now(), text: newMessage, replies: [] };
//       setMessages([...messages, newEntry]);
//       setNewMessage(""); // Clear input
//     }
//   };

//   // Add a reply to a message
//   const addReply = (messageId, replyText) => {
//     const updatedMessages = messages.map((msg) =>
//       msg.id === messageId
//         ? { ...msg, replies: [...msg.replies, { text: replyText }] }
//         : msg
//     );
//     setMessages(updatedMessages);
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 p-6">
//       <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg p-4">
//         <h2 className="text-2xl font-bold mb-4">Community Chat</h2>
//         <div className="space-y-4">
//           {/* Display all messages */}
//           {messages.map((msg) => (
//             <div key={msg.id} className="border rounded p-3">
//               <p className="font-semibold">{msg.text}</p>
//               <div className="pl-4 mt-2 space-y-2">
//                 {/* Display Replies */}
//                 {msg.replies.map((reply, index) => (
//                   <p key={index} className="text-gray-600">
//                     {reply.text}
//                   </p>
//                 ))}
//                 {/* Reply Input */}
//                 <ReplyInput onSendReply={(replyText) => addReply(msg.id, replyText)} />
//               </div>
//             </div>
//           ))}
//         </div>
//         {/* Message Input */}
//         <div className="mt-4">
//           <input
//             type="text"
//             value={newMessage}
//             onChange={(e) => setNewMessage(e.target.value)}
//             placeholder="Type your message..."
//             className="w-full border px-4 py-2 rounded mb-2"
//           />
//           <button
//             onClick={sendMessage}
//             className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
//           >
//             Send
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// // Reply Input Component
// const ReplyInput = ({ onSendReply }) => {
//   const [replyText, setReplyText] = useState("");

//   const handleReply = () => {
//     if (replyText.trim()) {
//       onSendReply(replyText);
//       setReplyText(""); // Clear input
//     }
//   };

//   return (
//     <div className="flex items-center space-x-2">
//       <input
//         type="text"
//         value={replyText}
//         onChange={(e) => setReplyText(e.target.value)}
//         placeholder="Type a reply..."
//         className="flex-grow border px-4 py-2 rounded"
//       />
//       <button
//         onClick={handleReply}
//         className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
//       >
//         Reply
//       </button>
//     </div>
//   );
// };

// export default CommunityChat;

import React, { useEffect, useState } from "react";
import axios from "axios";
import UserNavbar from "../../components/user/userNavbar";
import { toast } from "react-toastify";

const CommunityChat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [replyTo, setReplyTo] = useState(null);

  const username = localStorage.getItem("username") || "Guest"; // Get username from localStorage

  // Fetch messages
  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/user/getchat_messages`);
      setMessages(response.data);
      console.log("response.data" , response.data)
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // Handle message sending
  const handleSendMessage = async () => {
    if (!newMessage.trim()) 
        toast.error("Please type something in message box", { position: "top-right"});
       

    try {
      const message = {
        user: username,
        text: newMessage,
        replyTo: replyTo?._id || null,
      };

      const userid = localStorage.getItem("userregisterId");
      await axios.post(`${process.env.REACT_APP_URL}/user/chat_messages/${userid}`, message);
      toast.success("Message sent !", { position: "top-right" });
      fetchMessages(); // Refresh messages after sending
      setNewMessage("");
      setReplyTo(null);
    } catch (error) {
    //   toast.error("Error sending message", { position: "top-right" });
      console.error("Error sending message:", error);
    }
  };

  return (
    <>
       <UserNavbar />
       <h1 className="mt-[100px] font-bold text-[24px] m-5">Community Chat :-</h1>
    <div className="flex flex-col h-[80vh] p-4 bg-[#222559]  mx-[2vw] md:mx-[10vw]  lg:mx-[20vw] mt-10 overflow-y-auto relative pb-1">
   
      <div className="flex-1 overflow-y-auto space-y-4 pb-[3%] ">
        {messages.map((msg) => (
          <div
            key={msg._id}
            className={`flex ${msg.user === username ? "justify-end" : ""}`}  // Align right for user messages
          >
            <div className={`p-4 rounded shadow  ${msg.user === username ? "bg-blue-100 " : "bg-[#e7e7e7]"}`}>
              {msg.replyTo && (
                <div className={`p-2 mb-2 bg-gray-100 rounded border-l-2 border-black ${msg.user === username ? "flex justify-end " : "flex justify-start"}`}>
                  {/* <strong>{msg.replyTo.user}:</strong> <span className="ml-1">{msg.replyTo.text}</span> */}
                  <strong>{msg.replyTo.user === username ? "You" : msg.replyTo.user}:</strong>
                  <span className="ml-1">{msg.replyTo.text}</span>
                </div>
              )}
              <p className={`${msg.user === username ? "flex justify-end " : "flex justify-start"}`}>
                <strong>{msg.user === username ? "You" : msg.user}: </strong> <span className="ml-1"> {msg.text}</span>
              </p>
              <p className={`text-sm text-gray-500 ${msg.user === username ? "flex justify-end " : "flex justify-start"}`}>{new Date(msg.createdAt).toLocaleString()}</p>
              {msg.user !== username && (
                <button
                  onClick={() => setReplyTo(msg)}
                  className="text-blue-500 text-sm hover:underline"
                >
                  Reply
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {replyTo && (
        <div className="p-2 bg-gray-200 pb-[6%]">
          Replying to <strong>{replyTo.user}</strong>: {replyTo.text}
          <button
            onClick={() => setReplyTo(null)}
            className="ml-2 text-red-500 text-sm"
          >
            Cancel
          </button>
        </div>
      )}

      <div className="flex absolute w-[96%] mt-4 focus:outline-none bottom-[8px]">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message here..."
          className="flex-1 p-2 border rounded-l"
        />
        <button
          onClick={handleSendMessage}
          className="p-2 bg-blue-500 text-white rounded-r"
        >
          Send
        </button>
      </div>
    </div>
    </>
  );
};

export default CommunityChat;
