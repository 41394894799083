
import dayjs from "dayjs";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { fetchStockData } from "./services";
import formatStockData from "./utils";
import ReactApexChart from "react-apexcharts";
import { Bars } from "react-loader-spinner";
import { BsArrowLeft } from "react-icons/bs";
import loader_video from "../assets/loaderaltra.gif"

const CandleUser = () => {
  const [stockData, setStockData] = useState([]);
  const [countryname, setcountryname] = useState("");
  const [eventname, seteventname] = useState("");
  const [loading, setLoading] = useState(true);
  const chartRefs = useRef([]);
  const [noDataAvailable, setNoDataAvailable] = useState(false); 
 const [fullScreenIndex, setFullScreenIndex] = useState(null);

  
  const fetchCandleData = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const eventName = decodeURIComponent(urlParams.get('eventName'));
      const eventcountry = decodeURIComponent(urlParams.get('eventcountry'));
      let graphdataarray = localStorage.getItem("4GraphDataobjects");

      if (graphdataarray) {
        graphdataarray = JSON.parse(graphdataarray);
        // console.log("graphdataarray" , graphdataarray)
        if (graphdataarray.length > 0) {

          const current_Item = graphdataarray.find(
            (item) => item.event === eventName && item.country === eventcountry
          );
          // const lastItem = graphdataarray[graphdataarray.length - 1];
          // console.log("current_Item" , current_Item)
         

          const event = current_Item.event;
          seteventname(event); 
          const country = current_Item.country;
          setcountryname(country);
          let extractedDatesAndTimes = [];

          current_Item.four_graph_values.forEach((graphItem) => {
            const { date, time } = graphItem;
            // <------if same date and time is not available then push the data ------------>
            if (
              !extractedDatesAndTimes.some(
                (item) => item.date === date && item.time === time
              )
            ) {
              extractedDatesAndTimes.push({ date, time, country });
            }
            else {
              console.log("data is already available")
            }
            
          });
         
          const data = await fetchStockData(extractedDatesAndTimes);
          // setStockData(data);
         console.log("data------------->" , data)
         
           // Check for unsupported country error
        if (data.error === "Unsupported country") {
          console.log("No data available for this country");
          setStockData([]); // Clear any existing stock data
          setNoDataAvailable(true); 
          setLoading(false);
        } else {
          setStockData(data);
          // console.log("Fetched data:", data);
          setLoading(false);
        }
        } 
        
        else {
          console.log(
            "No data found in localStorage for key '4GraphDataobjects'"
          );
          setLoading(false);
          setNoDataAvailable(true); 
        }
      }

      else{
        console.log("No data available in local storage");
        setLoading(false);
        setNoDataAvailable(true); 
      }
    }
     catch (error) {
      console.error("Error fetching stock data:", error);
      setLoading(false);
      setNoDataAvailable(true); 
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCandleData();
  }, []);

  const findHighLow = (dataArray) => {
    let highestHigh = -Infinity;
    let lowestLow = Infinity;

    dataArray.forEach((item) => {
      if (
        item &&
        typeof item.High === "number" &&
        typeof item.Low === "number"
      ) {
        highestHigh = Math.max(highestHigh, item.High);
        lowestLow = Math.min(lowestLow, item.Low);
      }
    });

    return { highestHigh, lowestLow };
  };

  const seriesData = useMemo(() => {
    return stockData.map((item) => {
      const dateGroups = {};

      item.data.forEach((point) => {
        const date = point.date;
        if (!dateGroups[date]) {
          dateGroups[date] = [];
        }
        dateGroups[date].push(point);
        // console.log("dateGroups" , dateGroups[date])
      });


      // console.log("dateGroups" , dateGroups)
      // console.log("dateGroups[date]" , dateGroups[date])
      // Calculate averages and deviations
      const averagesByDate = Object.keys(dateGroups).map((date) => {
        const openValues = dateGroups[date].map((point) => point.Open);
        const highValues = dateGroups[date].map((point) => point.High);
        const lowValues = dateGroups[date].map((point) => point.Low);
        const closeValues = dateGroups[date].map((point) => point.Close);

        const sum = (arr) => arr.reduce((acc, val) => acc + val, 0);
        const average = (arr) => sum(arr) / arr.length;
      
        // console.log("openValues" , openValues[0])
        const openAverage = average(openValues);
        // const highAverage = average(highValues);
        // const lowAverage = average(lowValues);
        // const closeAverage = average(closeValues);
        // console.log("openAverage:", openAverage);
        const calculations = dateGroups[date].map((point) => {

          return {
            ...point,
            openDeviation: (((point.Open - openValues[0]) / openValues[0])*100).toFixed(3),
            highDeviation: (((point.High - openValues[0]) / openValues[0])*100).toFixed(3),
            lowDeviation: (((point.Low - openValues[0])   / openValues[0])*100).toFixed(3),
            closeDeviation: (((point.Close - openValues[0]) / openValues[0])*100).toFixed(3),
          };
        });
       
        return { date, calculations };
      });

      // console.log(`Averages for object :`, averagesByDate)
      const flattenedData = averagesByDate.flatMap((item) => item.calculations);
      // console.log("flattenedData" , flattenedData)

      return {
        model: item.model,
        data: formatStockData(flattenedData),
        highestHigh: findHighLow(item.data).highestHigh,
        lowestLow: findHighLow(item.data).lowestLow,
        averagesByDate, 
      };
    });
  }, [stockData]);



  // let chartOptions = [];

  //     let allYValues;
  //     let defaultMax;
  //     let defaultMin;
  //     seriesData.forEach((currentItem) => {
  //       allYValues = currentItem.data.flatMap(point => point.y);
  //       defaultMin = Math.min(...allYValues) - 0.002;
  //       defaultMax = Math.max(...allYValues) + 0.002;
  //       console.log("seriesData" , seriesData)
  //       // console.log("defaultMin" ,defaultMin)
  //       // console.log("defaultMax" ,defaultMax)

  //       const chartOption = {
  //         chart: {
  //           type: "candlestick",
  //           zoom: {
  //             enabled: true,
  //           },
  //         },
          
  //         xaxis: {
  //           type: "category",
  //         },
  //         yaxis: {
  //           tooltip: {
  //             enabled: false,
  //           },
  //           labels: {
  //             formatter: function (val) {
  //               return val !== undefined ? val.toFixed(3) : "";
  //             },
  //           },
  //           min: defaultMin,
  //           max: defaultMax,
  //           tickAmount: 10,
  //         },
          
  //         annotations: {
  //           yaxis: [
  //             {
  //               y: 0.000,
  //               strokeDashArray: 0,
  //               borderColor: '#007bff', // blue color 
  //               borderWidth: 2,
  //               // borderDash: [], 
  //               // fillColor: '#FEB019',
  //               label: {
  //                 borderColor: '#00E396',
  //                 style: {
  //                   color: '#fff',
  //                   background: '#00E396'
  //                 },
  //                 // text: 'base line at 0.000'
  //               }
  //             }
  //           ],
      
  //           xaxis:[
  //             {x: date,
  //               strokeDashArray: 0,
  //               borderColor: '#007bff', // blue color 
  //               borderWidth: 2,
  //               // borderDash: [], 
  //               // fillColor: '#FEB019',
  //               label: {
  //                 borderColor: '#00E396',
  //                 style: {
  //                   color: '#fff',
  //                   background: '#00E396'
  //                 },
  //                 // text: 'base line at 0.000'
  //               }
  //             }
  //           ]

  //         }

  //       };
        
      
  //       chartOptions.push(chartOption);


  // });
  let chartOptions = [];

  let allYValues;
  let defaultMax;
  let defaultMin;
  seriesData.forEach((currentItem) => {
    console.log("currentItem" , currentItem)
    allYValues = currentItem.data.flatMap(point => point.y);
    // console.log("allYValues" , allYValues)
    defaultMin = Math.min(...allYValues) - 0.002;
    defaultMax = Math.max(...allYValues) + 0.002;
    
  
    const chartOption = {
      chart: {
        type: "candlestick",
        zoom: {
          enabled: true,
        },
      },
      
      xaxis: {
        type: "category",
        labels: {
          style: {
            fontSize: "14px", // Increase the font size of X-axis labels
            fontWeight: "bold", // Optional: Make it bold
            fontFamily : "Open Sans"
          },
        },
        
      },
      yaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: function (val) {
            return val !== undefined ? val.toFixed(3) : "";
          },
          style: {
            fontSize: "14px", 
            fontWeight: "bold", 
             fontFamily : "Open Sans"
          },
        },
        min: defaultMin,
        max: defaultMax,
        tickAmount: 10,
        tickValues: [0.000]
       
      },
      annotations: {
        yaxis: [
          {
            y: 0.000,
            
            strokeDashArray: 0,
            borderColor: '#007bff', // blue color 
            borderWidth: 2,
           
            label: {
              
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396'
              },
              text: '0.000'
            }
          }
        ],
        xaxis: [] 
      }
    };
  
    const dates = [];
    currentItem.data.forEach((point, index) => {
      // const date = point.x.split(' ')[0];
      const date = point.x.split(',')[0].trim();
      const time = point.x.split(' ')[1]; 
      if (!dates.includes(date)) {
        dates.push(date);
        console.log("date---------------->" , date)
        chartOption.annotations.xaxis.push({
          x: point.x,
          borderColor: '#007bff', 
          borderWidth: 1,
          borderDash: [], 
          label: {
            position: 'top', 
            // offsetY: -10,
            offsetX : 22,         // position right align 
            borderColor: '#000',
            style: {
              color: '#fff',
              fontSize: '14px', 
              text:"bold",
              background: '#000'
            },
            text: `${date}`
          }
        });
        
      }
    });
    
    chartOptions.push(chartOption);
  });
//   console.log("seriesData" , seriesData)

  const handleGraphClick = (index) => {
    setFullScreenIndex(index);  // Set the clicked graph to full screen
  };

  const handleCloseFullScreen = () => {
    setFullScreenIndex(null);  // Reset to hide full-screen
  };

  return (
    <>
      <div className="flex flex-col text-[#170E32] mx-[4%] pl-[4px]">
        <h1 className="font-bold text-start mx-5 mt-5 text-[20px] sm:text-[20px] md:text-[24px] lg:text-[24px] xl:text-[24px]">
        {/* Here are the charts of country: */}
        Candlestick charts for :
          <span className="font-semibold "> {eventname}</span>  
         
       
        </h1>
        <h1 className="font-semibold text-start mx-5 text-[18px]">Country :  <span className="font-bold"> {countryname}</span></h1>
      </div>
      <div className="font-bold justify-center items-center text-[20px] w-[100%] flex text-[#170E32]">{noDataAvailable && <p>No data available for this country.</p>}</div>
      <div className="max-w-[100%] mx-[4%] p-0 sm:p-0 md:p-0 lg:p-4 xl:p-4">
        {/* {loading ? (
          <div>
            <div className="font-semibold m-6 font-xl text-center flex justify-center items-center ">
              Wait for charts .....
            </div>
            <div className="flex items-center justify-center">
              <Bars
                height="80"
                width="80"
                color="#94A3B8"
                ariaLabel="bars-loading"
                visible={true}
                className="loader"
              />
            </div>
          </div>
        ) : (
          fullScreenIndex === null ?(
            <div className="flex flex-wrap -mx-2">
            {seriesData.map((series, index) => (
            
              <div key={index} className="w-full md:w-1/2 p-2">
                <div className="border rounded shadow p-4">
                  <h2 className="text-center mb-4">{series.model}</h2>

                 
                  <ReactApexChart
                  series={[{ data: series.data }]}
                    options={chartOptions[index]}
                    // options={basicOptions}
                    type="candlestick"
                    height={800}
                    ref={(el) => (chartRefs.current[index] = el)}
                  />
                </div>
              </div>
            ))}
          </div>
          )
          :(
            <div className="fixed inset-0 bg-white z-50 flex flex-col items-center justify-center">
            <button
              className="absolute top-4 right-4 bg-red-500 text-white p-2 rounded"
              onClick={handleCloseFullScreen}
            >
              Close
            </button>
            <h2 className="text-center mb-4">{seriesData[fullScreenIndex].model}</h2>
            <ReactApexChart
              series={[{ data: seriesData[fullScreenIndex].data }]}
              options={chartOptions[fullScreenIndex]}
              type="candlestick"
              height={800}  // Fullscreen height
              ref={(el) => (chartRefs.current[fullScreenIndex] = el)}
            />
          </div>
          )
        
         
        )
        } */}
        {loading ? (
  <div>
    <div className="font-semibold m-6 font-xl text-center flex justify-center items-center">
      Wait for charts .....
    </div>
    {/* <div className="flex items-center justify-center"> */}
      {/* <Bars
        height="80"
        width="80"
        color="#94A3B8"
        ariaLabel="bars-loading"
        visible={true}
        className="loader"
      /> */}
       

       <div className="loader-container w-full flex justify-center h-[100vh]">
        {/* <h1>404 Error</h1>
        <p>Oops! Page not found.</p> */}
        <img
        src={loader_video}
        title="loader"
        alt="loader"
        className="w-[300px] h-[300px]"
        // width={500}
        // height={600}
        autoPlay
        loop
        muted
    />

      </div>  
    {/* </div> */}

  </div>
) : (
  fullScreenIndex === null ? (
    <div className="flex flex-wrap ">
      {seriesData.map((series, index) => (
        <div key={index} className="w-full md:w-1/2 p-2 px-4 ">
          <div className="bg-white rounded-md  p-4" onClick={() => handleGraphClick(index)}>
            <h2 className="text-center mb-4 font-semibold text-[24px]">{series.model}</h2>
            <ReactApexChart
              series={[{ data: series.data }]}
              options={chartOptions[index]}
              type="candlestick"
              height={800}
              ref={(el) => (chartRefs.current[index] = el)}
            />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <>
     <button
        className=" bg-[#170E32] flex justify-center items-center absolute top-[7%] md:top-[6%] right-[5%]  text-white py-2 px-6  rounded-md font-bold text-[13px] md:text-[20px] mb-5 "
        onClick={handleCloseFullScreen}
      >
        <BsArrowLeft className="mr-2"/>
        Back
      </button>
      <div className=" mt-5 inset-0 bg-white z-50 flex flex-col items-center justify-center">
     
     <h2 className="text-center mb-4 font-semibold">{seriesData[fullScreenIndex].model}</h2>
     <div className="w-[100%] min-w-[100%]">

     <ReactApexChart
       series={[{ data: seriesData[fullScreenIndex].data }]}
       options={chartOptions[fullScreenIndex]}
       type="candlestick"
       height={800}  // Fullscreen height
       ref={(el) => (chartRefs.current[fullScreenIndex] = el)}
     />
     </div>
   </div>
      
      </>
   
  )
)}

      </div>
    </>
  );
};

export default CandleUser;

