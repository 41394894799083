
import React, { useState, useEffect } from "react";
// import UserNavbar from "./userNavbar";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Filtericon from "../../assets/filter.png";
import { Select, Space } from "antd";
// import  from "../Table1";
import { useNavigate } from "react-router-dom";
import AllUsersTable from "./allusersTable";
import UsersTradedata from "./usersTradedata";



const AdminHeader = () => {
const navigate = useNavigate();
const [clickedOn, setClickedOn] = useState('All users');
const handleAllUsers = () => {
    setClickedOn('All users');
  };

  const handleUsersTrades = () => {
    setClickedOn('Users trades');
  };




  return (
   <>
    <div className="flex flex-row h-[140px]">
          <div className="w-[100%] ">
            <div className="mt-[80px] w-[100%]">
              <div className="">
                <div className="flex z-[999] flex-row w-[100%] bg-slate-400 justify-between p-3 fixed ">
                  <div>
                    <button className={` text-white hover:text-white `}>
                      <div className="flex items-center w-50 justify-center">
                        <img
                          src={Filtericon}
                          alt="Filter Icon"
                          className="h-6 w-6 mr-2"
                        />
                        <span className="text-lg font-bold">Filter</span>
                      </div>
                    </button>
                  </div>

                  <div className="second-div flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row items-center justify-center mt-5 sm:mt-5 md:mt-5 lg:mt-5 xl:mt-0 ml-[-6%] sm:ml-[-6%] md:ml-0 lg:ml-0 xl:ml-0">
                      <div className="flex">
                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-3">
                          <button
                            type="button"
                            onClick={handleAllUsers}
                            className={`inline-flex  justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75  ${
                              clickedOn === "All users" ?  "bg-slate-500" : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                           All users
                          </button>
                        </div>

                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-3">
                        <button
                            type="button"
                            onClick={handleUsersTrades}
                            className={`inline-flex  justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                              clickedOn === "Users trades" ? "bg-slate-500" : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                           Users trades
                          </button>
                        </div>
                      </div>

                    
                    </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>


        <div>
        {clickedOn === 'All users' ? <AllUsersTable /> : <UsersTradedata />}
      </div>



   </>
  )
}

export default AdminHeader
