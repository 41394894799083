// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// const ProtectedRouteUser = (props) => {
//   const { Component } = props;
//   const navigate = useNavigate();
//   useEffect(() => {
//     // let isAuthenticated = localStorage.getItem("token");
//     let isAuthenticated =  localStorage.getItem("usertoken");

//     console.log("isAuthenticated" , isAuthenticated)
//     if (!isAuthenticated) {
//       navigate("/");
//     }
//   }, []);

//   return (
//     <>
//       <Component />
//     </>
//   );
// };

// export default ProtectedRouteUser;



 import React from "react";
import axios from "axios";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

const ProtectedRouteUser = (props) => {
    const { Component } = props;

  const navigate = useNavigate();

  const verifytokenexists = async () => {
    
    try {
        // console.log("I am in verifytokenexists Try_sam");    
      const tokenfromls = localStorage.getItem("usertoken");
      // console.log("tokenfromls" , tokenfromls)

      const id = localStorage.getItem("userregisterId");
      const h = { authorization: tokenfromls };
      // console.log(h);
      if (tokenfromls) {
        const res = await axios.get(`
          ${process.env.REACT_APP_URL}/protect/userprotectedroute`,
          { headers: h }
        );
        // console.log("my resp", res);
       
        if (res) {
          // console.log(res.data , "jloashissh")
          if (res.data.m != id) {
            navigate("/");
          }
        }
      } 
      else{
        navigate("/");
      }
    } catch (e) {
        console.log("I am in verifytokenexists catch_sam");
      console.log("Error -> ",e)
      if(e.response&&e.response.status===401||e.response&&e.response.status===400){
        navigate("/");
      }
    
    }
  };

  useEffect(() => {
    // console.log("I am in useeffect_sam")
    verifytokenexists();
  }, []);

  return <div> <Component /></div>; 
{/* <Component /> */}
};

export default ProtectedRouteUser; 