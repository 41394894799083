import React, { useEffect, useState , useRef } from "react";
import { useNavigate } from "react-router-dom";
import NavLink from "../Navlink";
import logo from "../../assets/altrafxlogo.png";
import { FaRegHandshake } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import logout from "../../assets/Group.png"
import logout_eye_btn from "../../assets/IBUTTON.png";
import chat_btn from "../../assets/community.png"
// import brokerageicon from "../../assets/brokerage.png"
import usericon from "../../assets/Profile.png"
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
const UserNavbar = () => {
const navigate = useNavigate();
const [brokermodal , setbroker_modal] = useState(false);
const [broker , setbroker]= useState("");
const [brokerid , setbrokerid]= useState("");
const [brokerpassword , setBrokerpassword]= useState("");
const [username , setusername]= useState("")
const [showPassword, setShowPassword] = useState(false);
const [showDropdown, setShowDropdown] = useState(false);
const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const dropdownRef = useRef(null); // Ref for dropdown container
const [modalShow, setModalshow] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // Close dropdown when clicking or tapping outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    // Listen for mouse and touch events
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    // Cleanup the event listeners
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const handleModalShowClick = () => {
    setModalshow(true);
  };
  const handleLogout = () => {
    Object.keys(localStorage).forEach((key) => {
      if (
          key.includes("Clickeddate_data_") || 
          key.includes("table3_historyData_") || 
          key.includes("clickedmodal_data_2ndpage_")
      ) {
          localStorage.removeItem(key);
      }
  });
    localStorage.removeItem("selected_trade_events");
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userregisterId");
    localStorage.removeItem("username");
    localStorage.removeItem("4GraphDataobjects");
    localStorage.removeItem("username");
    
    localStorage.removeItem("lastDeletionTime");
    
    localStorage.removeItem("userEmail");
    localStorage.removeItem("token");
    localStorage.removeItem("adminregisterId");
    localStorage.removeItem("lastDeletionTime");
    localStorage.removeItem("All_events_history");
    localStorage.removeItem("UserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedtime");
    localStorage.removeItem("eventnameandcountry:");
    localStorage.removeItem("userregisterId");
    localStorage.removeItem("username");
    localStorage.removeItem("usertoken");
    localStorage.removeItem("todayDate");
    localStorage.removeItem("previousDate_u_search_history");
    localStorage.removeItem("adminregisterId");
 
    localStorage.removeItem("todayDate");
    navigate("/");
  };

const handlebrokermodal =()=>{
  setbroker_modal(true);
}

const closebrokermodal =()=>{
  setbroker_modal(false);
 
}

const handleCommunityChat = ()=>{
  window.open("/user/community_chat", "_blank");
}
const submitbroker_data = async (e) => {
  e.preventDefault();
 
  const tokenfromls = localStorage.getItem("usertoken");
  const h = { authorization: tokenfromls };
 
  const userId = localStorage.getItem('userregisterId');
  if (!userId) {
      toast.error("User ID not found. Please login again.", { position: "top-right" });
      return;
  }


  
  if (!broker || !brokerid || !brokerpassword) {
    toast.error("Please fill all inputs first", {
      position: "top-right",
    });
    return;
  }

  
  try {
    const tokenfromls = localStorage.getItem("usertoken");
        const h = { authorization: tokenfromls };
      const response = await axios.post(
          `${process.env.REACT_APP_URL}/user/userbrokerdata/${userId}`,
          
      {
         broker , brokerid , brokerpassword
      },
      {
        headers: {
          "Content-Type": "application/json", ...h
        },
        validateStatus: (status) => {
          return status >= 200 && status < 500;  // Accept all status codes between 200 and 499
        },
      } 
    );

    setBrokerpassword("");
      setbroker("");
      setbrokerid("");
      closebrokermodal()
      toast.success("Registration successful!", { position: "top-right" });
      // console.log("User registration successful ", response);
      window.location.reload()
      
  } 
  catch (error) {
    console.log("hlo am in catch")
    console.error("Error during sending data:", error);
  
    toast.error("Error in saving data, please fill all fields", {
      position: "top-right",
    });
  }
};



useEffect(()=>{
const loginusername = localStorage.getItem("username")
setusername(loginusername);
},[])



  return (
    <nav className="bg-[#151841] p-4 fixed top-0 w-full z-50">
      <div className=" flex justify-between items-center">
        <div className="flex items-center">
        <NavLink to="/user/economicdata">
          <img src={logo} alt="Logo" className="h-8 w-26 sm:h-8 md:h-8 lg:h-12 xl:h-12   pl-2 sm:pl-2 md:pl-10 lg:pl-10 xl:pl-10 " />
          </NavLink>
       
        </div>

        <div className="relative" ref={dropdownRef}>
       <div className="flex items-center justify-center cursor-pointer border-[#A0A0A0] border-opacity-[35%] border-0 sm:border-0 md:border-2 p-3 rounded-[18px]"  onClick={toggleDropdown}>
         <div className="flex mr-0 sm:mr-0 md:mr-0 lg:mr-0 xl:mr-0">
                          <img
                          src={usericon}
                          alt="user_icon"
                          className="h-7 w-7 mr-0 md:mr-2"
                          
                         
                        />
                        
                       
                        <h1 className="hidden sm:hidden md:block lg:block xl:block text-[18px] font-semibold text-[#F6F6F6] mr-1 ml-2 ">  
                        {username}
                        </h1>
                        {
                          isDropdownOpen ?    <MdKeyboardArrowUp className="text-[#F6F6F6] mr-4 w-6 h-6 mt-[1px]" />
                           :  <MdKeyboardArrowDown className="text-[#F6F6F6] mr-4 w-6 h-6 mt-[1px]"/>
                        }
                        </div>


        

        </div>
    
      {isDropdownOpen && (
        <div className="absolute right-[-4px] mt-[12px] w-[206px] h-[55px] bg-white  z-10 text-center border-white rounded-[8px]">
         <div className="relative bg-white rounded-[8px]">
         <div
            className="flex items-center gap-3 px-2 pb-3 pt-4 cursor-pointer  justify-start border-b "
            // onClick={handleLogout}
            onClick={handleCommunityChat}
          >
            {/* <MdOutlineLogout className="text-gray-600" /> */}
            <img src={chat_btn} alt="logout" className="h-[34px] w-[73px] pl-9" />
            <span className="text-[#170E32] font-medium"> Chat</span>
          </div>
         <div
            className="flex items-center gap-3 px-2 pb-3 pt-4 cursor-pointer  justify-start border-b rounded-[8px]"
            // onClick={handleLogout}
            onClick={handleModalShowClick}
          >
            {/* <MdOutlineLogout className="text-gray-600" /> */}
            <img src={logout} alt="logout" className="h-[26px] w-[68px] pl-10" />
            <span className="text-[#170E32] font-medium">Logout</span>
          </div>
        
          <div className="absolute right-[13px] top-[-9px] w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-white"></div>
          </div>


        </div>
      )}
    </div>

       </div>
  
       <div>
        {modalShow && (
          <div
            id="popup-modal"
            className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center bg-black bg-opacity-15 z-50"
            onClick={() => setModalshow(!modalShow)}
          >
            <div className="relative p-4 w-full max-w-md">
              <div className="relative bg-white rounded-lg shadow">
                
                <img
                  src={logout_eye_btn}
                  alt="logout_eye_btn"
                  className="h-[33px] w-[33px] absolute top-[12%] left-[47%]"
                />
                <div className="p-4 md:p-5 text-center">
                 

                  <h3 className="mb-5 mt-[15%] text-lg font-bold text-black">
                  Are you sure you want to Logout ?
                  </h3>
                  <div className="flex items-center justify-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="text-[#151841]  bg-[#ecebeb] mt-3 font-medium rounded-lg  inline-flex items-center justify-center px-3 py-2 w-[20%]"
                      onClick={handleLogout}
                    >
                      Yes
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="ml-4 text-white  bg-[#151841] mt-3 font-medium rounded-lg  inline-flex items-center justify-center px-3 py-2 w-[20%]"
                      onClick={() => setModalshow(!modalShow)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

    </nav>
  );
};

export default UserNavbar;
