import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import { Bars, Hourglass } from "react-loader-spinner";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import excelicon from "../assets/excel.png";

const MatchTables = () => {
  const [uniquedata, setuniquedata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isloading, setisLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const [selectedOptions, setSelectedOptions] = useState(
    new Array(uniquedata.length).fill("")
  );

  const onValueChange = (rowIndex, event) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[rowIndex] = event.target.value;
    setSelectedOptions(newSelectedOptions);
  };

  //  <-----------its basically watching our last element on the page----------->
  const lastDataElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const matcheddata = async (page) => {
    setisLoading(true);
    try {
      // const tokenfromls = localStorage.getItem("token");
      //   const h = { authorization: tokenfromls };
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/matcheddata/getstored`,
        { params: { page, limit: 30 } }
      );
      // console.log("respons" , response.data)
      setuniquedata((prevData) => [...prevData, ...response.data]);
      setHasMore(response.data.length > 0);
      setLoading(false);
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setisLoading(false);
    }
  };

  useEffect(() => {
    matcheddata(page);
  }, [page]);


  //  // Function to generate CSV and trigger download
  const downloadCSV = () => {
    const csvData = uniquedata.map((item) => {
      const eventRow = {
        "Event Date": item.eventDate,
        "Event Currency": item.eventCurrency,
        "Event Name": item.eventName,
        "3 Words Match": item.ukInvestingDatamatch3words
          .map((match) => match.ukinvestingeventName)
          .join("; "),
        "2 Words Match": item.ukInvestingDatamatch2words
          .map((match) => match.ukinvestingeventName)
          .join("; "),
        "Exact Match": item.ukinvestingexactmatchdata
          .map((match) => match.ukinvestingeventName)
          .join("; "),
        "Final Selection": selectedOptions[uniquedata.indexOf(item)],
      };
      return eventRow;
    });

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "fianlmatcheddata.csv");
  };

  return (
    <>
      <Navbar />
      {loading && page === 1 ? (
        <div className="mt-[25%] sm:mt-[25%] md:mt-[20%] lg:mt-[20%] xl:mt-[20%]">
          <div className="font-semibold m-6 font-xl text-center flex justify-center items-center h-[50%]">
            Wait !! Data is in Progress .....
          </div>
          <div className="flex items-center justify-center w-screen">
            <Bars
              height="80"
              width="80"
              color="#94A3B8"
              ariaLabel="bars-loading"
              visible={true}
            />
          </div>
        </div>
      ) : (
        <div className="container mx-auto mt-[10vh]">
          <div className="w-100 flex justify-end  mt-5">
            <img
              src={excelicon}
              onClick={downloadCSV}
              title="Download this data in excel"
              alt="Download"
              width={50}
              height={50}
              className="mt-10 cursor-pointer"
            />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full border rounded m-auto mt-2">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Event Date
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Event Currency
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Event Name
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    3 Words Match
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    2 Words Match
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Exact Match
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Final Selection
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {uniquedata.map((item, rowIndex) => (
                  <React.Fragment key={item._id}>
                    <tr className="hover:bg-gray-100 text-center border-b border-gray-300">
                      <td
                        className="py-2 px-4 border-gray-300 border-r border-b"
                        rowSpan={item.ukInvestingDataalladata.length}
                      >
                        {item.eventDate}
                      </td>
                      <td
                        className="py-2 px-4 border-gray-300 border-r border-b"
                        rowSpan={item.ukInvestingDataalladata.length}
                      >
                        {item.eventCurrency}
                      </td>
                      <td
                        className="py-2 px-4 border-gray-300 border-r border-b"
                        rowSpan={item.ukInvestingDataalladata.length}
                      >
                        {item.eventName}
                      </td>
                    </tr>

                    {item.ukInvestingDataalladata.map((ukEvent, index1) => (
                      <tr
                      key={index1}
                        className="text-center"
                      >
                        {index1 === 0 ? (
                          <>
                            <td className="border-gray-300 border-r">
                              {item.ukInvestingDatamatch3words.map(
                                (match, matchIndex) => (
                                  <div
                                   key={`${item._id}-match3words-${matchIndex}`} 
                                    className="flex items-center justify-center"
                                  >
                                    <span>{match.ukinvestingeventName}</span>
                                    <input
                                      type="radio"
                                      className="ml-2"
                                      value={match.ukinvestingeventName}
                                      checked={
                                        selectedOptions[rowIndex] ===
                                        match.ukinvestingeventName
                                      }
                                      onChange={(event) =>
                                        onValueChange(rowIndex, event)
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </td>
                            <td className="border-gray-300 border-r">
                              {item.ukInvestingDatamatch2words.map(
                                (match1, matchIndex) => (
                                  <div
                                    // key={matchIndex}
                                    key={`${item._id}-match2words-${matchIndex}`}
                                    className="flex items-center justify-center"
                                  >
                                    <span>{match1.ukinvestingeventName}</span>
                                    <input
                                      type="radio"
                                      className="ml-2"
                                      value={match1.ukinvestingeventName}
                                      checked={
                                        selectedOptions[rowIndex] ===
                                        match1.ukinvestingeventName
                                      }
                                      onChange={(event) =>
                                        onValueChange(rowIndex, event)
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </td>
                            <td className="border-gray-300 border-r">
                              {item.ukinvestingexactmatchdata.map(
                                (match2, matchIndex) => (
                                  <div
                                    // key={matchIndex}
                                    key={`${item._id}-exactmatch-${matchIndex}`}
                                    className="flex items-center justify-center"
                                  >
                                    <span>{match2.ukinvestingeventName}</span>
                                    <input
                                      type="radio"
                                      className="ml-2"
                                      value={match2.ukinvestingeventName}
                                      checked={
                                        selectedOptions[rowIndex] ===
                                        match2.ukinvestingeventName
                                      }
                                      onChange={(event) =>
                                        onValueChange(rowIndex, event)
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </td>
                            <td>
                              <div>{selectedOptions[rowIndex]}</div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="border-gray-300 border-r"></td>
                            <td className="border-gray-300 border-r"></td>
                            <td className="border-gray-300 border-r"></td>
                          </>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
                {loading && (
                  <tr>
                    <td colSpan="7" className="py-2 px-4 text-center">
                      Loading...
                    </td>
                  </tr>
                )}
                {uniquedata.length > 0 && !loading && (
                  <tr
                    ref={lastDataElementRef}
                    className="text-center border-b border-gray-300"
                  >
                    <td colSpan="7">More data loading.....</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {loading && (
            <div className="flex justify-center mt-8">
              <Bars
                height="50"
                width="50"
                color="#94A3B8"
                ariaLabel="bars-loading"
                visible={true}
              />
            </div>
          )}
          {isloading && (
            <div className="flex justify-center my-4">
              <Hourglass
                visible={true}
                height="20"
                width="20"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                className="mb-10"
                colors={["#94A3B8", "#94A3B8"]}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MatchTables;
