import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { LineWave } from "react-loader-spinner";
import AdminTest from "../components/admin/adminTest"



const Adminbuysell = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSell, setLoadingSell] = useState(false);

  const [iframeHeight1, setIframeHeight1] = useState("46vh");
  const [iframeHeight2, setIframeHeight2] = useState("46vh");
  const handleClick = async (value) => {
    try {
      if (value === "buy") {
        setLoading(true);
      } else {
        setLoadingSell(true);
      }
      const userid = localStorage.getItem("adminregisterId");
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/postbuysell`,
        {
          userid: userid,
          value: value,
        }
      );

      console.log(response);
      if (value === "buy") {
        setLoading(false);
      } else {
        setLoadingSell(false);
      }
      toast.success(`${value} command send successfully!`, {
        position: "top-right",
      });
    } catch (error) {
      console.error("Error posting buy/sell data:", error);
      toast.error(`Error in sending ${value} command!`, {
        position: "top-right",
      });
      if (value === "buy") {
        setLoading(false);
      } else {
        setLoadingSell(false);
      }
    }
  };
  return (
    <>
      <div className="flex justify-center items-center mt-2">
        <button
          className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${
            loading ? "opacity-50 cursor-not-allowed disabled" : ""
          }`}
          onClick={() => handleClick("buy")}
        >
          Buy
        </button>
        <button
          className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 m-2 ${
            loadingSell ? "opacity-50 cursor-not-allowed disabled" : ""
          }`}
          onClick={() => handleClick("sell")}
        >
          Sell
        </button>
      </div>



<div className="flex space-x-4 chart_button mt-5 justify-center items-center">
          <button
            onClick={() => setIframeHeight1("13vh")}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Reduce Height
          </button>
          <button
            onClick={() => setIframeHeight1("46vh")}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Reset Height
          </button>
        </div>

      <div className="flex justify-center items-center mt-1">
        <iframe
        
          title="Trading economics"
          src="https://tradingeconomics.com/calendar"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          className=" block    border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[60vw] rounded-2xl"
          style={{ height: iframeHeight1 }}
        ></iframe>
      </div>


      <div className="flex space-x-4 chart_button mt-3 justify-center items-center">
          <button
            onClick={() => setIframeHeight2("13vh")}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Reduce Height
          </button>
          <button
            onClick={() => setIframeHeight2("46vh")}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Reset Height
          </button>
        </div>
      <div className="flex justify-center items-center mt-1">
        <iframe
          title="Uk investing"
          src="https://uk.investing.com/economic-calendar/"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          className=" block   border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[60vw] rounded-2xl"
          // style={{border:2px solid #4A4A4A}}
          style={{ height: iframeHeight2 }}
        ></iframe>
        {/* <div>Hello World</div> */}
  {/* <AdminTest/> */}
      </div>
      
    </>
  );
};

export default Adminbuysell;
