import React from 'react'

const AdminTest = () => {
  return (
  <>
    <div className="flex justify-center items-center mt-1">
        <iframe
          title="Trading economics"
          src="https://uk.investing.com/economic-calendar/"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          className=" block   h-[64vh] border-[1px]  border-slate-300  sm:border-[0px]  w-[95vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] xl:w-[60vw] rounded-2xl"
        //   style={{ height: iframeHeight1 }}
        ></iframe>
      </div>
  </>
  )
}

export default AdminTest
