/* eslint-disable react/prop-types */

import { useEffect } from "react";
// import './Design.css'
import error_icon from "../../src/assets/404.mp4"

const Error = ({ setProgress }) => {
//   useEffect(() => {
//     setProgress(10);
//     setProgress(50);
//     setTimeout(() => {
//       setProgress(100);
//     }, 1500);
//   }, []);

  const capitalize = (word) => {
    const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };

//   document.title = ${capitalize("Error-page")} - React_veet;

  return (
    <>
      {/* <Navbar /> */}
      <div className="error-container w-full flex items-center justify-center h-[100vh]">
        {/* <h1>404 Error</h1>
        <p>Oops! Page not found.</p> */}


        <video
        src={error_icon}
        title="404 page"
        alt="404"
        className="w-[500px] h-[600px]"
        width={500}
        height={600}
        autoPlay
        loop
        muted
    />
      </div>
    </>
  );
};

export default Error;