// <---this is the code for charts , here we are showing a box of open, high , low ,close on hover -->

// const formatDateToMMDDYY = (dateStr) => {
//   const date = new Date(dateStr);
//   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
//   const day = date.getDate().toString().padStart(2, '0');
//   const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
//   return `${month}.${day}.${year}`;
// };
// const formatTimeToHHMM = (timeStr) => {
//   const [hours, minutes] = timeStr.split(':');
//   return `${hours}:${minutes}`; // Returns HH:mm format
// };

const formatStockData = (data) => {

  return data.map((quote) => ({
    // x: `${formatDateToMMDDYY(quote.date)}, ${formatTimeToHHMM(quote.time)}`,
    x: `${quote.date}, ${quote.time.slice(0, 5)}`,
    y: [
      parseFloat(quote.openDeviation),
      parseFloat(quote.highDeviation),
      parseFloat(quote.lowDeviation),
      parseFloat(quote.closeDeviation),
    ],
  }));
};

export default formatStockData;
