import React from 'react';
import { Link } from 'react-router-dom'; 
const NavLink = ({ to, children, active }) => {
    const defaultStyle = "text-white hover:text-gray-400 transition duration-300";
    const activeStyle = "border-b-2 border-teal-500";
  
    return (
      <Link
        to={to}
        className={`${defaultStyle} ${active ? activeStyle : ''}`}
      >
        {children}
      </Link>
    );
  };
  
  export default NavLink;