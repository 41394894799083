import React, { useState, useEffect } from "react";
import UserNavbar from "./userNavbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Filtericon from "../../assets/filter.png";
// import { Space } from "antd";
import UserTable from "./userTable";
import savedicon from "../../assets/Bookmark.png";
// import { TbFileDescription } from "react-icons/tb";
import chatbox from "../../assets/chatbox.png";
import { DatePicker } from "antd";
import logout_eye_btn from "../../assets/IBUTTON.png";
import hamburger from "../../assets/slide_left.png";
import hamburger1 from "../../assets/slide_right.png";
import refresh_icon from "../../assets/refresh.png";
import today_img_icon from "../../assets/today.png";
import today_img_icon_active from "../../assets/active_today.png";

import filter_icon from "../../assets/uil_calender.png";
import yesterday_img_icon from "../../assets/yesterday.png";
import yesterday_img_icon_active from "../../assets/active_yesterday.png";

import tomorrow_img_icon from "../../assets/tomorrow.png";
import tomorrow_img_icon_active from "../../assets/active_tomorrow.png";
import filtericon from "../../assets/filters.png";
import next7days_img_icon from "../../assets/next_7_days.png";
import next7days_img_icon_active from "../../assets/active_next_7_days.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import styles from "../tradeall.module.css";
import { DownOutlined } from "@ant-design/icons";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import live_trade_icon from "../../assets/money-exchange.png"

import { Select, Space } from "antd";
// const { RangePicker } = DatePicker;
const { RangePicker } = DatePicker;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const countries = [
  "UK",
  "US",
  "CA",
  "CH",
  "EU",
  "JP",
  "AU",
  "NZ",
  "DE",
  "IT",
  "FR",
];

const UserCalender = () => {
  const [clickedon, setclickedon] = useState("today");
  const [errorMessage, setErrorMessage] = useState("");
  const [maindata, setmaindata] = useState([]);
  // const [clickedend, setclickedend] = useState("todaydata");
  const [loading, setLoading] = useState(true);
  const [clickedButton, setClickedButton] = useState("Today");
  const [hideEstimate, setHideEstimate] = useState(false);
  // const [isReloadLoading, setIsReloadLoading] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // const [selectedCountries, setSelectedCountries] = useState([]);
  const { Option } = Select;
  const [selectedImpacts, setSelectedImpacts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCountry(typeof value === "string" ? value.split(",") : value);
  };

  // const handleImpactChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setSelectedImpacts(typeof value === "string" ? value.split(",") : value);
  // };

  const handleImpactChange = (values) => {

    setSelectedImpacts(values)
  }
  const handleCountryChange = (values) => {
   
    setSelectedCountry(values);
 
   
  
  };
 
  // <<-------for today , yesterday , tomorrow , and next week data on screen -------->>

  const handleTodayClick = async () => {
    // console.log("hi today api git hit")
    setLoading(true);

    const tokenfromls = localStorage.getItem("usertoken");

    const h = { authorization: tokenfromls };
    const endpoint = `${process.env.REACT_APP_URL}/todaydata`;
    try {
      // console.log("hi today try")

      const response = await axios.get(endpoint, { headers: h });
      let filteredData = response.data;
      // console.log("filteredData" , filteredData)
      setClickedButton("Today");
      //  return;
      setmaindata(filteredData.uniqueEvents);
      setLoading(false);
      setIsVisible(false);
      setDateRange(null);
      setclickedon("today");
      // setIsVisible(false);
      // setclickedend("todaydata");
    } catch (error) {
      setLoading(false);
      setClickedButton("Today");

      setclickedon("today");
      // setclickedend("todaydata");

      if (error.response && error.response.status === 400) {
        setErrorMessage(errorMessage);
        setmaindata([]);
      } else {
        const errorMessage = "Error fetching data. Please try again later.";
        setErrorMessage(errorMessage);
      }
      console.error("Error fetching data:", error);
    }
  };
  const handleYesterdayClick = async () => {
    setLoading(true);
    const tokenfromls = localStorage.getItem("usertoken");

    const h = { authorization: tokenfromls };
    const endpoint = `${process.env.REACT_APP_URL}/yesterdaydata`;
    try {
      const response1 = await axios.get(endpoint, { headers: h });

      let filteredData = response1.data;
      setClickedButton("Yesterday");
      setmaindata(filteredData.uniqueEvents);
      setLoading(false);
      setIsVisible(false);
      setDateRange(null);
      setclickedon("yesterday");
      // setclickedend("yesterdaydata");
    } catch (error) {
      setLoading(false);
      setClickedButton("Yesterday");

      setclickedon("yesterday");
      // setclickedend("yesterdaydata");

      if (error.response && error.response.status === 400) {
        setErrorMessage(errorMessage);
        setmaindata([]);
      } else {
        const errorMessage = "Error fetching data. Please try again later.";
        setErrorMessage(errorMessage);
      }
      console.error("Error fetching data:", error);
    }
  };
  const handleTomorrowClick = async () => {
    // console.log("hi am tomo clicked api")
    setLoading(true);
    setErrorMessage("");

    const tokenfromls = localStorage.getItem("usertoken");

    const h = { authorization: tokenfromls };
    const endpoint = `${process.env.REACT_APP_URL}/tomorrowdata`;
    try {
      // console.log("hi am in try tomorrow")

      const response2 = await axios.get(endpoint, { headers: h });

      const filteredData = response2.data;
      // console.log("tom result---------------", filteredData)
      // return;
      setClickedButton("Tomorrow");
      setmaindata(filteredData.uniqueEvents);
      setLoading(false);
      setIsVisible(false);
      setclickedon("tomorrow");
      // setclickedend("tomorrowdata");
      setDateRange(null);
    } catch (error) {
      setLoading(false);
      setClickedButton("Tomorrow");
      setclickedon("tomorrow");
      // setclickedend("tomorrowdata");

      if (error.response && error.response.status === 400) {
        setErrorMessage(errorMessage);
        setmaindata([]);
      } else {
        const errorMessage = "Error fetching data. Please try again later.";
        setErrorMessage(errorMessage);
      }
      console.error("Error fetching data:", error);
    }
  };
  const handleweekClick = async () => {
    setLoading(true);
    const tokenfromls = localStorage.getItem("usertoken");

    const h = { authorization: tokenfromls };
    // setclickedend("nextdaysdata");
    const endpoint = `${process.env.REACT_APP_URL}/nextdaysdata`;
    try {
      const response3 = await axios.get(endpoint, { headers: h });
      // console.log("response3" , response3)
      // const flattenedData = Array.isArray(response3.data)
      //   ? response3.data.uniqueEvents.flatMap((innerArray) => innerArray)
      //   : [];
      const flattenedData = response3.data.uniqueEvents;
      // console.log("flattenedData" , flattenedData)
      setClickedButton("Next7Days");
      // setmaindata(flattenedData);
      setmaindata(flattenedData);

      setclickedon("nextdays");
      // setclickedend("nextdaysdata");
      setLoading(false);
      setIsVisible(false);
      setDateRange(null);
    } catch (error) {
      setLoading(false);
      setClickedButton("Next7Days");
      setclickedon("nextdays");
      // setclickedend("nextdaysdata");

      if (error.response && error.response.status === 400) {
        setErrorMessage(errorMessage);
        setmaindata([]);
      } else {
        const errorMessage = "Error fetching data. Please try again later.";
        setErrorMessage(errorMessage);
      }
      console.error("Error fetching data:", error);
    }
  };

  // const handlelast4monthsClick = async() => {
  //   setLoading(true);
  //   // console.log("hlo am clicked");

  //   const tokenfromls = localStorage.getItem("usertoken");
  //   const h = { authorization: tokenfromls };

  //   const endpoint = `${process.env.REACT_APP_URL}/last4monthsdata`;
  // try{
  // const response = await axios.get(endpoint, { headers: h })

  // let filteredData = response.data;
  // // setClickedButton("Tomorrow");
  // // setmaindata(filteredData.uniqueEvents);
  // // setLoading(false);

  // // setclickedon("tomorrow");
  // // console.log("filteredData" , filteredData)
  // setmaindata(filteredData.uniqueEvents);
  // setLoading(false);
  // // setfinalfiltereddata(filteredData)
  // setClickedButton("Last4months");
  // setclickedon("last4months");

  // setDateRange(null);
  // // setmaindata(filteredData);
  // }
  // catch(error) {
  //       setClickedButton("Last4months");
  //       setclickedon("last4months");

  //       setLoading(false);
  //       if (error.response && error.response.status === 400) {
  //         setErrorMessage(errorMessage);
  //         setmaindata([]);
  //       } else {
  //         const errorMessage = "Error fetching data. Please try again later.";
  //         setErrorMessage(errorMessage);
  //       }
  //       console.error("Error fetching data:", error);
  //     };
  // };

  const handleRefresh = () => {
    // setIsReloadLoading(true);
    setLoading(false);
    // Loop through localStorage and remove any item with "Clickeddate_data_"
    Object.keys(localStorage).forEach((key) => {
      if (
        key.includes("Clickeddate_data_") ||
        key.includes("table3_historyData_") ||
        key.includes("clickedmodal_data_2ndpage_")
      ) {
        localStorage.removeItem(key);
      }
    });

    localStorage.removeItem("All_events_history");
    localStorage.removeItem("lastDeletionTime");
    localStorage.removeItem("UserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedtime");
    localStorage.removeItem("4GraphDataobjects");
    // localStorage.removeItem("userregisterId");
    // localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("todayDate");
    localStorage.removeItem("previousDate_u_search_history");
    localStorage.removeItem("eventnameandcountry:");

    localStorage.removeItem("selectedtime");
    localStorage.removeItem("userEmail");
    // localStorage.removeItem("previousDate_u_search_history");
    // localStorage.removeItem("previousDate_u_search_history");

    // window.location.reload();
  };

  const [modalShow, setModalshow] = useState(false);

  const handleModalShowClick = () => {
    setModalshow(true);
  };
  useEffect(() => {
    handleTodayClick();
  }, []);

  // <----------impact and country filters---------->

  // const handleCountryChange = (values) => {
  //   // console.log("hlo am in country filter")
  //   // setLoading(true);
  //   setSelectedCountry(values);
  //   // console.log("values.length" )
  // };

  const handleDateChange = async (dates, dateStrings) => {
    setLoading(true);
    setDateRange(dates);
    // const tokenfromls = localStorage.getItem("token");
    // const h = { authorization: tokenfromls };
    const [startDate, endDate] = dateStrings;
    if (!dates) {
      setDateRange(null);
      // setmaindata();
      // setClickedButton("Today");
      handleTodayClick()
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/datepicker`,
        { startDate, endDate }
        // { headers: h }
      );
      // setDateRange(dates);
      // console.log("Dates---->" , startDate , "" , + "" , endDate)
      setmaindata(response.data);
      setClickedButton("");
      setclickedon("");
      //  setclickedend("");
      setLoading(false);

      // console.log("response.data" , response.data)
    } catch (error) {
      setClickedButton("");
      setclickedon("");
      //  setclickedend("");
      setLoading(false);
      const errorMessage =
        error.response && error.response.status === 400
          ? error.response.data.error
          : "Error fetching data. Please try again later.";
      setErrorMessage(errorMessage);
      console.error("Error fetching data:", error);
    }
    setDateRange(dates);
  };
 
  // <--------------toggle estimate fields on frontend------------>
  const handleEstimateToggle = () => {
    setHideEstimate(!hideEstimate);
  };

  const handlesaved_tradeoptions = () => {
    // navigate("/user/savedtradeOptions")
    window.open("/user/savedtradeOptions", "_blank");
  };

  const handletradepage =()=>{
    window.open("/user/buysell", "_blank");
  }
  const handleResize = () => {
    const widthThreshold = 1500; // Set your desired width
    if (window.innerWidth <= widthThreshold) {
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Run once on mount
    handleResize();

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOverlayClick = (e) => {
    // Close modal only if clicking outside the modal content
    if (e.target.id === "overlay") {
      setIsVisible(false);
    }
  };

  return (
    <div>
      <div>
        <div>
          <UserNavbar />
        </div>

        <div className="flex flex-row ">
          {/* <---sidebar---> */}
          <div
            className={`${
              styles.sidebar_class
            } fixed h-[100vh] hidden lg:block pt-[6%] transition-all duration-400  ease-in-out cursor-pointer  ${
              isOpen ? "min-w-[13%]" : "min-w-[5%]"
            } bg-[#7AD895]  p-4`}
          >
            <div
              className="mb-4 top-[12%] fixed top:0 flex flex-col items-center justify-center"
              onClick={handlesaved_tradeoptions}
            >
              <img
                src={savedicon}
                alt="Saved Icon"
                className={` ${isOpen ? "h-6 w-4 ml-2" : "h-6 w-5 ml-0"}`}
                title="Check history of saved trade"
              />

              {/* {isOpen && ( */}
                <h1 className="text-[14px] font-bold  mt-2 break-words text-[#170E32]">Trade History</h1>
              {/* )} */}
            </div>
            {/* <div
              className="cursor-pointer absolute top-[9.5rem] right-[-18px]"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <img
                  src={hamburger}
                  alt="image"
                  className="h-[40px] w-[40px]"
                />
              ) : (
                <img
                  src={hamburger1}
                  alt="image"
                  className="h-[40px] w-[40px]"
                />
              )}

              
            </div> */}
            {/* Sidebar Content */}
            <div className="mt-[21vh] ">
              <div
                className={`flex flex-col  ${
                  isOpen ? "items-start" : "items-center"
                }`}
              >
               
                {/* <---hlo---> */}

                <div
                  className={`relative px-0 mb-4 flex justify-start items-start flex-col`}
                >
                      <div className="flex justify-center items-center flex-col">
                       <div className="relative flex items-center justify-center text-center">
                      <div>
                      <span className="ml-0 mt-[16%] flex align-center justify-center text-center"   onClick={handleYesterdayClick}>
                          {clickedButton === "Yesterday" ? (
                            <img
                              src={yesterday_img_icon_active}
                              alt="today_img"
                              className="h-[45px] w-[44px]"
                            />
                          ) : (
                            <img
                              src={yesterday_img_icon}
                              alt="today_img"
                              className="h-[45px] w-[44px]"
                            />
                          )}
                        </span> 
                          <button
                          type="button"
                          onClick={handleYesterdayClick}
                          className={`h-[40px] text-[14px] font-bold  inline-flex items-center justify-center w-full  pb-2   rounded-md transition-opacity duration-300  ${
                            clickedButton === "Yesterday"
                              ? " text-[#3B82F6]"
                              : " text-[#170E32]"
                          }`}
                        >
                          Yesterday
                        </button>
                      </div>

                         {clickedButton === "Yesterday" && (
                      <div
                        className=" absolute top-[37%] right-[-70%] transform -translate-y-1/2 w-0 h-0 border-t-[20.5px] border-b-[20.5px] border-l-[34px] border-transparent border-l-[#7AD895]"
                      ></div>
                        )}


                       </div>
                       <div className="relative flex items-center justify-center text-center">
                    <div>
                    <span className="ml-0 mt-[16%] flex align-center justify-center text-center"  onClick={handleTodayClick}>
                          {clickedButton === "Today" ? (
                            <img
                              src={today_img_icon_active}
                              alt="today_img"
                              className="h-[40px] w-[44px]"
                            />
                          ) : (
                            <img
                              src={today_img_icon}
                              alt="today_img"
                              className="h-[40px] w-[44px]"
                            />
                          )}
                        </span>
                        <button
                          type="button"
                          onClick={handleTodayClick}
                          className={`h-[40px] text-[14px]  font-bold inline-flex items-center justify-center w-full  pb-2    rounded-md transition-opacity duration-300  
                        ${
                          clickedButton === "Today"
                            ? " text-[#3B82F6]"
                            : " text-[#170E32]"
                        }`}
                        >
                          Today
                        </button>
                        {clickedButton === "Today" && (
                      <div
                        className=" absolute top-[35%] right-[-142%] transform -translate-y-1/2 w-0 h-0 border-t-[20.5px] border-b-[20.5px] border-l-[34px] border-transparent border-l-[#7AD895]"
                      ></div>
                        )}
                    </div>
                      </div>
                      <div className="relative flex items-center justify-center text-center">
                       <div>
                       <span className="ml-0 mt-[16%] flex align-center justify-center text-center" onClick={handleTomorrowClick}>
                          {clickedButton === "Tomorrow" ? (
                            <img
                              src={tomorrow_img_icon_active}
                              alt="today_img"
                              className="h-[45px] w-[44px]"
                            />
                          ) : (
                            <img
                              src={tomorrow_img_icon}
                              alt="today_img"
                              className="h-[45px] w-[44px]"
                            />
                          )}
                        </span>
                        <button
                          type="button"
                          onClick={handleTomorrowClick}
                          className={`h-[40px]  text-[14px] inline-flex items-center justify-center w-full  pb-2  font-bold  rounded-md transition-opacity duration-300  ${
                            clickedButton === "Tomorrow"
                              ? " text-[#3B82F6]"
                              : " text-[#170E32]"
                          }`}
                        >
                          Tomorrow
                        </button>
                        {clickedButton === "Tomorrow" && (
                      <div
                        className=" absolute top-[37%] right-[-68%] transform -translate-y-1/2 w-0 h-0 border-t-[20.5px] border-b-[20.5px] border-l-[34px] border-transparent border-l-[#7AD895]"
                      ></div>
                        )}
                       </div>
                        </div>

                        <div className="relative flex items-center justify-center text-center">
                    <div>
                    <span className="ml-0 mt-[16%] flex align-center justify-center text-center"   onClick={handleweekClick}>
                          {clickedButton === "Next7Days" ? (
                            <img
                              src={next7days_img_icon_active}
                              alt="today_img"
                              className="h-[45px] w-[44px]"
                            />
                          ) : (
                            <img
                              src={next7days_img_icon}
                              alt="today_img"
                              className="h-[45px] w-[44px]"
                            />
                          )}
                        </span>
                        <button
                          type="button"
                          onClick={handleweekClick}
                          className={`h-[40px]  inline-flex text-[14px] items-center justify-center w-full  pb-2  font-bold  rounded-md transition-opacity duration-300  ${
                            clickedButton === "Next7Days"
                              ? " text-[#3B82F6] "
                              : " text-[#170E32] "
                          }`}
                        >
                          Next 7 Days
                        </button>
                    </div>
                    {clickedButton === "Next7Days" && (
                      <div
                        className=" absolute top-[39%] right-[-52%] transform -translate-y-1/2 w-0 h-0 border-t-[20.5px] border-b-[20.5px] border-l-[34px] border-transparent border-l-[#7AD895]"
                      ></div>
                        )}
                       </div>
                      </div>
                      </div>
                   
            
              </div>
            </div>

            {/* <------mobile view sidebar start ------> */}
          </div>

          {isVisible && (
            <div
              id="overlay"
              className="fixed inset-0 z-[998] bg-black bg-opacity-50 flex items-end"
              onClick={handleOverlayClick}
            >
              <div
                className={`p-2 z-[999] bg-white rounded-t-[20px] w-full h-[34%] flex flex-col justify-center relative transition-all duration-500 transform ease-out ${
                  isVisible ? "translate-y-0" : "translate-y-full"
                }`}
              >
                {/* Cross Button */}
                <button
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                  onClick={() => setIsVisible(false)}
                  aria-label="Close Modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <div className="flex  items-center justify-around w-full">
                  <div className="flex  items-center border-2 rounded-[14px] border-[#A0A0A0] py-3 px-2 w-[180px]  md:w-[230px] border-opacity-[35%]">
                    {" "}
                    <span className="ml-2">
                      {clickedButton === "Yesterday" ? (
                        <img
                          src={yesterday_img_icon_active}
                          alt="today_img"
                          className="h-[40px] w-[65px]"
                        />
                      ) : (
                        <img
                          src={yesterday_img_icon}
                          alt="today_img"
                          className="h-[40px] w-[65px]"
                        />
                      )}
                    </span>
                    <button
                      type="button"
                      onClick={handleYesterdayClick}
                      className={`h-[40px] text-[16px] ml-2 inline-flex items-center justify-center w-full  py-2  font-medium  rounded-md transition-opacity duration-300  ${
                        clickedButton === "Yesterday"
                          ? " text-[#3B82F6]"
                          : " text-[#170E32]"
                      }`}
                    >
                      Yesterday
                    </button>
                  </div>
                  <div className="flex  items-center border-2 rounded-[14px] border-[#A0A0A0] py-3 px-2 w-[180px]  md:w-[230px] border-opacity-[35%]">
                    <span className="ml-2">
                      {clickedButton === "Today" ? (
                        <img
                          src={today_img_icon_active}
                          alt="today_img"
                          className="h-[37px] w-[71px]"
                        />
                      ) : (
                        <img
                          src={today_img_icon}
                          alt="today_img"
                          className="h-[37px] w-[71px]"
                        />
                      )}
                    </span>

                    <button
                      type="button"
                      onClick={handleTodayClick}
                      className={`h-[40px] text-[16px] ml-5 font-bold inline-flex items-center w-full  py-2   rounded-md transition-opacity duration-300  
                        ${
                          clickedButton === "Today"
                            ? " text-[#3B82F6]"
                            : " text-[#170E32]"
                        }`}
                    >
                      Today
                    </button>
                  </div>
                </div>
                <div className="mt-6 flex  items-center justify-around w-full">
                  <div className="flex  items-center border-2 rounded-[14px] border-[#A0A0A0] py-3 px-2 w-[180px]  md:w-[230px] border-opacity-[35%]">
                    <span className="ml-2">
                      {clickedButton === "Tomorrow" ? (
                        <img
                          src={tomorrow_img_icon_active}
                          alt="today_img"
                          className="h-[40px] w-[65px]"
                        />
                      ) : (
                        <img
                          src={tomorrow_img_icon}
                          alt="today_img"
                          className="h-[40px] w-[65px]"
                        />
                      )}
                    </span>
                    <button
                      type="button"
                      onClick={handleTomorrowClick}
                      className={`h-[40px] ml-2 text-[16px] inline-flex items-center justify-center w-full  py-2  font-medium  rounded-md transition-opacity duration-300  ${
                        clickedButton === "Tomorrow"
                          ? " text-[#3B82F6]"
                          : " text-[#170E32]"
                      }`}
                    >
                      Tomorrow
                    </button>
                  </div>
                  <div className="flex  items-center border-2 rounded-[14px] border-[#A0A0A0] py-3 px-2 w-[180px]  md:w-[230px] border-opacity-[35%]">
                    <span className="ml-2">
                      {clickedButton === "Next7Days" ? (
                        <img
                          src={next7days_img_icon_active}
                          alt="today_img"
                          className="h-[40px] w-[65px]"
                        />
                      ) : (
                        <img
                          src={next7days_img_icon}
                          alt="today_img"
                          className="h-[40px] w-[65px]"
                        />
                      )}
                    </span>
                    <button
                      type="button"
                      onClick={handleweekClick}
                      className={`h-[40px] ml-2 inline-flex text-[16px] items-center justify-center w-full  py-2  font-medium  rounded-md transition-opacity duration-300  ${
                        clickedButton === "Next7Days"
                          ? " text-[#3B82F6] "
                          : " text-[#170E32] "
                      }`}
                    >
                      Next 7 Days
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <------mobile view sidebar end ------> */}

          {/* <----main componnet render----> */}
          <div
            className={` transition-all duration-200  ease-in-out 
              " ml-[9%] w-[93%]"
           `}
          >
            <div className="">
              <div
                className={`fixed top-[10%] ${isOpen ? "w-[80%]" : "w-[89%]"}`}
              >
                <div className=" z-[999] w-[100%]   ">
                  <div className="ml:0 sm:ml-0 md:ml-0  lg:ml-[6%] xl:ml-0 mt-[8%] sm:mt-[9%] md:mt-0 lg:mt-0 xl:mt-0 buttons flex flex-col  items-start  justify-start">
                    <div className="flex justify-between w-full">
                      <h1 className="font-bold text-[22px] sm:text-[22px] md:text-[32px] lg:text-[32px] xl:text-[32px]  text-[#151841] mt-4">
                        Economic Calendar
                      </h1>

                     <div className="flex">
                     <button
                        className="hidden max-[1023px]:block mt-2"
                        onClick={handlesaved_tradeoptions}
                      >
                        <img
                          src={savedicon}
                          alt="filter icon"
                          className={`h-5 w-4 cursor-pointer   `}
                        />
                      </button>
                      <button
                        className="ml-2 hidden max-[1023px]:block mt-2"
                        onClick={toggleVisibility}
                      >
                        <img
                          src={filter_icon}
                          alt="filter icon"
                          className={`h-[24px] w-[24px] cursor-pointer   `}
                        />
                      </button>
                     </div>
                    </div>
                    <div
                      className={` ${styles.saved_haeder6} w-full first-div flex flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row mt-[1%] justify-between`}
                    >
                      <div className="ml-[-5px] flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
                        <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block pr-3 mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 z-[999] bg-[#F6F6F6]">
                          {/* <Space
                            style={{
                              width: "100%",
                              paddingTop: 0,
                              height: "46px",
                            }}
                            direction="vertical"
                          >
                            <FormControl
                              sx={{ m: 1, width: 200, borderColor: "#170E32" }}
                            >
                              <InputLabel id="country-select-label">
                                <span style={{ color: "#666666" }}>
                                  Country
                                </span>
                              </InputLabel>
                              <Select
                                labelId="country-select-label"
                                id="country-select"
                                multiple
                                value={selectedCountry}
                                onChange={handleChange}
                                input={
                                  <OutlinedInput
                                    label="Country"
                                    sx={{ height: 46 }}
                                  />
                                }
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                                sx={{ height: 46 }}
                              >
                                {countries.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    <Checkbox
                                      checked={selectedCountry.includes(option)}
                                    />
                                    <ListItemText primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Space> */}
                         <Space style={{ width: "100%" }} direction="vertical" className="z-[999] border-[#170E32] ">
      <Select
        mode="multiple"
        allowClear
        style={{
          width: "270px",
          height: "46px",
          maxHeight: "46px",
          backgroundColor: "#F6F6F6", // Grey background
          border: "1px solid #170E32", // Light grey border
          borderRadius: "4px",
        }}
        placeholder={<span style={{ color: "#170E32", fontSize: "16px" }}>Country</span>}
        onChange={handleCountryChange}
        getPopupContainer={(triggerNode) => document.body}
        className="z-[999] custom-select overflow-visible"
        dropdownStyle={{
          maxHeight: "800px",
          overflowY: "auto", // Adds vertical scrollbar for dropdown options
        }}
        suffixIcon={
          <DownOutlined
            style={{
              fontSize: "14px",
              color: "#170E32", // Change icon color here
            }}
          />
        }
        maxTagCount={1} // Only show one country in the select box
        maxTagTextLength={20} // Adjust max length if necessary
      >
        <Option value="UK">United Kingdom (UK)</Option>
        <Option value="US">United States (US)</Option>
        <Option value="CA">Canada (CA)</Option>
        <Option value="CH">Switzerland (CH)</Option>
        <Option value="EU">Europe (EU)</Option>
        <Option value="JP">Japan (JP)</Option>
        <Option value="AU">Australia (AU)</Option>
        <Option value="NZ">New Zealand (NZ)</Option>
        <Option value="DE">Germany (DE)</Option>
        <Option value="IT">Italy (IT)</Option>
        <Option value="FR">France (FR)</Option>
      </Select>
                         </Space>
                        </div>
                        <div className="relative inline-block px-0 sm:px-0 md:px-0 lg:px-3 xl:px-3">
                          {/* <Space
                            style={{ width: "100%", paddingTop: 0 }}
                            direction="vertical"
                          >
                            <FormControl
                              sx={{ m: 1, width: 200, borderColor: "#170E32" }}
                            >
                              <InputLabel id="impact-select-label">
                                <span style={{ color: "#666666" }}>Impact</span>
                              </InputLabel>
                              <Select
                                labelId="impact-select-label"
                                id="impact-select"
                                multiple
                                value={selectedImpacts}
                                onChange={handleImpactChange}
                                input={
                                  <OutlinedInput
                                    label="Impact"
                                    sx={{ height: 46 }}
                                  />
                                }
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                                sx={{ height: 46 }}
                              >
                                <MenuItem value="High">
                                  <Checkbox
                                    checked={selectedImpacts.includes("High")}
                                  />
                                  <ListItemText primary="High" />
                                </MenuItem>
                                <MenuItem value="Medium">
                                  <Checkbox
                                    checked={selectedImpacts.includes("Medium")}
                                  />
                                  <ListItemText primary="Medium" />
                                </MenuItem>
                                <MenuItem value="Low">
                                  <Checkbox
                                    checked={selectedImpacts.includes("Low")}
                                  />
                                  <ListItemText primary="Low" />
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Space> */}
                            <Space style={{ width: "100%" }} direction="vertical"  className="z-[999] border-[#170E32] ">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{
                              width: "270px",
                              height: "46px",
                              maxHeight: "46px",
                              backgroundColor: "#F6F6F6", // Grey background
                              border: "1px solid #170E32", // Light grey border
                              borderRadius: "4px",
                            }}
                            placeholder={
                              <span style={{ color: "#170E32", fontSize: "16px" }}>Impact</span>
                            }
                            onChange={handleImpactChange}
                            // getPopupContainer={(trigger) => trigger.parentNode}
                            getPopupContainer={(triggerNode) => document.body}
                            className="z-[999] custom-select overflow-visible"
                            dropdownStyle={{
                              maxHeight: "800px",
                              overflowY: "auto", // Adds vertical scrollbar for dropdown options
                            }}
                            suffixIcon={
                              <DownOutlined
                                style={{
                                  fontSize: "14px",
                                  color: "#170E32", // Change icon color here
                                }}
                              />
                            }
                            maxTagCount={3} // Only show one country in the select box
                            maxTagTextLength={20} // Adjust max length if necessary
                          >
                            <Option value="High">High</Option>
                            <Option value="Medium">Medium</Option>
                            <Option value="Low">Low</Option>
                          </Select>
                        </Space>
                        </div>

                      
                        <div
                          className={` ${styles.rangepicker}  range-picker items-center  text-center flex mt-2 sm:mt-2 md:mt-4 lg:mt-[-4px] xl:mt-[-4px] ml-[-6%] sm:ml-[-3%] md:ml-[-2%] lg:ml-0 xl:ml-0 pl-6 md:pl-3 w-[30vw] sm:w-[30vw] md:w-full `}
                          style={{ height: "56px" }}
                       >
                          <Space direction="vertical" size={16}>
                            {/* <RangePicker onChange={handleDateChange}/> */}
                            <RangePicker
                              value={dateRange}
                              onChange={(dates, dateStrings) =>
                                handleDateChange(dates, dateStrings)
                                
                              }
                              
                              className="text-[16px] h-[48px] w-[270px] lg:w-full border-[#170E32] border active:outline-none bg-[#F6F6F6] focus:outline-none  rounded-md placeholder-[#170E32] custom-rangepicker "
                            />
                          </Space>
                        </div>
                      </div>

                      <div className="flex mt-[8px] sm:mt-[8px] md:mt-[8px] lg:mt-0 xl:mt-0 ml-[-9px] sm:ml-[-9px] md:ml-[-9px] lg:ml-0 xl:ml-0">
                        <div className="relative mt-2 sm:mt-2 md:mt-2 lg:mt-2 xl:mt-0 inline-block  px-2 sm:px-2 md:px-1 lg:px-1 xl:px-3">
                          <button
                            type="button"
                            onClick={handleEstimateToggle}
                            className={`h-[46px] inline-flex items-center justify-center w-full px-4 py-2  font-medium text-[#170E32] border rounded-md focus:outline-none focus-visible:ring border-[#170E32] `}
                          >
                            {hideEstimate
                              ? "Show All Estimate"
                              : "Hide Empty Estimate"}
                          </button>
                        </div>
                        <div className="relative mt-2 sm:mt-2 md:mt-2 lg:mt-2 xl:mt-0 inline-block  px-2 sm:px-2 md:px-1 lg:px-1 xl:px-3">

                          <button
                            type="button"
                            onClick={handletradepage}
                            className={`h-[46px] inline-flex items-center justify-center w-full px-4 py-2  font-medium text-[#170E32] border rounded-md focus:outline-none focus-visible:ring border-[#170E32] `}
                          >
                              <img
                            src={live_trade_icon}
                            alt="trade_btn"
                            title="Live Trade"
                            className={`h-[35px] w-[34px] cursor-pointer mr-2`}
                            onClick={handletradepage}/>
                            Live Trade
                          </button>
                        </div>
                        <div>
                          <img
                            src={refresh_icon}
                            alt="Refresh"
                            title="Refresh"
                            className={`h-[40px] w-[40px] cursor-pointer mt-3 sm:mt-3 md:mt-3 lg:mt-3 xl:mt-0 ml-2`}
                            onClick={handleModalShowClick}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="second-div flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row items-center justify-center mt-5 sm:mt-5 md:mt-5 lg:mt-5 xl:mt-0 ml-[-6%] sm:ml-[-6%] md:ml-0 lg:ml-0 xl:ml-0">
                      <div className="flex ml-[-13px] sm:ml-0 md:ml-0 lg:ml-0 xl:ml-0">
                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-3">
                          <button
                            type="button"
                            onClick={handleEstimateToggle}
                            className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[40px] xl:h-[40px] inline-flex items-center bg-gray-700 hover:bg-gray-800 justify-center w-full px-4 py-2  font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 `}
                          >
                            {hideEstimate
                              ? "Show All Estimate"
                              : "Hide Empty Estimate"}
                          </button>
                        </div> */}

                    {/* <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-3">
                          <button
                            type="button"
                            onClick={handleYesterdayClick}
                            className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[40px] xl:h-[40px] inline-flex items-center justify-center w-full px-4 py-2  font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                              clickedButton === "Yesterday"
                                ? "bg-slate-500"
                                : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                            Yesterday
                          </button>
                        </div> */}
                  </div>

                  {/* <div className="flex mt-5 sm:mt-5 md:mt-0 lg:mt-0 xl:mt-0 ">
                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-3">
                          <button
                            type="button"
                            onClick={handleTodayClick}
                            className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[40px] xl:h-[40px] inline-flex items-center justify-center w-full px-2 sm:px-2 md:px-4 lg:px-4 xl:px-4 py-2  font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                              clickedButton === "Today"
                                ? "bg-slate-500"
                                : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                            Today
                          </button>
                        </div>
                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-3">
                          <button
                            type="button"
                            onClick={handleTomorrowClick}
                            className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[40px] xl:h-[40px] inline-flex items-center justify-center w-full px-2 sm:px-2 md:px-4 lg:px-4 xl:px-4 py-2  font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                              clickedButton === "Tomorrow"
                                ? "bg-slate-500"
                                : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                            Tomorrow
                          </button>

                        
                        </div>
                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-3">
                          <button
                            type="button"
                            onClick={handleweekClick}
                            className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[40px] xl:h-[40px] inline-flex items-center justify-center w-full px-2 sm:px-2 md:px-4 lg:px-4 xl:px-4 py-2  font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                              clickedButton === "Next7Days"
                                ? "bg-slate-500"
                                : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                            Next 7 Days
                          </button>
                          {loading && (
                            <div className="flex items-center justify-center h-screen w-screen loader-overlay ">
                              <Bars
                                height="80"
                                width="80"
                                color="#94A3B8"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                className="loader"
                              />
                            </div>
                          )}
                        </div>

                        <div>
                          <img
                            src={savedicon}
                            alt="Saved Icon"
                            className="h-6 w-6 ml-2 mt-2 cursor-pointer"
                            title="Check history of saved trade"
                            onClick={handlesaved_tradeoptions}
                          />
                        </div>
                                            
                       

                        <div onClick={handleModalShowClick} >
                 
                    <img
                           src={refresh_icon}
                           alt="refresh button"
                           title="Refresh"
                           className="h-8 w-8 ml-2 mt-1 cursor-pointer "
                         />
                   
                  </div>
                      </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className={` fixed overflow-auto `}>
              <UserTable
                clickedon={clickedon}
                maindata={maindata}
                hideEstimate={hideEstimate}
                selectedCountry={selectedCountry}
                selectedImpacts={selectedImpacts}
                isOpen={isOpen}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        {modalShow && (
          <div
            id="popup-modal"
            className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center bg-black bg-opacity-15 z-50"
            onClick={() => setModalshow(!modalShow)}
          >
            <div className="relative p-4 w-full max-w-md">
              <div className="relative bg-white rounded-lg shadow">
                {/* <button
                  type="button"
                  className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg  w-8 h-8 inline-flex justify-center items-center"
                  data-modal-hide="popup-modal"
                  onClick={() => setModalshow(!modalShow)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      onClick={() => setModalshow(!modalShow)}
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button> */}

                <img
                  src={logout_eye_btn}
                  alt="logout_eye_btn"
                  className="h-[33px] w-[33px] absolute top-[12%] left-[47%]"
                />
                <div className="p-4 md:p-5 text-center">
                  {/* <div className="flex justify-center items-center mt-5">
                                   <CiCircleInfo size={40} color='grayZ'/>
                                </div> */}

                  <h3 className="mb-5 mt-[15%] text-lg font-bold text-black">
                    Are you sure you want to refresh the page? This action will
                    clear all locally stored data.
                  </h3>
                  <div className="flex items-center justify-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="text-[#151841]  bg-[#ecebeb] mt-3 font-medium rounded-lg  inline-flex items-center justify-center px-3 py-2 w-[20%]"
                      onClick={handleRefresh}
                    >
                      Yes
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="ml-4 text-white  bg-[#151841] mt-3 font-medium rounded-lg  inline-flex items-center justify-center px-3 py-2 w-[20%]"
                      onClick={() => setModalshow(!modalShow)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCalender;
