import React, { useState } from "react";
import { toast } from "react-toastify";
// import { NavLink } from "react-router-dom";
import {LineWave} from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
// import logo from "../../assets/altrafxlogo_black.png";
// import bannerimg from "../../assets/userbanner.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import bannerimg2 from "../../assets/changepass.webp"
import styles from "../../components/tradeall.module.css"

const ChangenewPassword = () => {
    const navigate = useNavigate()
    const [Loading , setLoading]=useState(false)
    const [newPassword, setnewPassword] = useState("");
    const [confirmNewPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmNewPassword, setShowconfirmNewPassword] = useState(false);




    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
    
        if (newPassword !== confirmNewPassword) {
            // console.log("not euwal ")
            toast.error("Password do not match", {
                position: "top-right"});
        //   setError('New newPassword and confirmation do not match');
          return;
        }
    // console.log("newPassword" ,newPassword)
    // console.log("confirmNewPassword" ,confirmNewPassword)
        try {
            const email = localStorage.getItem("userEmail");
            toast.success("Password changed successfully", {
                position: "top-right"});
          const response = await axios.post(`
          ${process.env.REACT_APP_URL}/user/changePassword`, {
            email,
            newPassword,
            confirmNewPassword,
          });
          setLoading(false)
        //   console.log("response" ,response)
          navigate("/")
        //   setMessage(response.data.message);
        //   setError('');
        } catch (err) {
        //   setError(err.response?.data?.error || 'Server error');
        //   setMessage('');
        setLoading(false)
        // }
        console.log("error" , err)
        toast.error("Please check password again", {
            position: "top-right"});
        }
      };
    return (
        <div>
        
    
        <section className={`${styles.usersignin_bg}  `}>
        <div className="py-[3.5rem] sm:py-[3.5rem] md:py-[4.5rem] lg:py-[5.5rem] xl:py-[7.5rem]  px-[1.5rem] sm:px-[1.5rem] md:px-[4rem] lg:px-[5rem] xl:px-[7rem] h-full flex w-[100%] flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
        <div className="w-[100%] sm:w-[100%] md:w-[100%]  lg:w-[50%] xl:w-[50%] flex justify-center items-center ">
          <img
            src={bannerimg2}
            alt="bannerimg"
            className="h-[35vh] sm:h-[35vh] md:h-[35vh] lg:h-[55vh] xl:sm:h-[55vh]"
          ></img>
        </div>
            <div className=" w-[100%] z-[999] sm:w-[100%] md:w-[100%]  lg:w-[50%] xl:w-[50%] flex flex-col items-center justify-center  py-8 lg:py-0">
           
             
             <div className="rounded-[34px]  flex items-center justify-center sm:px-[2px] md:px-[6.5rem] lg:px-[4.5rem] xl:px-[4.5rem] px-0 py-0 sm:py-0 md:py-[3.5rem] lg:py-[4.5rem] xl:py-[8.5rem]  bg-white" style={{ boxShadow: '0px 2px 14.1px 0px rgba(223, 223, 223, 0.50)' }}>
             <div className="w-full bg-white     md:mt-0 sm:max-w-md xl:p-0  ">
                <div className="space-y-4 md:space-y-4  bg-white">
                  <h1 className="text-[24px] sm:text-[24px] md:text-[32px] lg:text-[32px] xl:text-[32px] font-bold  text-[#151841] text-center">New Password</h1>
    
                  <p className="text-[#170E32] text-center font-semibold text-[14px] sm:text-[14px] md:sm:text-[14px] lg:sm:text-[16px] xl:sm:text-[16px] ">Your new password should be different from previous password(s)</p>
                  <form  className="my-16 sm:my-16 md:my-10 lg:my-10 xl:my-10"  onSubmit={handleSubmit}>
                    <div className="flex flex-col space-y-5">
                    <label htmlFor="newPassword" className="relative">
                    <p className="font-bold text[16px] text-[#170E32] pb-2">New password</p>
                    <input
                      id="newPassword"
                      name="newPassword"
                      type={showPassword ? "text" : "Password"}
                      value={newPassword}
                      onChange={(e) => setnewPassword(e.target.value)}
                      autoComplete="off"
                      className="w-full text-[14px] py-3 input-field border border-[#8692A6] rounded-lg px-5  placeholder-[#545454] font-bold pr-10"
                      placeholder="Enter password"
                    />
                    {showPassword ? (
                      <IoIosEye
                        className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      // <img src={eye_img_open}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                      <IoIosEyeOff
                        className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      // <img src={eye_img}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                    )}
                  </label>
                  <label htmlFor="newPassword" className="relative">
                    <p className="font-bold text[16px] text-[#170E32] pb-2">Confirm password</p>
                    <input
                      id="confirmNewPassword"
                      name="newPassword"
                      type={showconfirmNewPassword ? "text" : "Password"}
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="off"
                      className="text-[14px] w-full py-3 input-field border border-[#8692A6] rounded-lg px-5  placeholder-[#545454] font-bold pr-10"
                      placeholder="Confirm password"
                    />
                    {showconfirmNewPassword ? (
                      <IoIosEye
                        className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowconfirmNewPassword(!showconfirmNewPassword)}
                      />
                    ) : (
                      // <img src={eye_img_open}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                      <IoIosEyeOff
                        className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500"
                        onClick={() => setShowconfirmNewPassword(!showconfirmNewPassword)}
                      />
                      // <img src={eye_img}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                    )}
                  </label>

               


                       
                       
                      <button
                        type="Verify"
                        className="login-btn w-full py-3 bg-[#151841] font-bold text-[#fff]  rounded-lg  inline-flex space-x-2 items-center justify-center mt-10"
                      >
                        <span  >Create Password</span>
                        {/* <PiArrowSquareRight className="text-xl" /> */}
                      </button>
                     
                    </div>
                  </form>
                </div>
              </div>
             </div>
          
    
    
    
    
    
              {
                 Loading ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
                 <LineWave 
                   height="60"
                   width="60"
                   color="#111827"
                   ariaLabel="bars-loading"
                   wrapperStyle={{}}
                   wrapperClass=""
                   visible={true}
                   className=" w-10 h-10 flex items-center justify-center  mt-10 "
                 />
               </div> :
                <div>
                
               </div>
              }
          
            </div>
    
        </div>
    
          </section>
    
    
        </div>
      )
}

export default ChangenewPassword
