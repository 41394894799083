import React from "react";
import { useNavigate } from "react-router-dom";
import NavLink from "./Navlink";
import logo from "../assets/altrafxlogo.png";

import { MdOutlineLogout } from "react-icons/md";

import admincicon from "../assets/admin.png"
const Navbar = () => {
  const navigate = useNavigate();

  // <demo-></demo->
  const handleLogout = () => {


    Object.keys(localStorage).forEach((key) => {
      if (
          key.includes("Clickeddate_data_") || 
          key.includes("table3_historyData_") || 
          key.includes("clickedmodal_data_2ndpage_")
      ) {
          localStorage.removeItem(key);
      }
  });
    localStorage.removeItem("token");
    localStorage.removeItem("adminregisterId");
    localStorage.removeItem("lastDeletionTime");
    localStorage.removeItem("All_events_history");
    localStorage.removeItem("UserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedtime");
    localStorage.removeItem("eventnameandcountry:");
    localStorage.removeItem("userregisterId");
    localStorage.removeItem("username");
    localStorage.removeItem("usertoken");
    localStorage.removeItem("todayDate");
    localStorage.removeItem("previousDate_u_search_history");
    localStorage.removeItem("adminregisterId");
    localStorage.removeItem("4GraphDataobjects");
    localStorage.removeItem("todayDate");
    localStorage.removeItem("userEmail");
    navigate("/admin");
  };

  return (
    <nav className="bg-gray-900 p-4 fixed top-0 w-full z-50 ">
      <div className="container mx-auto flex justify-between items-center">
     
        <div className="flex items-center">
        <NavLink to="/admin/economicdata">
          <img src={logo} alt="Logo" className="h-12 w-26 " /></NavLink>
         
         
        </div>

        <div className="flex items-center space-x-12 justify-center">
          <div className="flex flex-row justify-center items-center">
                         <img
                          src={admincicon}
                          alt="admincicon"
                          className="h-5 w-5 mr-2 "/>
            <h1 className=" text-white mr-8 text-[17px]">Admin</h1>
            <MdOutlineLogout className="text-white relative  right-1 ml-6" />
            <button onClick={handleLogout} className="text-white">
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
