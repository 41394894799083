// <-----------------this whole code is for charts , for now they are not in use ------------------>

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Mongodata = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
   
    axios.get(`${process.env.REACT_APP_URL}/getgraphdata`)
    .then(response => {
      console.log("Data :", response.data.slice(0, 20));
      setData(response.data.slice(0, 20));
    })
    .catch(error => console.error('Error fetching data:', error));
  
  }, []); 

  return (
    <>
      <div>
        <h1>Your Data:</h1>
        <ul>
          {data.length > 0 ? (
            data.map(item => (
              <li key={item._id}>
                {item.currency} - {item.date} - {item.time} - {item.open} - {item.high} - {item.low} - {item.close}
              </li>
            ))
          ) : (
            <li>No data available</li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Mongodata;
