
// summary color key , table color key


import React, { useEffect, useState, useCallback , useRef } from "react";
import axios from "axios";
import { Space } from "antd";
import { Bars } from "react-loader-spinner";
import movementicon from "../assets/average_tradeall.png";
import eye_btn_tooltip from "../assets/eye_btn_tooltip.png"
import { useNavigate } from "react-router-dom";
import color1 from "../assets/highest_average.png";
import color2 from "../assets/second_highest_average.png";
import color3 from "../assets/third_highest_average.png";

import color4 from "../assets/color4.png"
import color5 from "../assets/color5.png"
import color6 from "../assets/color6.png"

import color7 from "../assets/color7.png"
import color8 from "../assets/color8.png"
import color9 from "../assets/color9.png"

import crossicon from "../assets/cross1.png";
import refresh_icon from "../assets/refresh.png";
import logout_eye_btn from "../assets/IBUTTON.png"
import dateicon from "../assets/date.png" ;
import eventicon from "../assets/event.png" ;
import timeicon from "../assets/time1).png" ;
import locationicon from "../assets/location(1).png" ;
import impacticon from "../assets/impact(1).png" ;
import frequencyicon from "../assets/calendar(1).png" ;
import RingLoader from "react-spinners/RingLoader";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import UserNavbar from "../components/user/userNavbar";
import styles from "../components/tradeall.module.css";
const EventshistoryUser = () => {
  const navigate = useNavigate();

  const [off_minutebuttons1, setoff_minutebuttons1] = useState(true);
  const [off_minutebuttons2, setoff_minutebuttons2] = useState(true);
  const [off_minutebuttons3, setoff_minutebuttons3] = useState(true);
  const [off_minutebuttons4, setoff_minutebuttons4] = useState(true);

  const [run_modalloader, setRun_modalloader] = useState(true);
  const [run_clickedevent_dateapi, setRunclickedevent_dateapi] =useState(false);
  const [run_clickedevent_dateapi2, setRunclickedevent_dateapi2] =useState(false);
  const [runclickedevent_dateapi3, setRunclickedevent_dateapi3] =useState(false);
  const [Loading_3rdtable, setLoading_3rdtable] = useState(false);

const [timeclickedurl_state , setTimeclickedurl_state] = useState(1)

  const [isgraphbuttonDisabled , setIsgraphbuttonDisbled] = useState(true)
  const [optionValue, setOptionValue] = useState("Last 6");
  const [avgOfThirdTable, setAvgOfThirdTable] = useState(0);

  const [variance_Color, setvariance_Color] = useState("");
  const [avg_ofthirdtable_usd, setAvg_ofthirdtable_usd] = useState(null);
  const [avg_ofthirdtable_jpy, setAvg_ofthirdtable_jpy] = useState(null);
  const [avg_ofthirdtable_nzd, setAvg_ofthirdtable_nzd] = useState(null);
  const [avg_ofthirdtable_eur, setAvg_ofthirdtable_eur] = useState(null);
  const [avg_ofthirdtable_chf, setAvg_ofthirdtable_chf] = useState(null);
  const [avg_ofthirdtable_aud, setAvg_ofthirdtable_aud] = useState(null);
  const [avg_ofthirdtable_cad, setAvg_ofthirdtable_cad] = useState(null);

  const [eventname_forerror, seteventname_forerror] = useState("");

  // <----------------here is the final averge of flat points---------->
  const [avg_ofthirdtable_usd_flat0, setAvg_ofthirdtable_usd_flat0] =
    useState(null);
  const [avg_ofthirdtable_jpy_flat0, setAvg_ofthirdtable_jpy_flat0] =
    useState(null);
  const [avg_ofthirdtable_nzd_flat0, setAvg_ofthirdtable_nzd_flat0] =
    useState(null);
  const [avg_ofthirdtable_eur_flat0, setAvg_ofthirdtable_eur_flat0] =
    useState(null);
  const [avg_ofthirdtable_chf_flat0, setAvg_ofthirdtable_chf_flat0] =
    useState(null);
  const [avg_ofthirdtable_aud_flat0, setAvg_ofthirdtable_aud_flat0] =
    useState(null);
  const [avg_ofthirdtable_cad_flat0, setAvg_ofthirdtable_cad_flat0] =
    useState(null);

  const [isReloadLoading, setIsReloadLoading] = useState(false);
  const [averageHighLow_usddata, setAverageHighLow_usddata] = useState([]);
  const [averageHighLow_jpydata, setAverageHighLow_jpydata] = useState([]);
  const [averageHighLow_nzddata, setAverageHighLow_nzddata] = useState([]);
  const [averageHighLow_eurdata, setAverageHighLow_eurdata] = useState([]);
  const [averageHighLow_chfdata, setAverageHighLow_chfdata] = useState([]);
  const [averageHighLow_auddata, setAverageHighLow_auddata] = useState([]);
  const [averageHighLow_caddata, setAverageHighLow_caddata] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [eventHistory, setEventHistory] = useState([]);
  const [eventHistory2, setEventHistory2] = useState([]);
  const [clickedtimeButton, setClickedtimeButton] = useState("1min");
  const [country, setCountry] = useState("");
  const [event, setEvent] = useState("");
  const [impact, setimpact] = useState("");
  const [date, setdate] = useState("");
  const [time, settime] = useState("");

  const [impacthighlow, setimpacthighlow] = useState([]);
  const [impacthighlowbeforethis, setimpacthighlowbeforethis] = useState([]);
  const [modalimpactdatabeforetime, setmodalimpactdatabeforetime] = useState(
    []
  );
  const [modalimpactdataontime, setmodalimpactdataontime] = useState([]);
  const [usddata, setusddata] = useState([]);
  const [jpydata, setjpydata] = useState([]);
  const [gbpnzddata, setgbpnzddata] = useState([]);
  const [eurgbpdata, seteurgbpdata] = useState([]);
  const [gbpchfdata, setgbpchfdata] = useState([]);
  const [gbpauddata, setgbpauddata] = useState([]);
  const [gbpcaddata, setgbpcaddata] = useState([]);

  const [usddata_avg, setusddata_avg] = useState([]);
  const [jpydata_avg, setjpydata_avg] = useState([]);
  const [gbpnzddata_avg, setgbpnzddata_avg] = useState([]);
  const [eurgbpdata_avg, seteurgbpdata_avg] = useState([]);
  const [gbpchfdata_avg, setgbpchfdata_avg] = useState([]);
  const [gbpauddata_avg, setgbpauddata_avg] = useState([]);
  const [gbpcaddata_avg, setgbpcaddata_avg] = useState([]);

  const [frequency_date, setFrequency_date] = useState();
  const [Loading, setLoading] = useState(false);
  const [sumActualEstimate, setSumActualEstimate] = useState(0);
  const [sumActualEstimatebyo, setSumActualEstimatebyo] = useState(0);

  const [sumActualPrevious, setSumActualPrevious] = useState(0);
  const [sumActualPreviousbyo, setSumActualPreviousbyo] = useState(0);

  const [sumfinalmovement, setSumfinalmovement] = useState(0);
  const [sumfinalmovementbyo, setSumfinalmovementbyo] = useState(0);

  const [selectedTime, setSelectedTime] = useState(1);
  const [allEventHistory, setAllEventHistory] = useState([]);
  const [allEventHistory2, setAllEventHistory2] = useState([]);

  const [averagegbpusddiff1, setAverageDiff] = useState(0);
  const [averagegbpusddiff2, setAverageDiff2] = useState(0);
  const [averagegbpusddiff3, setAverageDiff3] = useState(0);

  const [averagegbpjpydiff1, setavgjpydiff] = useState(0);
  const [averagegbpjpydiff2, setavgjpydiff2] = useState(0);
  const [averagegbpjpydiff3, setavgjpydiff3] = useState(0);

  const [averagegbpcaddiff1, setaveragegbpcaddiff1] = useState(0);
  const [averagegbpcaddiff2, setaveragegbpcaddiff2] = useState(0);
  const [averagegbpcaddiff3, setaveragegbpcaddiff3] = useState(0);

  const [averagegbpauddiff1, setaveragegbpauddiff1] = useState(0);
  const [averagegbpauddiff2, setaveragegbpauddiff2] = useState(0);
  const [averagegbpauddiff3, setaveragegbpauddiff3] = useState(0);

  const [averagegbpchfdiff1, setaveragegbpchfdiff1] = useState(0);
  const [averagegbpchfdiff2, setaveragegbpchfdiff2] = useState(0);
  const [averagegbpchfdiff3, setaveragegbpchfdiff3] = useState(0);

  const [averagegbpeurdiff1, setaveragegbpeurdiff1] = useState(0);
  const [averagegbpeurdiff2, setaveragegbpeurdiff2] = useState(0);
  const [averagegbpeurdiff3, setaveragegbpeurdiff3] = useState(0);

  const [averagegbpnzddiff1, setaveragegbpnzddiff1] = useState(0);
  const [averagegbpnzddiff2, setaveragegbpnzddiff2] = useState(0);
  const [averagegbpnzddiff3, setaveragegbpnzddiff3] = useState(0);

  // <<--------for flat 0 averages----->
  const [averagegbpusddiff1_flat0, setAverageDiff_flat0] = useState(0);
  const [averagegbpusddiff2_flat0, setAverageDiff2_flat0] = useState(0);
  const [averagegbpusddiff3_flat0, setAverageDiff3_flat0] = useState(0);

  const [averagegbpjpydiff1_flat0, setavgjpydiff_flat0] = useState(0);
  const [averagegbpjpydiff2_flat0, setavgjpydiff2_flat0] = useState(0);
  const [averagegbpjpydiff3_flat0, setavgjpydiff3_flat0] = useState(0);

  const [averagegbpcaddiff1_flat0, setaveragegbpcaddiff1_flat0] = useState(0);
  const [averagegbpcaddiff2_flat0, setaveragegbpcaddiff2_flat0] = useState(0);
  const [averagegbpcaddiff3_flat0, setaveragegbpcaddiff3_flat0] = useState(0);

  const [averagegbpauddiff1_flat0, setaveragegbpauddiff1_flat0] = useState(0);
  const [averagegbpauddiff2_flat0, setaveragegbpauddiff2_flat0] = useState(0);
  const [averagegbpauddiff3_flat0, setaveragegbpauddiff3_flat0] = useState(0);

  const [averagegbpchfdiff1_flat0, setaveragegbpchfdiff1_flat0] = useState(0);
  const [averagegbpchfdiff2_flat0, setaveragegbpchfdiff2_flat0] = useState(0);
  const [averagegbpchfdiff3_flat0, setaveragegbpchfdiff3_flat0] = useState(0);

  const [averagegbpeurdiff1_flat0, setaveragegbpeurdiff1_flat0] = useState(0);
  const [averagegbpeurdiff2_flat0, setaveragegbpeurdiff2_flat0] = useState(0);
  const [averagegbpeurdiff3_flat0, setaveragegbpeurdiff3_flat0] = useState(0);

  const [averagegbpnzddiff1_flat0, setaveragegbpnzddiff1_flat0] = useState(0);
  const [averagegbpnzddiff2_flat0, setaveragegbpnzddiff2_flat0] = useState(0);
  const [averagegbpnzddiff3_flat0, setaveragegbpnzddiff3_flat0] = useState(0);

  const [averageDifff, setAverageDifff] = useState(0);
  const [averageDifff2, setAverageDifff2] = useState(0);
  const [averageDifff3, setAverageDifff3] = useState(0);
  const [avgjpydifff, setavgjpydifff] = useState(0);
  const [avgjpydifff2, setavgjpydifff2] = useState(0);
  const [avgjpydifff3, setavgjpydifff3] = useState(0);

  const [averagegbpcadbyodiff1, setaveragegbpcadbyodiff1] = useState(0);
  const [averagegbpcadbyodiff2, setaveragegbpcadbyodiff2] = useState(0);
  const [averagegbpcadbyodiff3, setaveragegbpcadbyodiff3] = useState(0);

  const [averagegbpaudbyodiff1, setaveragegbpaudbyodiff1] = useState(0);
  const [averagegbpaudbyodiff2, setaveragegbpaudbyodiff2] = useState(0);
  const [averagegbpaudbyodiff3, setaveragegbpaudbyodiff3] = useState(0);

  const [averagegbpchfbyodiff1, setaveragegbpchfbyodiff1] = useState(0);
  const [averagegbpchfbyodiff2, setaveragegbpchfbyodiff2] = useState(0);
  const [averagegbpchfbyodiff3, setaveragegbpchfbyodiff3] = useState(0);

  const [averagegbpeurbyodiff1, setaveragegbpeurbyodiff1] = useState(0);
  const [averagegbpeurbyodiff2, setaveragegbpeurbyodiff2] = useState(0);
  const [averagegbpeurbyodiff3, setaveragegbpeurbyodiff3] = useState(0);

  const [averagegbpnzdbyodiff1, setaveragegbpnzdbyodiff1] = useState(0);
  const [averagegbpnzdbyodiff2, setaveragegbpnzdbyodiff2] = useState(0);
  const [averagegbpnzdbyodiff3, setaveragegbpnzdbyodiff3] = useState(0);

  // <000000>
  const [averageDifff_flat0, setAverageDifff_flat0] = useState(0);
  const [averageDifff2_flat0, setAverageDifff2_flat0] = useState(0);
  const [averageDifff3_flat0, setAverageDifff3_flat0] = useState(0);
  const [avgjpydifff_flat0, setavgjpydifff_flat0] = useState(0);
  const [avgjpydifff2_flat0, setavgjpydifff2_flat0] = useState(0);
  const [avgjpydifff3_flat0, setavgjpydifff3_flat0] = useState(0);

  const [averagegbpcadbyodiff1_flat0, setaveragegbpcadbyodiff1_flat0] =useState(0);
  const [averagegbpcadbyodiff2_flat0, setaveragegbpcadbyodiff2_flat0] =useState(0);
  const [averagegbpcadbyodiff3_flat0, setaveragegbpcadbyodiff3_flat0] =useState(0);

  const [averagegbpaudbyodiff1_flat0, setaveragegbpaudbyodiff1_flat0] =useState(0);
  const [averagegbpaudbyodiff2_flat0, setaveragegbpaudbyodiff2_flat0] =useState(0);
  const [averagegbpaudbyodiff3_flat0, setaveragegbpaudbyodiff3_flat0] =useState(0);

  const [averagegbpchfbyodiff1_flat0, setaveragegbpchfbyodiff1_flat0] =useState(0);
  const [averagegbpchfbyodiff2_flat0, setaveragegbpchfbyodiff2_flat0] =useState(0);
  const [averagegbpchfbyodiff3_flat0, setaveragegbpchfbyodiff3_flat0] =useState(0);

  const [averagegbpeurbyodiff1_flat0, setaveragegbpeurbyodiff1_flat0] =useState(0);
  const [averagegbpeurbyodiff2_flat0, setaveragegbpeurbyodiff2_flat0] =useState(0);
  const [averagegbpeurbyodiff3_flat0, setaveragegbpeurbyodiff3_flat0] =useState(0);

  const [averagegbpnzdbyodiff1_flat0, setaveragegbpnzdbyodiff1_flat0] =useState(0);
  const [averagegbpnzdbyodiff2_flat0, setaveragegbpnzdbyodiff2_flat0] =useState(0);
  const [averagegbpnzdbyodiff3_flat0, setaveragegbpnzdbyodiff3_flat0] =useState(0);

  const [eventimpactontimespecificdate, seteventimpactontimespecificdate] =useState();
  const [eventimpactbeforetimespecificdate,seteventimpactbeforetimespecificdate,] = useState();

  // <----------------third table averge states---------------->
  const [averagegbpusddiff1_thirdtable, SetAveragegbpusddiff1_thirdtable] =useState(0);
  const [averagegbpjpydiff1_thirdtable, SetAveragegbpjpydiff1_thirdtable] =useState(0);
  const [averagegbpnzddiff1_thirdtable, SetAveragegbpnzddiff1_thirdtable] =useState(0);
  const [averagegbpeurdiff1_thirdtable, SetAveragegbpeurdiff1_thirdtable] =useState(0);
  const [averagegbpchfdiff1_thirdtable, SetAveragegbpchfdiff1_thirdtable] =useState(0);
  const [averagegbpauddiff1_thirdtable, SetAveragegbpauddiff1_thirdtable] =useState(0);
  const [averagegbpcaddiff1_thirdtable, SetAveragegbpcaddiff1_thirdtable] =useState(0);

  // <--------------third table flat 0 values----------->
  const [averagegbpusddiff1_flat0_thirdtable,setaveragegbpusddiff1_flat0_thirdtable,] = useState(0);
  const [averagegbpjpydiff1_flat0_thirdtable,setaveragegbpjpydiff1_flat0_thirdtable,] = useState(0);
  const [averagegbpnzddiff1_flat0_thirdtable,setaveragegbpnzddiff1_flat0_thirdtable,] = useState(0);
  const [averagegbpeurdiff1_flat0_thirdtable,setaveragegbpeurdiff1_flat0_thirdtable,] = useState(0);
  const [averagegbpchfdiff1_flat0_thirdtable,setaveragegbpchfdiff1_flat0_thirdtable,] = useState(0);
  const [averagegbpauddiff1_flat0_thirdtable,setaveragegbpauddiff1_flat0_thirdtable,] = useState(0);
  const [averagegbpcaddiff1_flat0_thirdtable,setaveragegbpcaddiff1_flat0_thirdtable,] = useState(0);

  const [clickeddate_Loading, setclickeddate_Loading] = useState(true);
  const [modalShow, setModalshow] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);



  const handleImageClick = (event) => {
    event.stopPropagation(); // Prevent immediate closing on click
    setShowTooltip((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });


  const averageactual_minus_estimate = () => {
    const filteredEventHistory = eventHistory.filter((item) => {
      const difference = Math.abs(item.actual - item.estimate);
      return (
        item.estimate !== null && item.estimate !== "" && difference !== 0.0
      );
    });
    const sum = filteredEventHistory.reduce((total, item) => {
      return total + Math.abs(item.actual - item.estimate);
    }, 0);

    const average = sum / filteredEventHistory.length;
    setSumActualEstimate(average.toFixed(4));
  };
  const averageactual_minus_estimatebyo = () => {
    const filteredEventHistory = eventHistory.filter((item) => {
      const difference = Math.abs(item.actual - item.estimate);
      return (
        item.estimate !== null && item.estimate !== "" && difference !== 0.0
      );
    });

    const sum = filteredEventHistory.reduce((total, item) => {
      return (
        total + Math.abs((item.actual - item.estimate) / item.estimate) * 100
      );
    }, 0);

    const average = sum / filteredEventHistory.length;
    setSumActualEstimatebyo(average.toFixed(1));
  };

  // <--final----->

  const averagefinalmovement = () => {
    const filteredEventHistory = eventHistory.filter((item) => {
      return item.estimate !== null && item.estimate !== "";
    });

    const sum = filteredEventHistory.reduce((total, item) => {
      const difference =
        item.actual - item.estimate === 0 ||
        item.estimate === null ||
        item.estimate === ""
          ? item.actual - item.previous
          : item.actual - item.estimate;

      return total + Math.abs(difference);
    }, 0);

    const average = sum / filteredEventHistory.length;
    // console.log("average" , average)
    setSumfinalmovement(average.toFixed(4));
  };
  const averagefinalmovementbyo = () => {
    const filteredEventHistory = eventHistory.filter((item) => {
      return item.estimate !== null && item.estimate !== "";
    });

    const sum = filteredEventHistory.reduce((total, item) => {
      const difference =
        item.actual - item.estimate === 0 ||
        item.estimate === null ||
        item.estimate === ""
          ? item.actual - item.previous
          : item.actual - item.estimate;
      const base =
        item.actual - item.estimate === 0 ||
        item.estimate === null ||
        item.estimate === ""
          ? item.previous
          : item.estimate;

      return total + Math.abs((difference / base) * 100);
    }, 0);

    const average = sum / filteredEventHistory.length;
    setSumfinalmovementbyo(average.toFixed(1));
  };

  const averageactual_minus_previous = () => {
    const filteredEventHistory = eventHistory.filter((item) => {
      const difference = Math.abs(item.actual - item.previous);
      return (
        item.previous !== null && item.previous !== "" && difference !== 0.0
      );
    });
    const sum = filteredEventHistory.reduce((total, item) => {
      return total + Math.abs(item.actual - item.previous);
    }, 0);

    const average = sum / filteredEventHistory.length;
    setSumActualPrevious(average.toFixed(4));
  };

  const averageactual_minus_previousbyo = () => {
    const filteredEventHistory = eventHistory.filter((item) => {
      const difference = Math.abs(item.actual - item.previous);
      return (
        item.previous !== null &&
        item.previous !== "" &&
        difference !== 0.0 &&
        item.previous !== 0
      );
    });

    const sum = filteredEventHistory.reduce((total, item) => {
      return (
        total + Math.abs((item.actual - item.previous) / item.previous) * 100
      );
    }, 0);

    // console.log("filteredEventHistory.length" , filteredEventHistory.length)
    const average = sum / filteredEventHistory.length;
    setSumActualPreviousbyo(average.toFixed(1));
  };

  useEffect(() => {
    averageactual_minus_estimate();
    averageactual_minus_estimatebyo();
    averageactual_minus_previous();
    averageactual_minus_previousbyo();
    averagefinalmovement();
    averagefinalmovementbyo();
  }, [eventHistory]);

  const UKHeaders = [
    { name: "GBP/USD", id: "gbpusd" },
    { name: "GBP/JPY", id: "gbpjpy" },
    { name: "GBP/NZD", id: "gbpnzd" },
    { name: "EUR/GBP", id: "eurgbp" },
    { name: "GBP/CHF", id: "gbpchf" },
    { name: "GBP/AUD", id: "gbpaud" },
    { name: "GBP/CAD", id: "gbpcad" },
  ];
  const USHeaders = [
    { name: "GBP/USD", id: "gbpusd" },
    { name: "USD/JPY", id: "usdjpy" },
    { name: "NZD/USD", id: "nzdusd" },
    { name: "EUR/USD", id: "eurusd" },
    { name: "USD/CHF", id: "usdchf" },
    { name: "AUD/USD", id: "audusd" },
    { name: "USD/CAD", id: "usdcad" },
  ];
  const JPHeaders = [
    { name: "USD/JPY", id: "usdjpy" },
    { name: "GBP/JPY", id: "gbpjpy" },
    { name: "NZD/JPY", id: "nzdjpy" },
    { name: "EUR/JPY", id: "eurjpy" },
    { name: "CHF/JPY", id: "chfjpy" },
    { name: "AUD/JPY", id: "audjpy" },
    { name: "CAD/JPY", id: "cadjpy" },
  ];
  const CAHeaders = [
    { name: "USD/CAD", id: "usdcad" },
    { name: "CAD/JPY", id: "cadjpy" },
    { name: "NZD/CAD", id: "nzdcad" },
    { name: "EUR/CAD", id: "eurcad" },
    { name: "CAD/CHF", id: "cadchf" },
    { name: "AUD/CAD", id: "audcad" },
    { name: "GBP/CAD", id: "gbpcad" },
  ];
  const CHEHeaders = [
    { name: "USD/CHF", id: "usdchf" },
    { name: "CHF/JPY", id: "chfjpy" },
    { name: "NZD/CHF", id: "nzdchf" },
    { name: "EUR/CHF", id: "eurchf" },
    { name: "GBP/CHF", id: "gbpchf" },
    { name: "AUD/CHF", id: "audchf" },
    { name: "CAD/CHF", id: "cadchf" },
  ];
  const EUHeaders = [
    { name: "EUR/USD", id: "eurusd" },
    { name: "EUR/JPY", id: "eurjpy" },
    { name: "EUR/NZD", id: "eurnzd" },
    { name: "EUR/GBP", id: "eurgbp" },
    { name: "EUR/CHF", id: "eurchf" },
    { name: "EUR/AUD", id: "euraud" },
    { name: "EUR/CAD", id: "eurcad" },
  ];
  const AUHeaders = [
    { name: "AUD/USD", id: "audusd" },
    { name: "AUD/JPY", id: "audjpy" },
    { name: "AUD/NZD", id: "audnzd" },
    { name: "EUR/AUD", id: "euraud" },
    { name: "AUD/CHF", id: "audchf" },
    { name: "GBP/AUD", id: "gbpaud" },
    { name: "AUD/CAD", id: "audcad" },
  ];
  const NZHeaders = [
    { name: "NZD/USD", id: "nzdusd" },
    { name: "NZD/JPY", id: "nzdjpy" },
    { name: "GBP/NZD", id: "gbpnzd" },
    { name: "EUR/NZD", id: "eurnzd" },
    { name: "NZD/CHF", id: "nzdchf" },
    { name: "AUD/NZD", id: "audnzd" },
    { name: "NZD/CAD", id: "nzdcad" },
  ];

  const headers =
    country === "UK"
      ? UKHeaders
      : country === "US"
      ? USHeaders
      : country === "JP"
      ? JPHeaders
      : country === "CA"
      ? CAHeaders
      : country === "CH"
      ? CHEHeaders
      : country === "EU" ||
        country === "DE" ||
        country === "IT" ||
        country === "FR"
      ? EUHeaders
      : country === "AU"
      ? AUHeaders
      : NZHeaders;

  const handlegeneratechart = () => {
    // window.open("/user/graphdata", "_blank");
    // navigate("/admin/graphdata");
    const url = new URL(window.location.origin + "/user/graphdata");
    url.searchParams.set("eventName", event);
    url.searchParams.set("eventcountry", country);

    const newTab = window.open(url.toString(), "_blank");
  };

  const historydata_table = async (timeclicked) => {
    try {
      // console.log("timeclicked" , timeclicked)
      // console.log("timeclicked:-------->", typeof timeclicked, timeclicked);

      if (timeclicked === 1) {
        setoff_minutebuttons1(true);
        setoff_minutebuttons2(false);
        setoff_minutebuttons3(false);
        setoff_minutebuttons4(false);
      } else if (timeclicked === 5) {
        setoff_minutebuttons1(true);
        setoff_minutebuttons2(true);
        setoff_minutebuttons3(false);
        setoff_minutebuttons4(false);
      } else if (timeclicked === 15) {
        setoff_minutebuttons1(true);
        setoff_minutebuttons2(true);
        setoff_minutebuttons3(true);
        setoff_minutebuttons4(false);
      } else {
        setoff_minutebuttons1(true);
        setoff_minutebuttons2(true);
        setoff_minutebuttons3(true);
        setoff_minutebuttons4(true);
      }

      // const eventandcountrydata = localStorage.getItem("eventnameandcountry:");
      // const eventDataObj = JSON.parse(eventandcountrydata);

      // const { eventName, eventcountry, eventimpact, eventtime, eventdate } =eventDataObj;
      const urlParams = new URLSearchParams(window.location.search);
      const eventName = decodeURIComponent(urlParams.get("eventName"));

      const eventcountry = decodeURIComponent(urlParams.get("eventcountry"));
      seteventname_forerror(eventName);
      const eventimpact = decodeURIComponent(urlParams.get("eventimpact"));
      const eventtime = decodeURIComponent(urlParams.get("eventtime"));
      const eventdate = decodeURIComponent(urlParams.get("eventdate"));
      const timeclickedurl = parseFloat(
        decodeURIComponent(urlParams.get("timeclickedurl"))
      );
      console.log("timeclickedurl", timeclickedurl, typeof timeclickedurl); // should log a float
      setTimeclickedurl_state(timeclickedurl)
      const storedData = JSON.parse(localStorage.getItem("All_events_history"));

      let matchingData;
      if (storedData == null) {
        matchingData = false;
      } else {
        matchingData = storedData.find((item) => {
          console.log("item.timeclicked", item.timeclicked);
          // console.log("timeclickedurl-------------" , timeclickedurl)

          return (
            item.timeclicked === timeclickedurl &&
            item.eventName === eventName &&
            item.eventcountry === eventcountry
          );
        });
      }
      // console.log("setoff_minutebuttons1" , setoff_minutebuttons1 , setoff_minutebuttons2 , setoff_minutebuttons3 , setoff_minutebuttons4)
      // <---------if else - depends on the data matched or not ------------>
      if (storedData && matchingData) {
        if (matchingData) {
          const { historydata, average_historydata } = matchingData;
          setCountry(eventcountry);
          setEvent(eventName);
          setimpact(eventimpact);
          settime(eventtime);
          setdate(eventdate);

          setAllEventHistory2(average_historydata.eventdata2);
          setEventHistory2(average_historydata.eventdata2);
          setusddata_avg(average_historydata.usddata2);
          setjpydata_avg(average_historydata.jpydata2);
          setgbpnzddata_avg(average_historydata.nzddata2);
          seteurgbpdata_avg(average_historydata.eurdata2);
          setgbpchfdata_avg(average_historydata.chfdata2);
          setgbpauddata_avg(average_historydata.auddata2);
          setgbpcaddata_avg(average_historydata.caddata2);
          setLoading(false);
          setIsgraphbuttonDisbled(false)
          setAllEventHistory(historydata.eventdata);
          setEventHistory(historydata.eventdata);
          setusddata(historydata.usddata);
          setjpydata(historydata.jpydata);
          setgbpnzddata(historydata.nzddata);
          seteurgbpdata(historydata.eurdata);
          setgbpchfdata(historydata.chfdata);
          setgbpauddata(historydata.auddata);
          setgbpcaddata(historydata.caddata);

          setLoading_3rdtable(false);
          setRunclickedevent_dateapi(true);
          setRunclickedevent_dateapi2(true);
          // setoff_minutebuttons(true)

          setoff_minutebuttons1(true);
          setoff_minutebuttons2(true);
          setoff_minutebuttons3(true);
          setoff_minutebuttons4(true);
          return;
        }
      } else {
        setLoading(true);
        const tokenfromls = localStorage.getItem("usertoken");
        const h = { authorization: tokenfromls };

        const response7 = await axios.post(
          `${process.env.REACT_APP_URL}/geteventhistory2`,
          { eventName, eventcountry, selectedTime: timeclicked },
          { headers: h }
        );

        if (response7 != null) {
          setLoading(false);
        }

        const average_historydata = response7.data;
        // console.log("hlo ashi am 1st api , ", response7.data);
        setCountry(eventcountry);
        setEvent(eventName);
        setimpact(eventimpact);
        settime(eventtime);
        setdate(eventdate);

        setAllEventHistory2(average_historydata.eventdata2);
        setEventHistory2(average_historydata.eventdata2);
        setusddata_avg(average_historydata.usddata2);
        setjpydata_avg(average_historydata.jpydata2);
        setgbpnzddata_avg(average_historydata.nzddata2);
        seteurgbpdata_avg(average_historydata.eurdata2);
        setgbpchfdata_avg(average_historydata.chfdata2);
        setgbpauddata_avg(average_historydata.auddata2);
        setgbpcaddata_avg(average_historydata.caddata2);
        setLoading(false);
        setLoading_3rdtable(true);

        const response = await axios.post(
          `${process.env.REACT_APP_URL}/geteventhistory`,
          { eventName, eventcountry, selectedTime: timeclicked },
          { headers: h }
        );

        const historydata = response.data;

        // console.log("hlo ashi am 2nd api , ", response.data);
        setAllEventHistory(historydata.eventdata);
        setEventHistory(historydata.eventdata);
        setusddata(historydata.usddata);
        setjpydata(historydata.jpydata);
        setgbpnzddata(historydata.nzddata);
        seteurgbpdata(historydata.eurdata);
        setgbpchfdata(historydata.chfdata);
        setgbpauddata(historydata.auddata);
        setgbpcaddata(historydata.caddata);
        setLoading_3rdtable(false);
        setRunclickedevent_dateapi(true);
        setRunclickedevent_dateapi2(true);
        // setoff_minutebuttons(true)

        setoff_minutebuttons1(true);
        setoff_minutebuttons2(true);
        setoff_minutebuttons3(true);
        setoff_minutebuttons4(true);

        // <-------------kapil---------->
        const dataToStore = {
          historydata,
          timeclicked,
          eventName,
          eventcountry,
          eventimpact,
          eventtime,
          eventdate,
          average_historydata,
        };
        const graph_data = response.data.eventdata;
        if (Array.isArray(graph_data)) {
          const limited_graph_data = graph_data.slice(0, 4);
          const gd_array = {
            event: eventName,
            country: eventcountry,
            time: timeclicked,
            four_graph_values: limited_graph_data,
          };

          let graph_aray = localStorage.getItem("4GraphDataobjects");
          let grapharray = JSON.parse(graph_aray) || [];
          grapharray.push(gd_array);
          localStorage.setItem("4GraphDataobjects", JSON.stringify(grapharray));
          setIsgraphbuttonDisbled(false)
        } else {
          console.error("graph_data is not an array:", graph_data);
          setIsgraphbuttonDisbled(false)
        }

        let curraray = localStorage.getItem("All_events_history");
        let arr = JSON.parse(curraray) || [];
        arr.push(dataToStore);

        localStorage.setItem("All_events_history", JSON.stringify(arr));
      }
    } catch (error) {
      setLoading(false);
      setLoading_3rdtable(false);
      if (error.response && error.response.status === 400) {
        setLoading(false);
        setLoading_3rdtable(false);
        console.log("hlo am here");
        const errorMessage = "No data available for ";
        setErrorMessage(errorMessage);
        setIsgraphbuttonDisbled(false)
      } else {
        setLoading(false);
        setLoading_3rdtable(false);
        const errorMessage = "Error fetching data. Please try again later.";
        setErrorMessage(errorMessage);
        setIsgraphbuttonDisbled(false)
      }
      console.error("Error showing data:", error);
    }
  };

  useEffect(() => {
    historydata_table(selectedTime);
  }, [selectedTime]);

  // <---------------headers------------>
  const getheaderscount_highlow = async () => {
    // console.log("hi am in header code now ");
    setclickeddate_Loading(true);
    if (run_clickedevent_dateapi) {
      try {
        // Fetch event and country data from localStorage
        // const eventandcountrydata = localStorage.getItem(
        //   "eventnameandcountry:"
        // );
        // const eventDataObj = JSON.parse(eventandcountrydata);
        // const { eventName, eventcountry, eventtime, eventdate } = eventDataObj;
        const urlParams = new URLSearchParams(window.location.search);
        const eventName = decodeURIComponent(urlParams.get("eventName"));
        const eventcountry = decodeURIComponent(urlParams.get("eventcountry"));
        // const eventimpact = decodeURIComponent(urlParams.get('eventimpact'));
        const eventtime = decodeURIComponent(urlParams.get("eventtime"));
        const eventdate = decodeURIComponent(urlParams.get("eventdate"));
        // const storedData = JSON.parse(localStorage.getItem("All_events_history"));
        // Create a unique key based on event attributes
        const uniqueKey = `${eventName}-${eventcountry}-${eventdate}-${eventtime}`;
        // console.log("uniqueKey_localdata" , uniqueKey)
        // Check if data already exists in localStorage for this event
        let storedData1 = JSON.parse(
          localStorage.getItem(`Clickeddate_data_${uniqueKey}`)
        );
        // console.log("storedData1_localdata" , storedData1)
        if (storedData1) {
          // If matching data found in localStorage, set the state directly
          seteventimpactontimespecificdate(
            storedData1.specificdateimpactontime
          );
          seteventimpactbeforetimespecificdate(
            storedData1.specificdateimpactbeforetime
          );
          setRunclickedevent_dateapi2(true);
          setclickeddate_Loading(false);
        } else {
          // Fetch new data if not found in localStorage
          const tokenfromls = localStorage.getItem("usertoken");
          const headers = { authorization: tokenfromls };

          // Fetch specific date impact data
          const response5 = await axios.post(
            `${process.env.REACT_APP_URL}/eventsimpactonaspecificdate`,
            { eventName, eventcountry, eventdate, eventtime },
            { headers }
          );

          const specificdateimpactontime = response5.data;
          console.log("specificdateimpactontime", specificdateimpactontime);

          // Fetch before specific date impact data
          const response6 = await axios.post(
            `${process.env.REACT_APP_URL}/eventsimpactbeforeaspecificdate`,
            { eventName, eventcountry, eventdate, eventtime },
            { headers }
          );

          const specificdateimpactbeforetime = response6.data;
          // console.log(
          //   "specificdateimpactbeforetime",
          //   specificdateimpactbeforetime
          // );
          setRunclickedevent_dateapi2(true);

          // Prepare the data to store
          const dataToStored = {
            specificdateimpactontime,
            specificdateimpactbeforetime,
          };

          // Update localStorage with the new data
          const d = localStorage.setItem(
            `Clickeddate_data_${uniqueKey}`,
            JSON.stringify(dataToStored)
          );

          // console.log("setdata" , d)
          // Update state with the fetched data
          seteventimpactontimespecificdate(specificdateimpactontime);
          seteventimpactbeforetimespecificdate(specificdateimpactbeforetime);
          setclickeddate_Loading(false);
        }
      } catch (error) {
        console.error("Error fetching specific date impact data:", error);
        setclickeddate_Loading(false);
      }
    }
  };

  useEffect(() => {
    if (run_clickedevent_dateapi) {
      getheaderscount_highlow();
    }
  }, [run_clickedevent_dateapi]);

  // <-----------modal high low count------------>
  const handlegetmodaldata_fromApi = async () => {
    if (run_clickedevent_dateapi2) {
      // console.log("hi am in modal now ");

      try {
        // const eventandcountrydata = localStorage.getItem(
        //   "eventnameandcountry:"
        // );
        // const eventDataObj2 = JSON.parse(eventandcountrydata);
        // const { eventName, eventcountry, eventdate, eventtime } = eventDataObj2;

        const urlParams = new URLSearchParams(window.location.search);
        const eventName = decodeURIComponent(urlParams.get("eventName"));
        const eventcountry = decodeURIComponent(urlParams.get("eventcountry"));
        // const eventimpact = decodeURIComponent(urlParams.get('eventimpact'));
        const eventtime = decodeURIComponent(urlParams.get("eventtime"));
        const eventdate = decodeURIComponent(urlParams.get("eventdate"));
        const storedData = JSON.parse(
          localStorage.getItem("All_events_history")
        );
        const uniqueKey1 = `${eventName}-${eventcountry}-${eventdate}-${eventtime}`;
        // console.log("uniqueKey_localdata" , uniqueKey1)
        const storedData2 = JSON.parse(
          localStorage.getItem(`clickedmodal_data_2ndpage_${uniqueKey1}`)
        );

        if (storedData2) {
          setimpacthighlow(storedData2[0].summaryimpacthighlow);
          setmodalimpactdataontime(storedData2[0].modalimpacthighlowontime);
          setimpacthighlowbeforethis(storedData2[0].finalimpactsummary);
          setmodalimpactdatabeforetime(storedData2[0].modalimpactdata2);
          setRun_modalloader(false);
        }
        // <---------------data first time run and setting into local------------->
        else {
          const tokenfromls = localStorage.getItem("usertoken");
          const h = { authorization: tokenfromls };

          const response2 = await axios.post(
            `${process.env.REACT_APP_URL}/findhighlowhistoryevents`,
            { eventName, eventcountry },
            { headers: h }
          );

          // console.log("response2", response2);
          const response3 = await axios.post(
            `${process.env.REACT_APP_URL}/highloweventbeforedate`,
            { eventName, eventcountry },
            { headers: h }
          );
          // console.log("response3", response3);

          // <-----------this code is for on time-------------->
          const gethighlowimpact = response2.data;
          const modalimpacthighlowontime = gethighlowimpact.impactdata1;
          const summaryimpacthighlow = gethighlowimpact.impactdata2;

          // <-----------this code is for before time-------------->
          const impactdata = response3.data;
          const finalimpactsummary = impactdata.impactdata2;
          const modalimpactdata2 = impactdata.impactdata1;
          // <-------------main data--------------->
          setRun_modalloader(false);
          const dataToStore3 = {
            finalimpactsummary,
            summaryimpacthighlow,
            modalimpacthighlowontime,
            modalimpactdata2,
          };

          let curraray_clickedmodal = localStorage.getItem(
            "clickedmodal_data_2ndpage"
          );

          let arr_clickedmodal = JSON.parse(curraray_clickedmodal) || [];
          arr_clickedmodal.push(dataToStore3);

          localStorage.setItem(
            `clickedmodal_data_2ndpage_${uniqueKey1}`,
            JSON.stringify(arr_clickedmodal)
          );

          setimpacthighlow(summaryimpacthighlow);
          setmodalimpactdataontime(modalimpacthighlowontime);
          setimpacthighlowbeforethis(finalimpactsummary);
          setmodalimpactdatabeforetime(modalimpactdata2);
        }
      } catch (error) {
        console.log("error", error);
        setRun_modalloader(false);
        // console.log("kushagra error")
      }
    }
  };

  useEffect(() => {
    if (run_clickedevent_dateapi2) {
      handlegetmodaldata_fromApi();
    }
  }, [run_clickedevent_dateapi2]);

  
  const handleOptionChange = useCallback(
    (value) => {
      setOptionValue(value);

      // Map values to slicing logic
      const slices = {
        "Last 24": [allEventHistory, allEventHistory2],
        "Last 12": [
          allEventHistory.slice(0, 12),
          allEventHistory2.slice(0, 12),
        ],
        "Last 6": [allEventHistory.slice(0, 6), allEventHistory2.slice(0, 6)],
      };

      // Extract slices or default to empty arrays
      const [history, history2] = slices[value] || [[], []];
      setEventHistory(history);
      setEventHistory2(history2);
    },
    [allEventHistory, allEventHistory2]
  );
  useEffect(() => {
    handleOptionChange("Last 6");
  }, [handleOptionChange]);

  // <------calvulate average of 1st table ------>
  // <--C O --->
  const calculate_currencyaverage = () => {
   

    const a_minus_evalue = eventHistory.map((item) =>
      item.actual - item.estimate === 0 ||
      item.estimate === null ||
      item.estimate === ""
        ? item.actual - item.previous
        : item.actual - item.estimate
    );

   
    // <---here we r matching date time , bcz. length r different --->
    const eventavgtime = eventHistory.map((item) => item.time);
    const eventavgestimate = eventHistory.map((item) => item.estimate);
    const eventavgdate = eventHistory.map((item) =>
      item.date.split(".").reverse().join(".")
    );

    const calculateSumAndAverage = (data, differences) => {
      // console.log("usddata" , usddata)
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        // <---open close ki empty value add kr rhe hum , for same length---->
        return entry || { date: date, time: time, Open: "", Close: "" };
      });
      

      if (filledData.some((entry) => entry.Open !== "" && entry.Close !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] !== 0.0 && differences[index] !== -0.0) {
            if (item.Close === "" || item.Open === "") {
              return total;
            }
            const differenceCO = item.Close - item.Open;

         

            let modulusCO;
            if (data === jpydata) {
              modulusCO = Math.abs(Math.round(differenceCO * 1000));
            } else {
              modulusCO = Math.abs(
                Math.round(
                  differenceCO.toFixed(country === "JP" ? 3 : 5) *
                    (country === "JP" ? 1000 : 100000)
                )
              );
            }

            // console.log("modulusCO", modulusCO)
            return total + modulusCO;
          }
          return total;
        }, 0);

        const validEntriesCount = filledData.filter((item, index) => {
          const opencloseNotEmpty = item.Open !== "" && item.Close !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            opencloseNotEmpty &&
            differences[index] !== 0.0 &&
            differences[index] !== -0.0
          );
        }).length;

        // console.log("validEntriesCount" , validEntriesCount)
        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        // console.log("average" , average)
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets
    const averageOC1 = calculateSumAndAverage(usddata, a_minus_evalue);
    setAverageDiff(averageOC1);
    const averageOC2 = calculateSumAndAverage(jpydata, a_minus_evalue);
    setavgjpydiff(averageOC2);
    const averageOC3 = calculateSumAndAverage(gbpnzddata, a_minus_evalue);
    setaveragegbpnzddiff1(averageOC3);
    const averageOC4 = calculateSumAndAverage(eurgbpdata, a_minus_evalue);
    setaveragegbpeurdiff1(averageOC4);
    const averageOC5 = calculateSumAndAverage(gbpchfdata, a_minus_evalue);
    setaveragegbpchfdiff1(averageOC5);
    const averageOC6 = calculateSumAndAverage(gbpauddata, a_minus_evalue);
    setaveragegbpauddiff1(averageOC6);
    const averageOC7 = calculateSumAndAverage(gbpcaddata, a_minus_evalue);
    setaveragegbpcaddiff1(averageOC7);

    // <----LO---->

    const calculateSumAndAverageLO = (data, differences) => {
      //   const finalentryavg = data.find((entry) => {
      //     return eventavgdate.includes(entry.date) && eventavgtime.includes(entry.time);
      // });
      //   if(finalentryavg){
      //     const sum = data.reduce((total, item, index) => {

      //       if (differences[index] !== 0.0000 && differences[index] !== -0.0000) {
      //         const differenceLO = item.Low - item.Open;
      //         const modulusLO = Math.abs(differenceLO);
      //         return total + modulusLO;
      //       }
      //       return total;
      //     }, 0);

      //     const validEntriesCount = differences.filter(diff => diff !== 0.0000 && diff !== -0.0000).length;
      //     const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      //     return average;
      //   }
      //   else{
      //     return null
      //    }

      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", Low: "" };
      });

      // console.log("filteredData" , filteredData)
      if (filledData.some((entry) => entry.Open !== "" && entry.Low !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] !== 0.0 && differences[index] !== -0.0) {
            if (item.Low === "" || item.Open === "") {
              return total;
            }
            // const differenceLO = item.Low - item.Open;
            // console.log("differenceLO" , differenceLO)
            const differenceLO = item.Low - item.Open;
            //  console.log(`item.Low (${item.Low}) - item.Open (${item.Open}) = ${differenceLO}`);

            let modulusLO;
            if (data === jpydata) {
              modulusLO = Math.abs(Math.round(differenceLO * 1000));
            } else {
              modulusLO = Math.abs(
                Math.round(
                  differenceLO.toFixed(country === "JP" ? 3 : 5) *
                    (country === "JP" ? 1000 : 100000)
                )
              );
            }

            return total + modulusLO;
          }
          return total;
        }, 0);

        const validEntriesCount = filledData.filter((item, index) => {
          const openlowNotEmpty = item.Open !== "" && item.Low !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openlowNotEmpty &&
            differences[index] !== 0.0 &&
            differences[index] !== -0.0
          );
        }).length;

        // console.log("validEntriesCount" , validEntriesCount)
        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets
    const averageOL1 = calculateSumAndAverageLO(usddata, a_minus_evalue);
    setAverageDiff2(averageOL1);
    const averageOL2 = calculateSumAndAverageLO(jpydata, a_minus_evalue);
    setavgjpydiff2(averageOL2);
    const averageOL3 = calculateSumAndAverageLO(gbpnzddata, a_minus_evalue);
    setaveragegbpnzddiff2(averageOL3);
    const averageOL4 = calculateSumAndAverageLO(eurgbpdata, a_minus_evalue);
    setaveragegbpeurdiff2(averageOL4);
    const averageOL5 = calculateSumAndAverageLO(gbpchfdata, a_minus_evalue);
    setaveragegbpchfdiff2(averageOL5);
    const averageOL6 = calculateSumAndAverageLO(gbpauddata, a_minus_evalue);
    setaveragegbpauddiff2(averageOL6);
    const averageOL7 = calculateSumAndAverageLO(gbpcaddata, a_minus_evalue);
    setaveragegbpcaddiff2(averageOL7);

    // <-----HO----->

    const calculateSumAndAverageHO = (data, differences) => {
      //   const finalentryavg = data.find((entry) => {
      //     return eventavgdate.includes(entry.date) && eventavgtime.includes(entry.time);
      // });
      //   if(finalentryavg){
      //     const sum = data.reduce((total, item, index) => {
      //       if (differences[index] !== 0.0000 && differences[index] !== -0.0000) {
      //         const differenceHO = item.High - item.Open;
      //         const modulusHO = Math.abs(differenceHO);
      //         return total + modulusHO;
      //       }
      //       return total;
      //     }, 0);

      //     const validEntriesCount = differences.filter(diff => diff !== 0.0000 && diff !== -0.0000).length;
      //     const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      //     return average;
      //   }
      //   else{
      //     return null
      //    }
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", High: "" };
      });

      // const filteredData = filledData.filter((entry, index) => {
      //   return (
      //     eventavgestimate[index] !== undefined &&
      //     eventavgestimate[index] !== null
      //   );
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.High !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] !== 0.0 && differences[index] !== -0.0) {
            if (item.High === "" || item.Open === "") {
              return total;
            }
            const differenceHO = item.High - item.Open;

            let modulusHO;
            if (data === jpydata) {
              modulusHO = Math.abs(Math.round(differenceHO * 1000));
            } else {
              modulusHO = Math.abs(
                Math.round(
                  differenceHO.toFixed(country === "JP" ? 3 : 5) *
                    (country === "JP" ? 1000 : 100000)
                )
              );
            }
            // const modulusHO = Math.abs(Math.round(differenceHO.toFixed(country === "JP" ? 3 : 5)*(country === "JP" ? 1000 : 100000)));
            return total + modulusHO;
          }
          return total;
        }, 0);

        // const validEntriesCount = filteredData.filter(
        //   (item, index) =>
        //     differences[index] !== 0.0 ||
        //     (differences[index] !== -0.0 &&
        //       item.Open !== "" &&
        //       item.High !== "")
        // ).length;

        const validEntriesCount = filledData.filter((item, index) => {
          const openhighNotEmpty = item.Open !== "" && item.High !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openhighNotEmpty &&
            differences[index] !== 0.0 &&
            differences[index] !== -0.0
          );
        }).length;
        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets
    const averageOH1 = calculateSumAndAverageHO(usddata, a_minus_evalue);
    setAverageDiff3(averageOH1);
    const averageOH2 = calculateSumAndAverageHO(jpydata, a_minus_evalue);
    setavgjpydiff3(averageOH2);
    const averageOH3 = calculateSumAndAverageHO(gbpnzddata, a_minus_evalue);
    setaveragegbpnzddiff3(averageOH3);
    const averageOH4 = calculateSumAndAverageHO(eurgbpdata, a_minus_evalue);
    setaveragegbpeurdiff3(averageOH4);
    const averageOH5 = calculateSumAndAverageHO(gbpchfdata, a_minus_evalue);
    setaveragegbpchfdiff3(averageOH5);
    const averageOH6 = calculateSumAndAverageHO(gbpauddata, a_minus_evalue);
    setaveragegbpauddiff3(averageOH6);
    const averageOH7 = calculateSumAndAverageHO(gbpcaddata, a_minus_evalue);
    setaveragegbpcaddiff3(averageOH7);

    /* <for 0, C-0--> */

    const calculateSumAndAverage_flat0 = (data, differences) => {
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", Close: "" };
      });

      // const filteredData = filledData.filter((entry, index) => {
      //   return eventavgestimate[index] !== undefined && eventavgestimate[index] !== null;
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.Close !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] === 0.0 || differences[index] === -0.0) {
            if (item.Close === "" || item.Open === "") {
              return total;
            }
            const differenceCO = item.Close - item.Open;

            let modulusCO;
            if (data === jpydata) {
              modulusCO = Math.abs(Math.round(differenceCO * 1000));
            } else {
              modulusCO = Math.abs(
                Math.round(
                  differenceCO.toFixed(country === "JP" ? 3 : 5) *
                    (country === "JP" ? 1000 : 100000)
                )
              );
            }
            // console.log("differenceCO" , differenceCO)
            // const modulusCO = Math.abs(Math.round(differenceCO.toFixed(country === "JP" ? 3 : 5)*(country === "JP" ? 1000 : 100000)));
            // console.log("modulusCO" , modulusCO)
            return total + modulusCO;
          }
          return total;
        }, 0);

        // const validEntriesCount = filledData.filter(
        //   (item, index) =>
        //     differences[index] === 0.0 ||
        //     (differences[index] === -0.0 &&
        //       item.Open !== "" &&
        //       item.Close !== "")
        // ).length;

        const validEntriesCount = filledData.filter((item, index) => {
          const opencloseNotEmpty = item.Open !== "" && item.Close !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            opencloseNotEmpty &&
            differences[index] === 0.0 &&
            differences[index] === -0.0
          );
        }).length;
        // console.log("validEntriesCount" , validEntriesCount)

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets when actual - estimate is zero
    const averageOC1_flat0 = calculateSumAndAverage_flat0(
      usddata,
      a_minus_evalue
    );
    setAverageDiff_flat0(averageOC1_flat0);
    const averageOC2_flat0 = calculateSumAndAverage_flat0(
      jpydata,
      a_minus_evalue
    );
    setavgjpydiff_flat0(averageOC2_flat0);
    const averageOC3_flat0 = calculateSumAndAverage_flat0(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzddiff1_flat0(averageOC3_flat0);
    const averageOC4_flat0 = calculateSumAndAverage_flat0(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurdiff1_flat0(averageOC4_flat0);
    const averageOC5_flat0 = calculateSumAndAverage_flat0(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfdiff1_flat0(averageOC5_flat0);
    const averageOC6_flat0 = calculateSumAndAverage_flat0(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpauddiff1_flat0(averageOC6_flat0);
    // console.log("averageOC6_flat0" , averageOC6_flat0)
    const averageOC7_flat0 = calculateSumAndAverage_flat0(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcaddiff1_flat0(averageOC7_flat0);

    // <----------------FLAT 0 , L-O---->

    const calculateSumAndAverageLO_flat0 = (data, differences) => {
      //   const finalentryavg = data.find((entry) => {
      //     return eventavgdate.includes(entry.date) && eventavgtime.includes(entry.time);
      // });

      // if(finalentryavg){
      //   const sum = data.reduce((total, item, index) => {
      //     if (differences[index] === 0.0000 || differences[index] === -0.0000) {
      //       const differenceLO = item.Low - item.Open;
      //       const modulusLO = Math.abs(differenceLO);
      //       return total + modulusLO;
      //     }
      //     return total;
      //   }, 0);

      //   const validEntriesCount = differences.filter(diff => diff === 0.0000 || diff === -0.0000).length;
      //   const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      //   return average;
      // }
      // else{
      //   return null
      //  }

      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", Low: "" };
      });

      // const filteredData = filledData.filter((entry, index) => {
      //   return eventavgestimate[index] !== undefined && eventavgestimate[index] !== null;
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.Low !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] === 0.0 || differences[index] === -0.0) {
            if (item.Low === "" || item.Open === "") {
              return total;
            }
            const differenceLO = item.Low - item.Open;

            let modulusLO;
            if (data === jpydata) {
              modulusLO = Math.abs(Math.round(differenceLO * 1000));
            } else {
              modulusLO = Math.abs(
                Math.round(
                  differenceLO.toFixed(country === "JP" ? 3 : 5) *
                    (country === "JP" ? 1000 : 100000)
                )
              );
            }
            // console.log("differenceLO" , differenceLO)
            // const modulusLO = Math.abs(Math.round(differenceLO.toFixed(country === "JP" ? 3 : 5)*(country === "JP" ? 1000 : 100000)));
            // console.log("modulusLO" , modulusLO)
            return total + modulusLO;
          }
          return total;
        }, 0);

        // const validEntriesCount = filledData.filter(
        //   (item, index) =>
        //     differences[index] === 0.0 ||
        //     (differences[index] === -0.0 && item.Open !== "" && item.Low !== "")
        // ).length;

        const validEntriesCount = filledData.filter((item, index) => {
          const openlowNotEmpty = item.Open !== "" && item.Low !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openlowNotEmpty &&
            differences[index] === 0.0 &&
            differences[index] === -0.0
          );
        }).length;
        // console.log("validEntriesCount" , validEntriesCount)

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets when actual - estimate is zero
    const averageOL1_flat0 = calculateSumAndAverageLO_flat0(
      usddata,
      a_minus_evalue
    );
    setAverageDiff2_flat0(averageOL1_flat0);
    const averageOL2_flat0 = calculateSumAndAverageLO_flat0(
      jpydata,
      a_minus_evalue
    );
    setavgjpydiff2_flat0(averageOL2_flat0);
    const averageOL3_flat0 = calculateSumAndAverageLO_flat0(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzddiff2_flat0(averageOL3_flat0);
    const averageOL4_flat0 = calculateSumAndAverageLO_flat0(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurdiff2_flat0(averageOL4_flat0);
    const averageOL5_flat0 = calculateSumAndAverageLO_flat0(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfdiff2_flat0(averageOL5_flat0);
    const averageOL6_flat0 = calculateSumAndAverageLO_flat0(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpauddiff2_flat0(averageOL6_flat0);
    const averageOL7_flat0 = calculateSumAndAverageLO_flat0(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcaddiff2_flat0(averageOL7_flat0);

    // <-----flat 0 average , H-O----->

    const calculateSumAndAverageHO_flat0 = (data, differences) => {
      //   const finalentryavg = data.find((entry) => {
      //     return eventavgdate.includes(entry.date) && eventavgtime.includes(entry.time);
      // });

      // if(finalentryavg){
      //   const sum = data.reduce((total, item, index) => {
      //     if (differences[index] === 0.0000 || differences[index] === -0.0000) {
      //       const differenceHO = item.High - item.Open;
      //       const modulusHO = Math.abs(differenceHO);
      //       return total + modulusHO;
      //     }
      //     return total;
      //   }, 0);

      //   const validEntriesCount = differences.filter(diff => diff === 0.0000 || diff === -0.0000).length;
      //   const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      //   return average;
      // }
      // else{
      //   return null
      //  }
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", High: "" };
      });
      // const filteredData = filledData.filter((entry, index) => {
      //   return eventavgestimate[index] !== undefined && eventavgestimate[index] !== null;
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.High !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] === 0.0 || differences[index] === -0.0) {
            if (item.High === "" || item.Open === "") {
              return total;
            }
            const differenceHO = item.High - item.Open;

            let modulusHO;
            if (data === jpydata) {
              modulusHO = Math.abs(Math.round(differenceHO * 1000));
            } else {
              modulusHO = Math.abs(
                Math.round(
                  differenceHO.toFixed(country === "JP" ? 3 : 5) *
                    (country === "JP" ? 1000 : 100000)
                )
              );
            }
            // const modulusHO = Math.abs(Math.round(differenceHO.toFixed(country === "JP" ? 3 : 5)*(country === "JP" ? 1000 : 100000)));
            return total + modulusHO;
          }
          return total;
        }, 0);

        // const validEntriesCount = filledData.filter(
        //   (item, index) =>
        //     differences[index] === 0.0 ||
        //     (differences[index] === -0.0 &&
        //       item.Open !== "" &&
        //       item.High !== "")
        // ).length;

        const validEntriesCount = filledData.filter((item, index) => {
          const openhighNotEmpty = item.Open !== "" && item.High !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openhighNotEmpty &&
            differences[index] === 0.0 &&
            differences[index] === -0.0
          );
        }).length;
        // console.log("validEntriesCount" , validEntriesCount)

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets when actual - estimate is zero
    const averageOH1_flat0 = calculateSumAndAverageHO_flat0(
      usddata,
      a_minus_evalue
    );
    setAverageDiff3_flat0(averageOH1_flat0);
    const averageOH2_flat0 = calculateSumAndAverageHO_flat0(
      jpydata,
      a_minus_evalue
    );
    setavgjpydiff3_flat0(averageOH2_flat0);
    const averageOH3_flat0 = calculateSumAndAverageHO_flat0(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzddiff3_flat0(averageOH3_flat0);
    const averageOH4_flat0 = calculateSumAndAverageHO_flat0(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurdiff3_flat0(averageOH4_flat0);
    const averageOH5_flat0 = calculateSumAndAverageHO_flat0(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfdiff3_flat0(averageOH5_flat0);
    const averageOH6_flat0 = calculateSumAndAverageHO_flat0(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpauddiff3_flat0(averageOH6_flat0);
    const averageOH7_flat0 = calculateSumAndAverageHO_flat0(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcaddiff3_flat0(averageOH7_flat0);
  };

  useEffect(() => {
    calculate_currencyaverage();
  }, [
    handleOptionChange,
    eventHistory2,
    allEventHistory,
    eventHistory,
    eurgbpdata,
    gbpauddata,
    gbpcaddata,
    gbpchfdata,
    gbpnzddata,
    jpydata,
    usddata,
  ]);

  // <------------calculate average in % value (1st table)----------->

  const calculate_currencyaveragebyopen = () => {
    // const filteredEventHistory = eventHistory.filter(
    //   (item) => item.estimate !== null && item.estimate !== ""
    // );
    // const a_minus_evalue = filteredEventHistory.map(
    //   (item) => item.actual - item.estimate
    // );

    const a_minus_evalue = eventHistory.map((item) =>
      item.actual - item.estimate === 0 ||
      item.estimate === null ||
      item.estimate === ""
        ? item.actual - item.previous
        : item.actual - item.estimate
    );
    const eventavgtime = eventHistory.map((item) => item.time);
    const eventavgdate = eventHistory.map((item) =>
      item.date.split(".").reverse().join(".")
    );
    const eventavgestimate = eventHistory.map((item) => item.estimate);

    const calculateSumAndAveragebyopen = (data, differences) => {
      // const sum = data.reduce((total, item, index) => {
      //   if (differences[index] !== 0.0000 && differences[index] !== -0.0000) {
      //     const differencebyopenCO = (item.Close - item.Open) / item.Open;
      //     // console.log("value 1 " , differencebyopenCO)
      //     const modulusbyopenCO = Math.abs(differencebyopenCO * 100);

      //     return total + modulusbyopenCO;
      //   }
      //   return total;
      // }, 0);
      // const validEntriesCount = differences.filter(diff => diff !== 0.0000 && diff !== -0.0000).length;
      // const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      // return average;

      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        // console.log("entry" , entry)
        return entry || { date: date, time: time, Open: "", Close: "" };
      });
      // const filteredData = filledData.filter((entry, index) => {
      //   return (
      //     eventavgestimate[index] !== undefined &&
      //     eventavgestimate[index] !== null
      //   );
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.Close !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] !== 0.0 && differences[index] !== -0.0) {
            if (item.Close === "" || item.Open === "") {
              return total;
            }
            const differencebyopenCO = (item.Close - item.Open) / item.Open;
            // console.log("differencebyopenCO" , differencebyopenCO*100)
            // console.log("differencebyopenCO" , differencebyopenCO)
            const modulusbyopenCO = (
              Math.abs(differencebyopenCO) * 100
            ).toFixed(3);
            // console.log("modulusbyopenCO" , modulusbyopenCO)
            return total + parseFloat(modulusbyopenCO);
            // return total + modulusbyopenCO;
          }
          return total;
        }, 0);
        // console.log("sum---------" , sum)
        // const validEntriesCount = filteredData.filter(
        //   (item, index) =>
        //     differences[index] !== 0.0 ||
        //     (differences[index] !== -0.0 &&
        //       item.Open !== "" &&
        //       item.Close !== "")
        // ).length;
        const validEntriesCount = filledData.filter((item, index) => {
          const openclosedNotEmpty = item.Open !== "" && item.Close !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openclosedNotEmpty &&
            differences[index] !== 0.0 &&
            differences[index] !== -0.0
          );
        }).length;

        // console.log("validEntriesCount" , validEntriesCount)
        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        // console.log("average" , average)
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets
    const averagebyopenOC1 = calculateSumAndAveragebyopen(
      usddata,
      a_minus_evalue
    );
    setAverageDifff(averagebyopenOC1);
    const averagebyopenOC2 = calculateSumAndAveragebyopen(
      jpydata,
      a_minus_evalue
    );
    setavgjpydifff(averagebyopenOC2);
    const averagebyopenOC3 = calculateSumAndAveragebyopen(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzdbyodiff1(averagebyopenOC3);
    const averagebyopenOC4 = calculateSumAndAveragebyopen(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurbyodiff1(averagebyopenOC4);
    const averagebyopenOC5 = calculateSumAndAveragebyopen(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfbyodiff1(averagebyopenOC5);
    const averagebyopenOC6 = calculateSumAndAveragebyopen(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpaudbyodiff1(averagebyopenOC6);
    const averagebyopenOC7 = calculateSumAndAveragebyopen(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcadbyodiff1(averagebyopenOC7);

    // <----LO---->

    const calculateSumAndAveragebyopenLO = (data, differences) => {
      // const sum = data.reduce((total, item, index) => {
      //   if (differences[index] !== 0.0000 && differences[index] !== -0.0000) {
      //     const differencebyopenLO = (item.Low - item.Open) / item.Open ;
      //     const modulusbyopenLO = Math.abs(differencebyopenLO * 100);
      //     return total + modulusbyopenLO ;
      //   }
      //   return total;
      // }, 0);

      // const validEntriesCount = differences.filter(diff => diff !== 0.0000 && diff !== -0.0000).length;
      // const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      // return average;
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", Low: "" };
      });

      // const filteredData = filledData.filter((entry, index) => {
      //   return (
      //     eventavgestimate[index] !== undefined &&
      //     eventavgestimate[index] !== null
      //   );
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.Low !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] !== 0.0 && differences[index] !== -0.0) {
            if (item.Low === "" || item.Open === "") {
              return total;
            }
            const differencebyopenLO = (item.Low - item.Open) / item.Open;
            const modulusbyopenLO = (
              Math.abs(differencebyopenLO) * 100
            ).toFixed(3);

            return total + parseFloat(modulusbyopenLO);
          }
          return total;
        }, 0);

        const validEntriesCount = filledData.filter((item, index) => {
          const openlowwNotEmpty = item.Open !== "" && item.Low !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openlowwNotEmpty &&
            differences[index] !== 0.0 &&
            differences[index] !== -0.0
          );
        }).length;

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets
    const averagebyopenOL1 = calculateSumAndAveragebyopenLO(
      usddata,
      a_minus_evalue
    );
    setAverageDifff2(averagebyopenOL1);
    const averagebyopenOL2 = calculateSumAndAveragebyopenLO(
      jpydata,
      a_minus_evalue
    );
    setavgjpydifff2(averagebyopenOL2);
    const averagebyopenOL3 = calculateSumAndAveragebyopenLO(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzdbyodiff2(averagebyopenOL3);
    const averagebyopenOL4 = calculateSumAndAveragebyopenLO(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurbyodiff2(averagebyopenOL4);
    const averagebyopenOL5 = calculateSumAndAveragebyopenLO(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfbyodiff2(averagebyopenOL5);
    const averagebyopenOL6 = calculateSumAndAveragebyopenLO(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpaudbyodiff2(averagebyopenOL6);
    const averagebyopenOL7 = calculateSumAndAveragebyopenLO(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcadbyodiff2(averagebyopenOL7);

    // <-----HO----->

    const calculateSumAndAveragebyopenHO = (data, differences) => {
      // const sum = data.reduce((total, item, index) => {
      //   if (differences[index] !== 0.0000 && differences[index] !== -0.0000) {
      //     const differencebyopenHO = (item.High - item.Open) / item.Open ;
      //     const modulusbyopenHO = Math.abs(differencebyopenHO * 100);
      //     return total + modulusbyopenHO ;
      //   }
      //   return total;
      // }, 0);

      // const validEntriesCount = differences.filter(diff => diff !== 0.0000 && diff !== -0.0000).length;
      // const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      // return average;
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", High: "" };
      });

      // const filteredData = filledData.filter((entry, index) => {
      //   return (
      //     eventavgestimate[index] !== undefined &&
      //     eventavgestimate[index] !== null
      //   );
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.High !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] !== 0.0 && differences[index] !== -0.0) {
            if (item.High === "" || item.Open === "") {
              return total;
            }
            const differencebyopenHO = (item.High - item.Open) / item.Open;
            const modulusbyopenHO = (
              Math.abs(differencebyopenHO) * 100
            ).toFixed(3);
            // return total + modulusbyopenHO;
            return total + parseFloat(modulusbyopenHO);
          }
          return total;
        }, 0);

        const validEntriesCount = filledData.filter((item, index) => {
          const openhighhNotEmpty = item.Open !== "" && item.High !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openhighhNotEmpty &&
            differences[index] !== 0.0 &&
            differences[index] !== -0.0
          );
        }).length;
        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets
    const averagebyopenOH1 = calculateSumAndAveragebyopenHO(
      usddata,
      a_minus_evalue
    );
    setAverageDifff3(averagebyopenOH1);
    const averagebyopenOH2 = calculateSumAndAveragebyopenHO(
      jpydata,
      a_minus_evalue
    );
    setavgjpydifff3(averagebyopenOH2);
    const averagebyopenOH3 = calculateSumAndAveragebyopenHO(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzdbyodiff3(averagebyopenOH3);
    const averagebyopenOH4 = calculateSumAndAveragebyopenHO(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurbyodiff3(averagebyopenOH4);
    const averagebyopenOH5 = calculateSumAndAveragebyopenHO(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfbyodiff3(averagebyopenOH5);
    const averagebyopenOH6 = calculateSumAndAveragebyopenHO(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpaudbyodiff3(averagebyopenOH6);
    const averagebyopenOH7 = calculateSumAndAveragebyopenHO(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcadbyodiff3(averagebyopenOH7);

    // <000000>
    {
      /* <for 0, C-0--> */
    }
    const calculateSumAndAveragebyopen_flat0 = (data, differences) => {
      //   const sum = data.reduce((total, item, index) => {
      //     if (differences[index] === 0.0000 || differences[index] === -0.0000) {
      //       const differencebyopenCO = (item.Close - item.Open) / item.Open;
      //       const modulusbyopenCO = Math.abs(differencebyopenCO.toFixed(4) * 100);
      //       return total + modulusbyopenCO;
      //     }
      //     return total;
      //   }, 0);

      //   const validEntriesCount = differences.filter(diff => diff === 0.0000 || diff === -0.0000).length;
      //   const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
      // // console.log("validEntriesCount" , validEntriesCount)
      //   return average;
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", Close: "" };
      });

      // const filteredData = filledData.filter((entry, index) => {
      //   return eventavgestimate[index] !== undefined && eventavgestimate[index] !== null;
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.Close !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] === 0.0 || differences[index] === -0.0) {
            if (item.Close === "" || item.Open === "") {
              return total;
            }
            const differencebyopenCO = (item.Close - item.Open) / item.Open;

            const modulusbyopenCO = Math.abs(differencebyopenCO * 100).toFixed(
              3
            );
            // console.log("modulusbyopenCO" ,modulusbyopenCO)
            return total + modulusbyopenCO;
          }
          return total;
        }, 0);

        // const validEntriesCount = filledData.filter(
        //   (item, index) =>
        //     differences[index] === 0.0 ||
        //     (differences[index] === -0.0 &&
        //       item.Open !== "" &&
        //       item.Close !== "")
        // ).length;

        const validEntriesCount = filledData.filter((item, index) => {
          const opencloseeNotEmpty = item.Open !== "" && item.Close !== "";
          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            opencloseeNotEmpty &&
            differences[index] === 0.0 &&
            differences[index] === -0.0
          );
        }).length;

        // console.log("validEntriesCount" , validEntriesCount)

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets when actual - estimate is zero
    const averagebyopenOC1_flat0 = calculateSumAndAveragebyopen_flat0(
      usddata,
      a_minus_evalue
    );
    setAverageDifff_flat0(averagebyopenOC1_flat0);
    const averagebyopenOC2_flat0 = calculateSumAndAveragebyopen_flat0(
      jpydata,
      a_minus_evalue
    );
    setavgjpydifff_flat0(averagebyopenOC2_flat0);
    const averagebyopenOC3_flat0 = calculateSumAndAveragebyopen_flat0(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzdbyodiff1_flat0(averagebyopenOC3_flat0);
    const averagebyopenOC4_flat0 = calculateSumAndAveragebyopen_flat0(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurbyodiff1_flat0(averagebyopenOC4_flat0);
    const averagebyopenOC5_flat0 = calculateSumAndAveragebyopen_flat0(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfbyodiff1_flat0(averagebyopenOC5_flat0);
    const averagebyopenOC6_flat0 = calculateSumAndAveragebyopen_flat0(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpaudbyodiff1_flat0(averagebyopenOC6_flat0);
    const averagebyopenOC7_flat0 = calculateSumAndAveragebyopen_flat0(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcadbyodiff1_flat0(averagebyopenOC7_flat0);

    // <----------------FLAT 0 , L-O---->

    const calculateSumAndAverageLObyopen_flat0 = (data, differences) => {
      // const sum = data.reduce((total, item, index) => {
      //   if (differences[index] === 0.0000 || differences[index] === -0.0000) {
      //     const differencebyopenLO = (item.Low - item.Open) / item.Open;
      //     const modulusbyopenLO = Math.abs(differencebyopenLO.toFixed(4) * 100);
      //     return total + modulusbyopenLO;
      //   }
      //   return total;
      // }, 0);

      // const validEntriesCount = differences.filter(diff => diff === 0.0000 || diff === -0.0000).length;
      // const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      // return average;
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", Low: "" };
      });

      // const filteredData = filledData.filter((entry, index) => {
      //   return eventavgestimate[index] !== undefined && eventavgestimate[index] !== null;
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.Low !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] === 0.0 || differences[index] === -0.0) {
            if (item.Low === "" || item.Open === "") {
              return total;
            }
            const differencebyopenLO = (item.Low - item.Open) / item.Open;
            const modulusbyopenLO = Math.abs(differencebyopenLO * 100).toFixed(
              3
            );
            return total + modulusbyopenLO;
          }
          return total;
        }, 0);

        // const validEntriesCount = filledData.filter(
        //   (item, index) =>
        //     differences[index] === 0.0 ||
        //     (differences[index] === -0.0 && item.Open !== "" && item.Low !== "")
        // ).length;

        const validEntriesCount = filledData.filter((item, index) => {
          const openlowwNotEmpty = item.Open !== "" && item.Low !== "";
          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openlowwNotEmpty &&
            differences[index] === 0.0 &&
            differences[index] === -0.0
          );
        }).length;

        // console.log("validEntriesCount" , validEntriesCount)

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets when actual - estimate is zero
    const averagebyopenOL1_flat0 = calculateSumAndAverageLObyopen_flat0(
      usddata,
      a_minus_evalue
    );
    setAverageDifff2_flat0(averagebyopenOL1_flat0);
    const averagebyopenOL2_flat0 = calculateSumAndAverageLObyopen_flat0(
      jpydata,
      a_minus_evalue
    );
    setavgjpydifff2_flat0(averagebyopenOL2_flat0);
    const averagebyopenOL3_flat0 = calculateSumAndAverageLObyopen_flat0(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzdbyodiff2_flat0(averagebyopenOL3_flat0);
    const averagebyopenOL4_flat0 = calculateSumAndAverageLObyopen_flat0(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurbyodiff2_flat0(averagebyopenOL4_flat0);
    const averagebyopenOL5_flat0 = calculateSumAndAverageLObyopen_flat0(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfbyodiff2_flat0(averagebyopenOL5_flat0);
    const averagebyopenOL6_flat0 = calculateSumAndAverageLObyopen_flat0(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpaudbyodiff2_flat0(averagebyopenOL6_flat0);
    const averagebyopenOL7_flat0 = calculateSumAndAverageLObyopen_flat0(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcadbyodiff2_flat0(averagebyopenOL7_flat0);

    // <-----flat 0 average , H-O----->

    const calculateSumAndAverageHObyopen_flat0 = (data, differences) => {
      // const sum = data.reduce((total, item, index) => {
      //   if (differences[index] === 0.0000 || differences[index] === -0.0000) {
      //     const differencebyopenHO = (item.High - item.Open) / item.Open;
      //     const modulusbyopenHO = Math.abs(differencebyopenHO.toFixed(4) * 100);
      //     return total + modulusbyopenHO;
      //   }
      //   return total;
      // }, 0);

      // const validEntriesCount = differences.filter(diff => diff === 0.0000 || diff === -0.0000).length;
      // const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;

      // return average;
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Open: "", High: "" };
      });

      // const filteredData = filledData.filter((entry, index) => {
      //   return eventavgestimate[index] !== undefined && eventavgestimate[index] !== null;
      // });
      if (filledData.some((entry) => entry.Open !== "" && entry.High !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (differences[index] === 0.0 || differences[index] === -0.0) {
            if (item.High === "" || item.Open === "") {
              return total;
            }
            const differencebyopenHO = (item.High - item.Open) / item.Open;
            const modulusbyopenHO = Math.abs(differencebyopenHO * 100).toFixed(
              3
            );
            return total + modulusbyopenHO;
          }
          return total;
        }, 0);

        // const validEntriesCount = filledData.filter(
        //   (item, index) =>
        //     differences[index] === 0.0 ||
        //     (differences[index] === -0.0 &&
        //       item.Open !== "" &&
        //       item.High !== "")
        // ).length;

        const validEntriesCount = filledData.filter((item, index) => {
          const openhighhNotEmpty = item.Open !== "" && item.High !== "";
          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            openhighhNotEmpty &&
            differences[index] === 0.0 &&
            differences[index] === -0.0
          );
        }).length;

        // console.log("validEntriesCount" , validEntriesCount)

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets when actual - estimate is zero
    const averagebyopenOH1_flat0 = calculateSumAndAverageHObyopen_flat0(
      usddata,
      a_minus_evalue
    );
    setAverageDifff3_flat0(averagebyopenOH1_flat0);
    const averagebyopenOH2_flat0 = calculateSumAndAverageHObyopen_flat0(
      jpydata,
      a_minus_evalue
    );
    setavgjpydifff3_flat0(averagebyopenOH2_flat0);
    const averagebyopenOH3_flat0 = calculateSumAndAverageHObyopen_flat0(
      gbpnzddata,
      a_minus_evalue
    );
    setaveragegbpnzdbyodiff3_flat0(averagebyopenOH3_flat0);
    const averagebyopenOH4_flat0 = calculateSumAndAverageHObyopen_flat0(
      eurgbpdata,
      a_minus_evalue
    );
    setaveragegbpeurbyodiff3_flat0(averagebyopenOH4_flat0);
    const averagebyopenOH5_flat0 = calculateSumAndAverageHObyopen_flat0(
      gbpchfdata,
      a_minus_evalue
    );
    setaveragegbpchfbyodiff3_flat0(averagebyopenOH5_flat0);
    const averagebyopenOH6_flat0 = calculateSumAndAverageHObyopen_flat0(
      gbpauddata,
      a_minus_evalue
    );
    setaveragegbpaudbyodiff3_flat0(averagebyopenOH6_flat0);
    const averagebyopenOH7_flat0 = calculateSumAndAverageHObyopen_flat0(
      gbpcaddata,
      a_minus_evalue
    );
    setaveragegbpcadbyodiff3_flat0(averagebyopenOH7_flat0);
  };

  useEffect(() => {
    calculate_currencyaveragebyopen();
  }, [
    eventHistory,
    eurgbpdata,
    gbpauddata,
    gbpcaddata,
    gbpchfdata,
    gbpnzddata,
    jpydata,
    usddata,
  ]);

  const handleModalShowClick = () => {
    setModalshow(true);
  };

  const handleRefresh = () => {
    setIsReloadLoading(true);
    setLoading(false);
    // Loop through localStorage and remove any item with "Clickeddate_data_"
    Object.keys(localStorage).forEach((key) => {
      if (
        key.includes("Clickeddate_data_") ||
        key.includes("table3_historyData_") ||
        key.includes("clickedmodal_data_2ndpage_")
      ) {
        localStorage.removeItem(key);
      }
    });

    localStorage.removeItem("All_events_history");
    localStorage.removeItem("lastDeletionTime");
    localStorage.removeItem("UserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedtime");
    localStorage.removeItem("4GraphDataobjects");
    // localStorage.removeItem("userregisterId");
    // localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("todayDate");
    localStorage.removeItem("previousDate_u_search_history");

    localStorage.removeItem("selectedtime");
    // localStorage.removeItem("previousDate_u_search_history");
    // localStorage.removeItem("previousDate_u_search_history");

    setIsReloadLoading(false);
    setLoading(false);
    historydata_table(selectedTime);
    window.location.reload();
  };

  function setDateInLocalStorage() {
    let today = new Date();
    let todayISOString = today.toISOString();
    let todayDatePart = todayISOString.split("T")[0];
    localStorage.setItem("todayDate", todayDatePart);

    let previousDate = localStorage.getItem("previousDate_u_search_history");

    if (previousDate) {
      // Calculate difference in days between today and previous date
      let prevDate = new Date(previousDate);
      let differenceInTime = today.getTime() - prevDate.getTime();
      let differenceInDays = differenceInTime / (1000 * 3600 * 24);

      // If more than 7 days have passed, remove the data
      if (differenceInDays > 7) {
        localStorage.removeItem("previousDate_u_search_history");
        localStorage.removeItem("All_events_history");
        localStorage.removeItem("selectedtime");
        localStorage.removeItem("eventnameandcountry:");
        // console.log("Local storage data removed!");
      }
    }

    // Update previousDate in localStorage to today's date (full ISO format for consistency)
    localStorage.setItem("previousDate_u_search_history", todayDatePart);
  }

  useEffect(() => {
    setDateInLocalStorage();
  }, []);

  // <----------1,5,15,60 min click----------->
  const handletimebtnclick = (value) => {
    let timeclickedurl;
    setLoading(true);

    if (value === "1min") {
      // console.log("im inside 1min btn");
      setSelectedTime(1);
      setClickedtimeButton("1min");
      timeclickedurl = 1;
      setTimeclickedurl_state(timeclickedurl)
      setLoading(false);

      localStorage.setItem("selectedtime", JSON.stringify(1));
      // historydata(1);
    } else if (value === "5min") {
      // console.log("im inside 5min btn");
      setSelectedTime(5);
      setClickedtimeButton("5min");
      timeclickedurl = 5;
      setTimeclickedurl_state(timeclickedurl)
      // console.log("5");
      setLoading(false);
      localStorage.setItem("selectedtime", JSON.stringify(5));
      // historydata(5);
    } else if (value === "15min") {
      // console.log("im inside 15min btn");
      setSelectedTime(15);
      setClickedtimeButton("15min");
      // console.log("15");
      timeclickedurl = 15;
      setTimeclickedurl_state(timeclickedurl)
      console.log("selectedTime", selectedTime);
      setLoading(false);
      localStorage.setItem("selectedtime", JSON.stringify(15));
      // historydata(15);
    } else if (value === "60min") {
      // console.log("im inside 60min btn");
      setSelectedTime(60);
      setClickedtimeButton("60min");
      timeclickedurl = 60;
      setTimeclickedurl_state(timeclickedurl)
      setLoading(false);
      localStorage.setItem("selectedtime", JSON.stringify(60));
    }
    const url = new URL(window.location.href);
    url.searchParams.set("timeclickedurl", timeclickedurl); // Set the new value
    window.history.replaceState(null, "", url.toString()); // Update the URL
  };

  function roundDownToNearestMultipleOf5(time) {
    const [hours, minutes] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const roundedMinutes = Math.floor(totalMinutes / 5) * 5;
    const roundedHours = Math.floor(roundedMinutes / 60);
    const finalHours = roundedHours.toString().padStart(2, "0");
    const finalMinutes = (roundedMinutes % 60).toString().padStart(2, "0");
    return `${finalHours}:${finalMinutes}:00`;
  }

  function roundDownToNearestMultipleOf15(time) {
    const [hours, minutes] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const roundedMinutes = Math.floor(totalMinutes / 15) * 15;
    const roundedHours = Math.floor(roundedMinutes / 60);
    const finalHours = roundedHours.toString().padStart(2, "0");
    const finalMinutes = (roundedMinutes % 60).toString().padStart(2, "0");
    return `${finalHours}:${finalMinutes}:00`;
  }

  function roundDownToNearestMultipleOf1Hour(time) {
    const [hours] = time.split(":").map(Number);
    const roundedHours = Math.floor(hours);
    const finalHours = roundedHours.toString().padStart(2, "0");
    const finalMinutes = "00";
    return `${finalHours}:${finalMinutes}:00`;
  }

  const handlegoback = () => {
    navigate("/user/economicdata");
  };

  let arraygbpusd = [];
  let arraygbpjpy = [];
  let arraygbpaud = [];
  let arraygbpcad = [];
  let arraygbpchf = [];
  let arraygbpeur = [];
  let arraygbpnzd = [];

  let arraygbpusdlo = [];
  let arraygbpjpylo = [];
  let arraygbpaudlo = [];
  let arraygbpcadlo = [];
  let arraygbpchflo = [];
  let arraygbpeurlo = [];
  let arraygbpnzdlo = [];

  let arraygbpusdho = [];
  let arraygbpjpyho = [];
  let arraygbpaudho = [];
  let arraygbpcadho = [];
  let arraygbpchfho = [];
  let arraygbpeurho = [];
  let arraygbpnzdho = [];

  function countemp(array) {
    let positiveCountgbp = 0;
    let negativeCountgbp = 0;
    let positivepositiveCountgbp = 0;
    let empty = 0;
    let emp = 0;   //emp=== unavailable

    for (let num of array) {
      if (num === "+") {
        positiveCountgbp++;
      } else if (num === "-") {
        negativeCountgbp++;
      } else if (num === "++") {
        positivepositiveCountgbp++;
      } else if (num === "") {
        empty++;
      } else if (num === "unavailable") {
        emp++;
      }
    }

    // console.log("unavailable" , emp)
    // console.log("length" ,array.length )
    return (emp / array.length) * 100;
  }

  // console.log("countemp" , countemp(array));
  function countPositive(array) {
    let positiveCountgbp = 0;
    let positivepositiveCountgbp = 0;
    let negativenegativeCountgbpusd = 0;
    // <----------ye khali vala hai means 0 ""----->
    let empty = 0;

    let emp = 0;

    for (let num of array) {
      if (num === "+") {
        positiveCountgbp++;
      } else if (num === "--") {
        negativenegativeCountgbpusd++;
      } else if (num === "++") {
        positivepositiveCountgbp++;
      } else if (num === "") {
        empty++;
      } else if (num === "unavailable") {
        emp++;
      }
    }
    const adjustedLength =
      array.length -
      (positivepositiveCountgbp + empty + emp + negativenegativeCountgbpusd);

    // console.log("negativeCountgbpusd" , negativeCountgbpusd)
    return (positiveCountgbp / adjustedLength) * 100;
  }

  function countNegative(array) {
    // let positiveCountgbp = 0;
    let negativenegativeCountgbpusd = 0;
    let positivepositiveCountgbp = 0;
    let negativeCountgbpusd = 0;
    let empty = 0;
    let emp = 0;

    for (let num of array) {
      if (num === "-") {
        negativeCountgbpusd++;
      } else if (num === "++") {
        positivepositiveCountgbp++;
      } else if (num === "") {
        empty++;
      } else if (num === "unavailable") {
        emp++;
      } else if (num === "--") {
        negativenegativeCountgbpusd++;
      }
    }
    const adjustedLength =
      array.length -
      (positivepositiveCountgbp + empty + emp + negativenegativeCountgbpusd);
    return (negativeCountgbpusd / adjustedLength) * 100;
  }

  // <--------------flat positive---------->
  function countPositivePositive(array) {
    let positivepositiveCountgbp = 0;
    let empty = 0;
    let negativenegativeCountgbpusd = 0;

    for (let num of array) {
      if (num === "++") {
        positivepositiveCountgbp++;
      } else if (num === "") {
        empty++;
      } else if (num === "--") {
        negativenegativeCountgbpusd++;
      }
    }
    const adjustedLength =
      positivepositiveCountgbp + negativenegativeCountgbpusd;
    return (positivepositiveCountgbp / adjustedLength) * 100;
  }

  // <--------------flat nagative---------->
  function countNegativeNegative(array) {
    // let negativenegativegbpusd = 0;
    let positivepositiveCountgbp = 0;
    let empty = 0;
    let negativenegativeCountgbpusd = 0;

    for (let num of array) {
      if (num === "++") {
        positivepositiveCountgbp++;
      } else if (num === "") {
        empty++;
      } else if (num === "--") {
        negativenegativeCountgbpusd++;
      }
    }
    const adjustedLength =
      positivepositiveCountgbp + negativenegativeCountgbpusd;
    return (negativenegativeCountgbpusd / adjustedLength) * 100;
  }

  // <------------co------------>
  const avgdatagbpusdco =
    (averageDifff !== null ? averageDifff.toFixed(3) : "N/A") + "%";
  const avgdatagbpjpyco =
    (avgjpydifff !== null ? avgjpydifff.toFixed(3) : "N/A") + "%";
  const avgdatagbpnzdco =
    (averagegbpnzdbyodiff1 !== null
      ? averagegbpnzdbyodiff1.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpeurco =
    (averagegbpeurbyodiff1 !== null
      ? averagegbpeurbyodiff1.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpchfco =
    (averagegbpchfbyodiff1 !== null
      ? averagegbpchfbyodiff1.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpaudco =
    (averagegbpaudbyodiff1 !== null
      ? averagegbpaudbyodiff1.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpcadco =
    (averagegbpcadbyodiff1 !== null
      ? averagegbpcadbyodiff1.toFixed(3)
      : "N/A") + "%";

  const values = [
    parseFloat(avgdatagbpusdco),
    parseFloat(avgdatagbpjpyco),
    parseFloat(avgdatagbpnzdco),
    parseFloat(avgdatagbpeurco),
    parseFloat(avgdatagbpchfco),
    parseFloat(avgdatagbpaudco),
    parseFloat(avgdatagbpcadco),
  ];

  values.sort((a, b) => b - a);
  let highest1 = values[0].toFixed(3) + "%";
  let highest2 = values[1].toFixed(3) + "%";
  let highest3 = values[2].toFixed(3) + "%";

  // console.log("highest1" , highest1 , highest2 , highest3)
  // <------------lo------------>
  const avgdatagbpusdlo =
    (averageDifff2 !== null ? averageDifff2.toFixed(3) : "N/A") + "%";
  const avgdatagbpjpylo =
    (avgjpydifff2 !== null ? avgjpydifff2.toFixed(3) : "N/A") + "%";
  const avgdatagbpnzdlo =
    (averagegbpnzdbyodiff2 !== null
      ? averagegbpnzdbyodiff2.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpeurlo =
    (averagegbpeurbyodiff2 !== null
      ? averagegbpeurbyodiff2.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpchflo =
    (averagegbpchfbyodiff2 !== null
      ? averagegbpchfbyodiff2.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpaudlo =
    (averagegbpaudbyodiff2 !== null
      ? averagegbpaudbyodiff2.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpcadlo =
    (averagegbpcadbyodiff2 !== null
      ? averagegbpcadbyodiff2.toFixed(3)
      : "N/A") + "%";

  const values1 = [
    parseFloat(avgdatagbpusdlo),
    parseFloat(avgdatagbpjpylo),
    parseFloat(avgdatagbpnzdlo),
    parseFloat(avgdatagbpeurlo),
    parseFloat(avgdatagbpchflo),
    parseFloat(avgdatagbpaudlo),
    parseFloat(avgdatagbpcadlo),
  ];

  values1.sort((a, b) => b - a);
  let highest1lo = values1[0].toFixed(3) + "%";
  let highest2lo = values1[1].toFixed(3) + "%";
  let highest3lo = values1[2].toFixed(3) + "%";
  // console.log("highest1lo" , highest1lo , highest2lo, highest3lo)
  // <------------ho------------>
  const avgdatagbpusdho =
    (averageDifff3 !== null ? averageDifff3.toFixed(4) : "N/A") + "%";
  const avgdatagbpjpyho =
    (avgjpydifff3 !== null ? avgjpydifff3.toFixed(4) : "N/A") + "%";
  const avgdatagbpnzdho =
    (averagegbpnzdbyodiff3 !== null
      ? averagegbpnzdbyodiff3.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpeurho =
    (averagegbpeurbyodiff3 !== null
      ? averagegbpeurbyodiff3.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpchfho =
    (averagegbpchfbyodiff3 !== null
      ? averagegbpchfbyodiff3.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpaudho =
    (averagegbpaudbyodiff3 !== null
      ? averagegbpaudbyodiff3.toFixed(3)
      : "N/A") + "%";
  const avgdatagbpcadho =
    (averagegbpcadbyodiff3 !== null
      ? averagegbpcadbyodiff3.toFixed(3)
      : "N/A") + "%";

  const values3 = [
    parseFloat(avgdatagbpusdho),
    parseFloat(avgdatagbpjpyho),
    parseFloat(avgdatagbpnzdho),
    parseFloat(avgdatagbpeurho),
    parseFloat(avgdatagbpchfho),
    parseFloat(avgdatagbpaudho),
    parseFloat(avgdatagbpcadho),
  ];

  values3.sort((a, b) => b - a);
  let highest1ho = values3[0].toFixed(3) + "%";
  let highest2ho = values3[1].toFixed(3) + "%";
  let highest3ho = values3[2].toFixed(3) + "%";
  // console.log("highest1ho" , highest1ho , highest2ho , highest3ho)
  // <--------------------------highest value of flat average ----------------->
  // <------------co------------>
  const avgdatausdco =
    (averageDifff_flat0 !== null ? averageDifff_flat0.toFixed(3) : "N/A") + "%";
  const avgdatajpyco =
    (avgjpydifff_flat0 !== null ? avgjpydifff_flat0.toFixed(3) : "N/A") + "%";
  const avgdatanzdco =
    (averagegbpnzdbyodiff1_flat0 !== null
      ? averagegbpnzdbyodiff1_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdataeurco =
    (averagegbpeurbyodiff1_flat0 !== null
      ? averagegbpeurbyodiff1_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdatachfco =
    (averagegbpchfbyodiff1_flat0 !== null
      ? averagegbpchfbyodiff1_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdataaudco =
    (averagegbpaudbyodiff1_flat0 !== null
      ? averagegbpaudbyodiff1_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdatacadco =
    (averagegbpcadbyodiff1_flat0 !== null
      ? averagegbpcadbyodiff1_flat0.toFixed(3)
      : "N/A") + "%";
  // console.log("avgdatausdco" , avgdatausdco)
  const valuesflatco = [
    parseFloat(avgdatausdco),
    parseFloat(avgdatajpyco),
    parseFloat(avgdatanzdco),
    parseFloat(avgdataeurco),
    parseFloat(avgdatachfco),
    parseFloat(avgdataaudco),
    parseFloat(avgdatacadco),
  ];
  // console.log("valuesflatco" , valuesflatco)
  valuesflatco.sort((a, b) => b - a);
  let highest1coflat = valuesflatco[0].toFixed(3) + "%";
  let highest2coflat = valuesflatco[1].toFixed(3) + "%";
  let highest3coflat = valuesflatco[2].toFixed(3) + "%";
  //
  // <------------lo------------>
  const avgdatausdlo =
    (averageDifff2_flat0 !== null ? averageDifff2_flat0.toFixed(3) : "N/A") +
    "%";
  const avgdatajpylo =
    (avgjpydifff2_flat0 !== null ? avgjpydifff2_flat0.toFixed(3) : "N/A") + "%";
  const avgdatanzdlo =
    (averagegbpnzdbyodiff2_flat0 !== null
      ? averagegbpnzdbyodiff2_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdataeurlo =
    (averagegbpeurbyodiff2_flat0 !== null
      ? averagegbpeurbyodiff2_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdatachflo =
    (averagegbpchfbyodiff2_flat0 !== null
      ? averagegbpchfbyodiff2_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdataaudlo =
    (averagegbpaudbyodiff2_flat0 !== null
      ? averagegbpaudbyodiff2_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdatacadlo =
    (averagegbpcadbyodiff2_flat0 !== null
      ? averagegbpcadbyodiff2_flat0.toFixed(3)
      : "N/A") + "%";

  const values1loflat = [
    parseFloat(avgdatausdlo),
    parseFloat(avgdatajpylo),
    parseFloat(avgdatanzdlo),
    parseFloat(avgdataeurlo),
    parseFloat(avgdatachflo),
    parseFloat(avgdataaudlo),
    parseFloat(avgdatacadlo),
  ];

  values1loflat.sort((a, b) => b - a);
  let highest1loflat = values1loflat[0].toFixed(3) + "%";
  let highest2loflat = values1loflat[1].toFixed(3) + "%";
  let highest3loflat = values1loflat[2].toFixed(3) + "%";

  // <------------ho------------>
  const avgdatausdho =
    (averageDifff3_flat0 !== null ? averageDifff3_flat0.toFixed(3) : "N/A") +
    "%";
  const avgdatajpyho =
    (avgjpydifff3_flat0 !== null ? avgjpydifff3_flat0.toFixed(3) : "N/A") + "%";
  const avgdatanzdho =
    (averagegbpnzdbyodiff3_flat0 !== null
      ? averagegbpnzdbyodiff3_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdataeurho =
    (averagegbpeurbyodiff3_flat0 !== null
      ? averagegbpeurbyodiff3_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdatachfho =
    (averagegbpchfbyodiff3_flat0 !== null
      ? averagegbpchfbyodiff3_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdataaudho =
    (averagegbpaudbyodiff3_flat0 !== null
      ? averagegbpaudbyodiff3_flat0.toFixed(3)
      : "N/A") + "%";
  const avgdatacadho =
    (averagegbpcadbyodiff3_flat0 !== null
      ? averagegbpcadbyodiff3_flat0.toFixed(3)
      : "N/A") + "%";

  const values3hoflat = [
    parseFloat(avgdatausdho),
    parseFloat(avgdatajpyho),
    parseFloat(avgdatanzdho),
    parseFloat(avgdataeurho),
    parseFloat(avgdatachfho),
    parseFloat(avgdataaudho),
    parseFloat(avgdatacadho),
  ];

  values3hoflat.sort((a, b) => b - a);
  let highest1hoflat = values3hoflat[0].toFixed(3) + "%";
  let highest2hoflat = values3hoflat[1].toFixed(3) + "%";
  let highest3hoflat = values3hoflat[2].toFixed(3) + "%";

  const [filteredModalData, setFilteredModalData] = useState([]);
  const handleShowModalbeforetime = (date) => {
    const filteredData = modalimpactdatabeforetime.filter(
      (item) => item.date === date
    );
    setFilteredModalData(filteredData);
    document.getElementById("my_modal_2").showModal();
  };

  const [filteredModalDataontime, setFilteredModalDataontime] = useState([]);
  const handleShowModalontime = (date) => {
    const filteredData = modalimpactdataontime.filter(
      (item) => item.date === date
    );
    setFilteredModalDataontime(filteredData);
    document.getElementById("my_modal_3").showModal();
  };

  function autoClickButton() {
    var button = document.getElementById("aashima_id");
    button.click();
  }
  function autoClickButton1() {
    var button = document.getElementById("closemod11");
    button.click();
  }

  useEffect(() => {
    const dates = eventHistory.map((item) => item.date);
    // Function to parse dates and calculate the difference in days
    function getDateDifferences(dates) {
      const dateDifferences = [];
      for (let i = 0; i < dates.length - 1; i++) {
        const date1 = new Date(dates[i]);
        const date2 = new Date(dates[i + 1]);
        const differenceInTime = Math.abs(date2.getTime() - date1.getTime());
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        dateDifferences.push(differenceInDays);
      }
      return dateDifferences;
    }

    // Get the date differences for the first 6 days
    const first6Dates = dates.slice(0, 6);
    const dateDifferences = getDateDifferences(first6Dates);
    const sumOfDifferences = dateDifferences.reduce(
      (sum, diff) => sum + diff,
      0
    );
    const averageGap = sumOfDifferences / dateDifferences.length;
    const roundedAverageGap = Math.round(averageGap);
    setFrequency_date(roundedAverageGap);
    // console.log('Average gap between the first 6 days:', roundedAverageGap);
  }, [eventHistory]);

  // <------third table averge movement varients are calculating here-------->

  const calculateAverageHighLow = (data_avg,setAverageHighLow,setAvgOfThirdTable,setAvgOfThirdTable_flat0, eventHistory) => {
    // const filteredEventHistory = eventHistory2.filter(
    //   (item) => item.estimate !== null && item.estimate !== ""
    // );
    // const a_minus_evalue = filteredEventHistory.map(
    //   (item) => item.actual - item.estimate
    // );

    const a_minus_evalue = eventHistory2.map((item) =>
      item.actual - item.estimate === 0 ||
      item.estimate === null ||
      item.estimate === ""
        ? item.actual - item.previous
        : item.actual - item.estimate
    );

   
    const groupedByDate = data_avg.reduce((acc, entry) => {
      const entryDate = entry.date.split(".").reverse().join(".");
      if (!acc[entryDate]) {
        acc[entryDate] = [];
      }

      acc[entryDate].push(entry);
      return acc;
    }, {});

    const groupedByDateArray = Object.entries(groupedByDate);
   
    let sliced_entries;
    if (optionValue === "Last 6") {
      sliced_entries = groupedByDateArray.slice(0, 6);
    } else if (optionValue === "Last 12") {
      sliced_entries = groupedByDateArray.slice(0, 12);
    } else {
      sliced_entries = groupedByDateArray;
    }

    const slicedGroupedByDate = Object.fromEntries(sliced_entries);
   
    const eventTimes = eventHistory2.map((item) => item.time);
    const eventDate = eventHistory2.map((item) => item.date);
    const findemptyestimate = eventHistory2.map((item) => item.estimate);
   

    //  <-------- it will return empty data if dates r not matching or data is missed in eventhistory2 --------->

    const result = eventHistory2.map((event) => {
      const formattedEventDate = event.date;
      if (formattedEventDate in slicedGroupedByDate) {
        return {
          date: event.date,
          entries: (slicedGroupedByDate[formattedEventDate] || []).slice(0, 6),
        };
      } else {
        return {
          date: event.date,
          entries: [],
        };
      }
    });

    // Now, calculate the average differences
    let sumOfAverages = 0;
    let countOfAverages = 0;
    let sumOfFirstIndexValues = 0;

    let countOfAverages_flat0 = 0;
    let sumOfAverages_flat0 = 0;

    const averageDifferencesArray = result.map((item, index) => {
      const { date, entries } = item;

      const matchingEntries = entries.filter((entry) =>
        eventTimes.includes(entry.time)
      );
      // <---matching entries means on that particular time--->
      console.log("matchingEntries" , matchingEntries)
      const nonMatchingEntries = entries.filter(
        (entry) => !eventTimes.includes(entry.time)
      );
      const highLowDifferences = nonMatchingEntries.map(
        (entry) => entry.High - entry.Low
      );
      console.log("nonMatchingEntries" , nonMatchingEntries)
      console.log("highLowDifferences" , highLowDifferences)
      // <--this is H-L of mathcing entry---->
      const matchingHighLowDifferences = matchingEntries.map(
        (entry) => entry.High - entry.Low
      );
      console.log("matchingHighLowDifferences" , matchingHighLowDifferences)

      let finalsum_uppervalue_HL; // Declare without initializing

      if (matchingHighLowDifferences.length > 0) {
        const firstIndexValue = matchingHighLowDifferences[0];
        finalsum_uppervalue_HL = parseFloat(firstIndexValue);
      } else {
        finalsum_uppervalue_HL = "N/A";
      }

      console.log("finalsum_uppervalue_HL", finalsum_uppervalue_HL);

      let sumOfFirstIndexValues = 0;
      // Only add to sum if finalsum_uppervalue_HL is a number
      if (finalsum_uppervalue_HL !== "N/A") {
        sumOfFirstIndexValues += finalsum_uppervalue_HL;
      } else {
        sumOfFirstIndexValues = "N/A";
      }

      // console.log("sumOfFirstIndexValues" , sumOfFirstIndexValues)

      const totalDifference = highLowDifferences.reduce(
        (sum, diff) => sum + diff,
        0
      );
      console.log("totalDifference" , totalDifference)

      const matchingTotalDifference = matchingHighLowDifferences.reduce(
        (sum, diff) => sum + diff,
        0
      );

      let averageDifference;

      if (sumOfFirstIndexValues !== "N/A") {
        // Check if totalDifference is 0
        if (totalDifference != 0 && highLowDifferences.length > 0) {
          averageDifference =
            sumOfFirstIndexValues /
            (totalDifference / highLowDifferences.length);
        } else {
          averageDifference = "Last 5 min's data is missing";
        }
      } else {
        averageDifference = "Data unavailable";
      }

     
console.log("averageDifference")
      const avgdata = parseFloat(averageDifference);
      // const finalavg = avgdata * 100;
      const finalavg = avgdata;
      // console.log("finalavg-------------" , finalavg)

      if (
       
        a_minus_evalue[index] !== 0.0 &&
        a_minus_evalue[index] !== -0.0
      ) {
     
        if (!isNaN(finalavg)) {
          sumOfAverages += Math.round(finalavg * 100);
          // console.log("sumOfAverages single---------------->" , Math.round(finalavg*100))
          // console.log("sumOfAverages---------------->" , sumOfAverages)
          countOfAverages += 1;
        } else {
          // console.log("finalavg is NaN, skipping this value");
        }
      } else {
        if (!isNaN(finalavg)) {
          sumOfAverages_flat0 += Math.round(finalavg * 100);

          countOfAverages_flat0 += 1;
          // console.log("sumOfAverages_flat0" , sumOfAverages_flat0 , countOfAverages_flat0)
        }
      }

      if (averageDifference === "Last 5 min's data is missing") {
        console.log("Last 5 min's data is missing");

        return { date, averageDifference: "Last 5 min's data is missing" };
      } else {
        return { date, averageDifference: avgdata };
      }
    });

    const overallAverage =
      countOfAverages > 0 ? sumOfAverages / countOfAverages : null;
    const overallAverage_flat0 =
      countOfAverages_flat0 > 0
        ? sumOfAverages_flat0 / countOfAverages_flat0
        : null;

    setAverageHighLow(averageDifferencesArray);
    setAvgOfThirdTable(Math.round(overallAverage));
    setAvgOfThirdTable_flat0(Math.round(overallAverage_flat0));
  };

  useEffect(() => {
    calculateAverageHighLow(usddata_avg,setAverageHighLow_usddata,setAvg_ofthirdtable_usd,setAvg_ofthirdtable_usd_flat0, eventHistory2,optionValue);
    calculateAverageHighLow(jpydata_avg,setAverageHighLow_jpydata,setAvg_ofthirdtable_jpy,setAvg_ofthirdtable_jpy_flat0,eventHistory2,optionValue);
    calculateAverageHighLow(gbpnzddata_avg,setAverageHighLow_nzddata,setAvg_ofthirdtable_nzd,setAvg_ofthirdtable_nzd_flat0,eventHistory2,optionValue);
    calculateAverageHighLow(
      eurgbpdata_avg,
      setAverageHighLow_eurdata,
      setAvg_ofthirdtable_eur,
      setAvg_ofthirdtable_eur_flat0,
      eventHistory2,
      optionValue
    );
    calculateAverageHighLow(
      gbpchfdata_avg,
      setAverageHighLow_chfdata,
      setAvg_ofthirdtable_chf,
      setAvg_ofthirdtable_chf_flat0,
      eventHistory2,
      optionValue
    );
    calculateAverageHighLow(
      gbpauddata_avg,
      setAverageHighLow_auddata,
      setAvg_ofthirdtable_aud,
      setAvg_ofthirdtable_aud_flat0,
      eventHistory2,
      optionValue
    );
    calculateAverageHighLow(
      gbpcaddata_avg,
      setAverageHighLow_caddata,
      setAvg_ofthirdtable_cad,
      setAvg_ofthirdtable_cad_flat0,
      eventHistory2,
      optionValue
    );
  }, [optionValue,handleOptionChange,eventHistory2,allEventHistory2,usddata_avg,jpydata_avg,gbpnzddata_avg,eurgbpdata_avg,gbpchfdata_avg,gbpauddata_avg,gbpcaddata_avg,
  ]);

  // <------render data in 3rd table(average of 5min averages ------->
  const renderTableRows_avg = (averagedata_table, date) => {
    const filteredData = averagedata_table.find((item) => item.date === date);
    if (!filteredData) {
      return <div className="py-7 bg-gray-200">No Data</div>;
    }

    

    const formattedDifference = filteredData.averageDifference === "Last 5 min's data is missing"
        ? "Last 5 min's data is missing"
        : typeof filteredData.averageDifference === "number" &&
          !isNaN(filteredData.averageDifference)
        ? filteredData.averageDifference
        : "Data unavailable";

    const colorClass =formattedDifference === "Data unavailable" || isNaN(formattedDifference)
        ? "bg-[#E5E7EB]"
        : Math.round(formattedDifference * 100) > 500.0
        ? "bg-[#7fe9a4]"
        : Math.round(formattedDifference * 100) > 300.0 &&
          Math.round(formattedDifference * 100) <= 500.0
        ? "bg-[#f7dc14]"
        : "bg-[#f3b2b2]";
   

    return (
      <div className={` py-6 ${colorClass}  w-[80px] h-[67px]`}>
       
        {formattedDifference === "Last 5 min's data is missing"
          ? "Data unavailable"
          : formattedDifference === "Data unavailable"
          ? "Data unavailable"
          : `(${Math.round(formattedDifference * 100)}%)`}
      </div>
    );
  };
  // <-------------color code special---------------->
  const renderTableRows_avg_colorcode = (averagedata_table, date) => {
    const filteredData = averagedata_table.find((item) => item.date === date);
    if (!filteredData) {
      return <div className="py-7 bg-gray-200">No Data</div>;
    }

    const formattedDifference =
      typeof filteredData.averageDifference === "number" &&
      !isNaN(filteredData.averageDifference)
        ? // ? filteredData.averageDifference.toFixed(5) * 100
          filteredData.averageDifference
        : "Data unavailable";

    return formattedDifference === "Data unavailable" || isNaN(formattedDifference)
      ? "bg-[#E5E7EB]"
      : Math.round(formattedDifference * 100) > 500.0
      ? "bg-[#7fe9a4]"
      : Math.round(formattedDifference * 100) >= 300.0 &&
        Math.round(formattedDifference * 100) < 500.0
      ? "bg-[#f7dc14]"
      : "bg-[#f3b2b2]";
  };

  // <----------calculate the average of 3rd table ------------->
  // <---cybercure-->
  const calculate_currencyaveragethirdtable = () => {
    const filteredEventHistory = eventHistory2.filter(
      (item) => item.estimate !== null && item.estimate !== ""
    );

    const a_minus_evalue = eventHistory2.map((item) =>
      item.actual - item.estimate === 0 ||
      item.estimate === null ||
      item.estimate === ""
        ? item.actual - item.previous
        : item.actual - item.estimate
    );

    // const a_minus_evalue = eventHistory.map(
    //   (item) => item.actual - item.estimate
    // );
    // <---here we r matching date time , bcz. length r different --->
    const eventavgtime = eventHistory2.map((item) => item.time);
    const eventavgestimate = eventHistory2.map((item) => item.estimate);
    const eventavgdate = eventHistory2.map((item) =>
      item.date.split(".").reverse().join(".")
    );

    const calculateAverageHL_thirdtable = (data, differences) => {
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Low: "", High: "" };
      });

     
      if (filledData.some((entry) => entry.Low !== "" && entry.High !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (a_minus_evalue[index] !== 0.0 && a_minus_evalue[index] !== -0.0) {
            if (item.High === "" || item.Low === "") {
              return total;
            }
            const differenceHL = item.High - item.Low;
            // console.log("differenceHL" , differenceHL)

            let modulusHL;
            if (data === jpydata_avg) {
              modulusHL = Math.abs(Math.round(differenceHL * 1000));
            } else {
              modulusHL = Math.abs(
                Math.round(differenceHL * (country === "JP" ? 1000 : 100000))
              );
            }

            // console.log("modulusHL" , modulusHL)
            return total + modulusHL;
          }
          return total;
        }, 0);
        // console.log("sum" , sum)
        const validEntriesCount = filledData.filter((item, index) => {
          const lowAndHighNotEmpty = item.Low !== "" && item.High !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            lowAndHighNotEmpty &&
            a_minus_evalue[index] !== 0.0 &&
            a_minus_evalue[index] !== -0.0
          );
        }).length;

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        // console.log("validEntriesCount----------" , validEntriesCount)
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets
    const averageHL1 = calculateAverageHL_thirdtable(usddata_avg,a_minus_evalue);
    SetAveragegbpusddiff1_thirdtable(averageHL1);
    const averageHL2 = calculateAverageHL_thirdtable(jpydata_avg,a_minus_evalue);
    SetAveragegbpjpydiff1_thirdtable(averageHL2);
    const averageHL3 = calculateAverageHL_thirdtable(gbpnzddata_avg,a_minus_evalue);
    SetAveragegbpnzddiff1_thirdtable(averageHL3);
    const averageHL4 = calculateAverageHL_thirdtable(eurgbpdata_avg,a_minus_evalue);
    SetAveragegbpeurdiff1_thirdtable(averageHL4);
    const averageHL5 = calculateAverageHL_thirdtable(gbpchfdata_avg,a_minus_evalue);
    SetAveragegbpchfdiff1_thirdtable(averageHL5);
    const averageHL6 = calculateAverageHL_thirdtable(gbpauddata_avg,a_minus_evalue);
    SetAveragegbpauddiff1_thirdtable(averageHL6);
    const averageHL7 = calculateAverageHL_thirdtable(gbpcaddata_avg,a_minus_evalue);
    SetAveragegbpcaddiff1_thirdtable(averageHL7);

    // <-------for flat 0 values------------>

    const calculateSumAndAverageHL_flat0_thirdtable = (data, differences) => {
      const filledData = eventavgdate.map((date, index) => {
        const time = eventavgtime[index];
        const entry = data.find(
          (item) => item.date === date && item.time === time
        );
        return entry || { date: date, time: time, Low: "", High: "" };
      });
      // const filteredData = filledData.filter((entry, index) => {
      //   return eventavgestimate[index] !== undefined && eventavgestimate[index] !== null;
      // });
      if (filledData.some((entry) => entry.Low !== "" && entry.High !== "")) {
        const sum = filledData.reduce((total, item, index) => {
          if (a_minus_evalue[index] === 0.0 || a_minus_evalue[index] === -0.0) {
            if (item.High === "" || item.Low === "") {
              return total;
            }
            const differenceHL_thirdtable = item.High - item.Low;

            let modulusHL_thirdtable;
            if (data === jpydata_avg) {
              modulusHL_thirdtable = Math.abs(
                Math.round(differenceHL_thirdtable * 1000)
              );
            } else {
              modulusHL_thirdtable = Math.abs(
                Math.round(
                  differenceHL_thirdtable * (country === "JP" ? 1000 : 100000)
                )
              );
            }
            return total + modulusHL_thirdtable;
          }

          return total;
        }, 0);

        const validEntriesCount = filledData.filter((item, index) => {
          const lowhighhNotEmpty = item.Low !== "" && item.High !== "";

          // Only include the item if Low and High are not empty and the difference of A-E is not 0.0 or -0.0
          return (
            lowhighhNotEmpty &&
            a_minus_evalue[index] === 0.0 &&
            a_minus_evalue[index] === -0.0
          );
        }).length;

        const average = validEntriesCount > 0 ? sum / validEntriesCount : 0;
        // console.log("average------------" , average)
        return average;
      } else {
        return null;
      }
    };

    // Calculate averages for different data sets when actual - estimate is zero
    const averageHL1_flat0_thirdtable =
      calculateSumAndAverageHL_flat0_thirdtable(usddata_avg, a_minus_evalue);
    setaveragegbpusddiff1_flat0_thirdtable(averageHL1_flat0_thirdtable);

    const averageHL2_flat0_thirdtable =
      calculateSumAndAverageHL_flat0_thirdtable(jpydata_avg, a_minus_evalue);
    setaveragegbpjpydiff1_flat0_thirdtable(averageHL2_flat0_thirdtable);

    const averageHL3_flat0_thirdtable =
      calculateSumAndAverageHL_flat0_thirdtable(gbpnzddata_avg, a_minus_evalue);
    setaveragegbpnzddiff1_flat0_thirdtable(averageHL3_flat0_thirdtable);

    const averageHL4_flat0_thirdtable =
      calculateSumAndAverageHL_flat0_thirdtable(eurgbpdata_avg, a_minus_evalue);
    setaveragegbpeurdiff1_flat0_thirdtable(averageHL4_flat0_thirdtable);

    const averageHL5_flat0_thirdtable =
      calculateSumAndAverageHL_flat0_thirdtable(gbpchfdata_avg, a_minus_evalue);
    setaveragegbpchfdiff1_flat0_thirdtable(averageHL5_flat0_thirdtable);

    const averageHL6_flat0_thirdtable =
      calculateSumAndAverageHL_flat0_thirdtable(gbpauddata_avg, a_minus_evalue);
    setaveragegbpauddiff1_flat0_thirdtable(averageHL6_flat0_thirdtable);

    const averageHL7_flat0_thirdtable =
      calculateSumAndAverageHL_flat0_thirdtable(gbpcaddata_avg, a_minus_evalue);
    setaveragegbpcaddiff1_flat0_thirdtable(averageHL7_flat0_thirdtable);
  };

  useEffect(() => {
    calculate_currencyaveragethirdtable();
  }, [
    handleOptionChange,
    eventHistory2,
    allEventHistory,
    eventHistory,
    eurgbpdata,
    gbpauddata,
    gbpcaddata,
    gbpchfdata,
    gbpnzddata,
    jpydata,
    usddata,

    eurgbpdata_avg,
    gbpauddata_avg,
    gbpcaddata_avg,
    gbpchfdata_avg,
    gbpnzddata_avg,
    jpydata_avg,
    usddata_avg,
  ]);

  // <----------------calculate the highest of average of third table---------------->

  // <------------hl------------>
  const avgdatagbpusdhl =
    averagegbpusddiff1_thirdtable !== null
      ? averagegbpusddiff1_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpjpyhl =
    averagegbpjpydiff1_thirdtable !== null
      ? averagegbpjpydiff1_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpnzdhl =
    averagegbpnzddiff1_thirdtable !== null
      ? averagegbpnzddiff1_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpeurhl =
    averagegbpeurdiff1_thirdtable !== null
      ? averagegbpeurdiff1_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpchfhl =
    averagegbpchfdiff1_thirdtable !== null
      ? averagegbpchfdiff1_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpaudhl =
    averagegbpauddiff1_thirdtable !== null
      ? averagegbpauddiff1_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpcadhl =
    averagegbpcaddiff1_thirdtable !== null
      ? averagegbpcaddiff1_thirdtable.toFixed(2)
      : "N/A";
  // console.log("avgdatagbpcadhl" ,avgdatagbpcadhl)
  const values4 = [
    avgdatagbpusdhl,
    avgdatagbpjpyhl,
    avgdatagbpnzdhl,
    avgdatagbpeurhl,
    avgdatagbpchfhl,
    avgdatagbpaudhl,
    avgdatagbpcadhl,
  ];

  values4.sort((a, b) => b - a);
  let highest1hl_thirdtable = values4[0];
  let highest2hl_thirdtable = values4[1];
  let highest3hl_thirdtable = values4[2];

  // console.log("high" , highest1hl_thirdtable , highest2hl_thirdtable , highest3hl_thirdtable)

  // <---------------------now , find the highest  of averges of third table of variance (color coding)----------->
  const avgdatagbpusdhl_last5min =
    avg_ofthirdtable_usd !== null ? Math.round(avg_ofthirdtable_usd) : "N/A";
  const avgdatagbpjpyhl_last5min =
    avg_ofthirdtable_jpy !== null ? Math.round(avg_ofthirdtable_jpy) : "N/A";
  const avgdatagbpnzdhl_last5min =
    avg_ofthirdtable_nzd !== null ? Math.round(avg_ofthirdtable_nzd) : "N/A";
  const avgdatagbpeurhl_last5min =
    avg_ofthirdtable_eur !== null ? Math.round(avg_ofthirdtable_eur) : "N/A";
  const avgdatagbpchfhl_last5min =
    avg_ofthirdtable_chf !== null ? Math.round(avg_ofthirdtable_chf) : "N/A";
  const avgdatagbpaudhl_last5min =
    avg_ofthirdtable_aud !== null ? Math.round(avg_ofthirdtable_aud) : "N/A";
  const avgdatagbpcadhl_last5min =
    avg_ofthirdtable_cad !== null ? Math.round(avg_ofthirdtable_cad) : "N/A";

  const values5 = [
    parseFloat(avgdatagbpusdhl_last5min),
    parseFloat(avgdatagbpjpyhl_last5min),
    parseFloat(avgdatagbpnzdhl_last5min),
    parseFloat(avgdatagbpeurhl_last5min),
    parseFloat(avgdatagbpchfhl_last5min),
    parseFloat(avgdatagbpaudhl_last5min),
    parseFloat(avgdatagbpcadhl_last5min),
  ];

  values5.sort((a, b) => b - a);
  let highest1hl_thirdtable_last5min = values5[0] + "%";
  let highest2hl_thirdtable_last5min = values5[1] + "%";
  let highest3hl_thirdtable_last5min = values5[2] + "%";

  // <------------hl flat avg highest normal (last portion)------------>
  const avgdatagbpusdhl_flat0 =
    averagegbpusddiff1_flat0_thirdtable !== null
      ? averagegbpusddiff1_flat0_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpjpyhl_flat0 =
    averagegbpjpydiff1_flat0_thirdtable !== null
      ? averagegbpjpydiff1_flat0_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpnzdhl_flat0 =
    averagegbpnzddiff1_flat0_thirdtable !== null
      ? averagegbpnzddiff1_flat0_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpeurhl_flat0 =
    averagegbpeurdiff1_flat0_thirdtable !== null
      ? averagegbpeurdiff1_flat0_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpchfhl_flat0 =
    averagegbpchfdiff1_flat0_thirdtable !== null
      ? averagegbpchfdiff1_flat0_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpaudhl_flat0 =
    averagegbpauddiff1_flat0_thirdtable !== null
      ? averagegbpauddiff1_flat0_thirdtable.toFixed(2)
      : "N/A";
  const avgdatagbpcadhl_flat0 =
    averagegbpcaddiff1_flat0_thirdtable !== null
      ? averagegbpcaddiff1_flat0_thirdtable.toFixed(2)
      : "N/A";
  // console.log("avgdatagbpcadhl" ,avgdatagbpcadhl)
  const values4_flat0 = [
    avgdatagbpusdhl_flat0,
    avgdatagbpjpyhl_flat0,
    avgdatagbpnzdhl_flat0,
    avgdatagbpeurhl_flat0,
    avgdatagbpchfhl_flat0,
    avgdatagbpaudhl_flat0,
    avgdatagbpcadhl_flat0,
  ];

  values4_flat0.sort((a, b) => b - a);
  let highest1hl_thirdtable_flat0 = values4_flat0[0];
  let highest2hl_thirdtable_flat0 = values4_flat0[1];
  let highest3hl_thirdtable_flat0 = values4_flat0[2];

  // console.log("highet" , highest1hl_thirdtable_flat0 , highest2hl_thirdtable_flat0 , highest3hl_thirdtable_flat0)

  // <------------hl flat avg highest (variance) (first portion)------------>
  const avgdatagbpusdhl_last5min_flat0 =
    avg_ofthirdtable_usd_flat0 !== null
      ? Math.round(avg_ofthirdtable_usd_flat0)
      : "N/A";
  const avgdatagbpjpyhl_last5min_flat0 =
    avg_ofthirdtable_jpy_flat0 !== null
      ? Math.round(avg_ofthirdtable_jpy_flat0)
      : "N/A";
  const avgdatagbpnzdhl_last5min_flat0 =
    avg_ofthirdtable_nzd_flat0 !== null
      ? Math.round(avg_ofthirdtable_nzd_flat0)
      : "N/A";
  const avgdatagbpeurhl_last5min_flat0 =
    avg_ofthirdtable_eur_flat0 !== null
      ? Math.round(avg_ofthirdtable_eur_flat0)
      : "N/A";
  const avgdatagbpchfhl_last5min_flat0 =
    avg_ofthirdtable_chf_flat0 !== null
      ? Math.round(avg_ofthirdtable_chf_flat0)
      : "N/A";
  const avgdatagbpaudhl_last5min_flat0 =
    avg_ofthirdtable_aud_flat0 !== null
      ? Math.round(avg_ofthirdtable_aud_flat0)
      : "N/A";
  const avgdatagbpcadhl_last5min_flat0 =
    avg_ofthirdtable_cad_flat0 !== null
      ? Math.round(avg_ofthirdtable_cad_flat0)
      : "N/A";

  const values5_third_last5min_flat0 = [
    parseFloat(avgdatagbpusdhl_last5min_flat0),
    parseFloat(avgdatagbpjpyhl_last5min_flat0),
    parseFloat(avgdatagbpnzdhl_last5min_flat0),
    parseFloat(avgdatagbpeurhl_last5min_flat0),
    parseFloat(avgdatagbpchfhl_last5min_flat0),
    parseFloat(avgdatagbpaudhl_last5min_flat0),
    parseFloat(avgdatagbpcadhl_last5min_flat0),
  ];

  values5_third_last5min_flat0.sort((a, b) => b - a);
  let highest1hl_thirdtable_last5min_flat0 =
    values5_third_last5min_flat0[0] + "%";
  let highest2hl_thirdtable_last5min_flat0 =
    values5_third_last5min_flat0[1] + "%";
  let highest3hl_thirdtable_last5min_flat0 =
    values5_third_last5min_flat0[2] + "%";
  // console.log("highest1h" , highest1hl_thirdtable_last5min_flat0 , highest2hl_thirdtable_last5min_flat0 , highest3hl_thirdtable_last5min_flat0)

  return (
    <>
      {eventHistory2.length > 0 && !Loading ? (
        <div className="">
          <UserNavbar />

          {/* <div className=" items-center ">
            <div className="flex  justify-between items-start sm:items-start md:items-start lg:items-end xl:items-end my-1 sm:flex-col flex-col md:flex-col lg:flex-row xl:flex-row  mx-[3%]">
              <div className="p-2 w-[52%]">
                <h1 className="mt-16 pt-6 font-semibold text-start ">
                  Date : {date} <br /> Time : {time} <br /> Country : {country}{" "}
                  <br /> Impact :{impact} <br /> Event : {event}
                  <p> Frequency of dates : {frequency_date}</p>
                </h1>
                <h1 className="font-bold text-start mt-3">
                  Count on<span className="font-bold"> {date}</span> at{" "}
                  <span className="font-bold">{time}</span> :{" "}
                
                  <span className="ml-3">
                    {clickeddate_Loading ? (
                      <span className="loader1 text-red-400">Loading...</span>
                    ) : (eventimpactontimespecificdate)}
                  </span>
                </h1>
                <h1 className="font-bold text-start mb-5">
          
                  Count on<span className="font-bold"> {date}</span> before{" "}
                  <span className="font-bold">{time}</span> :{" "}
                
                  <span className="ml-3">
                    {clickeddate_Loading ? (
                      <span className="loader1 text-red-400">Loading...</span>
                    ) : (eventimpactbeforetimespecificdate)}
                  </span>
                </h1>
              </div>
              <div className="flex justify-center items-start sm:items-start md:items-start lg:items-center xl:items-center sm:flex-col flex-col md:flex-col lg:flex-row xl:flex-row ">
                <div>
                  <Space style={{ width: "100%" }}>
                    <Select
                      defaultValue="Last 6"
                      style={{ width: "200px" }}
                      onChange={handleOptionChange}
                    >
                      <Select.Option value="Last 24">Last 24</Select.Option>
                      <Select.Option value="Last 12">Last 12</Select.Option>
                      <Select.Option value="Last 6">Last 6</Select.Option>
                    </Select>
                  </Space>
                </div>
                <div className="btn-div flex mt-4 sm:mt-4 md:mt-4 lg:mt-0 xl:mt-0">
               
                  <button
                    className={`px-2 mx-2 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                      clickedtimeButton === "1min"
                        ? "bg-slate-500"
                        : "bg-gray-700 hover:bg-gray-800"
                    } ${off_minutebuttons1 ? "" : "opacity-50 cursor-not-allowed"}`}
                    value="1min"
                    
                    disabled={!off_minutebuttons1}
                    onClick={() => handletimebtnclick("1min")}
                  >
                    1min
                  </button>
                 


                  
                  <button
                    className={`  px-2  mx-2 py-2 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75  ${
                      clickedtimeButton === "5min"
                        ? "bg-slate-500"
                        : "bg-gray-700 hover:bg-gray-800"
                    } ${off_minutebuttons2 ? "" : "opacity-50 cursor-not-allowed"}`}
                    value="5min"
                    onClick={() => handletimebtnclick("5min")}
                    disabled={!off_minutebuttons2}
                  >
                    5min
                  </button>
                  <button
                    className={`  px-2  mx-2 py-2 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75  ${
                      clickedtimeButton === "15min"
                        ? "bg-slate-500"
                        : "bg-gray-700 hover:bg-gray-800"
                    } ${off_minutebuttons3 ? "" : "opacity-50 cursor-not-allowed"}`}
                    value="15min"
                    onClick={() => handletimebtnclick("15min")}
                    disabled={!off_minutebuttons3}
                  >
                    15min
                  </button>
                  <button
                    className={`  px-2  mx-2 py-2 text-sm font-medium text-white bg-gray-700 rounded-md hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75  ${
                      clickedtimeButton === "60min"
                        ? "bg-slate-500"
                        : "bg-gray-700 hover:bg-gray-800"
                    } ${off_minutebuttons4 ? "" : "opacity-50 cursor-not-allowed"}`}
                    value="60min"
                    onClick={() => handletimebtnclick("60min")}
                    disabled={!off_minutebuttons4}
                  >
                    60min
                  </button>
                  <div onClick={handleModalShowClick}>
                    <TfiReload className="cursor-pointer h-[25px] w-[20px] top-[10px]  " />
                    {isReloadLoading && (
                      <div className="loader">
                        <Bars
                          height="20"
                          width="20"
                          color="#94A3B8"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex">
                  
                  <div
                    className="flex items-center space-x-4 ml-2"
                    onClick={handlegeneratechart}
                  >
                    <div className="cursor-pointer transition transform hover:scale-110">
                      <img
                        src={movementicon}
                        title="Generate graphs"
                        alt="graphs"
                        className="w-[35px] h-[35px]"
                        width={90}
                        height={90}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
<div className={` mx-auto  flex justify-center items-center w-full`}>
          <div className={`${styles.eventpage} flex flex-col mx-auto mt-[20%] sm:mt-[20%] md:mt-[10%] lg:mt-[8%] xl:mt-[3%] bg-[#fff]  rounded-[18px] justify-center items-start md:items-center`}>
            <div className={`  ${styles.eventpage} flex flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row px-[2%] pb-[1%] pt-[3%] `}>
            <div className=" font-bold flex text-[15px]  sm:text-[15px] md:text-[18px] text-[#003399] ">
                {" "}
                <span className="font-medium flex px-1 text-black pr-2"> <img
                                  src={eventicon}
                                  alt="eventicon"
                                  className="h-[20px] w-[20px] mr-2 mt-1 "
                                /> Event:</span><span className="md:w-[300px] mr-[3%]">{event}</span> {" "}
              </div>
              <div className="text-[15px] sm:text-[15px] md:text-[18px] font-bold text-[#003399] md:w-[200px]  flex ml-0 sm:ml-0 md:ml-0 lg:ml-0 xl:ml-[0%]">
                <span className="font-medium flex px-1 text-black"> 
                  <img
                                  src={locationicon}
                                  alt="locationicon"
                                  className="h-[20px] w-[20px] mr-2 mt-1 font-bold "
                                /> Country :</span> {country}{" "}
                </div>
              <div className="flex text-[15px] sm:text-[15px] md:text-[18px] font-semibold flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row">
              
                <div className="w-[200px] font-bold flex text-[#003399] ">
                  <span className="font-medium flex px-1 text-black">
                <img
                                  src={dateicon}
                                  alt="dateicon"
                                  className="h-[20px] w-[20px] mr-2 mt-1 "
                                />Date : </span> {date} </div>{" "}
                <div className="text-[#003399] ml-0 lg:ml-10 font-bold md:w-[300px] flex"><span className="font-medium flex px-1 text-black"> <img
                                  src={timeicon}
                                  alt="timeicon"
                                  className="h-[20px] w-[20px] mr-2 mt-1 "
                                />Time (GMT) :</span> {time.slice(0, 5)}  </div>
              </div>
              <div className="flex text-[15px] sm:text-[15px] md:text-[18px] font-semibold   ml-0 sm:ml-0 md:ml-0 lg:ml-0 xl:ml-[0%] xl:mr-[3%]">
                {" "}
              
                <div className="text-[#003399] ml-0 md:w-[180px] font-bold flex"><span className="font-medium flex px-1 text-black"> <img
                                  src={impacticon}
                                  alt="impacticon"
                                  className="h-[20px] w-[20px] mr-2 mt-1 "
                                />Impact :</span>{impact} </div>
              </div>
             
              <div className="text-[#003399]  font-bold flex text-[15px] sm:text-[15px] md:text-[18px]   md:w-[430px]  ml-0 sm:ml-0 md:ml-0 lg:ml-0 xl:ml-0">
                {" "}
                <span className="font-medium flex px-1 text-black">  <img
                                  src={frequencyicon}
                                  alt="frequencyicon"
                                  className="h-[20px] w-[20px] mr-2 mt-1 "
                                />Average frequency of Event Release :</span> {frequency_date}
              </div>
            </div>
            <hr className="bg-[#A0A0A0] bg-opacity-[5%] h-1 w-full" />
            <div className=" flex flex-col text-[15px] sm:text-[15px] md:text-[18px] text-[#170E32] py-[1%] px-[8%] md:px-0 lg:px-[1%] xl:px-[2%] w-full text-center justify-center items-start md:items-start">
              <h1 className="font-bold text-start my-1">
               Event Count on
                <span className="font-semibold text-[#003399]"> {date}</span> at{" "}
                <span className="font-semibold text-[#003399]">{time.slice(0, 5)}</span> :{" "}
                <span className="ml-3 font-semibold">
                  {clickeddate_Loading ? (
                    <span className="loader1 text-red-400">Loading...</span>
                  ) : (
                    // console.log("eventimpactontimespecificdate" , eventimpactontimespecificdate)
                    eventimpactontimespecificdate.every((item) => item === "") 
                    ? "No data" 
                    : eventimpactontimespecificdate || "No data"
                  
                    // eventimpactontimespecificdate || "None"
                  )}
                </span>
              </h1>
              <h1 className="font-bold text-start my-1 w-[90%] ">
              Event Count on
                <span className="font-semibold text-[#003399]"> {date} </span>{" "}
                before{" "}
                <span className="font-semibold text-[#003399]"> {time.slice(0, 5)} </span> :{" "}
                <span className="ml-3 font-semibold">
                  {clickeddate_Loading ? (
                    <span className="loader1 text-red-400">Loading...</span>
                  ) : (
                    // console.log("eventimpactbeforetimespecificdate",  eventimpactbeforetimespecificdate)
                    eventimpactbeforetimespecificdate.every((item)=> item === "")
                    ? "No data"
                    : eventimpactbeforetimespecificdate || "No data"
                    // eventimpactbeforetimespecificdate || "None"
                  )}
                </span>
              </h1>
            </div>
          </div>
          </div>

          <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row justify-between mx-[3%] mt-[2%]">
            <div>
              <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block pr-3 mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0">
                <Space
                  style={{
                    width: "100%",
                    paddingTop: 0,
                    height: "46px",
                  }}
                  direction="vertical"
                >
                  <FormControl sx={{ width: 200, height: 46 }}>
                    <InputLabel id="range-select-label">
                      Select Range
                    </InputLabel>
                    <Select
                      labelId="range-select-label"
                      value={optionValue}
                      onChange={(e) => handleOptionChange(e.target.value)}
                      label="Events Range"
                      sx={{ height: 46 }}
                    >
                      <MenuItem value="Last 6">Last 6</MenuItem>
                      <MenuItem value="Last 12">Last 12</MenuItem>
                      <MenuItem value="Last 24">Last 24</MenuItem>
                    </Select>
                  </FormControl>
                </Space>
              </div>
              <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block pr-3 mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0">
  <Space
    style={{
      width: "100%",
      paddingTop: 0,
      height: "46px",
    }}
    direction="vertical"
  >
    <FormControl sx={{ width: 200, height: 46 }}>
      <InputLabel id="time-select-label">Select Time</InputLabel>
      <Select
        labelId="time-select-label"
        value={clickedtimeButton}
        onChange={(e) => handletimebtnclick(e.target.value)}
        label="Select Time"
        sx={{ height: 46 }}
        disabled={!off_minutebuttons1 && !off_minutebuttons2 && !off_minutebuttons3 && !off_minutebuttons4} // Disable if all buttons are off
      >
        <MenuItem value="1min" disabled={!off_minutebuttons1}>
          1 min
        </MenuItem>
        <MenuItem value="5min" disabled={!off_minutebuttons2}>
          5 min
        </MenuItem>
        <MenuItem value="15min" disabled={!off_minutebuttons3}>
          15 min
        </MenuItem>
        <MenuItem value="60min" disabled={!off_minutebuttons4}>
          1 hr
        </MenuItem>
      </Select>
    </FormControl>
  </Space>
</div>

            </div>
            <div className="flex">
              {/* <div
                className="h-[40px] p-3 cursor-pointer text-[#170E32] font-small text-[14px] flex border border-solid border-[#170E32] items-center rounded-md"
                onClick={handlegeneratechart}
              > */}
                <div
                className={`h-[40px] p-3  text-[#170E32] font-small text-[14px] flex border border-solid border-[#170E32] items-center rounded-md ${
                  isgraphbuttonDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                }`}
                onClick={!isgraphbuttonDisabled ? handlegeneratechart : undefined} 
              >
                <img
                  src={movementicon}
                  title="Generate graphs"
                  alt="graphs"
                  className="w-[25px] h-[22px]"
                />

                <h1 className="ml-2">Generate Graph</h1>
              </div>
              <div className="ml-2">
                <img
                  src={refresh_icon}
                  alt="Refresh"
                  title="Refresh"
                  className={`h-[40px] w-[40px] cursor-pointer`}
                  onClick={handleModalShowClick}
                />
              </div>
            </div>
          </div>
          {/* <------------------first table which was 3rd--------------> */}

          <div className="m-3  py-2 px-3  mt-5 flex ">
            <h1 className="ml-[2%] font-bold text-[20px] text-[#170E32]">
            Event Volatility Impact:
            </h1>
          </div>

          <div className="overflow-auto">
            <div className="  mx-[3%] overflow-x-auto overflow-y-auto max-h-[87vh]">
            <div className="flex ">
                    <div className="min-w-[1107px] bg-[#151841] min-h-[79.96] border-r-2 border-r-white"></div>
                    <div className={`${styles.firsttableheader} bg-[#151841] w-[637px] min-w-[576px] flex justify-center items-center py-2 `}><h1 className="text-[18px] font-bold text-white">% Price movement vs. prior 5 minute range (High-Low)</h1></div>
                    <div className={`${styles.firsttableheader} bg-[#151841] w-[637px] min-w-[580px] flex justify-center items-center py-2 border-x-2 border-x-white`}><h1 className="text-[18px] font-bold text-white py-3">{`Total points moved post release (High – Low , ${
                    timeclickedurl_state === 1
                      ? "(1min)"
                      : timeclickedurl_state === 5
                      ? "(5min)"
                      : timeclickedurl_state === 15
                      ? "(15min)"
                      : timeclickedurl_state === 60
                      ? "(60min)"
                      : "(1min)"
                  })`}</h1></div>
                    {/* <div className="bg-[#151841] yellow-200 min-w-[643.51px] flex justify-center items-center py-2"><h1 className="text-[18px] font-bold text-white py-3">High price - Open Price (1min)</h1></div> */}
                  </div>
              <table
                className={` ${styles.tableuser} min-w-full  overflow-x-scroll m-auto  font-semibold `}
              >
                <thead className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[14px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px] ">
                  <tr className="h-[96px]">
                    <th className="sticky top-0 py-2 px-2 min-w-[81px] w-[80px]">
                      S. No
                    </th>
                    <th className="sticky top-0 py-2 px-2 w-[73px]">Date</th>
                    <th className="sticky top-0 py-2 px-2 w-[91px]">Time(GMT)</th>
                    <th className={`sticky top-0 py-2 px-2 w-[200px] min-w-[200px]`}>
                    Event Count before this Time
                    </th>
                    <th className="sticky top-0 py-2 w-[150px] px-2 min-w-[150px]">
                    Event Count at this Time
                    </th>
                    <th className="sticky top-0 py-2 px-2 w-[77px]">Previous</th>
                    <th className="sticky top-0 py-2 px-2 w-[60px]">Actual</th>
                    <th className="sticky top-0 py-2 px-2 w-[77px]">Estimate</th>

                    <th className="sticky w-[97px] top-0 py-2 px-2  min-w-[99.33px]">
                      Act - Prev
                    </th>

                    <th className="z-99 w-[98px] sticky top-0 py-2 px-2 border-r-[4px] border-[#F6F6F6]  min-w-[99.33px]">
                      Act - Est
                    </th>
                    <th className=" z-99 w-[100px] sticky top-0 py-2 px-2 border-r-[4px] border-[#F6F6F6] bg-[#3589FF8F] bg-opacity-[56%] ">
                      Final movement direction
                    </th>

                    {headers.map((header) => (
                      <th
                        key={header.id}
                        className="sticky w-[91px] top-0 py-2 px-2 bg-[#d0dcf4]   text-[#170E32]  "
                      >
                        {header.name}
                        {/* <br />
                        (severity) */}
                      </th>
                    ))}
                    {headers.map((header) => (
                      <th
                        key={header.id}
                        className="sticky top-0 py-2 px-2 bg-[#3589FF8F] bg-opacity-[56%]  text-[#170E32] "
                      >
                        {header.name}
                        {/* <br />
                        (H-L) */}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-[#F6F6F6]  z-0 text-[#170E32] font-bold text-[14px]">
                  {eventHistory2.map((item, index) => {
                    const itemDate = item.date.split(".").reverse().join(".");

                    // let itemTime;
                    let itemTime = item.time;
                    // if (selectedTime === 5) {
                    //   itemTime = roundDownToNearestMultipleOf5(item.time);
                    // } else if (selectedTime === 15) {
                    //   itemTime = roundDownToNearestMultipleOf15(item.time);
                    // } else if (selectedTime === 60) {
                    //   itemTime = roundDownToNearestMultipleOf1Hour(item.time);
                    // } else {
                    //   itemTime = item.time;
                    // }

                    const usdEntry_avg = usddata_avg.find((entry) => {
                      // console.log("entry.time", entry.time);
                      return entry.date === itemDate && entry.time === itemTime;
                    });
                    // console.log("usdEntry_avg", usdEntry_avg);

                    const jpyEntry_avg = jpydata_avg.find(
                      (entry) =>
                        entry.date === itemDate && entry.time === itemTime
                    );
                    const nzdEntry_avg = gbpnzddata_avg.find(
                      (entry) =>
                        entry.date === itemDate && entry.time === itemTime
                    );
                    const eurEntry_avg = eurgbpdata_avg.find(
                      (entry) =>
                        entry.date === itemDate && entry.time === itemTime
                    );
                    const chfEntry_avg = gbpchfdata_avg.find(
                      (entry) =>
                        entry.date === itemDate && entry.time === itemTime
                    );
                    const audEntry_avg = gbpauddata_avg.find(
                      (entry) =>
                        entry.date === itemDate && entry.time === itemTime
                    );
                    const cadEntry_avg = gbpcaddata_avg.find(
                      (entry) =>
                        entry.date === itemDate && entry.time === itemTime
                    );

                    const usdLow_avg = usdEntry_avg
                      ? usdEntry_avg.Low
                      : "Data unavailable";
                    const usdHigh_avg = usdEntry_avg
                      ? usdEntry_avg.High
                      : "Data unavailable";

                    const avg_diff =
                      usdLow_avg !== "Data unavailable" && usdHigh_avg !== "Data unavailable"
                        ? usdHigh_avg - usdLow_avg
                        : "Data unavailable";
                    // console.log("avg_diff" , avg_diff)

                    const jpyLow_avg = jpyEntry_avg
                      ? jpyEntry_avg.Low
                      : "Data unavailable";
                    const jpyHigh_avg = jpyEntry_avg
                      ? jpyEntry_avg.High
                      : "Data unavailable";
                    const avg_jpydiff =
                      jpyLow_avg !== "Data unavailable" && jpyHigh_avg !== "Data unavailable"
                        ? jpyHigh_avg - jpyLow_avg
                        : "Data unavailable";

                    const nzdLow_avg = nzdEntry_avg
                      ? nzdEntry_avg.Low
                      : "Data unavailable";
                    const nzdHigh_avg = nzdEntry_avg
                      ? nzdEntry_avg.High
                      : "Data unavailable";
                    const avg_diffnzdgbp =
                      nzdLow_avg !== "Data unavailable" && nzdHigh_avg !== "Data unavailable"
                        ? nzdHigh_avg - nzdLow_avg
                        : "Data unavailable";

                    const eurLow_avg = eurEntry_avg
                      ? eurEntry_avg.Low
                      : "Data unavailable";
                    const eurHigh_avg = eurEntry_avg
                      ? eurEntry_avg.High
                      : "Data unavailable";
                    const avg_diffeurgbp =
                      eurLow_avg !== "Data unavailable" && eurHigh_avg !== "Data unavailable"
                        ? eurHigh_avg - eurLow_avg
                        : "Data unavailable";

                    const chfLow_avg = chfEntry_avg
                      ? chfEntry_avg.Low
                      : "Data unavailable";
                    const chfHigh_avg = chfEntry_avg
                      ? chfEntry_avg.High
                      : "Data unavailable";
                    const avg_diffgbpchf =
                      chfLow_avg !== "Data unavailable" && chfHigh_avg !== "Data unavailable"
                        ? chfHigh_avg - chfLow_avg
                        : "Data unavailable";

                    const audLow_avg = audEntry_avg
                      ? audEntry_avg.Low
                      : "Data unavailable";
                    const audHigh_avg = audEntry_avg
                      ? audEntry_avg.High
                      : "Data unavailable";

                    // console.log("audLow_avg" , audLow_avg)
                    // console.log("audHigh_avg" , audHigh_avg)
                    const avg_diffgbpaud =
                      audLow_avg !== "Data unavailable" && audHigh_avg !== "Data unavailable"
                        ? audHigh_avg - audLow_avg
                        : "Data unavailable";

                    // console.log("avg_diffgbpaud" , avg_diffgbpaud)
                    const cadLow_avg = cadEntry_avg
                      ? cadEntry_avg.Low
                      : "Data unavailable";
                    const cadHigh_avg = cadEntry_avg
                      ? cadEntry_avg.High
                      : "Data unavailable";
                    const avg_diffgbpcad =
                      cadLow_avg !== "Data unavailable" && cadHigh_avg !== "Data unavailable"
                        ? cadHigh_avg - cadLow_avg
                        : "Data unavailable";

                    const itemDatecheck = item.date.split(" ")[0];

                    // Filtering impacthighlow array to find the matching data
                    const matchingData = impacthighlow.find((data) =>
                      data.startsWith(itemDatecheck)
                    );

                    const normalizeDate = (dateStr) => {
                      return dateStr.split(" ")[0];
                    };

                    const filterImpactData = (date) => {
                      const normalizedDate = normalizeDate(date);

                      return impacthighlowbeforethis.filter((impact) => {
                        const impactDate = impact.split(":")[0];

                        return impactDate === normalizedDate;
                      });
                    };

                    const impactData = filterImpactData(item.date);
                    return (
                      <>
                        <tr
                          key={index}
                          className=" cursor-pointer  text-center bg-white  border-y-[16px] border-solid border-[#F6F6F6] "
                        >
                          <td className="py-2 min-w-[81px]">{index + 1}.</td>
                          <td className="py-2 ">{item.date}</td>
                          <td className="py-2 ">{item.time.slice(0, 5)}</td>

                          {run_modalloader ? (
                            <td className="min-w-[200px] w-[200px]">
                              {" "}
                              <span className="loader1 text-[#1c72ca] text-center">
                                Loading...
                              </span>
                            </td>
                          ) : (
                            <>
                              <td
                                className="py-2  text-[13px] min-w-[200px] w-[200px] relative cursor-pointer"
                                onClick={() =>
                                  handleShowModalbeforetime(item.date)
                                }
                              >
                                {impactData.length > 0 ? (
                                  impactData.map((impact, i) => {
                                    const impactParts = impact.split(" ");
                                    // Remove the first element (date and time)
                                    const formattedImpact = impactParts
                                      .slice(1)
                                      .join(" ");

                                    return (
                                      <span key={i} className="font-bold block">
                                        {formattedImpact}
                                      </span>
                                    );
                                  })
                                ) : (
                                  <span className="font-bold">No Data</span>
                                )}
                              </td>

                              <dialog
                                id="my_modal_2"
                                onClick={() => {
                                  autoClickButton();
                                }}
                                className="modal p-6 absolute max-h-[80vh]  top-0 shadow-[#ba9696] shadow-opacity-10 shadow-lg rounded-lg"
                              >
                                <form
                                  method="dialog"
                                  className="modal- w-[300px] relative top-[-10px] bg-white right-[-6px] text-right"
                                >
                                  <div className="modal-box relative top-[3px]">
                                    <div
                                      onClick={() => {
                                        autoClickButton();
                                      }}
                                    >
                                      <button id="aashima_id">
                                        <img
                                          alt="img"
                                          src={crossicon}
                                          className="w-[20px] h-[20px]"
                                        ></img>
                                      </button>
                                    </div>
                                    <div>
                                      {filteredModalData.length > 0 ? (
                                        <>
                                          {/* High Impact */}
                                          {filteredModalData.some(
                                            (data) =>
                                              data.impact.toLowerCase() ===
                                              "high"
                                          ) && (
                                            <div className="text-center flex items-center justify-center">
                                              <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300 w-[193px]">
                                                High Impact :-
                                              </h3>
                                            </div>
                                          )}
                                          {filteredModalData.map(
                                            (data, index) => {
                                              if (
                                                data.impact.toLowerCase() ===
                                                "high"
                                              ) {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="text-start pl-[20px] my-4"
                                                  >
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Date:
                                                      </span>{" "}
                                                      {data.date}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Time:{" "}
                                                      </span>
                                                      {data.time.slice(0, 5)}
                                                     
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Country:{" "}
                                                      </span>
                                                      {data.country}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Event:{" "}
                                                      </span>
                                                      {data.event}
                                                    </p>
                                                    <hr />
                                                  </div>
                                                );
                                              }
                                              return null;
                                            }
                                          )}

                                          {/* Medium Impact */}
                                          {filteredModalData.some(
                                            (data) =>
                                              data.impact.toLowerCase() ===
                                              "medium"
                                          ) && (
                                            <div className="text-center flex items-center justify-center">
                                              <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300">
                                                Medium Impact :-
                                              </h3>
                                            </div>
                                          )}
                                          {filteredModalData.map(
                                            (data, index) => {
                                              if (
                                                data.impact.toLowerCase() ===
                                                "medium"
                                              ) {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="text-start pl-[20px] my-4"
                                                  >
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Date:
                                                      </span>{" "}
                                                      {data.date}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Time:{" "}
                                                      </span>
                                                      {data.time.slice(0, 5)}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Country:
                                                      </span>{" "}
                                                      {data.country}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Event:
                                                      </span>{" "}
                                                      {data.event}
                                                    </p>
                                                    <hr />
                                                  </div>
                                                );
                                              }
                                              return null;
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <p className="text-center mt-4">
                                          No Data
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </form>
                              </dialog>
                            </>
                          )}

                          {run_modalloader ? (
                            <td>
                              <span className="loader1 text-[#1c72ca] text-center min-w-[150px] w-[150px]">
                                Loading...
                              </span>
                            </td>
                          ) : (
                            <>
                              {" "}
                              <td
                                className=" py-2 px-4  min-w-[140px] relative cursor-pointer"
                                onClick={() => handleShowModalontime(item.date)}
                              >
                                {matchingData && (
                                  <span className="font-bold text-[13px]">
                                    {matchingData.includes(": ")
                                      ? matchingData.substring(
                                          matchingData.indexOf(": ") + 2
                                        )
                                      : matchingData}
                                  </span>
                                )}
                              </td>
                              <dialog
                                id="my_modal_3"
                                onClick={() => {
                                  autoClickButton1();
                                }}
                                className="modal p-6 absolute max-h-[90vh]  top-0 shadow-[#ba9696] shadow-opacity-10 shadow-lg rounded-lg"
                              >
                                <form
                                  method="dialog"
                                  className="modal- w-[300px] relative top-[-10px] right-[-6px] text-right"
                                >
                                  <div className="modal-box relative top-[6px]">
                                    <div
                                      onClick={() => {
                                        autoClickButton1();
                                      }}
                                    >
                                      <button id="closemod11">
                                        <img
                                          alt="img"
                                          src={crossicon}
                                          className="w-[20px] h-[20px]"
                                        ></img>
                                      </button>
                                    </div>
                                    <div>
                                      {filteredModalDataontime.length > 0 ? (
                                        <>
                                          {/* High Impact */}
                                          {filteredModalDataontime.some(
                                            (data) =>
                                              data.impact.toLowerCase() ===
                                              "high"
                                          ) && (
                                            <div className="text-center flex items-center justify-center">
                                              <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300 w-[193px]">
                                                High Impact :-
                                              </h3>
                                            </div>
                                          )}
                                          {filteredModalDataontime.map(
                                            (data, index) => {
                                              if (
                                                data.impact.toLowerCase() ===
                                                "high"
                                              ) {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="text-start pl-[20px] my-4"
                                                  >
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Date:
                                                      </span>{" "}
                                                      {data.date}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Time:{" "}
                                                      </span>
                                                      {data.time.slice(0, 5)}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Country:{" "}
                                                      </span>
                                                      {data.country}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Event:{" "}
                                                      </span>
                                                      {data.event}
                                                    </p>
                                                    <hr />
                                                  </div>
                                                );
                                              }
                                              return null;
                                            }
                                          )}

                                          {/* Medium Impact */}
                                          {filteredModalDataontime.some(
                                            (data) =>
                                              data.impact.toLowerCase() ===
                                              "medium"
                                          ) && (
                                            <div className="text-center flex items-center justify-center">
                                              <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300">
                                                Medium Impact :-
                                              </h3>
                                            </div>
                                          )}
                                          {filteredModalDataontime.map(
                                            (data, index) => {
                                              if (
                                                data.impact.toLowerCase() ===
                                                "medium"
                                              ) {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="text-start pl-[20px] my-4"
                                                  >
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Date:
                                                      </span>{" "}
                                                      {data.date}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Time:{" "}
                                                      </span>
                                                      {data.time.slice(0, 5)}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Country:
                                                      </span>{" "}
                                                      {data.country}
                                                    </p>
                                                    <p>
                                                      <span className="font-bold pr-2">
                                                        Event:
                                                      </span>{" "}
                                                      {data.event}
                                                    </p>
                                                    <hr />
                                                  </div>
                                                );
                                              }
                                              return null;
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <p className="text-center mt-4">
                                          No Data
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </form>
                              </dialog>
                            </>
                          )}

                          <td className="py-2 ">{item.previous}</td>
                          <td className="py-2 ">{item.actual}</td>
                          <td className="py-2 ">{item.estimate}</td>

                          <td
                            className={`py-2  text-[#170E32] font-bold  ${
                              item.previous === null || item.previous === ""
                                ? "bg-[#E5E7EB]" // N/A case:
                                : item.actual - item.previous > 0
                                ? "bg-[#7fe9a4]"
                                : item.actual - item.previous === 0
                                 ? "bg-[#f7dc14]"
                                : "bg-[#f3b2b2]"
                            }`}
                          >
                            {/* {(item.actual - item.previous).toFixed(4)} <br />(
                          {(
                            Math.abs(
                              (item.actual - item.previous) / item.previous
                            ) * 100
                          ).toFixed(4) + "%"}
                          ) */}

                            {item.previous === null || item.previous === "" ? (
                              "N/A"
                            ) : (
                              <>
                               {(
                                  Math.abs(
                                    (item.actual - item.previous) /
                                      item.previous
                                  ) * 100
                                ).toFixed(1) + "%"}
                                  <br />
                                ({(item.actual - item.previous).toFixed(1)})
                              
                               
                                
                              </>
                            )}
                          </td>

                          <td
                            className={`py-2 border border-r-4 border-[#F6F6F6] text-[#170E32] font-semibold  ${
                              item.estimate === null || item.estimate === ""
                                ? "bg-[#E5E7EB]" // N/A case:
                                : item.actual - item.estimate > 0
                                ? "bg-[#7fe9a4]"
                                : item.actual - item.estimate === 0
                                 ? "bg-[#f7dc14]"
                                : "bg-[#f3b2b2]"
                            }`}
                          >
                            {/* {(item.actual - item.estimate).toFixed(4)} <br />( */}
                            {item.estimate === null || item.estimate === "" ? (
                              "N/A"
                            ) : (
                              <>
                              {(
                                  Math.abs(
                                    (item.actual - item.estimate) /
                                      item.estimate
                                  ) * 100
                                ).toFixed(1) + "%"}<br />
                                ({(item.actual - item.estimate).toFixed(1)})
                             
                                
                                
                              </>
                            )}
                            {/* {(
                            Math.abs(
                              (item.actual - item.estimate) / item.estimate
                            ) * 100
                          ).toFixed(4) + "%"}
                          ) */}
                          </td>

                          <td
                            className={`py-2 border border-r-4 border-[#F6F6F6] text-[#170E32] font-semibold ${
                              item.actual - item.estimate === 0 ||
                              item.estimate === null ||
                              item.estimate === ""
                                ? item.actual - item.previous > 0
                                  ? "bg-[#7fe9a4]"
                                  : item.actual - item.previous === 0
                                  ? "bg-[#f7dc14]"
                                  : "bg-[#f3b2b2]"
                                : item.actual - item.estimate > 0
                                ? "bg-[#7fe9a4]"
                                : item.actual - item.estimate === 0 ||
                                  item.estimate === null ||
                                  item.estimate === ""
                                ? "bg-[#f7dc14]"
                                : "bg-[#f3b2b2]"
                            }`}
                          >
                             {(
                              Math.abs(
                                item.actual - item.estimate === 0 ||
                                  item.estimate === null ||
                                  item.estimate === ""
                                  ? (item.actual - item.previous) /
                                      item.previous
                                  : (item.actual - item.estimate) /
                                      item.estimate
                              ) * 100
                            ).toFixed(1) + "%"}
                              <br />
                            ({(item.actual - item.estimate === 0 ||
                            item.estimate === null ||
                            item.estimate === ""
                              ? item.actual - item.previous
                              : item.actual - item.estimate
                            ).toFixed(4)})
                            
                          </td>
                          {/* <---------differences start from here-------------> */}
                          <td className={` border-[#F6F6F6] `}>
                            {renderTableRows_avg(
                              averageHighLow_usddata,
                              item.date
                            )}
                          </td>
                          <td className={` border-[#F6F6F6] `}>
                            {renderTableRows_avg(
                              averageHighLow_jpydata,
                              item.date
                            )}
                          </td>
                          <td className={`border-[#F6F6F6] `}>
                            {renderTableRows_avg(
                              averageHighLow_nzddata,
                              item.date
                            )}
                          </td>
                          <td className={` border-[#F6F6F6] `}>
                            {renderTableRows_avg(
                              averageHighLow_eurdata,
                              item.date
                            )}
                          </td>
                          <td className={` border-[#F6F6F6] `}>
                            {renderTableRows_avg(
                              averageHighLow_chfdata,
                              item.date
                            )}
                          </td>
                          <td className={` border-[#F6F6F6] `}>
                            {renderTableRows_avg(
                              averageHighLow_auddata,
                              item.date
                            )}
                          </td>
                          <td className={`border-r-4 border-[#F6F6F6] `}>
                            {renderTableRows_avg(
                              averageHighLow_caddata,
                              item.date
                            )}
                          </td>

                          <td
                            className={`${renderTableRows_avg_colorcode(
                              averageHighLow_usddata,
                              item.date
                            )} border-r-2 border-[#F6F6F6] `}
                          >
                            {typeof avg_diff === "number"
                              ? // ? Math.round(avg_diff.toFixed(3)*1000)
                                Math.round(
                                  avg_diff.toFixed(country === "JP" ? 3 : 5) *
                                    (country === "JP" ? 1000 : 100000)
                                )
                              : avg_diff}
                            {/* <br/>
                            { avg_diff.toFixed(5)} */}
                          </td>

                          <td
                            className={` ${renderTableRows_avg_colorcode(
                              averageHighLow_jpydata,
                              item.date
                            )} border-r-2 border-[#F6F6F6] `}
                          >
                            {/* <-----------------JP is fixed here-------------> */}
                            {typeof avg_jpydiff === "number"
                              ? Math.round(avg_jpydiff.toFixed(3) * 1000)
                              : // ? Math.round((avg_jpydiff.toFixed(country === "JP" ? 3 : 5))*(country === "JP" ? 1000 : 100000))
                                avg_jpydiff}
                          </td>

                          <td
                            // className={`py-2  border border-[#ffffff] ${
                            //   typeof avg_diffnzdgbp === "number"
                            //     // ? avg_diffnzdgbp.toFixed(5) === "0.0000" ||
                            //     //   avg_diffnzdgbp.toFixed(5) === "-0.0000"
                            //      ? avg_diffnzdgbp.toFixed(country === "JP" ? 3 : 5) === "0.0000" ||
                            //      avg_diffnzdgbp.toFixed(country === "JP" ? 3 : 5) === "-0.0000"
                            //       ? "bg-yellow-200"
                            //       : typeof avg_diffnzdgbp === "number" &&
                            //         avg_diffnzdgbp > 0
                            //       ? "bg-[#BBF7D0]"
                            //       : "bg-[#FECACA]"
                            //     : "bg-[#E5E7EB]"
                            // }`}
                            className={` ${renderTableRows_avg_colorcode(
                              averageHighLow_nzddata,
                              item.date
                            )} border-r-2 border-[#F6F6F6] `}
                          >
                            {typeof avg_diffnzdgbp === "number"
                              ? Math.round(
                                  avg_diffnzdgbp.toFixed(
                                    country === "JP" ? 3 : 5
                                  ) * (country === "JP" ? 1000 : 100000)
                                )
                              : avg_diffnzdgbp}
                          </td>

                          <td
                            className={` ${renderTableRows_avg_colorcode(
                              averageHighLow_eurdata,
                              item.date
                            )} border-r-2 border-[#F6F6F6] `}
                          >
                            {typeof avg_diffeurgbp === "number"
                              ? Math.round(
                                  avg_diffeurgbp.toFixed(
                                    country === "JP" ? 3 : 5
                                  ) * (country === "JP" ? 1000 : 100000)
                                )
                              : avg_diffeurgbp}
                          </td>

                          <td
                            className={` ${renderTableRows_avg_colorcode(
                              averageHighLow_chfdata,
                              item.date
                            )} border-r-2 border-[#F6F6F6] `}
                          >
                            {typeof avg_diffgbpchf === "number"
                              ? Math.round(
                                  avg_diffgbpchf.toFixed(
                                    country === "JP" ? 3 : 5
                                  ) * (country === "JP" ? 1000 : 100000)
                                )
                              : avg_diffgbpchf}
                          </td>
                          <td
                            className={` ${renderTableRows_avg_colorcode(
                              averageHighLow_auddata,
                              item.date
                            )} border-r-2 border-[#F6F6F6] `}
                          >
                            {typeof avg_diffgbpaud === "number"
                              ? Math.round(
                                  avg_diffgbpaud.toFixed(
                                    country === "JP" ? 3 : 5
                                  ) * (country === "JP" ? 1000 : 100000)
                                )
                              : avg_diffgbpaud}
                          </td>

                          <td
                            className={` ${renderTableRows_avg_colorcode(
                              averageHighLow_caddata,
                              item.date
                            )} border-r-2 border-[#F6F6F6] `}
                          >
                            {typeof avg_diffgbpcad === "number"
                              ? Math.round(
                                  avg_diffgbpcad.toFixed(
                                    country === "JP" ? 3 : 5
                                  ) * (country === "JP" ? 1000 : 100000)
                                )
                              : avg_diffgbpcad}
                          </td>
                        </tr>
                      </>
                    );
                  })}

                  {/* <------normal average of 3rd table------> */}
                  <tr className="bg-[#AECAF14D]  bg-opacity-[30%] text-center">
                    <td className="py-2  font-bold   min-w-[80.8px]">
                      Average
                    </td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>

                    <td className="py-2">
                    {sumActualPreviousbyo}%<br />({sumActualPrevious}) 
                    </td>
                    <td className="py-2 border-r-4 border-[#F6F6F6]">
                    {sumActualEstimatebyo}%<br />({sumActualEstimate})
                    </td>
                    <td className="py-2 border-r-4 border-[#F6F6F6]">
                    {sumfinalmovementbyo}%<br />({sumfinalmovement}) 
                    </td>

                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_usd !== null
                          ? avg_ofthirdtable_usd + "%"
                          : "N/A") === highest1hl_thirdtable_last5min
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_usd !== null
                              ? avg_ofthirdtable_usd + "%"
                              : "N/A") === highest2hl_thirdtable_last5min
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_usd !== null
                              ? avg_ofthirdtable_usd + "%"
                              : "N/A") === highest3hl_thirdtable_last5min
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {avg_ofthirdtable_usd !== null
                        ? avg_ofthirdtable_usd
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_jpy !== null
                          ? avg_ofthirdtable_jpy + "%"
                          : "N/A") === highest1hl_thirdtable_last5min
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_jpy !== null
                              ? avg_ofthirdtable_jpy + "%"
                              : "N/A") === highest2hl_thirdtable_last5min
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_jpy !== null
                              ? avg_ofthirdtable_jpy + "%"
                              : "N/A") === highest3hl_thirdtable_last5min
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_jpy !== null
                        ? avg_ofthirdtable_jpy
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_nzd !== null
                          ? avg_ofthirdtable_nzd + "%"
                          : "N/A") === highest1hl_thirdtable_last5min
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_nzd !== null
                              ? avg_ofthirdtable_nzd + "%"
                              : "N/A") === highest2hl_thirdtable_last5min
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_nzd !== null
                              ? avg_ofthirdtable_nzd + "%"
                              : "N/A") === highest3hl_thirdtable_last5min
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_nzd !== null
                        ? avg_ofthirdtable_nzd
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_eur !== null
                          ? avg_ofthirdtable_eur + "%"
                          : "N/A") === highest1hl_thirdtable_last5min
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_eur !== null
                              ? avg_ofthirdtable_eur + "%"
                              : "N/A") === highest2hl_thirdtable_last5min
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_eur !== null
                              ? avg_ofthirdtable_eur + "%"
                              : "N/A") === highest3hl_thirdtable_last5min
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_eur !== null
                        ? avg_ofthirdtable_eur
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_chf !== null
                          ? avg_ofthirdtable_chf + "%"
                          : "N/A") === highest1hl_thirdtable_last5min
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_chf !== null
                              ? avg_ofthirdtable_chf + "%"
                              : "N/A") === highest2hl_thirdtable_last5min
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_chf !== null
                              ? avg_ofthirdtable_chf + "%"
                              : "N/A") === highest3hl_thirdtable_last5min
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_chf !== null
                        ? avg_ofthirdtable_chf
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_aud !== null
                          ? avg_ofthirdtable_aud + "%"
                          : "N/A") === highest1hl_thirdtable_last5min
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_aud !== null
                              ? avg_ofthirdtable_aud + "%"
                              : "N/A") === highest2hl_thirdtable_last5min
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_aud !== null
                              ? avg_ofthirdtable_aud + "%"
                              : "N/A") === highest3hl_thirdtable_last5min
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_aud !== null
                        ? avg_ofthirdtable_aud
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-4 border-[#F6F6F6] ${
                        (avg_ofthirdtable_cad !== null
                          ? avg_ofthirdtable_cad + "%"
                          : "N/A") === highest1hl_thirdtable_last5min
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_cad !== null
                              ? avg_ofthirdtable_cad + "%"
                              : "N/A") === highest2hl_thirdtable_last5min
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_cad !== null
                              ? avg_ofthirdtable_cad
                              : "N/A") === highest3hl_thirdtable_last5min
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_cad !== null
                        ? avg_ofthirdtable_cad
                        : "N/A"}
                      %
                    </td>

                    <td
                      className={`text-[15px] py-2  border border-r-2 border-[#F6F6F6] ${
                        (averagegbpusddiff1_thirdtable !== null
                          ? averagegbpusddiff1_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpusddiff1_thirdtable !== null
                              ? averagegbpusddiff1_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpusddiff1_thirdtable !== null
                              ? averagegbpusddiff1_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {averagegbpusddiff1_thirdtable !== null
                        ? averagegbpusddiff1_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                    <td
                      // className={`text-[15px] py-2 ${
                      //   (averagegbpjpydiff1_thirdtable !== null
                      //     ? averagegbpjpydiff1_thirdtable
                      //     : "N/A") === highest1hl_thirdtable
                      //     ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                      //     : (averagegbpjpydiff1_thirdtable !== null
                      //         ? averagegbpjpydiff1_thirdtable
                      //         : "N/A") === highest2hl_thirdtable
                      //     ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                      //     : (averagegbpjpydiff1_thirdtable !== null
                      //         ? averagegbpjpydiff1_thirdtable
                      //         : "N/A") === highest3hl_thirdtable
                      //     ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                      //     : null
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpjpydiff1_thirdtable !== null
                          ? averagegbpjpydiff1_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpjpydiff1_thirdtable !== null
                              ? averagegbpjpydiff1_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpjpydiff1_thirdtable !== null
                              ? averagegbpjpydiff1_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {averagegbpjpydiff1_thirdtable !== null
                        ? averagegbpjpydiff1_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpnzddiff1_thirdtable !== null
                          ? averagegbpnzddiff1_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpnzddiff1_thirdtable !== null
                              ? averagegbpnzddiff1_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpnzddiff1_thirdtable !== null
                              ? averagegbpnzddiff1_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {averagegbpnzddiff1_thirdtable !== null
                        ? averagegbpnzddiff1_thirdtable.toFixed(2)
                        : "N/A"}{" "}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpeurdiff1_thirdtable !== null
                          ? averagegbpeurdiff1_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpeurdiff1_thirdtable !== null
                              ? averagegbpeurdiff1_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpeurdiff1_thirdtable !== null
                              ? averagegbpeurdiff1_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {averagegbpeurdiff1_thirdtable !== null
                        ? averagegbpeurdiff1_thirdtable.toFixed(2)
                        : "N/A"}{" "}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpchfdiff1_thirdtable !== null
                          ? averagegbpchfdiff1_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpchfdiff1_thirdtable !== null
                              ? averagegbpchfdiff1_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpchfdiff1_thirdtable !== null
                              ? averagegbpchfdiff1_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {averagegbpchfdiff1_thirdtable !== null
                        ? averagegbpchfdiff1_thirdtable.toFixed(2)
                        : "N/A"}{" "}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpauddiff1_thirdtable !== null
                          ? averagegbpauddiff1_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpauddiff1_thirdtable !== null
                              ? averagegbpauddiff1_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpauddiff1_thirdtable !== null
                              ? averagegbpauddiff1_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {averagegbpauddiff1_thirdtable !== null
                        ? averagegbpauddiff1_thirdtable.toFixed(2)
                        : "N/A"}{" "}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpcaddiff1_thirdtable !== null
                          ? averagegbpcaddiff1_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpcaddiff1_thirdtable !== null
                              ? averagegbpcaddiff1_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpcaddiff1_thirdtable !== null
                              ? averagegbpcaddiff1_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {averagegbpcaddiff1_thirdtable !== null
                        ? averagegbpcaddiff1_thirdtable.toFixed(2)
                        : "N/A"}{" "}
                    </td>
                  </tr>

                  {/* <------FLAT Average of 3rd table------> */}
                  <tr className="bg-[#AECAF14D]  bg-opacity-[30%] text-center">
                    <td className="py-2  font-bold   min-w-[80.8px]">
                      Flat Average
                    </td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>
                    <td className="py-2 "></td>

                    <td className="py-2">
                    0.0000%  <br />
                      (0.0000)
                    
                     
                    </td>
                    <td className="py-2 border-r-4 border-[#F6F6F6]">
                    0.0000%  <br />  (0.0000)
                      <br />
                     
                    </td>
                    <td className="py-2 border-r-4 border-[#F6F6F6]">
                    0.0000%  <br />  (0.0000)
                     
                     
                    </td>

                    {/* <--------------------flat final avg of variance-------------> */}
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_usd_flat0 !== null
                          ? avg_ofthirdtable_usd_flat0 + "%"
                          : "N/A") === highest1hl_thirdtable_last5min_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_usd_flat0 !== null
                              ? avg_ofthirdtable_usd_flat0 + "%"
                              : "N/A") === highest2hl_thirdtable_last5min_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_usd_flat0 !== null
                              ? avg_ofthirdtable_usd_flat0 + "%"
                              : "N/A") === highest3hl_thirdtable_last5min_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {avg_ofthirdtable_usd_flat0 !== null
                        ? avg_ofthirdtable_usd_flat0
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_jpy_flat0 !== null
                          ? avg_ofthirdtable_jpy_flat0 + "%"
                          : "N/A") === highest1hl_thirdtable_last5min_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_jpy_flat0 !== null
                              ? avg_ofthirdtable_jpy_flat0 + "%"
                              : "N/A") === highest2hl_thirdtable_last5min_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_jpy_flat0 !== null
                              ? avg_ofthirdtable_jpy_flat0 + "%"
                              : "N/A") === highest3hl_thirdtable_last5min_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_jpy_flat0 !== null
                        ? avg_ofthirdtable_jpy_flat0
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_nzd_flat0 !== null
                          ? avg_ofthirdtable_nzd_flat0 + "%"
                          : "N/A") === highest1hl_thirdtable_last5min_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_nzd_flat0 !== null
                              ? avg_ofthirdtable_nzd_flat0 + "%"
                              : "N/A") === highest2hl_thirdtable_last5min_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_nzd_flat0 !== null
                              ? avg_ofthirdtable_nzd_flat0 + "%"
                              : "N/A") === highest3hl_thirdtable_last5min_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_nzd_flat0 !== null
                        ? avg_ofthirdtable_nzd_flat0
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_eur_flat0 !== null
                          ? avg_ofthirdtable_eur_flat0 + "%"
                          : "N/A") === highest1hl_thirdtable_last5min_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_eur_flat0 !== null
                              ? avg_ofthirdtable_eur_flat0 + "%"
                              : "N/A") === highest2hl_thirdtable_last5min_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_eur_flat0 !== null
                              ? avg_ofthirdtable_eur_flat0 + "%"
                              : "N/A") === highest3hl_thirdtable_last5min_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_eur_flat0 !== null
                        ? avg_ofthirdtable_eur_flat0
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_chf_flat0 !== null
                          ? avg_ofthirdtable_chf_flat0 + "%"
                          : "N/A") === highest1hl_thirdtable_last5min_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_chf_flat0 !== null
                              ? avg_ofthirdtable_chf_flat0 + "%"
                              : "N/A") === highest2hl_thirdtable_last5min_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_chf_flat0 !== null
                              ? avg_ofthirdtable_chf_flat0 + "%"
                              : "N/A") === highest3hl_thirdtable_last5min_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_chf_flat0 !== null
                        ? avg_ofthirdtable_chf_flat0
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (avg_ofthirdtable_aud_flat0 !== null
                          ? avg_ofthirdtable_aud_flat0 + "%"
                          : "N/A") === highest1hl_thirdtable_last5min_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_aud_flat0 !== null
                              ? avg_ofthirdtable_aud_flat0 + "%"
                              : "N/A") === highest2hl_thirdtable_last5min_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_aud_flat0 !== null
                              ? avg_ofthirdtable_aud_flat0 + "%"
                              : "N/A") === highest3hl_thirdtable_last5min_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_aud_flat0 !== null
                        ? avg_ofthirdtable_aud_flat0
                        : "N/A"}
                      %
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-4 border-[#F6F6F6] ${
                        (avg_ofthirdtable_cad_flat0 !== null
                          ? avg_ofthirdtable_cad_flat0 + "%"
                          : "N/A") === highest1hl_thirdtable_last5min_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_cad_flat0 !== null
                              ? avg_ofthirdtable_cad_flat0 + "%"
                              : "N/A") === highest2hl_thirdtable_last5min_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (avg_ofthirdtable_cad_flat0 !== null
                              ? avg_ofthirdtable_cad_flat0 + "%"
                              : "N/A") === highest3hl_thirdtable_last5min_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : null
                      }`}
                    >
                      {avg_ofthirdtable_cad_flat0 !== null
                        ? avg_ofthirdtable_cad_flat0
                        : "N/A"}
                      %
                    </td>

                    {/* <--------------normal flat average-----------> */}

                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpusddiff1_flat0_thirdtable !== null
                          ? averagegbpusddiff1_flat0_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpusddiff1_flat0_thirdtable !== null
                              ? averagegbpusddiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpusddiff1_flat0_thirdtable !== null
                              ? averagegbpusddiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {averagegbpusddiff1_flat0_thirdtable !== null
                        ? averagegbpusddiff1_flat0_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpjpydiff1_flat0_thirdtable !== null
                          ? averagegbpjpydiff1_flat0_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpjpydiff1_flat0_thirdtable !== null
                              ? averagegbpjpydiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpjpydiff1_flat0_thirdtable !== null
                              ? averagegbpjpydiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {averagegbpjpydiff1_flat0_thirdtable !== null
                        ? averagegbpjpydiff1_flat0_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpnzddiff1_flat0_thirdtable !== null
                          ? averagegbpnzddiff1_flat0_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpnzddiff1_flat0_thirdtable !== null
                              ? averagegbpnzddiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpnzddiff1_flat0_thirdtable !== null
                              ? averagegbpnzddiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {averagegbpnzddiff1_flat0_thirdtable !== null
                        ? averagegbpnzddiff1_flat0_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpeurdiff1_flat0_thirdtable !== null
                          ? averagegbpeurdiff1_flat0_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpeurdiff1_flat0_thirdtable !== null
                              ? averagegbpeurdiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpeurdiff1_flat0_thirdtable !== null
                              ? averagegbpeurdiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {averagegbpeurdiff1_flat0_thirdtable !== null
                        ? averagegbpeurdiff1_flat0_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpchfdiff1_flat0_thirdtable !== null
                          ? averagegbpchfdiff1_flat0_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpchfdiff1_flat0_thirdtable !== null
                              ? averagegbpchfdiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpchfdiff1_flat0_thirdtable !== null
                              ? averagegbpchfdiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {averagegbpchfdiff1_flat0_thirdtable !== null
                        ? averagegbpchfdiff1_flat0_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpauddiff1_flat0_thirdtable !== null
                          ? averagegbpauddiff1_flat0_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpauddiff1_flat0_thirdtable !== null
                              ? averagegbpauddiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpauddiff1_flat0_thirdtable !== null
                              ? averagegbpauddiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {averagegbpauddiff1_flat0_thirdtable !== null
                        ? averagegbpauddiff1_flat0_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                    <td
                      className={`text-[15px] py-2 border border-r-2 border-[#F6F6F6] ${
                        (averagegbpcaddiff1_flat0_thirdtable !== null
                          ? averagegbpcaddiff1_flat0_thirdtable.toFixed(2)
                          : "N/A") === highest1hl_thirdtable_flat0
                          ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          : (averagegbpcaddiff1_flat0_thirdtable !== null
                              ? averagegbpcaddiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest2hl_thirdtable_flat0
                          ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          : (averagegbpcaddiff1_flat0_thirdtable !== null
                              ? averagegbpcaddiff1_flat0_thirdtable.toFixed(2)
                              : "N/A") === highest3hl_thirdtable_flat0
                          ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          : ""
                      }`}
                    >
                      {averagegbpcaddiff1_flat0_thirdtable !== null
                        ? averagegbpcaddiff1_flat0_thirdtable.toFixed(2)
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* <-------------highest color codes pics-----------> */}
           <div className="flex flex-col justify-end sm:flex-col md:flex-row lg:flex-row xl:flex-row ">
            <div className="bg-[#fff] p-4 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-12  my-8 py-4  items-center   flex justify-start flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
              
              <div className="sm:flex-col flex-col md:flex-row lg:flex-row xl:flex-row flex mt-[5%] sm:mt-[5%] md:mt-[5%] lg:mt-[0%] xl:mt-[0%]">
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    Highest Average:
                  </h1>
                  <img
                    src={color1}
                    title="color"
                    alt="color"
                    className="ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    {" "}
                    2nd Highest Average:
                  </h1>
                  <img
                    src={color2}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    {" "}
                    3rd Highest Average:
                  </h1>
                  <img
                    src={color3}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />  
                  <span className="ml-[8px]">,</span>
                </div>
              </div>

              <div className="sm:flex-col flex-col md:flex-row lg:flex-row xl:flex-row flex mt-[5%] sm:mt-[5%] md:mt-[5%] lg:mt-[0%] xl:mt-[0%]">
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                   
                  {">500 % :"} 
                  </h1>
                  <img
                    src={color4}
                    title="color"
                    alt="color"
                    className="ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                  
                   {"300-500 % :"}
                  </h1>
                  <img
                    src={color5}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                   
                    {/* <300 %: */}
                    {"<300 % :"}
                  </h1>
                  <img
                    src={color6}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  /> 
                </div>
              </div>
            </div>
          </div>
         

          {Loading_3rdtable ? (
            <div className="mt-5">
              <div className="font-semibold m-2 font-xl text-center flex justify-center items-center">
                Wait !! Correlation and Average Movement Tables are Loading ....
              </div>

              <div className="flex items-center justify-center  w-screen ">
                <RingLoader
                  height="80"
                  width="80"
                  color="#94A3B8"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  className=" flex items-center justify-center  mt-10 w-[100%]"
                />
              </div>
            </div>
          ) : (
            <>
              <div className="m-3  py-2 px-3  mt-4 flex ">
              <div className="relative inline-block ml-[2%] ">
      {/* Icon */}
      <img
        src={eye_btn_tooltip}
        title="Information of Volatility Correlation Table"
        alt="eye_btn_tooltip"
        className="w-[22px] h-[25px]  cursor-pointer pb-2"
        onClick={handleImageClick}
      />

      {/* Tooltip */}
      {showTooltip && (
        <div
          ref={tooltipRef}
          className="w-[400px] border-white rounded-[8px] bg-white absolute bottom-[155%] left-[760%] transform -translate-x-1/2 z-10  text-[#170E32] text-sm p-4  shadow-lg"
        >
         <h1 className="m-1 text-[170E32] font-bold text-[18px]">Non-Zero Events Definition: <span className="text-[#545454] text-[14px]">Actual release values differing from the estimated value, and from the previous release value when equal to the estimated value. </span></h1>
         <h1 className="mt-4 m-1 text-[170E32] font-bold text-[18px]">Flat Events Definition: <span className="text-[#545454] text-[14px]">Actual release values are same as the estimated value and the previous release value. </span></h1>

          {/* Triangle Tip */}
          <div className="absolute bottom-[-10px] left-[8%] transform -translate-x-1/2 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-white"></div>
        </div>
      )}
    </div>
                <h1 className=" ml-1 font-bold text-[20px] text-[#170E32]">
                Event Volatility Correlation:
                </h1>
                {/* <img
                    src={eye_btn_tooltip}
                    title="Information of Volatility Correlation Table"
                    alt="eye_btn_tooltip"
                    className=" w-[25px] h-[25px] mr-[2%] cursor-pointer"
                    onClick={handleImageClick}  /> */}


              </div>


              {/* <------------------second table--------------> */}
              <div className="overflow-auto">
                <div className="  mx-[3%] overflow-x-auto overflow-y-auto  max-h-[87vh] ">
                <div className="flex ">
                    <div className="min-w-[1111px] bg-[#151841] min-h-[79.96] border-r-2 border-r-white"></div>
                    <div className="bg-[#151841] min-w-[643.51px] flex justify-center items-center py-2"><h1 className="text-[18px] font-bold text-white">{`Close price - Open Price ${
      timeclickedurl_state === 1
        ? "(1min)"
        : timeclickedurl_state === 5
        ? "(5min)"
        : timeclickedurl_state === 15
        ? "(15min)"
        : timeclickedurl_state === 60
        ? "(60min)"
        : "(1min)"
    }`}</h1></div>
                    <div className="bg-[#151841] min-w-[643.51px] flex justify-center items-center py-2 border-x-2 border-x-white"><h1 className="text-[18px] font-bold text-white py-3">{`Low price - Open Price ${
      timeclickedurl_state === 1
        ? "(1min)"
        : timeclickedurl_state === 5
        ? "(5min)"
        : timeclickedurl_state === 15
        ? "(15min)"
        : timeclickedurl_state === 60
        ? "(60min)"
        : "(1min)"
    }`}</h1></div>
                    <div className="bg-[#151841] yellow-200 min-w-[643.51px] flex justify-center items-center py-2"><h1 className="text-[18px] font-bold text-white py-3">{`High price - Open Price ${
      timeclickedurl_state === 1
        ? "(1min)"
        : timeclickedurl_state === 5
        ? "(5min)"
        : timeclickedurl_state === 15
        ? "(15min)"
        : timeclickedurl_state === 60
        ? "(60min)"
        : "(1min)"
    }`}</h1></div>
                  </div>
                  <table
                    className={` ${styles.tableuser} min-w-full  overflow-x-scroll m-auto  font-semibold `}
                  >
                    <thead className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[14px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px] ">
                      <tr className="h-[96px]">
                        <th className="sticky top-0 py-2 px-2">S. No</th>
                        <th className="sticky top-0 py-2 px-2">Date</th>
                        <th className="sticky top-0 py-2 px-2">Time(GMT)</th>
                        <th className="sticky top-0 py-2 px-2  min-w-[200px] w-[200px]">
                         Event Count before this Time
                        </th>
                        <th className="sticky top-0 py-2 px-2  min-w-[150px] w-[150px]">
                        Event Count at this Time
                        </th>

                        <th className="sticky top-0 py-2 px-2">Previous</th>

                        <th className="sticky top-0 py-2 px-2">Actual</th>
                        <th className="sticky top-0 py-2 px-2">Estimate</th>
                        <th className="sticky top-0 py-2 px-2  min-w-[97px] ">
                          Act - Prev movement direction
                        </th>
                        <th className="sticky top-0 py-2 px-2  border-r-4 border-[#F6F6F6] min-w-[98px] ">
                          Act - Est movement direction
                        </th>
                        <th className="sticky top-0 py-2 px-2  border-r-4 bg-[#3589FF8F] bg-opacity-[56%] border-[#F6F6F6] min-w-[100px] ">
                          Final movement direction
                        </th>

                        {headers.map((header) => (
                          <th
                            key={header.id}
                            className="sticky top-0 py-2 px-2 bg-[#d0dcf4]   text-[#170E32]"
                          >
                            {header.name}
                            <br />
                            {/* (C-O)
                            <br /> */}
                            movement
                          </th>
                        ))}

                        {headers.map((header) => (
                          <th
                            key={header.id}
                            className="sticky top-0 py-2 px-2 bg-[#3589FF8F] bg-opacity-[56%]  text-[#170E32]"
                          >
                            {header.name}
                            <br />
                            {/* (L-O)
                            <br /> */}
                            movement
                          </th>
                        ))}
                        {headers.map((header) => (
                          <th
                            key={header.id}
                            className="sticky top-0 py-2 px-2 bg-[#d0dcf4]   text-[#170E32]"
                          >
                            {header.name}
                            <br />
                            {/* (H-O)
                            <br /> */}
                            movement
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-[#F6F6F6]  z-0 text-[#170E32] font-bold text-[14px]">
                      {eventHistory.map((item, index) => {
                        const itemDate = item.date
                          .split(".")
                          .reverse()
                          .join(".");
                        let itemTime;

                        if (selectedTime === 5) {
                          itemTime = roundDownToNearestMultipleOf5(item.time);
                        } else if (selectedTime === 15) {
                          itemTime = roundDownToNearestMultipleOf15(item.time);
                        } else if (selectedTime === 60) {
                          itemTime = roundDownToNearestMultipleOf1Hour(
                            item.time
                          );
                        } else {
                          itemTime = item.time;
                        }

                        const usdEntry = usddata.find((entry) => {
                          return (
                            entry.date === itemDate && entry.time === itemTime
                          );
                        });

                        const jpyEntry = jpydata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const gbpnzdEntry = gbpnzddata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const eurgbpEntry = eurgbpdata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const gbpchfEntry = gbpchfdata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const gbpaudEntry = gbpauddata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const gbpcadEntry = gbpcaddata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );

                        const usdOpen = usdEntry ? usdEntry.Open : "Data unavailable";
                        const usdClose = usdEntry ? usdEntry.Close : "Data unavailable";
                        const usdLow = usdEntry ? usdEntry.Low : "Data unavailable";
                        const usdHigh = usdEntry ? usdEntry.High : "Data unavailable";

                        const diff =
                          usdOpen !== "Data unavailable" && usdClose !== "Data unavailable"
                            ? usdClose - usdOpen
                            : "Data unavailable";

                        const diff2 =
                          usdOpen !== "Data unavailable" && usdLow !== "Data unavailable"
                            ? usdLow - usdOpen
                            : "Data unavailable";
                        const diff3 =
                          usdOpen !== "Data unavailable" && usdHigh !== "Data unavailable"
                            ? usdHigh - usdOpen
                            : "Data unavailable";

                        const jpyOpen = jpyEntry ? jpyEntry.Open : "Data unavailable";
                        const jpyClose = jpyEntry ? jpyEntry.Close : "Data unavailable";
                        const jpyLow = jpyEntry ? jpyEntry.Low : "Data unavailable";
                        const jpyHigh = jpyEntry ? jpyEntry.High : "Data unavailable";

                        const jpydiff =
                          jpyOpen !== "Data unavailable" && jpyClose !== "Data unavailable"
                            ? jpyClose - jpyOpen
                            : "Data unavailable";
                        const jpydiff2 =
                          jpyOpen !== "Data unavailable" && jpyLow !== "Data unavailable"
                            ? jpyLow - jpyOpen
                            : "Data unavailable";
                        const jpydiff3 =
                          jpyOpen !== "Data unavailable" && jpyHigh !== "Data unavailable"
                            ? jpyHigh - jpyOpen
                            : "Data unavailable";

                        const nzdOpen = gbpnzdEntry
                          ? gbpnzdEntry.Open
                          : "Data unavailable";
                        const nzdClose = gbpnzdEntry
                          ? gbpnzdEntry.Close
                          : "Data unavailable";
                        const nzdLow = gbpnzdEntry
                          ? gbpnzdEntry.Low
                          : "Data unavailable";
                        const nzdHigh = gbpnzdEntry
                          ? gbpnzdEntry.High
                          : "Data unavailable";

                        const diffnzdgbp =
                          nzdOpen !== "Data unavailable" && nzdClose !== "Data unavailable"
                            ? nzdClose - nzdOpen
                            : "Data unavailable";

                        const diff2nzdgbp =
                          nzdOpen !== "Data unavailable" && nzdLow !== "Data unavailable"
                            ? nzdLow - nzdOpen
                            : "Data unavailable";
                        const diff3nzdgbp =
                          nzdOpen !== "Data unavailable" && nzdHigh !== "Data unavailable"
                            ? nzdHigh - nzdOpen
                            : "Data unavailable";

                        const eurgbpOpen = eurgbpEntry
                          ? eurgbpEntry.Open
                          : "Data unavailable";
                        const eurgbpClose = eurgbpEntry
                          ? eurgbpEntry.Close
                          : "Data unavailable";
                        const eurgbpLow = eurgbpEntry
                          ? eurgbpEntry.Low
                          : "Data unavailable";
                        const eurgbpHigh = eurgbpEntry
                          ? eurgbpEntry.High
                          : "Data unavailable";

                        const diffeurgbp =
                          eurgbpOpen !== "Data unavailable" && eurgbpClose !== "Data unavailable"
                            ? eurgbpClose - eurgbpOpen
                            : "Data unavailable";

                        const diff2eurgbp =
                          eurgbpOpen !== "Data unavailable" && eurgbpLow !== "Data unavailable"
                            ? eurgbpLow - eurgbpOpen
                            : "Data unavailable";
                        const diff3eurgbp =
                          eurgbpOpen !== "Data unavailable" && eurgbpHigh !== "Data unavailable"
                            ? eurgbpHigh - eurgbpOpen
                            : "Data unavailable";

                        const gbpchfOpen = gbpchfEntry
                          ? gbpchfEntry.Open
                          : "Data unavailable";
                        const gbpchfClose = gbpchfEntry
                          ? gbpchfEntry.Close
                          : "Data unavailable";
                        const gbpchfLow = gbpchfEntry
                          ? gbpchfEntry.Low
                          : "Data unavailable";
                        const gbpchfHigh = gbpchfEntry
                          ? gbpchfEntry.High
                          : "Data unavailable";

                        const diffgbpchf =
                          gbpchfOpen !== "Data unavailable" && gbpchfClose !== "Data unavailable"
                            ? gbpchfClose - gbpchfOpen
                            : "Data unavailable";

                        const diff2gbpchf =
                          gbpchfOpen !== "Data unavailable" && gbpchfLow !== "Data unavailable"
                            ? gbpchfLow - gbpchfOpen
                            : "Data unavailable";
                        const diff3gbpchf =
                          gbpchfOpen !== "Data unavailable" && gbpchfHigh !== "Data unavailable"
                            ? gbpchfHigh - gbpchfOpen
                            : "Data unavailable";

                        const gbpaudOpen = gbpaudEntry
                          ? gbpaudEntry.Open
                          : "Data unavailable";
                        const gbpaudClose = gbpaudEntry
                          ? gbpaudEntry.Close
                          : "Data unavailable";
                        const gbpaudLow = gbpaudEntry
                          ? gbpaudEntry.Low
                          : "Data unavailable";
                        const gbpaudHigh = gbpaudEntry
                          ? gbpaudEntry.High
                          : "Data unavailable";

                        const diffgbpaud =
                          gbpaudOpen !== "Data unavailable" && gbpaudClose !== "Data unavailable"
                            ? gbpaudClose - gbpaudOpen
                            : "Data unavailable";

                        const diff2gbpaud =
                          gbpaudOpen !== "Data unavailable" && gbpaudLow !== "Data unavailable"
                            ? gbpaudLow - gbpaudOpen
                            : "Data unavailable";
                        const diff3gbpaud =
                          gbpaudOpen !== "Data unavailable" && gbpaudHigh !== "Data unavailable"
                            ? gbpaudHigh - gbpaudOpen
                            : "Data unavailable";

                        const gbpcadOpen = gbpcadEntry
                          ? gbpcadEntry.Open
                          : "Data unavailable";
                        const gbpcadClose = gbpcadEntry
                          ? gbpcadEntry.Close
                          : "Data unavailable";
                        const gbpcadLow = gbpcadEntry
                          ? gbpcadEntry.Low
                          : "Data unavailable";
                        const gbpcadHigh = gbpcadEntry
                          ? gbpcadEntry.High
                          : "Data unavailable";

                        const diffgbpcad =
                          gbpcadOpen !== "Data unavailable" && gbpcadClose !== "Data unavailable"
                            ? gbpcadClose - gbpcadOpen
                            : "Data unavailable";

                        const diff2gbpcad =
                          gbpcadOpen !== "Data unavailable" && gbpcadLow !== "Data unavailable"
                            ? gbpcadLow - gbpcadOpen
                            : "Data unavailable";
                        const diff3gbpcad =
                          gbpcadOpen !== "Data unavailable" && gbpcadHigh !== "Data unavailable"
                            ? gbpcadHigh - gbpcadOpen
                            : "Data unavailable";

                        // <----------------------------------------code of 2nd table------------------------->

                        const comparedataactminusest =
                          // item.actual - item.estimate === 0
                          //   ? item.actual - item.previous > 0
                          //     ? "+"
                          //     : item.actual - item.previous === 0
                          //     ? ""
                          //     : "-"
                          //   : item.actual - item.estimate > 0
                          //   ? "+"
                          //   : item.actual - item.estimate === 0
                          //   ? ""
                          //   : "-";

                          item.actual - item.estimate === 0 ||
                          item.estimate === null ||
                          item.estimate === ""
                            ? item.actual - item.previous > 0
                              ? "+"
                              : item.actual - item.previous === 0
                              ? ""
                              : "-"
                            : item.actual - item.estimate > 0
                            ? "+"
                            : item.actual - item.estimate === 0
                            ? ""
                            : "-";

                        const compareddatagbpusdcomove =
                          typeof diff === "number"
                            ? (
                                country === "JP"
                                  ? diff.toFixed(3) === "0.000" ||
                                    diff.toFixed(3) === "-0.000"
                                  : diff.toFixed(5) === "0.00000" ||
                                    diff.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff === "number" && diff > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpcousd =
                          comparedataactminusest === "-" &&
                          compareddatagbpusdcomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdcomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdcomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdcomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdcomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdcomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdcomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdcomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdcomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpusd.push(finalmovemntgbpcousd);

                        const compareddatagbpjpycomove =
                          typeof jpydiff === "number"
                            ? jpydiff.toFixed(3) === "0.000" ||
                              jpydiff.toFixed(3) === "-0.000"
                              ? ""
                              : typeof jpydiff === "number" && jpydiff > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpjpyco =
                          comparedataactminusest === "-" &&
                          compareddatagbpjpycomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpycomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpycomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpycomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpycomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpycomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpycomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpycomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpycomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpycomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpycomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpjpy.push(finalmovemntgbpjpyco);

                        const compareddatagbpnzdcomove =
                          typeof diffnzdgbp === "number"
                            ? (
                                country === "JP"
                                  ? diffnzdgbp.toFixed(3) === "0.000" ||
                                    diffnzdgbp.toFixed(3) === "-0.000"
                                  : diffnzdgbp.toFixed(5) === "0.00000" ||
                                    diffnzdgbp.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diffnzdgbp === "number" && diffnzdgbp > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpnzdco =
                          comparedataactminusest === "-" &&
                          compareddatagbpnzdcomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdcomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdcomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdcomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdcomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdcomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdcomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdcomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdcomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpnzd.push(finalmovemntgbpnzdco);

                        const compareddatagbpeurcomove =
                          typeof diffeurgbp === "number"
                            ? (
                                country === "JP"
                                  ? diffeurgbp.toFixed(3) === "0.000" ||
                                    diffeurgbp.toFixed(3) === "-0.000"
                                  : diffeurgbp.toFixed(5) === "0.00000" ||
                                    diffeurgbp.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diffeurgbp === "number" && diffeurgbp > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpeurco =
                          comparedataactminusest === "-" &&
                          compareddatagbpeurcomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurcomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurcomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurcomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurcomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurcomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurcomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurcomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurcomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpeur.push(finalmovemntgbpeurco);

                        const compareddatagbpchfcomove =
                          typeof diffgbpchf === "number"
                            ? (
                                country === "JP"
                                  ? diffgbpchf.toFixed(3) === "0.000" ||
                                    diffgbpchf.toFixed(3) === "-0.000"
                                  : diffgbpchf.toFixed(5) === "0.00000" ||
                                    diffgbpchf.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diffgbpchf === "number" && diffgbpchf > 0
                              ? "+"
                              : "-"
                            : "unavailable";
                        const finalmovemntgbpchfco =
                          comparedataactminusest === "-" &&
                          compareddatagbpchfcomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpchfcomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchfcomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchfcomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpchfcomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchfcomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchfcomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchfcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpchfcomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchfcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpchfcomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpchf.push(finalmovemntgbpchfco);

                        const compareddatagbpaudcomove =
                          typeof diffgbpaud === "number"
                            ? (
                                country === "JP"
                                  ? diffgbpaud.toFixed(3) === "0.000" ||
                                    diffgbpaud.toFixed(3) === "-0.000"
                                  : diffgbpaud.toFixed(5) === "0.00000" ||
                                    diffgbpaud.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diffgbpaud === "number" && diffgbpaud > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpaudco =
                          comparedataactminusest === "-" &&
                          compareddatagbpaudcomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudcomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpaudcomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpaudcomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudcomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudcomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudcomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpaudcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudcomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudcomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpaud.push(finalmovemntgbpaudco);

                        const compareddatagbpcadcomove =
                          typeof diffgbpcad === "number"
                            ? (
                                country === "JP"
                                  ? diffgbpcad.toFixed(3) === "0.000" ||
                                    diffgbpcad.toFixed(3) === "-0.000"
                                  : diffgbpcad.toFixed(5) === "0.00000" ||
                                    diffgbpcad.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diffgbpcad === "number" && diffgbpcad > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpcadco =
                          comparedataactminusest === "-" &&
                          compareddatagbpcadcomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadcomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadcomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadcomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadcomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadcomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadcomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadcomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadcomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadcomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpcad.push(finalmovemntgbpcadco);

                        // <------------------------hlo am the second block here for LO========================>
                        const compareddatagbpusdlomove =
                          typeof diff2 === "number"
                            ? (
                                country === "JP"
                                  ? diff2.toFixed(3) === "0.000" ||
                                    diff2.toFixed(3) === "-0.000"
                                  : diff2.toFixed(5) === "0.00000" ||
                                    diff2.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff2 === "number" && diff2 > 0
                              ? "+"
                              : "-"
                            : "unavailable";
                        const finalmovemntgbplousd =
                          comparedataactminusest === "-" &&
                          compareddatagbpusdlomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdlomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdlomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdlomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdlomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdlomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdlomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdlomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdlomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpusdlo.push(finalmovemntgbplousd);

                        const compareddatagbpjpylomove =
                          typeof jpydiff2 === "number"
                            ? jpydiff2.toFixed(3) === "0.000" ||
                              jpydiff2.toFixed(3) === "-0.000"
                              ? ""
                              : typeof jpydiff2 === "number" && jpydiff2 > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpjpylo =
                          comparedataactminusest === "-" &&
                          compareddatagbpjpylomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpylomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpylomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpylomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpylomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpylomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpylomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpylomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpylomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpylomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpylomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpjpylo.push(finalmovemntgbpjpylo);

                        const compareddatagbpnzdlomove =
                          typeof diff2nzdgbp === "number"
                            ? (
                                country === "JP"
                                  ? diff2nzdgbp.toFixed(3) === "0.000" ||
                                    diff2nzdgbp.toFixed(3) === "-0.000"
                                  : diff2nzdgbp.toFixed(5) === "0.00000" ||
                                    diff2nzdgbp.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff2nzdgbp === "number" &&
                                diff2nzdgbp > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpnzdlo =
                          comparedataactminusest === "-" &&
                          compareddatagbpnzdlomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdlomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdlomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdlomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdlomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdlomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdlomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdlomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdlomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpnzdlo.push(finalmovemntgbpnzdlo);

                        const compareddatagbpeurlomove =
                          typeof diff2eurgbp === "number"
                            ? (
                                country === "JP"
                                  ? diff2eurgbp.toFixed(3) === "0.000" ||
                                    diff2eurgbp.toFixed(3) === "-0.000"
                                  : diff2eurgbp.toFixed(5) === "0.00000" ||
                                    diff2eurgbp.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff2eurgbp === "number" &&
                                diff2eurgbp > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpeurlo =
                          comparedataactminusest === "-" &&
                          compareddatagbpeurlomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurlomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurlomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurlomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurlomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurlomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurlomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurlomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurlomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpeurlo.push(finalmovemntgbpeurlo);

                        const compareddatagbpchflomove =
                          typeof diff2gbpchf === "number"
                            ? (
                                country === "JP"
                                  ? diff2gbpchf.toFixed(3) === "0.000" ||
                                    diff2gbpchf.toFixed(3) === "-0.000"
                                  : diff2gbpchf.toFixed(5) === "0.00000" ||
                                    diff2gbpchf.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff2gbpchf === "number" &&
                                diff2gbpchf > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpchflo =
                          comparedataactminusest === "-" &&
                          compareddatagbpchflomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpchflomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchflomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchflomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpchflomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchflomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchflomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchflomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpchflomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchflomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpchflomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpchflo.push(finalmovemntgbpchflo);

                        const compareddatagbpaudlomove =
                          typeof diff2gbpaud === "number"
                            ? (
                                country === "JP"
                                  ? diff2gbpaud.toFixed(3) === "0.000" ||
                                    diff2gbpaud.toFixed(3) === "-0.000"
                                  : diff2gbpaud.toFixed(5) === "0.00000" ||
                                    diff2gbpaud.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff2gbpaud === "number" &&
                                diff2gbpaud > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpaudlo =
                          comparedataactminusest === "-" &&
                          compareddatagbpaudlomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudlomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpaudlomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpaudlomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudlomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudlomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudlomove === "-"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudlomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudlomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpaudlo.push(finalmovemntgbpaudlo);

                        const compareddatagbpcadlomove =
                          typeof diff2gbpcad === "number"
                            ? (
                                country === "JP"
                                  ? diff2gbpcad.toFixed(3) === "0.000" ||
                                    diff2gbpcad.toFixed(3) === "-0.000"
                                  : diff2gbpcad.toFixed(5) === "0.00000" ||
                                    diff2gbpcad.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff2gbpcad === "number" &&
                                diff2gbpcad > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpcadlo =
                          comparedataactminusest === "-" &&
                          compareddatagbpcadlomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadlomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadlomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadlomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadlomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadlomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadlomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadlomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadlomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadlomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpcadlo.push(finalmovemntgbpcadlo);

                        // <----------------------hlo am 3rd block here for HO---------------->
                        const compareddatagbpusdhomove =
                          typeof diff3 === "number"
                            ? (
                                country === "JP"
                                  ? diff3.toFixed(3) === "0.000" ||
                                    diff3.toFixed(3) === "-0.000"
                                  : diff3.toFixed(5) === "0.00000" ||
                                    diff3.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff3 === "number" && diff3 > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbphousd =
                          comparedataactminusest === "-" &&
                          compareddatagbpusdhomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdhomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdhomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdhomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdhomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdhomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdhomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpusdhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdhomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpusdhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpusdhomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpusdho.push(finalmovemntgbphousd);

                        const compareddatagbpjpyhomove =
                          typeof jpydiff3 === "number"
                            ? jpydiff3.toFixed(3) === "0.000" ||
                              jpydiff3.toFixed(3) === "-0.000"
                              ? ""
                              : typeof jpydiff3 === "number" && jpydiff3 > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpjpyho =
                          comparedataactminusest === "-" &&
                          compareddatagbpjpyhomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpyhomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpyhomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpyhomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpyhomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpyhomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpyhomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpjpyhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpyhomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpjpyhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpjpyhomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpjpyho.push(finalmovemntgbpjpyho);

                        const compareddatagbpnzdhomove =
                          typeof diff3nzdgbp === "number"
                            ? (
                                country === "JP"
                                  ? diff3nzdgbp.toFixed(3) === "0.000" ||
                                    diff3nzdgbp.toFixed(3) === "-0.000"
                                  : diff3nzdgbp.toFixed(5) === "0.00000" ||
                                    diff3nzdgbp.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff3nzdgbp === "number" &&
                                diff3nzdgbp > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpnzdho =
                          comparedataactminusest === "-" &&
                          compareddatagbpnzdhomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdhomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdhomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdhomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdhomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdhomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdhomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpnzdhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdhomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpnzdhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpnzdhomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpnzdho.push(finalmovemntgbpnzdho);

                        const compareddatagbpeurhomove =
                          typeof diff3eurgbp === "number"
                            ? (
                                country === "JP"
                                  ? diff3eurgbp.toFixed(3) === "0.000" ||
                                    diff3eurgbp.toFixed(3) === "-0.000"
                                  : diff3eurgbp.toFixed(5) === "0.00000" ||
                                    diff3eurgbp.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff3eurgbp === "number" &&
                                diff3eurgbp > 0
                              ? "+"
                              : "-"
                            : "unavailable";
                        const finalmovemntgbpeurho =
                          comparedataactminusest === "-" &&
                          compareddatagbpeurhomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurhomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurhomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurhomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurhomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurhomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurhomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpeurhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurhomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpeurhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpeurhomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpeurho.push(finalmovemntgbpeurho);

                        const compareddatagbpchfhomove =
                          typeof diff3gbpchf === "number"
                            ? (
                                country === "JP"
                                  ? diff3gbpchf.toFixed(3) === "0.000" ||
                                    diff3gbpchf.toFixed(3) === "-0.000"
                                  : diff3gbpchf.toFixed(5) === "0.00000" ||
                                    diff3gbpchf.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff3gbpchf === "number" &&
                                diff3gbpchf > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpchfho =
                          comparedataactminusest === "-" &&
                          compareddatagbpchfhomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpchfhomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchfhomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchfhomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpchfhomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchfhomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchfhomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpchfhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpchfhomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpchfhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpchfhomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpchfho.push(finalmovemntgbpchfho);

                        const compareddatagbpaudhomove =
                          typeof diff3gbpaud === "number"
                            ? (
                                country === "JP"
                                  ? diff3gbpaud.toFixed(3) === "0.000" ||
                                    diff3gbpaud.toFixed(3) === "-0.000"
                                  : diff3gbpaud.toFixed(5) === "0.00000" ||
                                    diff3gbpaud.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff3gbpaud === "number" &&
                                diff3gbpaud > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpaudho =
                          comparedataactminusest === "-" &&
                          compareddatagbpaudhomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudhomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpaudhomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpaudhomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudhomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudhomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudhomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpaudhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudhomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpaudhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpaudhomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpaudho.push(finalmovemntgbpaudho);

                        const compareddatagbpcadhomove =
                          typeof diff3gbpcad === "number"
                            ? (
                                country === "JP"
                                  ? diff3gbpcad.toFixed(3) === "0.000" ||
                                    diff3gbpcad.toFixed(3) === "-0.000"
                                  : diff3gbpcad.toFixed(5) === "0.00000" ||
                                    diff3gbpcad.toFixed(5) === "-0.00000"
                              )
                              ? ""
                              : typeof diff3gbpcad === "number" &&
                                diff3gbpcad > 0
                              ? "+"
                              : "-"
                            : "unavailable";

                        const finalmovemntgbpcadho =
                          comparedataactminusest === "-" &&
                          compareddatagbpcadhomove === "-"
                            ? "+"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadhomove === "-"
                            ? "--"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadhomove === ""
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadhomove === "+"
                            ? "-"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadhomove === "+"
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadhomove === ""
                            ? "+"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadhomove === "-"
                            ? "-"
                            : comparedataactminusest === "-" &&
                              compareddatagbpcadhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadhomove === ""
                            ? "++"
                            : comparedataactminusest === "+" &&
                              compareddatagbpcadhomove === "unavailable"
                            ? "unavailable"
                            : comparedataactminusest === "" &&
                              compareddatagbpcadhomove === "unavailable"
                            ? "unavailable"
                            : "+";

                        arraygbpcadho.push(finalmovemntgbpcadho);

                        const itemDatecheck = item.date.split(" ")[0];

                        // Filtering impacthighlow array to find the matching data
                        const matchingData = impacthighlow.find((data) =>
                          data.startsWith(itemDatecheck)
                        );

                        const normalizeDate = (dateStr) => {
                          return dateStr.split(" ")[0];
                        };

                        const filterImpactData = (date) => {
                          const normalizedDate = normalizeDate(date);

                          return impacthighlowbeforethis.filter((impact) => {
                            const impactDate = impact.split(":")[0];

                            return impactDate === normalizedDate;
                          });
                        };

                        const impactData = filterImpactData(item.date);

                        return (
                          <>
                            <tr
                              key={index}
                              className=" cursor-pointer  text-center bg-white  border-y-[16px] border-solid border-[#F6F6F6] "
                            >
                              <td className="py-2 min-w-[81px]">{index + 1}.</td>
                              <td className="py-2 ">{item.date}</td>
                              <td className="py-2 ">{item.time.slice(0, 5)}</td>

                              {run_modalloader ? (
                                <td>
                                  {" "}
                                  <span className="loader1 text-[#1c72ca] text-center min-w-[200px] w-[200px]">
                                    Loading...
                                  </span>
                                </td>
                              ) : (
                                <>
                                  <td
                                    className="py-2  text-[13px] min-w-[200px] w-[200px] relative cursor-pointer"
                                    onClick={() =>
                                      handleShowModalbeforetime(item.date)
                                    }
                                  >
                                    {impactData.length > 0 ? (
                                      impactData.map((impact, i) => {
                                        const impactParts = impact.split(" ");
                                        // Remove the first element (date and time)
                                        const formattedImpact = impactParts
                                          .slice(1)
                                          .join(" ");

                                        return (
                                          <span
                                            key={i}
                                            className="font-bold block"
                                          >
                                            {formattedImpact}
                                          </span>
                                        );
                                      })
                                    ) : (
                                      <span className="font-bold">No Data</span>
                                    )}
                                  </td>

                                  <dialog
                                    id="my_modal_2"
                                    onClick={() => {
                                      autoClickButton();
                                    }}
                                    className="modal p-6 absolute max-h-[90vh]  top-0 shadow-[#ba9696] shadow-opacity-10 shadow-lg rounded-lg"
                                  >
                                    <form
                                      method="dialog"
                                      className="modal- w-[300px] relative top-[-10px] bg-white right-[-6px] text-right"
                                    >
                                      <div className="modal-box relative top-[3px]">
                                        <div
                                          onClick={() => {
                                            autoClickButton();
                                          }}
                                        >
                                          <button id="aashima_id">
                                            <img
                                              alt="img"
                                              src={crossicon}
                                              className="w-[20px] h-[20px]"
                                            ></img>
                                          </button>
                                        </div>
                                        <div>
                                          {filteredModalData.length > 0 ? (
                                            <>
                                              {/* High Impact */}
                                              {filteredModalData.some(
                                                (data) =>
                                                  data.impact.toLowerCase() ===
                                                  "high"
                                              ) && (
                                                <div className="text-center flex items-center justify-center">
                                                  <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300 w-[193px]">
                                                    High Impact :-
                                                  </h3>
                                                </div>
                                              )}
                                              {filteredModalData.map(
                                                (data, index) => {
                                                  if (
                                                    data.impact.toLowerCase() ===
                                                    "high"
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="text-start pl-[20px] my-4"
                                                      >
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Date:
                                                          </span>{" "}
                                                          {data.date}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Time:{" "}
                                                          </span>
                                                          {data.time}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Country:{" "}
                                                          </span>
                                                          {data.country}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Event:{" "}
                                                          </span>
                                                          {data.event}
                                                        </p>
                                                        <hr />
                                                      </div>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}

                                              {/* Medium Impact */}
                                              {filteredModalData.some(
                                                (data) =>
                                                  data.impact.toLowerCase() ===
                                                  "medium"
                                              ) && (
                                                <div className="text-center flex items-center justify-center">
                                                  <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300">
                                                    Medium Impact :-
                                                  </h3>
                                                </div>
                                              )}
                                              {filteredModalData.map(
                                                (data, index) => {
                                                  if (
                                                    data.impact.toLowerCase() ===
                                                    "medium"
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="text-start pl-[20px] my-4"
                                                      >
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Date:
                                                          </span>{" "}
                                                          {data.date}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Time:{" "}
                                                          </span>
                                                          {data.time}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Country:
                                                          </span>{" "}
                                                          {data.country}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Event:
                                                          </span>{" "}
                                                          {data.event}
                                                        </p>
                                                        <hr />
                                                      </div>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <p className="text-center mt-4">
                                              No Data
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </dialog>
                                </>
                              )}

                              {run_modalloader ? (
                                <td>
                                  <span className="loader1 text-[#1c72ca] text-center min-w-[150px] w-[150px]">
                                    Loading...
                                  </span>
                                </td>
                              ) : (
                                <>
                                  {" "}
                                  <td
                                    className=" py-2 px-4  min-w-[140px] relative cursor-pointer"
                                    onClick={() =>
                                      handleShowModalontime(item.date)
                                    }
                                  >
                                    {matchingData && (
                                      <span className="font-bold  text-[13px]">
                                        {matchingData.includes(": ")
                                          ? matchingData.substring(
                                              matchingData.indexOf(": ") + 2
                                            )
                                          : matchingData}
                                      </span>
                                    )}
                                  </td>
                                  <dialog
                                    id="my_modal_3"
                                    onClick={() => {
                                      autoClickButton1();
                                    }}
                                    className="modal p-6 absolute max-h-[90vh]  top-0 shadow-[#ba9696] shadow-opacity-10 shadow-lg rounded-lg"
                                  >
                                    <form
                                      method="dialog"
                                      className="modal- w-[300px] relative top-[-10px] right-[-6px] text-right"
                                    >
                                      <div className="modal-box relative top-[6px]">
                                        <div
                                          onClick={() => {
                                            autoClickButton1();
                                          }}
                                        >
                                          <button id="closemod11">
                                            <img
                                              alt="img"
                                              src={crossicon}
                                              className="w-[20px] h-[20px]"
                                            ></img>
                                          </button>
                                        </div>
                                        <div>
                                          {filteredModalDataontime.length >
                                          0 ? (
                                            <>
                                              {/* High Impact */}
                                              {filteredModalDataontime.some(
                                                (data) =>
                                                  data.impact.toLowerCase() ===
                                                  "high"
                                              ) && (
                                                <div className="text-center flex items-center justify-center">
                                                  <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300 w-[193px]">
                                                    High Impact :-
                                                  </h3>
                                                </div>
                                              )}
                                              {filteredModalDataontime.map(
                                                (data, index) => {
                                                  if (
                                                    data.impact.toLowerCase() ===
                                                    "high"
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="text-start pl-[20px] my-4"
                                                      >
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Date:
                                                          </span>{" "}
                                                          {data.date}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Time:{" "}
                                                          </span>
                                                          {data.time}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Country:{" "}
                                                          </span>
                                                          {data.country}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Event:{" "}
                                                          </span>
                                                          {data.event}
                                                        </p>
                                                        <hr />
                                                      </div>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}

                                              {/* Medium Impact */}
                                              {filteredModalDataontime.some(
                                                (data) =>
                                                  data.impact.toLowerCase() ===
                                                  "medium"
                                              ) && (
                                                <div className="text-center flex items-center justify-center">
                                                  <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300">
                                                    Medium Impact :-
                                                  </h3>
                                                </div>
                                              )}
                                              {filteredModalDataontime.map(
                                                (data, index) => {
                                                  if (
                                                    data.impact.toLowerCase() ===
                                                    "medium"
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="text-start pl-[20px] my-4"
                                                      >
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Date:
                                                          </span>{" "}
                                                          {data.date}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Time:{" "}
                                                          </span>
                                                          {data.time}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Country:
                                                          </span>{" "}
                                                          {data.country}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Event:
                                                          </span>{" "}
                                                          {data.event}
                                                        </p>
                                                        <hr />
                                                      </div>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <p className="text-center mt-4">
                                              No Data
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </dialog>
                                </>
                              )}
                              <td className="py-2">{item.previous || ""}</td>
                              <td className="py-2">{item.actual || ""}</td>
                              <td className="py-2">{item.estimate || ""}</td>

                              <td
                                className={`py-2 text-[#170E32] font-semibold border-r-2 border-[#F6F6F6] ${
                                  item.previous === null || item.previous === ""
                                    ? "bg-[#E5E7EB]" // N/A case:
                                    : item.actual - item.previous > 0
                                    ? "bg-[#BBF7D0]"
                                    : item.actual - item.previous === 0
                                    ? "bg-yellow-200"
                                    : "bg-[#FECACA]"
                                }`}
                              >
                                {item.previous === null ||
                                item.previous === "" ? (
                                  ""
                                ) : (
                                  <>
                                    {item.actual - item.previous > 0
                                      ? "+"
                                      : item.actual - item.previous === 0
                                      ? ""
                                      : "-"}
                                  </>
                                )}
                              </td>

                              <td
                                className={`py-2  text-[#170E32] font-semibold   border-r-4 border-[#F6F6F6] ${
                                  item.estimate === null || item.estimate === ""
                                    ? "bg-[#E5E7EB]" // N/A case:
                                    : item.actual - item.estimate > 0
                                    ? "bg-[#BBF7D0]"
                                    : item.actual - item.estimate === 0
                                    ? "bg-yellow-200"
                                    : "bg-[#FECACA]"
                                }`}
                              >
                                {item.estimate === null ||
                                item.estimate === "" ? (
                                  ""
                                ) : (
                                  <>
                                    {item.actual - item.estimate > 0
                                      ? "+"
                                      : item.actual - item.estimate === 0
                                      ? ""
                                      : "-"}
                                  </>
                                )}
                              </td>

                              {/* <---------final------> */}
                              <td
                                className={`py-2 border border-r-4 border-[#F6F6F6] text-[#170E32] font-semibold ${
                                  item.actual - item.estimate === 0 ||
                                  item.estimate === null ||
                                  item.estimate === ""
                                    ? item.actual - item.previous > 0
                                      ? "bg-[#BBF7D0]"
                                      : item.actual - item.previous === 0
                                      ? "bg-yellow-200"
                                      : "bg-[#FECACA]"
                                    : item.actual - item.estimate > 0
                                    ? "bg-[#BBF7D0]"
                                    : item.actual - item.estimate === 0 ||
                                      item.estimate === null ||
                                      item.estimate === ""
                                    ? "bg-yellow-200"
                                    : "bg-[#FECACA]"
                                }`}
                              >
                                {item.actual - item.estimate === 0 ||
                                item.estimate === null ||
                                item.estimate === ""
                                  ? item.actual - item.previous > 0
                                    ? "+"
                                    : item.actual - item.previous === 0
                                    ? ""
                                    : "-"
                                  : item.actual - item.estimate > 0
                                  ? "+"
                                  : item.actual - item.estimate === 0
                                  ? ""
                                  : "-"}
                              </td>

                              <td
                                className={`py-2 border min-w-[91.95px] ${
                                  typeof diff === "number"
                                    ? (
                                        country === "JP"
                                          ? diff.toFixed(3) === "0.000" ||
                                            diff.toFixed(3) === "-0.000"
                                          : diff.toFixed(5) === "0.00000" ||
                                            diff.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff === "number" && diff > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff === "number"
                                  ? (
                                      country === "JP"
                                        ? diff.toFixed(3) === "0.000" ||
                                          diff.toFixed(3) === "-0.000"
                                        : diff.toFixed(5) === "0.00000" ||
                                          diff.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff === "number" && diff > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border min-w-[91.95px] ${
                                  typeof jpydiff === "number"
                                    ? jpydiff.toFixed(3) === "0.000" ||
                                      jpydiff.toFixed(3) === "-0.000"
                                      ? "bg-yellow-200"
                                      : typeof jpydiff === "number" &&
                                        jpydiff > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof jpydiff === "number"
                                  ? jpydiff.toFixed(3) === "0.000" ||
                                    jpydiff.toFixed(3) === "-0.000"
                                    ? ""
                                    : typeof jpydiff === "number" && jpydiff > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border min-w-[91.95px] ${
                                  typeof diffnzdgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diffnzdgbp.toFixed(3) === "0.000" ||
                                            diffnzdgbp.toFixed(3) === "-0.000"
                                          : diffnzdgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diffnzdgbp.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffnzdgbp === "number" &&
                                        diffnzdgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diffnzdgbp === "number"
                                  ? (
                                      country === "JP"
                                        ? diffnzdgbp.toFixed(3) === "0.000" ||
                                          diffnzdgbp.toFixed(3) === "-0.000"
                                        : diffnzdgbp.toFixed(5) === "0.00000" ||
                                          diffnzdgbp.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diffnzdgbp === "number" &&
                                      diffnzdgbp > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diffeurgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diffeurgbp.toFixed(3) === "0.000" ||
                                            diffeurgbp.toFixed(3) === "-0.000"
                                          : diffeurgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diffeurgbp.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffeurgbp === "number" &&
                                        diffeurgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diffeurgbp === "number"
                                  ? (
                                      country === "JP"
                                        ? diffeurgbp.toFixed(3) === "0.000" ||
                                          diffeurgbp.toFixed(3) === "-0.000"
                                        : diffeurgbp.toFixed(5) === "0.00000" ||
                                          diffeurgbp.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diffeurgbp === "number" &&
                                      diffeurgbp > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diffgbpchf === "number"
                                    ? (
                                        country === "JP"
                                          ? diffgbpchf.toFixed(3) === "0.000" ||
                                            diffgbpchf.toFixed(3) === "-0.000"
                                          : diffgbpchf.toFixed(5) ===
                                              "0.00000" ||
                                            diffgbpchf.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffgbpchf === "number" &&
                                        diffgbpchf > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diffgbpchf === "number"
                                  ? (
                                      country === "JP"
                                        ? diffgbpchf.toFixed(3) === "0.000" ||
                                          diffgbpchf.toFixed(3) === "-0.000"
                                        : diffgbpchf.toFixed(5) === "0.00000" ||
                                          diffgbpchf.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diffgbpchf === "number" &&
                                      diffgbpchf > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diffgbpaud === "number"
                                    ? (
                                        country === "JP"
                                          ? diffgbpaud.toFixed(3) === "0.000" ||
                                            diffgbpaud.toFixed(3) === "-0.000"
                                          : diffgbpaud.toFixed(5) ===
                                              "0.00000" ||
                                            diffgbpaud.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffgbpaud === "number" &&
                                        diffgbpaud > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diffgbpaud === "number"
                                  ? (
                                      country === "JP"
                                        ? diffgbpaud.toFixed(3) === "0.000" ||
                                          diffgbpaud.toFixed(3) === "-0.000"
                                        : diffgbpaud.toFixed(5) === "0.00000" ||
                                          diffgbpaud.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diffgbpaud === "number" &&
                                      diffgbpaud > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2   border-r-4 border-[#F6F6F6] ${
                                  typeof diffgbpcad === "number"
                                    ? (
                                        country === "JP"
                                          ? diffgbpcad.toFixed(3) === "0.000" ||
                                            diffgbpcad.toFixed(3) === "-0.000"
                                          : diffgbpcad.toFixed(5) ===
                                              "0.00000" ||
                                            diffgbpcad.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffgbpcad === "number" &&
                                        diffgbpcad > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diffgbpcad === "number"
                                  ? (
                                      country === "JP"
                                        ? diffgbpcad.toFixed(3) === "0.000" ||
                                          diffgbpcad.toFixed(3) === "-0.000"
                                        : diffgbpcad.toFixed(5) === "0.00000" ||
                                          diffgbpcad.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diffgbpcad === "number" &&
                                      diffgbpcad > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff2 === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2.toFixed(3) === "0.000" ||
                                            diff2.toFixed(3) === "-0.000"
                                          : diff2.toFixed(5) === "0.00000" ||
                                            diff2.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2 === "number" && diff2 > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff2 === "number"
                                  ? (
                                      country === "JP"
                                        ? diff2.toFixed(3) === "0.000" ||
                                          diff2.toFixed(3) === "-0.000"
                                        : diff2.toFixed(5) === "0.00000" ||
                                          diff2.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff2 === "number" && diff2 > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof jpydiff2 === "number"
                                    ? jpydiff2.toFixed(3) === "0.000" ||
                                      jpydiff2.toFixed(3) === "-0.000"
                                      ? "bg-yellow-200"
                                      : typeof jpydiff2 === "number" &&
                                        jpydiff2 > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof jpydiff2 === "number"
                                  ? jpydiff2.toFixed(3) === "0.000" ||
                                    jpydiff2.toFixed(3) === "-0.000"
                                    ? ""
                                    : typeof jpydiff2 === "number" &&
                                      jpydiff2 > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff2nzdgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2nzdgbp.toFixed(3) ===
                                              "0.000" ||
                                            diff2nzdgbp.toFixed(3) === "-0.000"
                                          : diff2nzdgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diff2nzdgbp.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2nzdgbp === "number" &&
                                        diff2nzdgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff2nzdgbp === "number"
                                  ? (
                                      country === "JP"
                                        ? diff2nzdgbp.toFixed(3) === "0.000" ||
                                          diff2nzdgbp.toFixed(3) === "-0.000"
                                        : diff2nzdgbp.toFixed(5) ===
                                            "0.00000" ||
                                          diff2nzdgbp.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff2nzdgbp === "number" &&
                                      diff2nzdgbp > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff2eurgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2eurgbp.toFixed(3) ===
                                              "0.000" ||
                                            diff2eurgbp.toFixed(3) === "-0.000"
                                          : diff2eurgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diff2eurgbp.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2eurgbp === "number" &&
                                        diff2eurgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff2eurgbp === "number"
                                  ? (
                                      country === "JP"
                                        ? diff2eurgbp.toFixed(3) === "0.000" ||
                                          diff2eurgbp.toFixed(3) === "-0.000"
                                        : diff2eurgbp.toFixed(5) ===
                                            "0.00000" ||
                                          diff2eurgbp.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff2eurgbp === "number" &&
                                      diff2eurgbp > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff2gbpchf === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2gbpchf.toFixed(3) ===
                                              "0.000" ||
                                            diff2gbpchf.toFixed(3) === "-0.000"
                                          : diff2gbpchf.toFixed(5) ===
                                              "0.00000" ||
                                            diff2gbpchf.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2gbpchf === "number" &&
                                        diff2gbpchf > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff2gbpchf === "number"
                                  ? (
                                      country === "JP"
                                        ? diff2gbpchf.toFixed(3) === "0.000" ||
                                          diff2gbpchf.toFixed(3) === "-0.000"
                                        : diff2gbpchf.toFixed(5) ===
                                            "0.00000" ||
                                          diff2gbpchf.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff2gbpchf === "number" &&
                                      diff2gbpchf > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff2gbpaud === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2gbpaud.toFixed(3) ===
                                              "0.000" ||
                                            diff2gbpaud.toFixed(3) === "-0.000"
                                          : diff2gbpaud.toFixed(5) ===
                                              "0.00000" ||
                                            diff2gbpaud.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2gbpaud === "number" &&
                                        diff2gbpaud > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff2gbpaud === "number"
                                  ? (
                                      country === "JP"
                                        ? diff2gbpaud.toFixed(3) === "0.000" ||
                                          diff2gbpaud.toFixed(3) === "-0.000"
                                        : diff2gbpaud.toFixed(5) ===
                                            "0.00000" ||
                                          diff2gbpaud.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff2gbpaud === "number" &&
                                      diff2gbpaud > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2   border-r-4 border-[#F6F6F6] ${
                                  typeof diff2gbpcad === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2gbpcad.toFixed(3) ===
                                              "0.000" ||
                                            diff2gbpcad.toFixed(3) === "-0.000"
                                          : diff2gbpcad.toFixed(5) ===
                                              "0.00000" ||
                                            diff2gbpcad.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2gbpcad === "number" &&
                                        diff2gbpcad > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff2gbpcad === "number"
                                  ? (
                                      country === "JP"
                                        ? diff2gbpcad.toFixed(3) === "0.000" ||
                                          diff2gbpcad.toFixed(3) === "-0.000"
                                        : diff2gbpcad.toFixed(5) ===
                                            "0.00000" ||
                                          diff2gbpcad.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff2gbpcad === "number" &&
                                      diff2gbpcad > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff3 === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3.toFixed(3) === "0.000" ||
                                            diff3.toFixed(3) === "-0.000"
                                          : diff3.toFixed(5) === "0.00000" ||
                                            diff3.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3 === "number" && diff3 > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff3 === "number"
                                  ? (
                                      country === "JP"
                                        ? diff3.toFixed(3) === "0.000" ||
                                          diff3.toFixed(3) === "-0.000"
                                        : diff3.toFixed(5) === "0.00000" ||
                                          diff3.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff3 === "number" && diff3 > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof jpydiff3 === "number"
                                    ? jpydiff3.toFixed(3) === "0.000" ||
                                      jpydiff3.toFixed(3) === "-0.000"
                                      ? "bg-yellow-200"
                                      : typeof jpydiff3 === "number" &&
                                        jpydiff3 > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof jpydiff3 === "number"
                                  ? jpydiff3.toFixed(3) === "0.000" ||
                                    jpydiff3.toFixed(3) === "-0.000"
                                    ? ""
                                    : typeof jpydiff3 === "number" &&
                                      jpydiff3 > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff3nzdgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3nzdgbp.toFixed(3) ===
                                              "0.000" ||
                                            diff3nzdgbp.toFixed(3) === "-0.000"
                                          : diff3nzdgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diff3nzdgbp.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3nzdgbp === "number" &&
                                        diff3nzdgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff3nzdgbp === "number"
                                  ? (
                                      country === "JP"
                                        ? diff3nzdgbp.toFixed(3) === "0.000" ||
                                          diff3nzdgbp.toFixed(3) === "-0.000"
                                        : diff3nzdgbp.toFixed(5) ===
                                            "0.00000" ||
                                          diff3nzdgbp.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff3nzdgbp === "number" &&
                                      diff3nzdgbp > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff3eurgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3eurgbp.toFixed(3) ===
                                              "0.000" ||
                                            diff3eurgbp.toFixed(3) === "-0.000"
                                          : diff3eurgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diff3eurgbp.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3eurgbp === "number" &&
                                        diff3eurgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff3eurgbp === "number"
                                  ? (
                                      country === "JP"
                                        ? diff3eurgbp.toFixed(3) === "0.000" ||
                                          diff3eurgbp.toFixed(3) === "-0.000"
                                        : diff3eurgbp.toFixed(5) ===
                                            "0.00000" ||
                                          diff3eurgbp.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff3eurgbp === "number" &&
                                      diff3eurgbp > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff3gbpchf === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3gbpchf.toFixed(3) ===
                                              "0.000" ||
                                            diff3gbpchf.toFixed(3) === "-0.000"
                                          : diff3gbpchf.toFixed(5) ===
                                              "0.00000" ||
                                            diff3gbpchf.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3gbpchf === "number" &&
                                        diff3gbpchf > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff3gbpchf === "number"
                                  ? (
                                      country === "JP"
                                        ? diff3gbpchf.toFixed(3) === "0.000" ||
                                          diff3gbpchf.toFixed(3) === "-0.000"
                                        : diff3gbpchf.toFixed(5) ===
                                            "0.00000" ||
                                          diff3gbpchf.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff3gbpchf === "number" &&
                                      diff3gbpchf > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2 border  min-w-[91.95px] ${
                                  typeof diff3gbpaud === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3gbpaud.toFixed(3) ===
                                              "0.000" ||
                                            diff3gbpaud.toFixed(3) === "-0.000"
                                          : diff3gbpaud.toFixed(5) ===
                                              "0.00000" ||
                                            diff3gbpaud.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3gbpaud === "number" &&
                                        diff3gbpaud > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff3gbpaud === "number"
                                  ? (
                                      country === "JP"
                                        ? diff3gbpaud.toFixed(3) === "0.000" ||
                                          diff3gbpaud.toFixed(3) === "-0.000"
                                        : diff3gbpaud.toFixed(5) ===
                                            "0.00000" ||
                                          diff3gbpaud.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff3gbpaud === "number" &&
                                      diff3gbpaud > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>

                              <td
                                className={`py-2   border-r-4 border-[#F6F6F6] ${
                                  typeof diff3gbpcad === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3gbpcad.toFixed(3) ===
                                              "0.000" ||
                                            diff3gbpcad.toFixed(3) === "-0.000"
                                          : diff3gbpcad.toFixed(5) ===
                                              "0.00000" ||
                                            diff3gbpcad.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3gbpcad === "number" &&
                                        diff3gbpcad > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                              >
                                {typeof diff3gbpcad === "number"
                                  ? (
                                      country === "JP"
                                        ? diff3gbpcad.toFixed(3) === "0.000" ||
                                          diff3gbpcad.toFixed(3) === "-0.000"
                                        : diff3gbpcad.toFixed(5) ===
                                            "0.00000" ||
                                          diff3gbpcad.toFixed(5) === "-0.00000"
                                    )
                                    ? ""
                                    : typeof diff3gbpcad === "number" &&
                                      diff3gbpcad > 0
                                    ? "+"
                                    : "-"
                                  : "unavailable"}
                              </td>
                            </tr>
                          </>
                        );
                      })}

                      <tr className="bg-[#AECAF14D]  bg-opacity-[30%] text-center">
                        <td className="py-2  font-bold   min-w-[80.8px]" colSpan={4}>
                          % data missing
                        </td>
                        {/* <td className="py-2 "></td>
                        <td className="py-2 "></td> */}
                        {/* <td className="py-2 "></td> */}
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]"></td>

                        <td className="py-2 ">
                          {countemp(arraygbpusd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpjpy).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpnzd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpeur).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpchf).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpaud).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countemp(arraygbpcad).toFixed(2)}%
                        </td>

                        <td className="py-2">
                          {countemp(arraygbpusdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpjpylo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpnzdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpeurlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpchflo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpaudlo).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countemp(arraygbpcadlo).toFixed(2)}%
                        </td>

                        <td className="py-2 ">
                          {countemp(arraygbpusdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpjpyho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpnzdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpeurho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpchfho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countemp(arraygbpaudho).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countemp(arraygbpcadho).toFixed(2)}%
                        </td>
                      </tr>
                      <tr className="bg-[#AECAF14D] text-center">
                        <td className="py-2 font-bold " colSpan={4}>Non- Zero event (Positive currency correlation)</td>
                        {/* <td className="py-2 "></td>
                        <td className="py-2 "></td> */}
                        {/* <td className="py-2 "></td> */}
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>

                        <td className="py-2 border-r-4 border-[#F6F6F6]"></td>

                        <td className="py-2 ">
                          {countPositive(arraygbpusd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpjpy).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpnzd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpeur).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpchf).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpaud).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countPositive(arraygbpcad).toFixed(2)}%
                        </td>

                        <td className="py-2">
                          {countPositive(arraygbpusdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpjpylo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpnzdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpeurlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpchflo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpaudlo).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countPositive(arraygbpcadlo).toFixed(2)}%
                        </td>

                        <td className="py-2 ">
                          {countPositive(arraygbpusdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpjpyho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpnzdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpeurho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpchfho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositive(arraygbpaudho).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countPositive(arraygbpcadho).toFixed(2)}%
                        </td>
                      </tr>
                      <tr className="bg-[#AECAF14D] text-center">
                        <td className="py-2 font-bold " colSpan={4}>Non- Zero event (Negative currency correlation)</td>
                        {/* <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td> */}
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>

                        <td className="py-2 border-r-4 border-[#F6F6F6]"></td>

                        <td className="py-2 ">
                          {countNegative(arraygbpusd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpjpy).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpnzd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpeur).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpchf).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpaud).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countNegative(arraygbpcad).toFixed(2)}%
                        </td>

                        <td className="py-2 ">
                          {countNegative(arraygbpusdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpjpylo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpnzdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpeurlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpchflo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpaudlo).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countNegative(arraygbpcadlo).toFixed(2)}%
                        </td>

                        <td className="py-2 ">
                          {countNegative(arraygbpusdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpjpyho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpnzdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpeurho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpchfho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegative(arraygbpaudho).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countNegative(arraygbpcadho).toFixed(2)}%
                        </td>
                      </tr>
                      <tr className="bg-[#AECAF14D] text-center">
                        <td className="py-2 font-bold " colSpan={4}>Flat event (Positive currency correlation)</td>
                        {/* <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td> */}
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>

                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]"></td>

                        <td className="py-2 ">
                          {countPositivePositive(arraygbpusd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpjpy).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpnzd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpeur).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpchf).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpaud).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpcad).toFixed(2)}%
                        </td>

                        <td className="py-2 border-l-4 border-[#F6F6F6]">
                          {countPositivePositive(arraygbpusdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpjpylo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpnzdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpeurlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpchflo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpaudlo).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countPositivePositive(arraygbpcadlo).toFixed(2)}%
                        </td>

                        <td className="py-2 ">
                          {countPositivePositive(arraygbpusdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpjpyho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpnzdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpeurho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpchfho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countPositivePositive(arraygbpaudho).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countPositivePositive(arraygbpcadho).toFixed(2)}%
                        </td>
                      </tr>
                      <tr className="bg-[#AECAF14D] text-center">
                        <td className="py-2 font-bold" colSpan={4}>Flat event (Negative currency correlation)</td>
                        {/* <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td> */}
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>

                        <td className="py-2 "></td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]"></td>

                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpusd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpjpy).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpnzd).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpeur).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpchf).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpaud).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpcad).toFixed(2)}%
                        </td>
                        <td className="py-2 border-l-4 border-[#F6F6F6]">
                          {countNegativeNegative(arraygbpusdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpjpylo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpnzdlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpeurlo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpchflo).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpaudlo).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countNegativeNegative(arraygbpcadlo).toFixed(2)}%
                        </td>

                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpusdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpjpyho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpnzdho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpeurho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpchfho).toFixed(2)}%
                        </td>
                        <td className="py-2 ">
                          {countNegativeNegative(arraygbpaudho).toFixed(2)}%
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                          {countNegativeNegative(arraygbpcadho).toFixed(2)}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


{/* <-------------highest color codes pics-----------> */}
<div className="flex flex-col justify-end sm:flex-col md:flex-row lg:flex-row xl:flex-row ">
            <div className="bg-[#fff] p-4 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-12  my-8 py-4  items-center   flex justify-start flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
              
              <div className="sm:flex-col flex-col md:flex-row lg:flex-row xl:flex-row flex mt-[5%] sm:mt-[5%] md:mt-[5%] lg:mt-[0%] xl:mt-[0%]">
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    Highest Average:
                  </h1>
                  <img
                    src={color1}
                    title="color"
                    alt="color"
                    className="ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    {" "}
                    2nd Highest Average:
                  </h1>
                  <img
                    src={color2}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    {" "}
                    3rd Highest Average:
                  </h1>
                  <img
                    src={color3}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />  
                  <span className="ml-[8px]">,</span>
                </div>
              </div>

              <div className="sm:flex-col flex-col md:flex-row lg:flex-row xl:flex-row flex mt-[5%] sm:mt-[5%] md:mt-[5%] lg:mt-[0%] xl:mt-[0%]">
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                   
                  {"Positive values :"} 
                  </h1>
                  <img
                    src={color7}
                    title="color"
                    alt="color"
                    className="ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                  
                   {"Flat values:"}
                  </h1>
                  <img
                    src={color8}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                   
                    {/* <300 %: */}
                    {"Negative values:"}
                  </h1>
                  <img
                    src={color9}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  /> 
                </div>
              </div>
            </div>
          </div>



              <div className="m-3  py-2 px-3  mt-4 flex">
                <h1 className="ml-[2%] font-bold text-[20px] text-[#170E32]">
                Price movement (% and points, from Open):
                </h1>
              </div>

              {/* <------------------third table which was 1st--------------> */}
{/* <--9:06> */}
              <div className="overflow-auto">
                <div className="  mx-[3%] overflow-x-auto overflow-y-auto  max-h-[87vh] ">
                  <div className="flex ">
                    <div className="min-w-[1111px] bg-[#151841] min-h-[79.96] border-r-2 border-r-white"></div>
                    <div className="bg-[#151841] min-w-[643.51px] flex justify-center items-center py-2"><h1 className="text-[18px] font-bold text-white">{`Close price - Open Price ${
      timeclickedurl_state === 1
        ? "(1min)"
        : timeclickedurl_state === 5
        ? "(5min)"
        : timeclickedurl_state === 15
        ? "(15min)"
        : timeclickedurl_state === 60
        ? "(60min)"
        : "(1min)"
    }`}</h1></div>
                    <div className="bg-[#151841] min-w-[643.51px] flex justify-center items-center py-2 border-x-2 border-x-white"><h1 className="text-[18px] font-bold text-white py-3">{`Low price - Open Price ${
      timeclickedurl_state === 1
        ? "(1min)"
        : timeclickedurl_state === 5
        ? "(5min)"
        : timeclickedurl_state === 15
        ? "(15min)"
        : timeclickedurl_state === 60
        ? "(60min)"
        : "(1min)"
    }`}</h1></div>
                    <div className="bg-[#151841] yellow-200 min-w-[643.51px] flex justify-center items-center py-2"><h1 className="text-[18px] font-bold text-white py-3">{`High price - Open Price ${
      timeclickedurl_state === 1
        ? "(1min)"
        : timeclickedurl_state === 5
        ? "(5min)"
        : timeclickedurl_state === 15
        ? "(15min)"
        : timeclickedurl_state === 60
        ? "(60min)"
        : "(1min)"
    }`}</h1></div>
                  </div>
                  <table
                    className={` ${styles.tableuser} min-w-full  overflow-x-scroll m-auto  font-semibold `}
                  >
                    <thead className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[14px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px] ">
                      <tr className="h-[96px]">
                        <th className="sticky top-0 py-2 px-2">S. No</th>
                        <th className="sticky top-0 py-2 px-2">Date</th>
                        <th className="sticky top-0 py-2 px-2">Time(GMT)</th>
                        <th className="sticky top-0 py-2 px-2 min-w-[200px] w-[200px]">
                        Event Count before this Time
                        </th>
                        <th className="sticky top-0 py-2 px-2 min-w-[150px] w-[150px]">
                        Event Count at this Time
                        </th>
                        <th className="sticky top-0 py-2 px-2">Previous</th>
                        <th className="sticky top-0 py-2 px-2">Actual</th>
                        <th className="sticky top-0 py-2 px-2">Estimate</th>

                        <th className="sticky top-0 py-2 px-2  min-w-[99.33px] w-[97px] ">
                          Act - Prev
                        </th>

                        <th className="sticky top-0 py-2 px-2 border-r-4 border-[#F6F6F6]  min-w-[99.33px] w-[98px] ">
                          Act - Est
                        </th>
                        <th className="sticky top-0 py-2 px-2 border-r-4 border-[#F6F6F6] bg-[#3589FF8F] bg-opacity-[56%] min-w-[99.33px] w-[100px] ">
                          Final movement direction
                        </th>

                        {headers.map((header) => (
                          <th
                            key={header.id}
                            className="sticky top-0 py-2 px-2 bg-[#d0dcf4]   text-[#170E32]"
                          >
                            {header.name}
                            {/* <br />
                            (C-O) */}
                          </th>
                        ))}

                        {headers.map((header) => (
                          <th
                            key={header.id}
                            className="sticky top-0 py-2 px-2 bg-[#3589FF8F] bg-opacity-[56%]  text-[#170E32]"
                          >
                            {header.name}
                            {/* <br />
                            (L-O) */}
                          </th>
                        ))}
                        {headers.map((header) => (
                          <th
                            key={header.id}
                            className="sticky top-0 py-2 px-2 bg-[#d0dcf4]   text-[#170E32]"
                          >
                            {header.name}
                            {/* <br />
                            (H-O) */}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-[#F6F6F6]  z-0 text-[#170E32] font-bold text-[14px]">
                      {eventHistory.map((item, index) => {
                        const itemDate = item.date
                          .split(".")
                          .reverse()
                          .join(".");

                        let itemTime;

                        if (selectedTime === 5) {
                          itemTime = roundDownToNearestMultipleOf5(item.time);
                        } else if (selectedTime === 15) {
                          itemTime = roundDownToNearestMultipleOf15(item.time);
                        } else if (selectedTime === 60) {
                          itemTime = roundDownToNearestMultipleOf1Hour(
                            item.time
                          );
                        } else {
                          itemTime = item.time;
                        }

                        const usdEntry = usddata.find((entry) => {
                          return (
                            entry.date === itemDate && entry.time === itemTime
                          );
                        });

                        const jpyEntry = jpydata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const gbpnzdEntry = gbpnzddata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const eurgbpEntry = eurgbpdata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const gbpchfEntry = gbpchfdata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const gbpaudEntry = gbpauddata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );
                        const gbpcadEntry = gbpcaddata.find(
                          (entry) =>
                            entry.date === itemDate && entry.time === itemTime
                        );

                        const usdOpen = usdEntry ? usdEntry.Open : "Data unavailable";
                        const usdClose = usdEntry ? usdEntry.Close : "Data unavailable";
                        const usdLow = usdEntry ? usdEntry.Low : "Data unavailable";
                        const usdHigh = usdEntry ? usdEntry.High : "Data unavailable";

                        const diff =
                          usdOpen !== "Data unavailable" && usdClose !== "Data unavailable"
                            ? usdClose - usdOpen
                            : "Data unavailable";

                        // console.log("diff" , diff)
                        const diff2 =
                          usdOpen !== "Data unavailable" && usdLow !== "Data unavailable"
                            ? usdLow - usdOpen
                            : "Data unavailable";
                        const diff3 =
                          usdOpen !== "Data unavailable" && usdHigh !== "Data unavailable"
                            ? usdHigh - usdOpen
                            : "Data unavailable";

                        const jpyOpen = jpyEntry ? jpyEntry.Open : "Data unavailable";
                        const jpyClose = jpyEntry ? jpyEntry.Close : "Data unavailable";
                        const jpyLow = jpyEntry ? jpyEntry.Low : "Data unavailable";
                        const jpyHigh = jpyEntry ? jpyEntry.High : "Data unavailable";

                        const jpydiff =
                          jpyOpen !== "Data unavailable" && jpyClose !== "Data unavailable"
                            ? jpyClose - jpyOpen
                            : "Data unavailable";
                        const jpydiff2 =
                          jpyOpen !== "Data unavailable" && jpyLow !== "Data unavailable"
                            ? jpyLow - jpyOpen
                            : "Data unavailable";
                        const jpydiff3 =
                          jpyOpen !== "Data unavailable" && jpyHigh !== "Data unavailable"
                            ? jpyHigh - jpyOpen
                            : "Data unavailable";

                        const nzdOpen = gbpnzdEntry
                          ? gbpnzdEntry.Open
                          : "Data unavailable";
                        const nzdClose = gbpnzdEntry
                          ? gbpnzdEntry.Close
                          : "Data unavailable";
                        const nzdLow = gbpnzdEntry
                          ? gbpnzdEntry.Low
                          : "Data unavailable";
                        const nzdHigh = gbpnzdEntry
                          ? gbpnzdEntry.High
                          : "Data unavailable";

                        const diffnzdgbp =
                          nzdOpen !== "Data unavailable" && nzdClose !== "Data unavailable"
                            ? nzdClose - nzdOpen
                            : "Data unavailable";

                        const diff2nzdgbp =
                          nzdOpen !== "Data unavailable" && nzdLow !== "Data unavailable"
                            ? nzdLow - nzdOpen
                            : "Data unavailable";
                        const diff3nzdgbp =
                          nzdOpen !== "Data unavailable" && nzdHigh !== "Data unavailable"
                            ? nzdHigh - nzdOpen
                            : "Data unavailable";

                        const eurgbpOpen = eurgbpEntry
                          ? eurgbpEntry.Open
                          : "Data unavailable";
                        const eurgbpClose = eurgbpEntry
                          ? eurgbpEntry.Close
                          : "Data unavailable";
                        const eurgbpLow = eurgbpEntry
                          ? eurgbpEntry.Low
                          : "Data unavailable";
                        const eurgbpHigh = eurgbpEntry
                          ? eurgbpEntry.High
                          : "Data unavailable";

                        const diffeurgbp =
                          eurgbpOpen !== "Data unavailable" && eurgbpClose !== "Data unavailable"
                            ? eurgbpClose - eurgbpOpen
                            : "Data unavailable";

                        const diff2eurgbp =
                          eurgbpOpen !== "Data unavailable" && eurgbpLow !== "Data unavailable"
                            ? eurgbpLow - eurgbpOpen
                            : "Data unavailable";
                        const diff3eurgbp =
                          eurgbpOpen !== "Data unavailable" && eurgbpHigh !== "Data unavailable"
                            ? eurgbpHigh - eurgbpOpen
                            : "Data unavailable";

                        const gbpchfOpen = gbpchfEntry
                          ? gbpchfEntry.Open
                          : "Data unavailable";
                        const gbpchfClose = gbpchfEntry
                          ? gbpchfEntry.Close
                          : "Data unavailable";
                        const gbpchfLow = gbpchfEntry
                          ? gbpchfEntry.Low
                          : "Data unavailable";
                        const gbpchfHigh = gbpchfEntry
                          ? gbpchfEntry.High
                          : "Data unavailable";

                        const diffgbpchf =
                          gbpchfOpen !== "Data unavailable" && gbpchfClose !== "Data unavailable"
                            ? gbpchfClose - gbpchfOpen
                            : "Data unavailable";

                        const diff2gbpchf =
                          gbpchfOpen !== "Data unavailable" && gbpchfLow !== "Data unavailable"
                            ? gbpchfLow - gbpchfOpen
                            : "Data unavailable";
                        const diff3gbpchf =
                          gbpchfOpen !== "Data unavailable" && gbpchfHigh !== "Data unavailable"
                            ? gbpchfHigh - gbpchfOpen
                            : "Data unavailable";

                        const gbpaudOpen = gbpaudEntry
                          ? gbpaudEntry.Open
                          : "Data unavailable";
                        const gbpaudClose = gbpaudEntry
                          ? gbpaudEntry.Close
                          : "Data unavailable";
                        const gbpaudLow = gbpaudEntry
                          ? gbpaudEntry.Low
                          : "Data unavailable";
                        const gbpaudHigh = gbpaudEntry
                          ? gbpaudEntry.High
                          : "Data unavailable";

                        const diffgbpaud =
                          gbpaudOpen !== "Data unavailable" && gbpaudClose !== "Data unavailable"
                            ? gbpaudClose - gbpaudOpen
                            : "Data unavailable";

                        const diff2gbpaud =
                          gbpaudOpen !== "Data unavailable" && gbpaudLow !== "Data unavailable"
                            ? gbpaudLow - gbpaudOpen
                            : "Data unavailable";
                        const diff3gbpaud =
                          gbpaudOpen !== "Data unavailable" && gbpaudHigh !== "Data unavailable"
                            ? gbpaudHigh - gbpaudOpen
                            : "Data unavailable";

                        const gbpcadOpen = gbpcadEntry
                          ? gbpcadEntry.Open
                          : "Data unavailable";
                        const gbpcadClose = gbpcadEntry
                          ? gbpcadEntry.Close
                          : "Data unavailable";
                        const gbpcadLow = gbpcadEntry
                          ? gbpcadEntry.Low
                          : "Data unavailable";
                        const gbpcadHigh = gbpcadEntry
                          ? gbpcadEntry.High
                          : "Data unavailable";

                        const diffgbpcad =
                          gbpcadOpen !== "Data unavailable" && gbpcadClose !== "Data unavailable"
                            ? gbpcadClose - gbpcadOpen
                            : "Data unavailable";

                        const diff2gbpcad =
                          gbpcadOpen !== "Data unavailable" && gbpcadLow !== "Data unavailable"
                            ? gbpcadLow - gbpcadOpen
                            : "Data unavailable";
                        const diff3gbpcad =
                          gbpcadOpen !== "Data unavailable" && gbpcadHigh !== "Data unavailable"
                            ? gbpcadHigh - gbpcadOpen
                            : "Data unavailable";

                        // <----------------------------------------code of 2nd table------------------------->

                        const difff = (usdClose - usdOpen) / usdOpen;
                        const difff2 = (usdLow - usdOpen) / usdOpen;
                        const difff3 = (usdHigh - usdOpen) / usdOpen;

                        const jpydifff = (jpyClose - jpyOpen) / jpyOpen;
                        const jpydifff2 = (jpyLow - jpyOpen) / jpyOpen;
                        const jpydifff3 = (jpyHigh - jpyOpen) / jpyOpen;

                        const diffgbpnzdbyopen =
                          nzdOpen !== "Data unavailable" && nzdClose !== "Data unavailable"
                            ? (nzdClose - nzdOpen) / nzdOpen
                            : "Data unavailable";

                        const diff2gbpnzdbyopen =
                          nzdOpen !== "Data unavailable" && nzdLow !== "Data unavailable"
                            ? (nzdLow - nzdOpen) / nzdOpen
                            : "Data unavailable";
                        const diff3gbpnzdbyopen =
                          nzdOpen !== "Data unavailable" && nzdHigh !== "Data unavailable"
                            ? (nzdHigh - nzdOpen) / nzdOpen
                            : "Data unavailable";

                        const diffeurgbpbyopen =
                          eurgbpOpen !== "Data unavailable" && eurgbpClose !== "Data unavailable"
                            ? (eurgbpClose - eurgbpOpen) / eurgbpOpen
                            : "Data unavailable";

                        const diff2eurgbpbyopen =
                          eurgbpOpen !== "Data unavailable" && eurgbpLow !== "Data unavailable"
                            ? (eurgbpLow - eurgbpOpen) / eurgbpOpen
                            : "Data unavailable";
                        const diff3eurgbpbyopen =
                          eurgbpOpen !== "Data unavailable" && eurgbpHigh !== "Data unavailable"
                            ? (eurgbpHigh - eurgbpOpen) / eurgbpOpen
                            : "Data unavailable";

                        const diffgbpchfbyopen =
                          gbpchfOpen !== "Data unavailable" && gbpchfClose !== "Data unavailable"
                            ? (gbpchfClose - gbpchfOpen) / gbpchfOpen
                            : "Data unavailable";

                        const diff2gbpchfbyopen =
                          gbpchfOpen !== "Data unavailable" && gbpchfLow !== "Data unavailable"
                            ? (gbpchfLow - gbpchfOpen) / gbpchfOpen
                            : "Data unavailable";
                        const diff3gbpchfbyopen =
                          gbpchfOpen !== "Data unavailable" && gbpchfHigh !== "Data unavailable"
                            ? (gbpchfHigh - gbpchfOpen) / gbpchfOpen
                            : "Data unavailable";

                        const diffgbpaudbyopen =
                          gbpaudOpen !== "Data unavailable" && gbpaudClose !== "Data unavailable"
                            ? (gbpaudClose - gbpaudOpen) / gbpaudOpen
                            : "Data unavailable";

                        const diff2gbpaudbyopen =
                          gbpaudOpen !== "Data unavailable" && gbpaudLow !== "Data unavailable"
                            ? (gbpaudLow - gbpaudOpen) / gbpaudOpen
                            : "Data unavailable";
                        const diff3gbpaudbyopen =
                          gbpaudOpen !== "Data unavailable" && gbpaudHigh !== "Data unavailable"
                            ? (gbpaudHigh - gbpaudOpen) / gbpaudOpen
                            : "Data unavailable";

                        const diffgbpcadbyopen =
                          gbpcadOpen !== "Data unavailable" && gbpcadClose !== "Data unavailable"
                            ? (gbpcadClose - gbpcadOpen) / gbpcadOpen
                            : "Data unavailable";

                        const diff2gbpcadbyopen =
                          gbpcadOpen !== "Data unavailable" && gbpcadLow !== "Data unavailable"
                            ? (gbpcadLow - gbpcadOpen) / gbpcadOpen
                            : "Data unavailable";
                        const diff3gbpcadbyopen =
                          gbpcadOpen !== "Data unavailable" && gbpcadHigh !== "Data unavailable"
                            ? (gbpcadHigh - gbpcadOpen) / gbpcadOpen
                            : "Data unavailable";

                        const itemDatecheck = item.date.split(" ")[0];

                        // Filtering impacthighlow array to find the matching data
                        const matchingData = impacthighlow.find((data) =>
                          data.startsWith(itemDatecheck)
                        );

                        const normalizeDate = (dateStr) => {
                          return dateStr.split(" ")[0];
                        };

                        const filterImpactData = (date) => {
                          const normalizedDate = normalizeDate(date);

                          return impacthighlowbeforethis.filter((impact) => {
                            const impactDate = impact.split(":")[0];

                            return impactDate === normalizedDate;
                          });
                        };

                        const impactData = filterImpactData(item.date);

                        return (
                          <>
                            <tr
                              key={index}
                              className=" cursor-pointer  text-center bg-white  border-y-[16px] border-solid border-[#F6F6F6] "
                            >
                              <td className="py-2 min-w-[81px]">{index + 1}.</td>
                              <td className="py-2 ">{item.date}</td>
                              <td className="py-2 ">{item.time.slice(0, 5)}</td>

                              {run_modalloader ? (
                                <td className="min-w-[200px] w-[200px]">
                                  {" "}
                                  <span className="loader1 text-[#1c72ca] text-center">
                                    Loading...
                                  </span>
                                </td>
                              ) : (
                                <>
                                  <td
                                    className="py-2  text-[13px] min-w-[200px] w-[200px] relative cursor-pointer"
                                    onClick={() =>
                                      handleShowModalbeforetime(item.date)
                                    }
                                  >
                                    {impactData.length > 0 ? (
                                      impactData.map((impact, i) => {
                                        const impactParts = impact.split(" ");
                                        // Remove the first element (date and time)
                                        const formattedImpact = impactParts
                                          .slice(1)
                                          .join(" ");

                                        return (
                                          <span
                                            key={i}
                                            className="font-bold block"
                                          >
                                            {formattedImpact}
                                          </span>
                                        );
                                      })
                                    ) : (
                                      <span className="font-bold ">No Data</span>
                                    )}
                                  </td>

                                  <dialog
                                    id="my_modal_2"
                                    onClick={() => {
                                      autoClickButton();
                                    }}
                                    className="modal p-6 absolute max-h-[90vh]  top-0 shadow-[#ba9696] shadow-opacity-10 shadow-lg rounded-lg"
                                  >
                                    <form
                                      method="dialog"
                                      className="modal- w-[300px] relative top-[-10px] bg-white right-[-6px] text-right"
                                    >
                                      <div className="modal-box relative top-[3px]">
                                        <div
                                          onClick={() => {
                                            autoClickButton();
                                          }}
                                        >
                                          <button id="aashima_id">
                                            <img
                                              alt="img"
                                              src={crossicon}
                                              className="w-[20px] h-[20px]"
                                            ></img>
                                          </button>
                                        </div>
                                        <div>
                                          {filteredModalData.length > 0 ? (
                                            <>
                                              {/* High Impact */}
                                              {filteredModalData.some(
                                                (data) =>
                                                  data.impact.toLowerCase() ===
                                                  "high"
                                              ) && (
                                                <div className="text-center flex items-center justify-center">
                                                  <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300 w-[193px]">
                                                    High Impact :-
                                                  </h3>
                                                </div>
                                              )}
                                              {filteredModalData.map(
                                                (data, index) => {
                                                  if (
                                                    data.impact.toLowerCase() ===
                                                    "high"
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="text-start pl-[20px] my-4"
                                                      >
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Date:
                                                          </span>{" "}
                                                          {data.date}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Time:{" "}
                                                          </span>
                                                          {data.time}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Country:{" "}
                                                          </span>
                                                          {data.country}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Event:{" "}
                                                          </span>
                                                          {data.event}
                                                        </p>
                                                        <hr />
                                                      </div>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}

                                              {/* Medium Impact */}
                                              {filteredModalData.some(
                                                (data) =>
                                                  data.impact.toLowerCase() ===
                                                  "medium"
                                              ) && (
                                                <div className="text-center flex items-center justify-center">
                                                  <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300">
                                                    Medium Impact :-
                                                  </h3>
                                                </div>
                                              )}
                                              {filteredModalData.map(
                                                (data, index) => {
                                                  if (
                                                    data.impact.toLowerCase() ===
                                                    "medium"
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="text-start pl-[20px] my-4"
                                                      >
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Date:
                                                          </span>{" "}
                                                          {data.date}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Time:{" "}
                                                          </span>
                                                          {data.time}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Country:
                                                          </span>{" "}
                                                          {data.country}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Event:
                                                          </span>{" "}
                                                          {data.event}
                                                        </p>
                                                        <hr />
                                                      </div>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <p className="text-center mt-4">
                                              No Data
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </dialog>
                                </>
                              )}

                              {run_modalloader ? (
                                <td className="min-w-[150px] w-[150px]">
                                  <span className="loader1 text-[#1c72ca] text-center">
                                    Loading...
                                  </span>
                                </td>
                              ) : (
                                <>
                                  {" "}
                                  <td
                                    className=" py-2 px-4  min-w-[140px] relative cursor-pointer"
                                    onClick={() =>
                                      handleShowModalontime(item.date)
                                    }
                                  >
                                    {matchingData && (
                                      <span className="font-bold text-[13px]">
                                        {matchingData.includes(": ")
                                          ? matchingData.substring(
                                              matchingData.indexOf(": ") + 2
                                            )
                                          : matchingData}
                                      </span>
                                    )}
                                  </td>
                                  <dialog
                                    id="my_modal_3"
                                    onClick={() => {
                                      autoClickButton1();
                                    }}
                                    className="modal p-6 absolute max-h-[90vh]  top-0 shadow-[#ba9696] shadow-opacity-10 shadow-lg rounded-lg"
                                  >
                                    <form
                                      method="dialog"
                                      className="modal- w-[300px] relative top-[-10px] right-[-6px] text-right"
                                    >
                                      <div className="modal-box relative top-[6px]">
                                        <div
                                          onClick={() => {
                                            autoClickButton1();
                                          }}
                                        >
                                          <button id="closemod11">
                                            <img
                                              alt="img"
                                              src={crossicon}
                                              className="w-[20px] h-[20px]"
                                            ></img>
                                          </button>
                                        </div>
                                        <div>
                                          {filteredModalDataontime.length >
                                          0 ? (
                                            <>
                                              {/* High Impact */}
                                              {filteredModalDataontime.some(
                                                (data) =>
                                                  data.impact.toLowerCase() ===
                                                  "high"
                                              ) && (
                                                <div className="text-center flex items-center justify-center">
                                                  <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300 w-[193px]">
                                                    High Impact :-
                                                  </h3>
                                                </div>
                                              )}
                                              {filteredModalDataontime.map(
                                                (data, index) => {
                                                  if (
                                                    data.impact.toLowerCase() ===
                                                    "high"
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="text-start pl-[20px] my-4"
                                                      >
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Date:
                                                          </span>{" "}
                                                          {data.date}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Time:{" "}
                                                          </span>
                                                          {data.time}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Country:{" "}
                                                          </span>
                                                          {data.country}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Event:{" "}
                                                          </span>
                                                          {data.event}
                                                        </p>
                                                        <hr />
                                                      </div>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}

                                              {/* Medium Impact */}
                                              {filteredModalDataontime.some(
                                                (data) =>
                                                  data.impact.toLowerCase() ===
                                                  "medium"
                                              ) && (
                                                <div className="text-center flex items-center justify-center">
                                                  <h3 className="font-bold text-lg mt-4 mb-4 border-red-300 py-2 px-4 rounded-xl border-2 bg-red-300">
                                                    Medium Impact :-
                                                  </h3>
                                                </div>
                                              )}
                                              {filteredModalDataontime.map(
                                                (data, index) => {
                                                  if (
                                                    data.impact.toLowerCase() ===
                                                    "medium"
                                                  ) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="text-start pl-[20px] my-4"
                                                      >
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Date:
                                                          </span>{" "}
                                                          {data.date}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Time:{" "}
                                                          </span>
                                                          {data.time}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Country:
                                                          </span>{" "}
                                                          {data.country}
                                                        </p>
                                                        <p>
                                                          <span className="font-bold pr-2">
                                                            Event:
                                                          </span>{" "}
                                                          {data.event}
                                                        </p>
                                                        <hr />
                                                      </div>
                                                    );
                                                  }
                                                  return null;
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <p className="text-center mt-4">
                                              No Data
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </dialog>
                                </>
                              )}

                              <td className="py-2 ">{item.previous}</td>
                              <td className="py-2 ">{item.actual}</td>
                              <td className="py-2 ">{item.estimate}</td>

                              <td
                                className={`py-2  text-[#170E32] font-semibold  ${
                                  item.previous === null || item.previous === ""
                                    ? "bg-[#E5E7EB]" // N/A case:
                                    : item.actual - item.previous > 0
                                    ? "bg-[#BBF7D0]"
                                    : item.actual - item.previous === 0
                                    ? "bg-yellow-200"
                                    : "bg-[#FECACA]"
                                }`}
                              >
                                {item.previous === null ||
                                item.previous === "" ? (
                                  "N/A"
                                ) : (
                                  <>
                                   
                                    {(
                                      Math.abs(
                                        (item.actual - item.previous) /
                                          item.previous
                                      ) * 100
                                    ).toFixed(1) + "%"}
                                      <br />
                                    ({(item.actual - item.previous).toFixed(4)})
                                  
                                   
                                  </>
                                )}
                              </td>

                              <td
                                className={`py-2 border border-r-4 border-[#F6F6F6] text-[#170E32] font-semibold  ${
                                  item.estimate === null || item.estimate === ""
                                    ? "bg-[#E5E7EB]" // N/A case:
                                    : item.actual - item.estimate > 0
                                    ? "bg-[#BBF7D0]"
                                    : item.actual - item.estimate === 0
                                    ? "bg-yellow-200"
                                    : "bg-[#FECACA]"
                                }`}
                              >
                                {item.estimate === null ||
                                item.estimate === "" ? (
                                  "N/A"
                                ) : (
                                  <>
                                  
                                    {(
                                      Math.abs(
                                        (item.actual - item.estimate) /
                                          item.estimate
                                      ) * 100
                                    ).toFixed(1) + "%"}
                                    
                                    <br />
                                    ({(item.actual - item.estimate).toFixed(4)}){" "}
                                    
                                    
                                  </>
                                )}
                              </td>

                              <td
                                className={`py-2 border border-r-4 border-[#F6F6F6] text-[#170E32] font-semibold ${
                                  item.actual - item.estimate === 0 ||
                                  item.estimate === null ||
                                  item.estimate === ""
                                    ? item.actual - item.previous > 0
                                      ? "bg-[#BBF7D0]"
                                      : item.actual - item.previous === 0
                                      ? "bg-yellow-200"
                                      : "bg-[#FECACA]"
                                    : item.actual - item.estimate > 0
                                    ? "bg-[#BBF7D0]"
                                    : item.actual - item.estimate === 0 ||
                                      item.estimate === null ||
                                      item.estimate === ""
                                    ? "bg-yellow-200"
                                    : "bg-[#FECACA]"
                                }`}
                              >
                                  
                                {(
                                  Math.abs(
                                    item.actual - item.estimate === 0 ||
                                      item.estimate === null ||
                                      item.estimate === ""
                                      ? (item.actual - item.previous) /
                                          item.previous
                                      : (item.actual - item.estimate) /
                                          item.estimate
                                  ) * 100
                                ).toFixed(1) + "%"}
                                
                                <br />
                                ({(item.actual - item.estimate === 0 ||
                                item.estimate === null ||
                                item.estimate === ""
                                  ? item.actual - item.previous
                                  : item.actual - item.estimate
                                ).toFixed(4)})
                             
                              
                              </td>
                              {/* <---------differences start from here-------------> */}
                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diff === "number"
                                    ? //  diff.toFixed(5) === "0.00000" ||
                                      //   diff.toFixed(5) === "-0.00000"
                                      (
                                        country === "JP"
                                          ? diff.toFixed(3) === "0.000" ||
                                            diff.toFixed(3) === "-0.000"
                                          : diff.toFixed(5) === "0.00000" ||
                                            diff.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff === "number" && diff > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff === "number"
                                    ? `Difference: ${usdClose}-${usdOpen} =${diff.toFixed(
                                        6
                                      )}     | Relative change: ${difff.toFixed(
                                        6
                                      )}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof difff === "number" ? difff : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff === "number"
                                  ? Math.round(
                                      diff.toFixed(country === "JP" ? 3 : 5) *
                                        (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff})
                              
                               
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof jpydiff === "number"
                                    ? jpydiff.toFixed(3) === "0.000" ||
                                      jpydiff.toFixed(3) === "-0.000"
                                      ? "bg-yellow-200"
                                      : typeof jpydiff === "number" &&
                                        jpydiff > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof jpydiff === "number"
                                    ? `Difference: ${jpyClose}-${jpyOpen} =${jpydiff.toFixed(
                                        6
                                      )}`
                                    : "No value"
                                }
                              >
                                
                                {(
                                  Math.abs(
                                    typeof jpydifff === "number"
                                      ? jpydifff
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof jpydiff === "number"
                                  ? Math.round(jpydiff.toFixed(3) * 1000)
                                  : jpydiff})
                               
                                
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diffnzdgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diffnzdgbp.toFixed(3) === "0.000" ||
                                            diffnzdgbp.toFixed(3) === "-0.000"
                                          : diffnzdgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diffnzdgbp.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffnzdgbp === "number" &&
                                        diffnzdgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diffnzdgbp === "number"
                                    ? `Difference: ${nzdClose}-${nzdOpen} =${diffnzdgbp.toFixed(
                                        6
                                      )}`
                                    : "No value"
                                }
                              >
                                
                                {(
                                  Math.abs(
                                    typeof diffgbpnzdbyopen === "number"
                                      ? diffgbpnzdbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diffnzdgbp === "number"
                                  ? Math.round(
                                      diffnzdgbp.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diffnzdgbp})
                             
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diffeurgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diffeurgbp.toFixed(3) === "0.000" ||
                                            diffeurgbp.toFixed(3) === "-0.000"
                                          : diffeurgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diffeurgbp.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffeurgbp === "number" &&
                                        diffeurgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diffeurgbp === "number"
                                    ? `Difference: ${eurgbpClose}-${eurgbpOpen} =${diffeurgbp.toFixed(
                                        6
                                      )}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diffeurgbpbyopen === "number"
                                      ? diffeurgbpbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diffeurgbp === "number"
                                  ? Math.round(
                                      diffeurgbp.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diffeurgbp})
                             
                               
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diffgbpchf === "number"
                                    ? (
                                        country === "JP"
                                          ? diffgbpchf.toFixed(3) === "0.000" ||
                                            diffgbpchf.toFixed(3) === "-0.000"
                                          : diffgbpchf.toFixed(5) ===
                                              "0.00000" ||
                                            diffgbpchf.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffgbpchf === "number" &&
                                        diffgbpchf > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diffgbpchf === "number"
                                    ? `Difference: ${gbpchfClose}-${gbpchfOpen} =${diffgbpchf.toFixed(
                                        6
                                      )}`
                                    : "No value"
                                }
                              >
                                  
                                {(
                                  Math.abs(
                                    typeof diffgbpchfbyopen === "number"
                                      ? diffgbpchfbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diffgbpchf === "number"
                                  ? Math.round(
                                      diffgbpchf.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diffgbpchf})
                             
                              
                              </td>
                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diffgbpaud === "number"
                                    ? (
                                        country === "JP"
                                          ? diffgbpaud.toFixed(3) === "0.000" ||
                                            diffgbpaud.toFixed(3) === "-0.000"
                                          : diffgbpaud.toFixed(5) ===
                                              "0.00000" ||
                                            diffgbpaud.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffgbpaud === "number" &&
                                        diffgbpaud > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diffgbpaud === "number"
                                    ? `Difference: ${gbpaudClose}-${gbpaudOpen} =${diffgbpaud.toFixed(
                                        6
                                      )}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diffgbpaudbyopen === "number"
                                      ? diffgbpaudbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diffgbpaud === "number"
                                  ? Math.round(
                                      diffgbpaud.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diffgbpaud})
                              
                               
                              </td>

                              <td
                                className={`py-2  border border-r-4 border-[#F6F6F6] min-w-[91.95px] ${
                                  typeof diffgbpcad === "number"
                                    ? (
                                        country === "JP"
                                          ? diffgbpcad.toFixed(3) === "0.000" ||
                                            diffgbpcad.toFixed(3) === "-0.000"
                                          : diffgbpcad.toFixed(5) ===
                                              "0.00000" ||
                                            diffgbpcad.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diffgbpcad === "number" &&
                                        diffgbpcad > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diffgbpcad === "number"
                                    ? `Difference: ${gbpcadClose}-${gbpcadOpen} =${diffgbpcad.toFixed(
                                        6
                                      )}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diffgbpcadbyopen === "number"
                                      ? diffgbpcadbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diffgbpcad === "number"
                                  ? Math.round(
                                      diffgbpcad.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diffgbpcad})
                             
                               
                              </td>

                              <td
                                className={`py-2  border  min-w-[91.95px] ${
                                  typeof diff2 === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2.toFixed(3) === "0.000" ||
                                            diff2.toFixed(3) === "-0.000"
                                          : diff2.toFixed(5) === "0.00000" ||
                                            diff2.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2 === "number" && diff2 > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff2 === "number"
                                    ? `Difference: ${usdLow}-${usdOpen} =${diff2}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof difff2 === "number" ? difff2 : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff2 === "number"
                                  ? Math.round(
                                      diff2.toFixed(country === "JP" ? 3 : 5) *
                                        (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff2})
                             
                               
                              </td>
                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof jpydiff2 === "number"
                                    ? jpydiff2.toFixed(3) === "0.000" ||
                                      jpydiff2.toFixed(3) === "-0.000"
                                      ? "bg-yellow-200"
                                      : typeof jpydiff2 === "number" &&
                                        jpydiff2 > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof jpydiff2 === "number"
                                    ? `Difference: ${jpyLow}-${jpyOpen} =${jpydiff2}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof jpydifff2 === "number"
                                      ? jpydifff2
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof jpydiff2 === "number"
                                  ? Math.round(jpydiff2.toFixed(3) * 1000)
                                  : jpydiff2})
                              
                               
                              </td>

                              <td
                                className={`py-2  border  min-w-[91.95px] ${
                                  typeof diff2nzdgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2nzdgbp.toFixed(3) ===
                                              "0.000" ||
                                            diff2nzdgbp.toFixed(3) === "-0.000"
                                          : diff2nzdgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diff2nzdgbp.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2nzdgbp === "number" &&
                                        diff2nzdgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff2nzdgbp === "number"
                                    ? `Difference: ${nzdLow}-${nzdOpen} =${diff2nzdgbp}`
                                    : "No value"
                                }
                              >
                                
                                {(
                                  Math.abs(
                                    typeof diff2gbpnzdbyopen === "number"
                                      ? diff2gbpnzdbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff2nzdgbp === "number"
                                  ? Math.round(
                                      diff2nzdgbp.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff2nzdgbp})
                             
                                
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diff2eurgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2eurgbp.toFixed(3) ===
                                              "0.000" ||
                                            diff2eurgbp.toFixed(3) === "-0.000"
                                          : diff2eurgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diff2eurgbp.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2eurgbp === "number" &&
                                        diff2eurgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff2eurgbp === "number"
                                    ? `Difference: ${eurgbpLow}-${eurgbpOpen} =${diff2eurgbp}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diff2eurgbpbyopen === "number"
                                      ? diff2eurgbpbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff2eurgbp === "number"
                                  ? Math.round(
                                      diff2eurgbp.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff2eurgbp})
                              
                               
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diff2gbpchf === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2gbpchf.toFixed(3) ===
                                              "0.000" ||
                                            diff2gbpchf.toFixed(3) === "-0.000"
                                          : diff2gbpchf.toFixed(5) ===
                                              "0.00000" ||
                                            diff2gbpchf.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2gbpchf === "number" &&
                                        diff2gbpchf > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff2gbpchf === "number"
                                    ? `Difference: ${gbpchfLow}-${gbpchfOpen} =${diff2gbpchf}`
                                    : "No value"
                                }
                              >
                                
                                {(
                                  Math.abs(
                                    typeof diff2gbpchfbyopen === "number"
                                      ? diff2gbpchfbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff2gbpchf === "number"
                                  ? Math.round(
                                      diff2gbpchf.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff2gbpchf})
                             
                                
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diff2gbpaud === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2gbpaud.toFixed(3) ===
                                              "0.000" ||
                                            diff2gbpaud.toFixed(3) === "-0.000"
                                          : diff2gbpaud.toFixed(5) ===
                                              "0.00000" ||
                                            diff2gbpaud.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2gbpaud === "number" &&
                                        diff2gbpaud > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff2gbpaud === "number"
                                    ? `Difference: ${gbpaudLow}-${gbpaudOpen} =${diff2gbpaud}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diff2gbpaudbyopen === "number"
                                      ? diff2gbpaudbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff2gbpaud === "number"
                                  ? Math.round(
                                      diff2gbpaud.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff2gbpaud})
                              
                               
                              </td>

                              <td
                                className={`py-2  border  border-r-4 border-[#F6F6F6] min-w-[91.95px] ${
                                  typeof diff2gbpcad === "number"
                                    ? (
                                        country === "JP"
                                          ? diff2gbpcad.toFixed(3) ===
                                              "0.000" ||
                                            diff2gbpcad.toFixed(3) === "-0.000"
                                          : diff2gbpcad.toFixed(5) ===
                                              "0.00000" ||
                                            diff2gbpcad.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff2gbpcad === "number" &&
                                        diff2gbpcad > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff2gbpcad === "number"
                                    ? `Difference: ${gbpcadLow}-${gbpaudOpen} =${diff2gbpcad}`
                                    : "No value"
                                }
                              >
                                  
                                {(
                                  Math.abs(
                                    typeof diff2gbpcadbyopen === "number"
                                      ? diff2gbpcadbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff2gbpcad === "number"
                                  ? Math.round(
                                      diff2gbpcad.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff2gbpcad})
                            
                              
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diff3 === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3.toFixed(3) === "0.000" ||
                                            diff3.toFixed(3) === "-0.000"
                                          : diff3.toFixed(5) === "0.00000" ||
                                            diff3.toFixed(5) === "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3 === "number" && diff3 > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff3 === "number"
                                    ? `Difference: ${usdHigh}-${usdOpen} =${diff3}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof difff3 === "number" ? difff3 : ""
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff3 === "number"
                                  ? Math.round(
                                      diff3.toFixed(country === "JP" ? 3 : 5) *
                                        (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff3})
                              
                               
                              </td>

                              <td
                                className={`py-2  border  min-w-[91.95px] ${
                                  typeof jpydiff3 === "number"
                                    ? jpydiff3.toFixed(3) === "0.000" ||
                                      jpydiff3.toFixed(3) === "-0.000"
                                      ? "bg-yellow-200"
                                      : typeof jpydiff3 === "number" &&
                                        jpydiff3 > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof jpydiff3 === "number"
                                    ? `Difference: ${jpyHigh}-${jpyOpen} =${jpydiff3}`
                                    : "No value"
                                }
                              >
                                
                                {(
                                  Math.abs(
                                    typeof jpydifff3 === "number"
                                      ? jpydifff3
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof jpydiff3 === "number"
                                  ? Math.round(jpydiff3.toFixed(3) * 1000)
                                  : jpydiff3})
                               
                                
                              </td>

                              <td
                                className={`py-2  border  min-w-[91.95px] ${
                                  typeof diff3nzdgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3nzdgbp.toFixed(3) ===
                                              "0.000" ||
                                            diff3nzdgbp.toFixed(3) === "-0.000"
                                          : diff3nzdgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diff3nzdgbp.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3nzdgbp === "number" &&
                                        diff3nzdgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff3nzdgbp === "number"
                                    ? `Difference: ${nzdHigh}-${nzdOpen} =${diff3nzdgbp}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diff3gbpnzdbyopen === "number"
                                      ? diff3gbpnzdbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff3nzdgbp === "number"
                                  ? Math.round(
                                      diff3nzdgbp.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff3nzdgbp})
                             
                               
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diff3eurgbp === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3eurgbp.toFixed(3) ===
                                              "0.000" ||
                                            diff3eurgbp.toFixed(3) === "-0.000"
                                          : diff3eurgbp.toFixed(5) ===
                                              "0.00000" ||
                                            diff3eurgbp.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3eurgbp === "number" &&
                                        diff3eurgbp > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff3eurgbp === "number"
                                    ? `Difference: ${eurgbpHigh}-${eurgbpOpen} =${diff3eurgbp}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diff3eurgbpbyopen === "number"
                                      ? diff3eurgbpbyopen
                                      : "Nan"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff3eurgbp === "number"
                                  ? Math.round(
                                      diff3eurgbp.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff3eurgbp})
                              
                               
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diff3gbpchf === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3gbpchf.toFixed(3) ===
                                              "0.000" ||
                                            diff3gbpchf.toFixed(3) === "-0.000"
                                          : diff3gbpchf.toFixed(5) ===
                                              "0.00000" ||
                                            diff3gbpchf.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3gbpchf === "number" &&
                                        diff3gbpchf > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff3gbpchf === "number"
                                    ? `Difference: ${gbpchfHigh}-${gbpchfOpen} =${diff3gbpchf}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diff3gbpchfbyopen === "number"
                                      ? diff3gbpchfbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff3gbpchf === "number"
                                  ? Math.round(
                                      diff3gbpchf.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff3gbpchf})
                              
                               
                              </td>

                              <td
                                className={`py-2  border min-w-[91.95px] ${
                                  typeof diff3gbpaud === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3gbpaud.toFixed(3) ===
                                              "0.000" ||
                                            diff3gbpaud.toFixed(3) === "-0.000"
                                          : diff3gbpaud.toFixed(5) ===
                                              "0.00000" ||
                                            diff3gbpaud.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3gbpaud === "number" &&
                                        diff3gbpaud > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff3gbpaud === "number"
                                    ? `Difference: ${gbpaudHigh}-${gbpaudOpen} =${diff3gbpaud}`
                                    : "No value"
                                }
                              >
                                 
                                {(
                                  Math.abs(
                                    typeof diff3gbpaudbyopen === "number"
                                      ? diff3gbpaudbyopen
                                      : "Nan"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff3gbpaud === "number"
                                  ? Math.round(
                                      diff3gbpaud.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff3gbpaud})
                              
                               
                              </td>

                              <td
                                className={`py-2  border  border-r-4 border-[#F6F6F6] min-w-[91.95px] ${
                                  typeof diff3gbpcad === "number"
                                    ? (
                                        country === "JP"
                                          ? diff3gbpcad.toFixed(3) ===
                                              "0.000" ||
                                            diff3gbpcad.toFixed(3) === "-0.000"
                                          : diff3gbpcad.toFixed(5) ===
                                              "0.00000" ||
                                            diff3gbpcad.toFixed(5) ===
                                              "-0.00000"
                                      )
                                      ? "bg-yellow-200"
                                      : typeof diff3gbpcad === "number" &&
                                        diff3gbpcad > 0
                                      ? "bg-[#BBF7D0]"
                                      : "bg-[#FECACA]"
                                    : "bg-[#E5E7EB]"
                                }`}
                                title={
                                  typeof diff3gbpcad === "number"
                                    ? `Difference: ${gbpcadHigh}-${gbpaudOpen} =${diff3gbpcad}`
                                    : "No value"
                                }
                              >
                                
                                {(
                                  Math.abs(
                                    typeof diff3gbpcadbyopen === "number"
                                      ? diff3gbpcadbyopen
                                      : "NaN"
                                  ) * 100
                                ).toFixed(3) + "%"}
                                
                                <br />
                                ({typeof diff3gbpcad === "number"
                                  ? Math.round(
                                      diff3gbpcad.toFixed(
                                        country === "JP" ? 3 : 5
                                      ) * (country === "JP" ? 1000 : 100000)
                                    )
                                  : diff3gbpcad})
                               
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      {/* <---cybercure----> */}
                      <tr className="bg-[#AECAF14D] text-center">
                        <td className="py-2  font-bold min-w-[80.8px] " colSpan={4}>
                        Non- Zero event (average movement)
                        </td>
                        {/* <td className="py-2 "></td> */}
                        {/* <td className="py-2 "></td> */}
                        {/* <td className="py-2 "></td> */}
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>

                        <td className="py-2">
                        {sumActualPreviousbyo}% <br /> ({sumActualPrevious}) 
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                        {sumActualEstimatebyo}%  <br />  ({sumActualEstimate})
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                        {sumfinalmovementbyo}%  <br />  ({sumfinalmovement}) 
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averageDifff !== null
                              ? averageDifff.toFixed(3) + "%"
                              : "N/A") === highest1
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averageDifff !== null
                                  ? averageDifff.toFixed(3) + "%"
                                  : "N/A") === highest2
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averageDifff !== null
                                  ? averageDifff.toFixed(3) + "%"
                                  : "N/A") === highest3
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          {averageDifff !== null
                            ? averageDifff.toFixed(3) + "%"
                            : "N/A"}
                          
                          <br />
                          ({averagegbpusddiff1 !== null
                            ? averagegbpusddiff1.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (avgjpydifff !== null
                              ? avgjpydifff.toFixed(3) + "%"
                              : "N/A") === highest1
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (avgjpydifff !== null
                                  ? avgjpydifff.toFixed(3) + "%"
                                  : "N/A") === highest2
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (avgjpydifff !== null
                                  ? avgjpydifff.toFixed(3) + "%"
                                  : "N/A") === highest3
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {avgjpydifff !== null
                            ? avgjpydifff.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpjpydiff1 !== null
                            ? averagegbpjpydiff1.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpnzdbyodiff1 !== null
                              ? averagegbpnzdbyodiff1.toFixed(3) + "%"
                              : "N/A") === highest1
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff1 !== null
                                  ? averagegbpnzdbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest2
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff1 !== null
                                  ? averagegbpnzdbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest3
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpnzdbyodiff1 !== null
                            ? averagegbpnzdbyodiff1.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpnzddiff1 !== null
                            ? averagegbpnzddiff1.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpeurbyodiff1 !== null
                              ? averagegbpeurbyodiff1.toFixed(3) + "%"
                              : "N/A") === highest1
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff1 !== null
                                  ? averagegbpeurbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest2
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff1 !== null
                                  ? averagegbpeurbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest3
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpeurbyodiff1 !== null
                            ? averagegbpeurbyodiff1.toFixed(3) + "%"
                            : "N/A"}
                          <br />
                          ({averagegbpeurdiff1 !== null
                            ? averagegbpeurdiff1.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          // className={`text-[15px] py-2 ${averagegbpchfbyodiff1.toFixed(4) + "%" === highest1
                          //   ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          //   : averagegbpchfbyodiff1.toFixed(4) + "%" === highest2
                          //     ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          //     : averagegbpchfbyodiff1.toFixed(4) + "%" === highest3
                          //       ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                          //       : null
                          //   }`}
                          className={`text-[15px] py-2 ${
                            (averagegbpchfbyodiff1 !== null
                              ? averagegbpchfbyodiff1.toFixed(3) + "%"
                              : "N/A") === highest1
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff1 !== null
                                  ? averagegbpchfbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest2
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff1 !== null
                                  ? averagegbpchfbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest3
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          {/* {averagegbpchfdiff1.toFixed(4)} <br /> (
                    {averagegbpchfbyodiff1.toFixed(4) + "%"}){" "} */}


                          {averagegbpchfbyodiff1 !== null
                            ? averagegbpchfbyodiff1.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpchfdiff1 !== null
                            ? averagegbpchfdiff1.toFixed(1)
                            : "N/A"})
                          <br />
                          
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpaudbyodiff1 !== null
                              ? averagegbpaudbyodiff1.toFixed(3) + "%"
                              : "N/A") === highest1
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff1 !== null
                                  ? averagegbpaudbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest2
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff1 !== null
                                  ? averagegbpaudbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest3
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpaudbyodiff1 !== null
                            ? averagegbpaudbyodiff1.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpauddiff1 !== null
                            ? averagegbpauddiff1.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 border-r-4 border-[#F6F6F6]  ${
                            (averagegbpcadbyodiff1 !== null
                              ? averagegbpcadbyodiff1.toFixed(3) + "%"
                              : "N/A") === highest1
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff1 !== null
                                  ? averagegbpcadbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest2
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff1 !== null
                                  ? averagegbpcadbyodiff1.toFixed(3) + "%"
                                  : "N/A") === highest3
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpcadbyodiff1 !== null
                            ? averagegbpcadbyodiff1.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpcaddiff1 !== null
                            ? averagegbpcaddiff1.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averageDifff2 !== null
                              ? averageDifff2.toFixed(3) + "%"
                              : "N/A") === highest1lo
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averageDifff2 !== null
                                  ? averageDifff2.toFixed(3) + "%"
                                  : "N/A") === highest2lo
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averageDifff2 !== null
                                  ? averageDifff2.toFixed(3) + "%"
                                  : "N/A") === highest3lo
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                            
                          {averageDifff2 !== null
                            ? averageDifff2.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpusddiff2 !== null
                            ? averagegbpusddiff2.toFixed(1)
                            : "N/A"})
                          <br />
                        
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (avgjpydifff2 !== null
                              ? avgjpydifff2.toFixed(3) + "%"
                              : "N/A") === highest1lo
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (avgjpydifff2 !== null
                                  ? avgjpydifff2.toFixed(3) + "%"
                                  : "N/A") === highest2lo
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (avgjpydifff2 !== null
                                  ? avgjpydifff2.toFixed(3) + "%"
                                  : "N/A") === highest3lo
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                            
                          {avgjpydifff2 !== null
                            ? avgjpydifff2.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpjpydiff2 !== null
                            ? averagegbpjpydiff2.toFixed(1)
                            : "N/A"})
                          <br />
                        
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpnzdbyodiff2 !== null
                              ? averagegbpnzdbyodiff2.toFixed(3) + "%"
                              : "N/A") === highest1lo
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff2 !== null
                                  ? averagegbpnzdbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest2lo
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff2 !== null
                                  ? averagegbpnzdbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest3lo
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpnzdbyodiff2 !== null
                            ? averagegbpnzdbyodiff2.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpnzddiff2 !== null
                            ? averagegbpnzddiff2.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpeurbyodiff2 !== null
                              ? averagegbpeurbyodiff2.toFixed(3) + "%"
                              : "N/A") === highest1lo
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff2 !== null
                                  ? averagegbpeurbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest2lo
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff2 !== null
                                  ? averagegbpeurbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest3lo
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpeurbyodiff2 !== null
                            ? averagegbpeurbyodiff2.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpeurdiff2 !== null
                            ? averagegbpeurdiff2.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpchfbyodiff2 !== null
                              ? averagegbpchfbyodiff2.toFixed(3) + "%"
                              : "N/A") === highest1lo
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff2 !== null
                                  ? averagegbpchfbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest2lo
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff2 !== null
                                  ? averagegbpchfbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest3lo
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                           
                          {averagegbpchfbyodiff2 !== null
                            ? averagegbpchfbyodiff2.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpchfdiff2 !== null
                            ? averagegbpchfdiff2.toFixed(1)
                            : "N/A"})
                          <br />
                         
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpaudbyodiff2 !== null
                              ? averagegbpaudbyodiff2.toFixed(3) + "%"
                              : "N/A") === highest1lo
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff2 !== null
                                  ? averagegbpaudbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest2lo
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff2 !== null
                                  ? averagegbpaudbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest3lo
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpaudbyodiff2 !== null
                            ? averagegbpaudbyodiff2.toFixed(3) + "%"
                            : "N/A"}
                          <br />
                          ({averagegbpauddiff2 !== null
                            ? averagegbpauddiff2.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 border-r-4 border-[#F6F6F6] ${
                            (averagegbpcadbyodiff2 !== null
                              ? averagegbpcadbyodiff2.toFixed(3) + "%"
                              : "N/A") === highest1lo
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff2 !== null
                                  ? averagegbpcadbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest2lo
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff2 !== null
                                  ? averagegbpcadbyodiff2.toFixed(3) + "%"
                                  : "N/A") === highest3lo
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpcadbyodiff2 !== null
                            ? averagegbpcadbyodiff2.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpcaddiff2 !== null
                            ? averagegbpcaddiff2.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averageDifff3 !== null
                              ? averageDifff3.toFixed(3) + "%"
                              : "N/A") === highest1ho
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averageDifff3 !== null
                                  ? averageDifff3.toFixed(3) + "%"
                                  : "N/A") === highest2ho
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averageDifff3 !== null
                                  ? averageDifff3.toFixed(3) + "%"
                                  : "N/A") === highest3ho
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averageDifff3 !== null
                            ? averageDifff3.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpusddiff3 !== null
                            ? averagegbpusddiff3.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (avgjpydifff3 !== null
                              ? avgjpydifff3.toFixed(3) + "%"
                              : "N/A") === highest1ho
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (avgjpydifff3 !== null
                                  ? avgjpydifff3.toFixed(3) + "%"
                                  : "N/A") === highest2ho
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (avgjpydifff3 !== null
                                  ? avgjpydifff3.toFixed(3) + "%"
                                  : "N/A") === highest3ho
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {avgjpydifff3 !== null
                            ? avgjpydifff3.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpjpydiff3 !== null
                            ? averagegbpjpydiff3.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpnzdbyodiff3 !== null
                              ? averagegbpnzdbyodiff3.toFixed(3) + "%"
                              : "N/A") === highest1ho
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff3 !== null
                                  ? averagegbpnzdbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest2ho
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff3 !== null
                                  ? averagegbpnzdbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest3ho
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpnzdbyodiff3 !== null
                            ? averagegbpnzdbyodiff3.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpnzddiff3 !== null
                            ? averagegbpnzddiff3.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpeurbyodiff3 !== null
                              ? averagegbpeurbyodiff3.toFixed(3) + "%"
                              : "N/A") === highest1ho
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff3 !== null
                                  ? averagegbpeurbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest2ho
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff3 !== null
                                  ? averagegbpeurbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest3ho
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpeurbyodiff3 !== null
                            ? averagegbpeurbyodiff3.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpeurdiff3 !== null
                            ? averagegbpeurdiff3.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpchfbyodiff3 !== null
                              ? averagegbpchfbyodiff3.toFixed(3) + "%"
                              : "N/A") === highest1ho
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff3 !== null
                                  ? averagegbpchfbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest2ho
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff3 !== null
                                  ? averagegbpchfbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest3ho
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpchfbyodiff3 !== null
                            ? averagegbpchfbyodiff3.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpchfdiff3 !== null
                            ? averagegbpchfdiff3.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpaudbyodiff3 !== null
                              ? averagegbpaudbyodiff3.toFixed(3) + "%"
                              : "N/A") === highest1ho
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff3 !== null
                                  ? averagegbpaudbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest2ho
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff3 !== null
                                  ? averagegbpaudbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest3ho
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpaudbyodiff3 !== null
                            ? averagegbpaudbyodiff3.toFixed(3) + "%"
                            : "N/A"}
                          
                          <br />
                          ({averagegbpauddiff3 !== null
                            ? averagegbpauddiff3.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 border-r-4 border-[#F6F6F6] ${
                            (averagegbpcadbyodiff3 !== null
                              ? averagegbpcadbyodiff3.toFixed(3) + "%"
                              : "N/A") === highest1ho
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff3 !== null
                                  ? averagegbpcadbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest2ho
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff3 !== null
                                  ? averagegbpcadbyodiff3.toFixed(3) + "%"
                                  : "N/A") === highest3ho
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : null
                          }`}
                        >
                          
                          {averagegbpcadbyodiff3 !== null
                            ? averagegbpcadbyodiff3.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpcaddiff3 !== null
                            ? averagegbpcaddiff3.toFixed(1)
                            : "N/A"})
                          <br />
                        </td>
                      </tr>

                      {/* <2nd row--> */}
                      <tr className="bg-[#AECAF14D] text-center">
                        <td className="py-2    font-bold min-w-[80.8px] " colSpan={4}>
                        Flat event (average movement)
                        </td>
                        {/* <td className="py-2 "></td>
                        <td className="py-2 "></td> */}
                        {/* <td className="py-2 "></td> */}
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>
                        <td className="py-2 "></td>

                        <td className="py-2">
                        0.0000%   <br />
                          (0.0000)
                        
                         
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                        0.0000% <br /> (0.0000)
                         
                         
                        </td>
                        <td className="py-2 border-r-4 border-[#F6F6F6]">
                       
                        0.0000%  <br /> (0.0000)
                        
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averageDifff_flat0 !== null
                              ? averageDifff_flat0.toFixed(3) + "%"
                              : "N/A") === highest1coflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averageDifff_flat0 !== null
                                  ? averageDifff_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2coflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averageDifff_flat0 !== null
                                  ? averageDifff_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3coflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averageDifff_flat0 !== null
                            ? averageDifff_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpusddiff1_flat0 !== null
                            ? averagegbpusddiff1_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          // className={`text-[15px] py-2 ${avgjpydifff_flat0.toFixed(4) + "%" === highest1
                          //   ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                          //   : avgjpydifff_flat0.toFixed(4) + "%" === highest2
                          //     ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                          //     : avgjpydifff_flat0.toFixed(4) + "%" === highest3
                          //       ? "bg-gray-400"
                          //       : null
                          //   }`}
                          className={`text-[15px] py-2 ${
                            (avgjpydifff_flat0 !== null
                              ? avgjpydifff_flat0.toFixed(3) + "%"
                              : "N/A") === highest1coflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (avgjpydifff_flat0 !== null
                                  ? avgjpydifff_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2coflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (avgjpydifff_flat0 !== null
                                  ? avgjpydifff_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3coflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          {/* {averagegbpjpydiff1_flat0.toFixed(4)} <br /> (
                    {avgjpydifff_flat0.toFixed(4) + "%"}) */}
                    
                          {avgjpydifff_flat0 !== null
                            ? avgjpydifff_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpjpydiff1_flat0 !== null
                            ? averagegbpjpydiff1_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpnzdbyodiff1_flat0 !== null
                              ? averagegbpnzdbyodiff1_flat0.toFixed(3) + "%"
                              : "N/A") === highest1coflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff1_flat0 !== null
                                  ? averagegbpnzdbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2coflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff1_flat0 !== null
                                  ? averagegbpnzdbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3coflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpnzdbyodiff1_flat0 !== null
                            ? averagegbpnzdbyodiff1_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpnzddiff1_flat0 !== null
                            ? averagegbpnzddiff1_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpeurbyodiff1_flat0 !== null
                              ? averagegbpeurbyodiff1_flat0.toFixed(3) + "%"
                              : "N/A") === highest1coflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff1_flat0 !== null
                                  ? averagegbpeurbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2coflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff1_flat0 !== null
                                  ? averagegbpeurbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3coflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpeurbyodiff1_flat0 !== null
                            ? averagegbpeurbyodiff1_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpeurdiff1_flat0 !== null
                            ? averagegbpeurdiff1_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpchfbyodiff1_flat0 !== null
                              ? averagegbpchfbyodiff1_flat0.toFixed(3) + "%"
                              : "N/A") === highest1coflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff1_flat0 !== null
                                  ? averagegbpchfbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2coflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff1_flat0 !== null
                                  ? averagegbpchfbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3coflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpchfbyodiff1_flat0 !== null
                            ? averagegbpchfbyodiff1_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpchfdiff1_flat0 !== null
                            ? averagegbpchfdiff1_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpaudbyodiff1_flat0 !== null
                              ? averagegbpaudbyodiff1_flat0.toFixed(3) + "%"
                              : "N/A") === highest1coflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff1_flat0 !== null
                                  ? averagegbpaudbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2coflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff1_flat0 !== null
                                  ? averagegbpaudbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3coflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpaudbyodiff1_flat0 !== null
                            ? averagegbpaudbyodiff1_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpauddiff1_flat0 !== null
                            ? averagegbpauddiff1_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2  border-r-4 border-[#F6F6F6] ${
                            (averagegbpcadbyodiff1_flat0 !== null
                              ? averagegbpcadbyodiff1_flat0.toFixed(3) + "%"
                              : "N/A") === highest1coflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff1_flat0 !== null
                                  ? averagegbpcadbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2coflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff1_flat0 !== null
                                  ? averagegbpcadbyodiff1_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3coflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpcadbyodiff1_flat0 !== null
                            ? averagegbpcadbyodiff1_flat0.toFixed(3) + "%"
                            : "N/A"}
                          <br />
                          ({averagegbpcaddiff1_flat0 !== null
                            ? averagegbpcaddiff1_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averageDifff2_flat0 !== null
                              ? averageDifff2_flat0.toFixed(3) + "%"
                              : "N/A") === highest1loflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averageDifff2_flat0 !== null
                                  ? averageDifff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2loflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averageDifff2_flat0 !== null
                                  ? averageDifff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3loflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averageDifff2_flat0 !== null
                            ? averageDifff2_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpusddiff2_flat0 !== null
                            ? averagegbpusddiff2_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (avgjpydifff2_flat0 !== null
                              ? avgjpydifff2_flat0.toFixed(3) + "%"
                              : "N/A") === highest1loflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (avgjpydifff2_flat0 !== null
                                  ? avgjpydifff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2loflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (avgjpydifff2_flat0 !== null
                                  ? avgjpydifff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3loflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {avgjpydifff2_flat0 !== null
                            ? avgjpydifff2_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpjpydiff2_flat0 !== null
                            ? averagegbpjpydiff2_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpnzdbyodiff2_flat0 !== null
                              ? averagegbpnzdbyodiff2_flat0.toFixed(3) + "%"
                              : "N/A") === highest1loflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff2_flat0 !== null
                                  ? averagegbpnzdbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2loflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff2_flat0 !== null
                                  ? averagegbpnzdbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3loflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpnzdbyodiff2_flat0 !== null
                            ? averagegbpnzdbyodiff2_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpnzddiff2_flat0 !== null
                            ? averagegbpnzddiff2_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpeurbyodiff2_flat0 !== null
                              ? averagegbpeurbyodiff2_flat0.toFixed(3) + "%"
                              : "N/A") === highest1loflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff2_flat0 !== null
                                  ? averagegbpeurbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2loflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff2_flat0 !== null
                                  ? averagegbpeurbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3loflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpeurbyodiff2_flat0 !== null
                            ? averagegbpeurbyodiff2_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpeurdiff2_flat0 !== null
                            ? averagegbpeurdiff2_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpchfbyodiff2_flat0 !== null
                              ? averagegbpchfbyodiff2_flat0.toFixed(3) + "%"
                              : "N/A") === highest1loflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff2_flat0 !== null
                                  ? averagegbpchfbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2loflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff2_flat0 !== null
                                  ? averagegbpchfbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3loflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpchfbyodiff2_flat0 !== null
                            ? averagegbpchfbyodiff2_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpchfdiff2_flat0 !== null
                            ? averagegbpchfdiff2_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpaudbyodiff2_flat0 !== null
                              ? averagegbpaudbyodiff2_flat0.toFixed(3) + "%"
                              : "N/A") === highest1loflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff2_flat0 !== null
                                  ? averagegbpaudbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2loflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff2_flat0 !== null
                                  ? averagegbpaudbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3loflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpaudbyodiff2_flat0 !== null
                            ? averagegbpaudbyodiff2_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpauddiff2_flat0 !== null
                            ? averagegbpauddiff2_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 border-r-4 border-[#F6F6F6] ${
                            (averagegbpcadbyodiff2_flat0 !== null
                              ? averagegbpcadbyodiff2_flat0.toFixed(3) + "%"
                              : "N/A") === highest1loflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff2_flat0 !== null
                                  ? averagegbpcadbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2loflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff2_flat0 !== null
                                  ? averagegbpcadbyodiff2_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3loflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpcadbyodiff2_flat0 !== null
                            ? averagegbpcadbyodiff2_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpcaddiff2_flat0 !== null
                            ? averagegbpcaddiff2_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averageDifff3_flat0 !== null
                              ? averageDifff3_flat0.toFixed(3) + "%"
                              : "N/A") === highest1hoflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averageDifff3_flat0 !== null
                                  ? averageDifff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2hoflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averageDifff3_flat0 !== null
                                  ? averageDifff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3hoflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averageDifff3_flat0 !== null
                            ? averageDifff3_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpusddiff3_flat0 !== null
                            ? averagegbpusddiff3_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (avgjpydifff3_flat0 !== null
                              ? avgjpydifff3_flat0.toFixed(3) + "%"
                              : "N/A") === highest1hoflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (avgjpydifff3_flat0 !== null
                                  ? avgjpydifff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2hoflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (avgjpydifff3_flat0 !== null
                                  ? avgjpydifff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3hoflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {avgjpydifff3_flat0 !== null
                            ? avgjpydifff3_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpjpydiff3_flat0 !== null
                            ? averagegbpjpydiff3_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>

                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpnzdbyodiff3_flat0 !== null
                              ? averagegbpnzdbyodiff3_flat0.toFixed(3) + "%"
                              : "N/A") === highest1hoflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff3_flat0 !== null
                                  ? averagegbpnzdbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2hoflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpnzdbyodiff3_flat0 !== null
                                  ? averagegbpnzdbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3hoflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpnzdbyodiff3_flat0 !== null
                            ? averagegbpnzdbyodiff3_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpnzddiff3_flat0 !== null
                            ? averagegbpnzddiff3_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpeurbyodiff3_flat0 !== null
                              ? averagegbpeurbyodiff3_flat0.toFixed(3) + "%"
                              : "N/A") === highest1hoflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff3_flat0 !== null
                                  ? averagegbpeurbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2hoflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpeurbyodiff3_flat0 !== null
                                  ? averagegbpeurbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3hoflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpeurbyodiff3_flat0 !== null
                            ? averagegbpeurbyodiff3_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpeurdiff3_flat0 !== null
                            ? averagegbpeurdiff3_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpchfbyodiff3_flat0 !== null
                              ? averagegbpchfbyodiff3_flat0.toFixed(3) + "%"
                              : "N/A") === highest1hoflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff3_flat0 !== null
                                  ? averagegbpchfbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2hoflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpchfbyodiff3_flat0 !== null
                                  ? averagegbpchfbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3hoflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpchfbyodiff3_flat0 !== null
                            ? averagegbpchfbyodiff3_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpchfdiff3_flat0 !== null
                            ? averagegbpchfdiff3_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 ${
                            (averagegbpaudbyodiff3_flat0 !== null
                              ? averagegbpaudbyodiff3_flat0.toFixed(3) + "%"
                              : "N/A") === highest1hoflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff3_flat0 !== null
                                  ? averagegbpaudbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2hoflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpaudbyodiff3_flat0 !== null
                                  ? averagegbpaudbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3hoflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpaudbyodiff3_flat0 !== null
                            ? averagegbpaudbyodiff3_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpauddiff3_flat0 !== null
                            ? averagegbpauddiff3_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                        <td
                          className={`text-[15px] py-2 border-r-4 border-[#F6F6F6] ${
                            (averagegbpcadbyodiff3_flat0 !== null
                              ? averagegbpcadbyodiff3_flat0.toFixed(3) + "%"
                              : "N/A") === highest1hoflat
                              ? "bg-[#4EA8D2]   text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff3_flat0 !== null
                                  ? averagegbpcadbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest2hoflat
                              ? "bg-[#77C9EF] text-[#170E32] font-semibold"
                              : (averagegbpcadbyodiff3_flat0 !== null
                                  ? averagegbpcadbyodiff3_flat0.toFixed(3) + "%"
                                  : "N/A") === highest3hoflat
                              ? "bg-[#A9ECFF] text-[#170E32] font-semibold"
                              : ""
                          }`}
                        >
                          
                          {averagegbpcadbyodiff3_flat0 !== null
                            ? averagegbpcadbyodiff3_flat0.toFixed(3) + "%"
                            : "N/A"}
                           <br />
                          ({averagegbpcaddiff3_flat0 !== null
                            ? averagegbpcaddiff3_flat0.toFixed(3)
                            : "N/A"})
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
               {/* <-------------highest color codes pics-----------> */}
           <div className="flex flex-col justify-end sm:flex-col md:flex-row lg:flex-row xl:flex-row ">
            <div className="bg-[#fff] p-4 mx-0 sm:mx-0 md:mx-10 lg:mx-10 xl:mx-12  my-8 py-4  items-center   flex justify-start flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
              
              <div className="sm:flex-col flex-col md:flex-row lg:flex-row xl:flex-row flex mt-[5%] sm:mt-[5%] md:mt-[5%] lg:mt-[0%] xl:mt-[0%]">
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    Highest Average:
                  </h1>
                  <img
                    src={color1}
                    title="color"
                    alt="color"
                    className="ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    {" "}
                    2nd Highest Average:
                  </h1>
                  <img
                    src={color2}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                    {" "}
                    3rd Highest Average:
                  </h1>
                  <img
                    src={color3}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />  
                  <span className="ml-[8px]">,</span>
                </div>
              </div>

              <div className="sm:flex-col flex-col md:flex-row lg:flex-row xl:flex-row flex mt-[5%] sm:mt-[5%] md:mt-[5%] lg:mt-[0%] xl:mt-[0%]">
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                   
                  {"Positive values :"} 
                  </h1>
                  <img
                    src={color7}
                    title="color"
                    alt="color"
                    className="ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                  
                   {"Flat values:"}
                  </h1>
                  <img
                    src={color8}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  />
                  {/* <span className="font-bold ml-2">,</span> */}
                </div>
                <div className="flex items-center my-2 sm:my-2 md:my-0 lg:my-0 xl:my-0">
                  <h1 className="font-bold text-[#170E32] pl-0 sm:pl-0 md:pl-6 lg:pl-6 xl:pl-6  md:text-[14px] lg:text-[14px] xl:text-[14px]">
                   
                    {/* <300 %: */}
                    {"Negative values:"}
                  </h1>
                  <img
                    src={color9}
                    title="color"
                    alt="color"
                    className="ml-0 sm:ml-0 md:ml-4 lg:ml-4 xl:ml-4 w-[25px] h-[25px]"
                  /> 
                </div>
              </div>
            </div>
          </div>
            </>
          )}
        </div>
      ) : // <----------if no data available in backend ( 400 error)---------------->
      !Loading && errorMessage ? (
        <div className="font-semibold m-10 text-xl text-center flex justify-center items-center">
          <p className="error ">{errorMessage}</p>
          <span className="error pl-2 font-bold">{eventname_forerror} ...</span>
          <button
            className={`px-2  mx-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75  bg-slate-500 hover:bg-gray-800
                    `}
            value="Go Back "
            onClick={handlegoback}
          >
            Go Back
          </button>
        </div>
      ) : (
        <div>
          <div className="font-semibold m-6 font-xl text-center flex justify-center items-center ">
            Wait !! Data is in Progress .....
          </div>
          <div className="flex items-center justify-center  w-screen ">
            <Bars
              height="80"
              width="80"
              color="#94A3B8"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              className="loader"
            />
          </div>
        </div>
      )}

      {/* { !Loading && errorMessage && <div className="font-semibold m-6 font-xl text-center flex justify-center items-center h-[50%]"> <p className="error ">{errorMessage}</p> </div>} */}
      <div>
        {modalShow && (
          <div
            id="popup-modal"
            className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center bg-black bg-opacity-15 z-50"
            onClick={() => setModalshow(!modalShow)}
          >
            <div className="relative p-4 w-full max-w-md">
              <div className="relative bg-white rounded-lg shadow">
                {/* <button
                  type="button"
                  className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg  w-8 h-8 inline-flex justify-center items-center"
                  data-modal-hide="popup-modal"
                  onClick={() => setModalshow(!modalShow)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      onClick={() => setModalshow(!modalShow)}
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button> */}

<img src={logout_eye_btn} alt="logout_eye_btn" className="h-[33px] w-[33px] absolute top-[12%] left-[47%]"/>
                <div className="p-4 md:p-5 text-center">
                  {/* <div className="flex justify-center items-center mt-5">
                                   <CiCircleInfo size={40} color='grayZ'/>
                                </div> */}

                  <h3 className="mb-5 mt-[15%] text-lg font-bold text-black">
                  Are you sure you want to refresh the page? This action will clear all locally stored data.
                  </h3>
                  <div className="flex items-center justify-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="text-[#151841]  bg-[#ecebeb] mt-3 font-medium rounded-lg  inline-flex items-center justify-center px-3 py-2 w-[20%]"
                      onClick={handleRefresh}
                    >
                      Yes
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="ml-4 text-white  bg-[#151841] mt-3 font-medium rounded-lg  inline-flex items-center justify-center px-3 py-2 w-[20%]"
                      onClick={() => setModalshow(!modalShow)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EventshistoryUser;
