import React, { useEffect, useState } from "react";
import styles from "../../components/tradeall.module.css";
import axios from "axios";
import UserNavbar from "../../components/user/userNavbar";
import { toast } from "react-toastify";
import RingLoader from "react-spinners/RingLoader";
import Selects from "react-select";
import { FiCheckCircle } from "react-icons/fi";
import { LineWave } from "react-loader-spinner";
import { IoIosEye } from "react-icons/io";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
// import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import filtericon from "../../assets/filters.png";
import { Select, Space } from "antd";
// import { Select } from "antd";
import { DatePicker } from "antd";
import { DownOutlined } from "@ant-design/icons";
// import { Space } from "antd";
const { RangePicker } = DatePicker;

const Savedtrade = () => {
  const [savedtradedatavalues, setSavedtradedatavalues] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Loading1, setLoading1] = useState(false);
  const [refetchData, setRefetchData] = useState(false);

  const [tradeUpdates, setTradeUpdates] = useState([]);
  const [sendUpdatedItemToBackend, set_SendUpdatedItemToBackend] = useState([]);

  const [dateRange, setDateRange] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);

  const [tradelogchange, setTradelogchange] = useState([]);
  const { Option } = Select;

  const currencies = ["GBP", "USD", "JPY", "EUR", "CAD", "AUD", "NZD"];
  const countries = [
    "UK",
    "US",
    "CA",
    "CH",
    "EU",
    "JP",
    "AU",
    "NZ",
    "DE",
    "IT",
    "FR",
  ];
  const tradesarray = ["Yes", "No"];


  const savedtradedata = async () => {
    try {
      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId");
      const savedresponse = await axios.get(
        `${process.env.REACT_APP_URL}/user/getsavedtradingdata/${userid}`,
        { headers: h }
      );
      setLoading(false);
      if (savedresponse.status === 200) {
        const latestDocuments = savedresponse.data.latestDocuments;

        let allTrades = [];

        latestDocuments.forEach((doc) => {
          allTrades = allTrades.concat(doc.trades);
        });
        setSavedtradedatavalues(allTrades);
      }

      if (savedresponse.status === 404) {
        toast.error("No data found in history.", { position: "top-right" });
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const sortedsavedData = [...savedtradedatavalues].sort((a, b) => {
    const dateComparison = new Date(b.date) - new Date(a.date);

    // If dates are the same, compare by time
    if (dateComparison === 0) {
      return (
        new Date(`1970-01-01T${b.time}`) - new Date(`1970-01-01T${a.time}`)
      );
    }

    return dateComparison;
  });

  useEffect(() => {
    savedtradedata();
  }, [refetchData]);

  useEffect(() => {
    if (refetchData) {
      setRefetchData(false);
    }
  }, [refetchData]);

  const trade_log = [
    { value: "Profit", label: "Profit" },
    { value: "Loss", label: "Loss" },
    { value: "N.A", label: "N.A" },
  ];

  const prediction = [
    { value: "Correct", label: "Correct" },
    { value: "Wrong", label: "Wrong" },
    { value: "N.A", label: "N.A" },
  ];

  const customStyles = {
    option: (provided, { data, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? "#f0f0f0"
        : isSelected
        ? "#F6F6F6"
        : "#F6F6F6",
      color:
        data.value === "Correct"
          ? "green"
          : data.value === "Wrong"
          ? "red"
          : data.value === "N.A"
          ? "#c78c06"
          : "black",
    }),
    control: (provided) => ({
      ...provided,
      minWidth: "145px",
      backgroundColor: "#F6F6F6",
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color:
        data.value === "Correct"
          ? "green"
          : data.value === "Wrong"
          ? "red"
          : data.value === "N.A"
          ? "#c78c06"
          : "black",
    }),
  };
  
  const customStyles1 = {
    option: (provided, { data, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? "#f0f0f0"
        : isSelected
        ? "#F6F6F6"
        : "#F6F6F6",
      color:
        data.value === "Profit"
          ? "green"
          : data.value === "Loss"
          ? "red"
          : data.value === "N.A"
          ? "#c78c06"
          : "black",
      // color: 'white',
      // ':hover': {
      //     backgroundColor:
      //         data.value === 'Correct' ? 'darkgreen' :
      //         data.value === 'Wrong' ? 'darkred' :
      //         data.value === 'N.A' ? 'gold' : 'lightgray',
      //     color: 'white'
      // },
    }),
    control: (provided) => ({
      ...provided,
      minWidth: "145px",
      backgroundColor: "#F6F6F6",
    }),
    singleValue: (provided, { data }) => ({
      ...provided,
      color:
        data.value === "Profit"
          ? "green"
          : data.value === "Loss"
          ? "red"
          : data.value === "N.A"
          ? "#c78c06"
          : "black",
    }),
  };

  const handleSelectChange = (
    tradeid,
    fieldName,
    selectedValue,
    fullTradeData
  ) => {
    const value = selectedValue?.value || selectedValue || null;
    // console.log("value" ,value)
    // console.log("fullTradeData: " + fullTradeData);
    // console.log("hlo select", tradeid, fieldName, selectedValue);

    setSavedtradedatavalues((prevState) => {
      // Find the existing item by tradeid
      const existingItemIndex = prevState.findIndex(
        (selectedItem) => selectedItem._id === tradeid // Ensure you are checking the correct key
      );

      // console.log("existingItemIndex", existingItemIndex);

      if (existingItemIndex !== -1) {
        // Update existing entry
        const updatedItem = {
          ...prevState[existingItemIndex],
          [fieldName]: value, // Ensure fieldName is set correctly
          date: fullTradeData.date,
          time: fullTradeData.time,
          country: fullTradeData.country,
          event: fullTradeData.event,
          currency: fullTradeData.currency,
        };

        if (
          JSON.stringify(prevState[existingItemIndex]) !==
          JSON.stringify(updatedItem)
        ) {
          set_SendUpdatedItemToBackend((prevItems) => {
            const itemExists = prevItems.find(
              (item) => item._id === updatedItem._id
            );

            return itemExists
              ? prevItems.map((item) =>
                  item._id === updatedItem._id ? updatedItem : item
                )
              : [...prevItems, updatedItem];
          });
        }

        const updatedState = prevState.map((selectedItem, index) => {
          return index === existingItemIndex ? updatedItem : selectedItem;
        });

        return updatedState;
      } else {
        const newItem = {
          _id: tradeid,
          [fieldName]: value,
          date: fullTradeData.date,
          time: fullTradeData.time,
          country: fullTradeData.country,
          event: fullTradeData.event,
          currency: fullTradeData.currency,
        };

        return [...prevState, newItem];
      }
    });
  };

  useEffect(() => {}, [savedtradedatavalues]);

  const handleInputChange = (tradeid, fieldName, value, fullTradeData) => {
    console.log("hlo inputs", tradeid, fieldName, value);
    console.log("fullTradeData", fullTradeData);
    setSavedtradedatavalues((prevState) => ({
      ...prevState,

      [tradeid]: {
        ...prevState[tradeid],
        [fieldName]: value,
        date: fullTradeData.date,
        time: fullTradeData.time,
        country: fullTradeData.country,
        event: fullTradeData.event,
        currency: fullTradeData.currency,
      },
    }));
  };

  const updateTradeData = async () => {
    try {
      setLoading1(true);
      const userid = localStorage.getItem("userregisterId");

      if (savedtradedatavalues.length === 0) {
        console.error("No saved trade data to update.");
        return;
      }

      const updatePromises = sendUpdatedItemToBackend.map(async (trade) => {
        const tradeid = trade._id;

        const apiUrl = `${process.env.REACT_APP_URL}/user/updatesavedtradingdata/${userid}/${tradeid}/${trade.date}`;
        const updateFields = {
          prediction: trade.prediction,
          trade_log: trade.trade_log,
          trade_amount: trade.trade_amount,
          trade_time: trade.trade_time,
          trade_comment: trade.trade_comment,
        };

        try {
          const response = await axios.put(apiUrl, updateFields);
          if (
            response.data.message === "Trade updated successfully" &&
            response.status === 200
          ) {
            return true;
          }
        } catch (error) {
          console.error("Error updating trade:", error);
        }
        return false;
      });

      const results = await Promise.all(updatePromises);
      const tradesUpdated = results.filter((result) => result).length;

      if (tradesUpdated > 0) {
        toast.success("Trades updated successfully!", {
          position: "top-right",
        });
        setTimeout(() => {
          toast.info("Reloading page .....", { position: "top-right" });
          window.location.reload();
        }, 1000);
      } else {
        toast.info("No trades were updated.", { position: "top-right" });
      }

      savedtradedata();
      setRefetchData(true);
    } catch (error) {
      console.error("Error updating trades:", error);
    } finally {
      setLoading1(false);
    }
  };

  // const handleCountryChange = (event) => {
  //   setSelectedCountry(event.target.value);
  // };

  // const handleCurrencyChange = (event) => {
  //   setSelectedCurrency(event.target.value);
  // };

  // const handleTradelogChange = (event) => {
  //   setTradelogchange(event.target.value);
  // };
  const handleCountryChange = (values) => {

    setSelectedCountry(values);
  };
  
  const handleCurrencyChange = (values) => {
  
    setSelectedCurrency(values);
  };
  
  
  
  const handleTradelogChange = (values)=>{
  setTradelogchange(values)
  }

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // const handleDateChange = async () => {
  //   console.log("startDate", startDate);
  //   console.log("endDate", endDate);
  //   if (!startDate || !endDate) {
  //     setStartDate("");
  //     setEndDate("");
  //     savedtradedata();
  //     setLoading(false);
  //     console.error("Both start and end dates are required");
  //     return;
  //   }
  //   setLoading(true);
  //   const userid = localStorage.getItem("userregisterId");

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_URL}/user/datepicker_fromsavedtrade`,
  //       { startDate, endDate, userid }
  //     );
  //     const latestDocument = response.data;
  //     let allTrades = [];

  //     latestDocument.forEach((doc) => {
  //       allTrades = allTrades.concat(doc.trades);
  //     });
  //     setSavedtradedatavalues(allTrades);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);

  //     console.error("Error fetching data:", error);
  //   }
  // };
  // const handleStartDateChange = (e) => {
  //   setStartDate(e.target.value);
  // };

  // const handleEndDateChange = (e) => {
  //   setEndDate(e.target.value);
  // };
 
 
 
  const handleDateChange = async(dates, dateStrings) => {
    setLoading(true);
    const userid = localStorage.getItem("userregisterId")
    // const tokenfromls = localStorage.getItem("token");
    // const h = { authorization: tokenfromls };
    const [startDate, endDate] = dateStrings;
    if (!dates) {
      setDateRange(null);
      // setSavedtradedatavalues([]); 
      savedtradedata();
      setLoading(false);
      return; 
    }
    try{
      const response = await axios.post(`${process.env.REACT_APP_URL}/user/datepicker_fromsavedtrade` ,  { startDate, endDate  , userid} , 
          // { headers: h }
        );
     const latestDocument = response.data
     let allTrades = [];
  
     latestDocument.forEach((doc) => {
       allTrades = allTrades.concat(doc.trades);
     });
     setSavedtradedatavalues(allTrades);
     setLoading(false);
     // console.log("allTrades", allTrades);
     
     
    
      
      // console.log("response.data" , response.data)
    }
      catch(error) {
       
        setLoading(false);
       
        console.error("Error fetching data:", error);
      };
      setDateRange(dates);
  };
  
 
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    // console.log("e.target.value" , e.target.value)
  };

  const filteredEventsbysearch = sortedsavedData.filter((event) => {
    return (
      event.event &&
      event.event.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 6;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      {Loading ? (
        <div className="flex items-center justify-center  mt-10 w-full">
          <RingLoader
            height="80"
            width="80"
            color="#94A3B8"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            className=" flex items-center justify-center  mt-10 w-[100%]"
          />
        </div>
      ) : (
        <>
          <UserNavbar />
          <div className="max-w-[100%] mx-[4%] ">
            {sortedsavedData.length === 0 ? (
              <div
                className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl `}
              >
                Sorry!! you haven't saved any data yet .
              </div>
            ) : (
              <>
                <h1 className={` ${styles.historyheading} font-bold fixed w-full text-[#151841] m-2 text-[24px] sm:text-[24px] md:text-[32px] mt-[26%] sm:mt-[26%] md:mt-[12%] lg:mt-[8%] xl:mt-[5%] `}>
                  History
                </h1>

                <div
                  className={` ${styles.saved_haeder} fixed w-[50%] sm:w-[50%]  md:w-[70%] lg:w-[90%] xl:w-[100%] mt-[16%] sm:mt-[16%] md:mt-[16%] lg:mt-[12%] xl:mt-[8%] flex-col sm:flex-col md:flex-col  lg:flex-col xl:flex-row flex md:ml-[-13px] xl:ml-0 `}
                >
                  <div
                    className={`${styles.saved_haeder1} flex justify-center items-start sm:items-start md:items-start lg:items-start xl:items-center  sm:flex-col md:flex-col flex-col lg:flex-col xl:flex-row`}
                  >
                    <div className="pl-[0px] sm:pl-[0px] md:pl-[12px] lg:pl-[14px] xl:pl-0">
                      <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block pr-0 sm:pr-3 md:pr-3 lg:pr-3 xl:pr-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
                        {/* <Space
                          style={{
                            width: "100%",
                            paddingTop: 0,
                            height: "46px",
                          }}
                          direction="vertical"
                        >
                          <FormControl
                            sx={{ m: 1, width: 200, borderColor: "#170E32" }}
                          >
                            <InputLabel id="country-select-label">
                              <span style={{ color: "#666666" }}>Country</span>
                            </InputLabel>
                            <Select
                              labelId="country-select-label"
                              id="country-select"
                              multiple
                              value={selectedCountry}
                              onChange={handleCountryChange}
                              input={
                                <OutlinedInput
                                  label="Country"
                                  sx={{ height: 46 }}
                                />
                              }
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              sx={{ height: 46 }}
                            >
                              {countries.map((option) => (
                                <MenuItem key={option} value={option}>
                                  <Checkbox
                                    checked={selectedCountry.includes(option)}
                                  />
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Space> */}
  <Space style={{ width: "100%" }} direction="vertical" className="z-[999] border-[#170E32] ">
      <Select
        mode="multiple"
        allowClear
        style={{
          width: "270px",
          height: "46px",
          maxHeight: "46px",
          backgroundColor: "#F6F6F6", // Grey background
          border: "1px solid #170E32", // Light grey border
          borderRadius: "4px",
        }}
        placeholder={<span style={{ color: "#170E32", fontSize: "16px" }}>Country</span>}
        onChange={handleCountryChange}
        getPopupContainer={(triggerNode) => document.body}
        className="z-[999] custom-select overflow-visible"
        dropdownStyle={{
          maxHeight: "800px",
          overflowY: "auto", // Adds vertical scrollbar for dropdown options
        }}
        suffixIcon={
          <DownOutlined
            style={{
              fontSize: "14px",
              color: "#170E32", // Change icon color here
            }}
          />
        }
        maxTagCount={1} // Only show one country in the select box
        maxTagTextLength={20} // Adjust max length if necessary
      >
        <Option value="UK">United Kingdom (UK)</Option>
        <Option value="US">United States (US)</Option>
        <Option value="CA">Canada (CA)</Option>
        <Option value="CH">Switzerland (CH)</Option>
        <Option value="EU">Europe (EU)</Option>
        <Option value="JP">Japan (JP)</Option>
        <Option value="AU">Australia (AU)</Option>
        <Option value="NZ">New Zealand (NZ)</Option>
        <Option value="DE">Germany (DE)</Option>
        <Option value="IT">Italy (IT)</Option>
        <Option value="FR">France (FR)</Option>
      </Select>
                         </Space>
                      </div>

                      <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
                        {/* <Space
                          style={{ width: "100%", paddingTop: 0 }}
                          direction="vertical"
                        >
                          <FormControl
                            sx={{ m: 1, width: 200, borderColor: "#170E32" }}
                          >
                            <InputLabel id="currency-select-label">
                              <span style={{ color: "#666666" }}>Currency</span>
                            </InputLabel>
                            <Select
                              labelId="currency-select-label"
                              id="currency-select"
                              multiple
                              value={selectedCurrency}
                              onChange={handleCurrencyChange}
                              input={
                                <OutlinedInput
                                  label="Country"
                                  sx={{ height: 46 }}
                                />
                              }
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              sx={{ height: 46 }}
                            >
                              {currencies.map((option) => (
                                <MenuItem key={option} value={option}>
                                  <Checkbox
                                    checked={selectedCurrency.includes(option)}
                                  />
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Space> */}
                          <Space style={{ width: "100%" }} direction="vertical"  className="z-[999] border-[#170E32] ">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{
                              width: "270px",
                              height: "46px",
                              maxHeight: "46px",
                              backgroundColor: "#F6F6F6", // Grey background
                              border: "1px solid #170E32", // Light grey border
                              borderRadius: "4px",
                            }}
                            placeholder={
                              <span style={{ color: "#170E32", fontSize: "16px" }}>Currency</span>
                            }
                            onChange={handleCurrencyChange}
                            // getPopupContainer={(trigger) => trigger.parentNode}
                            getPopupContainer={(triggerNode) => document.body}
                            className="z-[999] custom-select overflow-visible"
                            dropdownStyle={{
                              maxHeight: "800px",
                              overflowY: "auto", // Adds vertical scrollbar for dropdown options
                            }}
                            suffixIcon={
                              <DownOutlined
                                style={{
                                  fontSize: "14px",
                                  color: "#170E32", // Change icon color here
                                }}
                              />
                            }
                            maxTagCount={3} // Only show one country in the select box
                            maxTagTextLength={20} // Adjust max length if necessary
                          >
                            <Option value="GBP">GBP</Option>
                            <Option value="USD">USD</Option>
                            <Option value="JPY">JPY</Option>
                            <Option value="EUR">EUR</Option>
                            <Option value="CAD">CAD</Option>
                            <Option value="AUD">AUD</Option>
                            <Option value="NZD">NZD</Option>
                          </Select>
                        </Space>
                      </div>
                    </div>

                    <div className={`flex justify-center items-center mt-0 md:mt-[6px] lg:mt-[6px] xl:mt-0`} >
                      <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3  mb-4 sm:mb-4 md:mb-4 lg:mb-0 xl:mb-0 ">
                        {/* <Space
                          style={{ width: "100%", paddingTop: 0 }}
                          direction="vertical"
                        >
                          <FormControl
                            sx={{ m: 1, width: 200, borderColor: "#170E32" }}
                          >
                            <InputLabel id="trade-select-label">
                              <span style={{ color: "#666666" }}>Trade</span>
                            </InputLabel>
                            <Select
                              labelId="trade-select-label"
                              id="trade-select"
                              multiple
                              value={tradelogchange}
                              onChange={handleTradelogChange}
                              input={
                                <OutlinedInput
                                  label="Country"
                                  sx={{ height: 46 }}
                                />
                              }
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              sx={{ height: 46 }}
                            >
                              {tradesarray.map((option) => (
                                <MenuItem key={option} value={option}>
                                  <Checkbox
                                    checked={tradelogchange.includes(option)}
                                  />
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Space> */}
                          <Space style={{ width: "100%" }} direction="vertical"  className="z-[999] border-[#170E32] ">
                          <Select
                            mode="multiple"
                            allowClear
                            style={{
                              // width: "270px",
                              height: "46px",
                              maxHeight: "46px",
                              backgroundColor: "#F6F6F6", // Grey background
                              border: "1px solid #170E32", // Light grey border
                              borderRadius: "4px",
                            }}
                            placeholder={
                              <span style={{ color: "#170E32", fontSize: "16px" }}>Trade</span>
                            }
                            onChange={handleTradelogChange}
                            // getPopupContainer={(trigger) => trigger.parentNode}
                            getPopupContainer={(triggerNode) => document.body}
                            className="w-[190px] sm:w-[190px] md:w-[270px] lg:w-[270px] xl:w-[270px] z-[999] custom-select overflow-visible"
                            dropdownStyle={{
                              maxHeight: "800px",
                              overflowY: "auto", // Adds vertical scrollbar for dropdown options
                            }}
                            suffixIcon={
                              <DownOutlined
                                style={{
                                  fontSize: "14px",
                                  color: "#170E32", // Change icon color here
                                }}
                              />
                            }
                            maxTagCount={3} // Only show one country in the select box
                            maxTagTextLength={20} // Adjust max length if necessary
                          >
                            <Option value="Yes">YES</Option>
                            <Option value="No">NO</Option>
                          
                          </Select>
                        </Space>
                      </div>
                      <div
                        className={` ${styles.search} w-[280px] h-[46px]  relative block sm:block md:inline-block lg:inline-block xl:inline-block px-3 sm:px-3 md:px-3 lg:px-2 xl:px-2  `}
                      >
                        <input
                          type="text"
                          placeholder="Search Events"
                          className="rounded-[4px]  placeholder-[#170E32]  pl-4 bg-[#F6F6F6] h-[48px] w-[50%] sm:w-[50%] md:w-[76%] lg:w-full xl:w-full p-1 placeholder:text-left  border-[#170E32] border  focus:outline-none  "
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={` ${styles.saved_haeder3} range-picker items-center  text-center flex mt-[-4px] sm:mt-[-4px] md:mt-[2px] ml-[0%] sm:ml-[0%] md:ml-[0%] lg:ml-0 xl:ml-0 pl-0 sm:pl-0 md:pl-[20px] pr-4  `}
                    style={{ height: "56px" }}
                  >
                      <Space direction="vertical" size={16}>
                        {/* <RangePicker onChange={handleDateChange}/> */}
                        <RangePicker 
                        value={dateRange} 
                        onChange={(dates, dateStrings) => handleDateChange(dates, dateStrings)} 
                            className="text-[16px] h-[48px] w-[332px] sm:w-[332px] md:w-[270px] lg:w-[270px] xl:w-[270px]   border-[#170E32] border active:outline-none bg-[#F6F6F6] focus:outline-none  rounded-md placeholder-[#170E32] custom-rangepicker "
                      />
                      </Space>
                  </div>
                </div>

                <div
                  className={`${styles.saved_table} fixed  w-[90%] overflow-auto mt-[100%] sm:mt-[100%] md:mt-[44%] lg:mt-[27%] xl:mt-[12%]`}
                >
                  <div className={`flex justify-end items-center mb-5  `}>
                    <h1
                      className="cursor-pointer text-[16px] font-semibold bg-[#151841] text-white rounded-[8px] py-2 px-6"
                      title="Click here to save your trade"
                      onClick={updateTradeData}
                    >
                      Save Changes
                    </h1>
                    {/* <FiCheckCircle className="cursor-pointer" title="Save your data" /> */}
                  </div>
                  <div className="overflow-auto">
                    <div className="overflow-x-auto overflow-y-auto  max-h-[40vh] sm:max-h-[40vh] md:max-h-[50vh] lg:max-h-[56vh] xl:max-h-[64vh]">
                      <>
                        <table
                          className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}
                        >
                          <thead
                            key={2}
                            className="bg-[#d0dcf4]   key={1} text-black  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]"
                          >
                            <tr className="h-[96px]">
                              <th className="sticky top-0 py-2 px-4">S. No.</th>
                              <th className=" sticky top-0 py-2 px-4">Date</th>
                              <th className=" sticky top-0 py-2 px-4">
                                Time(GMT)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Country
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Currency
                              </th>
                              <th className="sticky top-0 py-2 px-4">Event</th>
                              <th className="sticky top-0 py-2 px-4">Trade</th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade Grade
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Investing.com Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trading view Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trading Economics Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Movement (Currency)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Movement (Correlation)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Flat (Currency)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Flat (Correlation)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Comments
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Prediction
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade log(profit/loss)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade Amount
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade in time(seconds)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade comment
                              </th>
                            </tr>
                          </thead>

                          <tbody className="bg-[#F6F6F6]  z-0">
                            {sortedsavedData
                              .filter(
                                (item) =>
                                  (selectedCountry.length > 0
                                    ? selectedCountry.includes(item.country)
                                    : true) &&
                                  (selectedCurrency.length > 0
                                    ? selectedCurrency.includes(item.currency)
                                    : true) &&
                                  (filteredEventsbysearch.length > 0
                                    ? filteredEventsbysearch.some(
                                        (filteredItem) =>
                                          filteredItem.event === item.event
                                      )
                                    : true) &&
                                  (tradelogchange.length > 0
                                    ? tradelogchange.includes(item.trade)
                                    : true)
                              )

                              .map((item, index) => {
                                return (
                                  <>
                                    <tr
                                      key={item._id}
                                      className="cursor-pointer  text-center bg-white  border-y-[16px] border-solid border-[#F6F6F6] "
                                    >
                                      <td className="py-1 px-2 min-w-[80px]">
                                        {index + 1}.
                                      </td>
                                      <td className="py-1 px-2">{item.date}<br/>{item._id}</td>
                                      {/* <td className="py-1 px-2">{item.time}</td> */}
                                      <td className="py-1 px-2">{item.time.slice(0, 5)}</td>
                                      <td className="py-1 px-2">
                                        {item.country}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.currency}
                                      </td>
                                      <td className="py-1 px-2 ml-[-5px] text-black font-bold min-w-[250px]">
                                        {item.event}
                                      </td>

                                      <td className="py-1 px-2">
                                        {item.trade}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.trade_grade}
                                      </td>
                                      <td className="py-1 px-2 min-w-[145px]">
                                        {item.investing_name}
                                      </td>
                                      <td className="py-1 px-2 min-w-[145px]">
                                        {item.trading_view}
                                      </td>
                                      <td className="py-1 px-2 min-w-[145px]">
                                        {item.trading_economics}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.movement_currency}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.movement_correlation}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.flat_currency}
                                      </td>
                                      <td className="py-1 px-2">
                                        {item.flat_correlation}
                                      </td>
                                      <td className="py-1 px-2 min-w-[360px]">
                                        {item.comments}
                                      </td>

                                      <td className="py-1 px-2 min-w-[165px] ">
                                        <Selects
                                          options={prediction}
                                          className="bg-[#F6F6F6]"
                                          value={prediction.find(
                                            (option) =>
                                              option.value === item.prediction
                                          )}
                                          onChange={(selected) =>
                                            handleSelectChange(
                                              item._id,
                                              "prediction",
                                              selected,
                                              item
                                            )
                                          }
                                          styles={customStyles}
                                        />
                                      </td>
                                      <td className="py-1 px-2 min-w-[165px]">
                                        <Selects
                                          options={trade_log}
                                          value={trade_log.find(
                                            (option) =>
                                              option.value === item.trade_log
                                          )}
                                          onChange={(selected) =>
                                            handleSelectChange(
                                              item._id,
                                              "trade_log",
                                              selected,
                                              item
                                            )
                                          }
                                          styles={customStyles1}
                                        />
                                      </td>

                                      <td className="py-1 px-2 min-w-[165px]">
                                        <input
                                          type="text"
                                          name="trade_amount"
                                          id="trade_amount"
                                          className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[4px] block w-full p-2.5"
                                          placeholder=""
                                          required
                                          // value={
                                          //   trade_log.find(
                                          //     (option) =>
                                          //       option.value === item.trade_log
                                          //   )?.value === "Loss"
                                          //     // ? `-${
                                          //     //     Math.abs(
                                          //     //       Number(item.trade_amount)
                                          //     //     ) || ""
                                          //     //   }`
                                          //      ? (Math.abs(Number(item.trade_amount)) * -1) || ""
                                          //     : item.trade_amount || ""
                                          // }
                                          value={item.trade_amount}
                                          onChange={(e) => {
                                            const isLoss = trade_log.find((option) =>option.value === item.trade_log)?.value === "Loss";
                                            const value = e.target.value;

                                           
                                            let numericValue = value.replace( /[^0-9.-]/g, "");
                                            // Ensure only one decimal point is allowed
                                            if ((numericValue.match(/\./g) || []).length > 1) {
                                              numericValue = numericValue.slice(0,numericValue.lastIndexOf("."));
                                            }
                                           // Handle Loss case without breaking the decimal input
                                            if (isLoss && numericValue !== "" && !numericValue.startsWith("-")) {
                                            
                                              numericValue=(Math.abs(Number(numericValue)))*-1
                                             
                                          }
                                         

                                            handleSelectChange(item._id, "trade_amount", numericValue, item);
                                          }}
                                        />
                                      </td>

                                      <td className="py-1 px-2 min-w-[165px]">
                                        <input
                                          type="text"
                                          name="trade_time"
                                          id="trade_time"
                                          className="focus:border-0 bg-gray-50 border border-gray-300 rounded-[4px]
                          text-gray-900 text-sm  block w-full p-2.5  "
                                          placeholder=""
                                          required
                                          value={item.trade_time}
                                          onChange={(e) =>
                                            handleSelectChange(
                                              item._id,
                                              "trade_time",
                                              e.target.value,
                                              item
                                            )
                                          }
                                        />
                                      </td>

                                      <td className="py-1 px-2 min-w-[270px]">
                                        <input
                                          type="text"
                                          name="trade_comment"
                                          id="trade_comment"
                                          className="focus:border-0 bg-gray-50 border border-gray-300
                                        text-gray-900 text-sm rounded-[4px]  block w-full p-2.5 "
                                          placeholder=""
                                          required
                                          value={item.trade_comment}
                                          title={item.trade_comment}
                                          onChange={(e) =>
                                            handleSelectChange(
                                              item._id,
                                              "trade_comment",
                                              e.target.value,
                                              item
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>

                        {Loading1 ? (
                          <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
                            <LineWave
                              height="60"
                              width="60"
                              color="#111827"
                              ariaLabel="bars-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              className=" w-10 h-10 flex items-center justify-center  mt-10 "
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Savedtrade;
