// // <-----------------this whole code is for charts , for now they are not in use ------------------>



// export const candleStickOptions = {
//   chart: {
//     height: 800,
//     type: "candlestick",
//     zoom: {
//       enabled: true,  
//       type: 'x',    
//       zoomedArea: {
//         fill: {
//           color: '#90CAF9',
//           opacity: 0.4,     
//         },
//         stroke: {
//           color: '#0D47A1',  
//           opacity: 0.4,    
//           width: 1         
//         }
//       },
//     },
//     toolbar: {
//       show: true,  
//       tools: {
//         zoomin: true,  
//         zoomout: true,
//         pan: true      
//       },
//       export: {
//         // csv: true,  
//         png: true,  
//         // svg: true  
//       }
//     }
//   },
//   title: {
//     text: "CandleStick Chart",
//     align: "left",
//   },
//   plotOptions: {
//     candlestick: {
//       colors: {
//         upward: "#0d9488",
//         downward: "#d9534f",
//       },
//       wick: {
//         useFillColor: true,
//         color: "#666", 
//       },
//       border: {
//         color: "#666", 
//         width: 4, 
//       },
//       width: "60%",
//     },
//   },
//   annotations: {
//     xaxis: [
//       {
//         x: "Feb 06 14:00",
//         borderColor: "#00E396",
//         label: {
//           borderColor: "#00E396",
//           style: {
//             fontSize: "12px",
//             color: "#fff",
//             background: "#00E396",
//           },
//           orientation: "horizontal",
//           offsetY: 7,
//           text: "Annotation Test",
//         },
//       },
//     ],
//   },
//   tooltip: {
//     enabled: true,
//   },
//   xaxis: {
//     type: "category",
//     labels: {
//       formatter: function (val) {
//         if (val && val.trim() !== "") {
//           return val; 
//         } else {
//           return ""; 
//         }
//       },
//       tickAmount: 5
//     },
//   },
//   yaxis: {
//     tooltip: {
//       enabled: true,
//     },
//     labels: {
//       formatter: function (val) {
//         return val.toFixed(3); // You can format the labels as per your requirement
//       }
//     }
//   },
// };
// <---hi new code---->

export const candleStickOptions = {
  chart: {
    height: 800,
    type: "candlestick",
    zoom: {
      enabled: true,  
      type: 'x',    
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4,     
        },
        stroke: {
          color: '#0D47A1',  
          opacity: 0.4,    
          width: 1         
        }
      },
    },
    toolbar: {
      show: true,  
      tools: {
        zoomin: true,  
        zoomout: true,
        pan: true      
      },
      export: {
        // csv: true,  
        png: true,  
        // svg: true  
      }
    }
  },
  title: {
    text: "CandleStick Chart",
    align: "left",
  },
  plotOptions: {
    candlestick: {
      colors: {
        upward: "#0d9488",
        downward: "#d9534f",
      },
      wick: {
        useFillColor: true,
        color: "#666", 
      },
      border: {
        color: "#666", 
        width: 4, 
      },
      width: "60%",
    },
  },
  annotations: {
    xaxis: [
      {
        x: "Feb 06 14:00",
        borderColor: "#00E396",
        label: {
          borderColor: "#00E396",
          style: {
            fontSize: "12px",
            color: "#fff",
            background: "#00E396",
          },
          orientation: "horizontal",
          offsetY: 7,
          text: "Annotation Test",
        },
      },
    ],
  },
  tooltip: {
    enabled: true,
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: function (val) {
        if (val && val.trim() !== "") {
          return val; 
        } else {
          return ""; 
        }
      },
      tickAmount: 5
    },
  },
  yaxis: {
    tooltip: {
      enabled: true,
    },
    labels: {
      formatter: function (val) {
        return val.toFixed(3); // You can format the labels as per your requirement
      }
    }
  },
};