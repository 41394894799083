

import React from 'react';
import Navbar from "../components/Navbar"
import AdminHeader from '../components/admin/adminHeader';

const AdminDashboard = () => {
  return (
   <>
   <Navbar/>
   <AdminHeader/>
   
   <div></div>
   </>
  )
}

export default AdminDashboard
