import React, { useState, useEffect } from "react";
import styles from "../../components/tradeall.module.css";
// import * as Icon from "react-icons/fi";
// import Checkbox from "react-custom-checkbox";
import axios from "axios";
import { FiCheckCircle } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Select from "react-select";
import { LineWave } from "react-loader-spinner";
import { IoIosEye } from "react-icons/io";
// import { MdEdit } from "react-icons/md";
import editbtn from "../../assets/adddataactive.png"
import loader_video from "../../assets/loaderaltra.gif" 
import editbtndisable from "../../assets/adddatainactive.png";
import crossicon from "../../assets/cross.png";
import eyeiconactive from "../../assets/active_eye.png"


const UserTable = ({
  maindata,
  hideEstimate,
  clickedon,
  selectedCountry,
  selectedImpacts,
  item,
  matchingTrade,
  isOpen,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state

  const [isModalOpenforedit, setIsModalOpenforedit] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [currtradeid, setcurrtradeid] = useState("");

  const [modalShow, setModalshow] = useState(false);
  const [pendingUncheck, setPendingUncheck] = useState(false);

  const [savedtradedatavalues, setSavedtradedatavalues] = useState([]);

  const [Loading, setLoading] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [eyeLoader, seteyeLoader] = useState(true);
  const [matcheduserdata, setMatcheduserdata] = useState([]);

  const [modalFinalSelection, setModalFinalSelection] = useState("");
  const [modal_selectedItemId, setSelectedItemId] = useState(null);
  const [uncheckeditem, setuncheckeditem] = useState([]);

  const [trade1, settrade1] = useState({});
  const [investing_name1, setinvesting_name1] = useState({});
  const [trading_view1, settrading_view1] = useState({});
  const [flat_correlation1, setflat_correlation1] = useState({});
  const [comments1, setcomments1] = useState({});
  const [flat_currency1, setflat_currency1] = useState({});
  const [movement_correlation1, setmovement_correlation1] = useState({});
  const [movement_currency1, setmovement_currency1] = useState({});
  const [trading_economics1, settrading_economics1] = useState({});
  const [trade_grade1, settrade_grade1] = useState({});
  const [maintradedata, setmaintradedata] = useState({});
  const [geteditdata, setgetEditdata] = useState("");
  const [repopulateloader, setRepopulateloader] = useState(false);
  const [checkednull, setCheckednull] = useState({});
  const [loading_startpage, setLoading_startpage] = useState(true);
 

  function removePair(tradeid) {
    // if (trade.investing_name !== null && trade.investing_name !== 'NaN') {
    setinvesting_name1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.trading_view !== null && trade.trading_view !== 'NaN') {
    settrading_view1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.trade_grade !== null && trade.trade_grade !== 'NaN') {
    settrade_grade1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.trade !== null && trade.trade !== 'NaN') {
    settrade1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.trading_economics !== null && trade.trading_economics !== 'NaN') {
    settrading_economics1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.movement_currency !== null && trade.movement_currency !== 'NaN') {
    setmovement_currency1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.movement_correlation !== null && trade.movement_correlation !== 'NaN') {
    setmovement_correlation1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.flat_currency !== null && trade.flat_currency !== 'NaN') {
    setflat_currency1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.flat_correlation !== null && trade.flat_correlation !== 'NaN') {
    setflat_correlation1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));

    // if (trade.comments !== null && trade.comments !== 'NaN') {
    setcomments1((prev) => ({
      ...prev,
      [tradeid]: null,
    }));
    // }
  }

  const handleEventClick = async (item) => {
    let eventName;
    let eventcountry = item.country;
    let eventimpact = item.impact;
    let eventdate = item.date;
    let eventtime = item.time;
    let timeclickedurl = 1;

    const cleanEventName = (event) => {
      const monthPattern =
        /\((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)(\/\d+)?\)/g;

      const cleanedEvent = event
        .replace(monthPattern, "")
        .replace(/\(\s*\)/g, "")
        .trim();
      return cleanedEvent;
    };

    if (item.event.includes("(")) {
      eventName = cleanEventName(item.event);
    } else {
      eventName = item.event;
    }

    // console.log("eventName" , eventName)
    if (
      eventcountry === "UK" ||
      eventcountry === "US" ||
      eventcountry === "CA" ||
      eventcountry === "CH" ||
      eventcountry === "EU" ||
      eventcountry === "JP" ||
      eventcountry === "AU" ||
      eventcountry === "NZ" ||
      eventcountry === "DE" ||
      eventcountry === "IT" ||
      eventcountry === "FR"
    ) {
      localStorage.setItem(
        "eventnameandcountry:",
        JSON.stringify({
          eventName,
          eventcountry,
          eventimpact,
          eventdate,
          eventtime,
          timeclickedurl,
        })
      );

      const url = new URL(window.location.origin + "/user/eventshistory");
      url.searchParams.set("eventName", eventName);
      url.searchParams.set("eventcountry", eventcountry);
      url.searchParams.set("eventimpact", eventimpact);
      url.searchParams.set("eventtime", eventtime);
      url.searchParams.set("eventdate", eventdate);
      url.searchParams.set("timeclickedurl", timeclickedurl);

      const newTab = window.open(url.toString(), "_blank");

      if (newTab) {
        newTab.focus();
      } else {
        alert("Failed to open a new tab.");
      }
    } else {
      alert("Sorry, data is available  ");
    }
  };

  
  const handleRowClick = (item) => {
    setModalFinalSelection(item._id);
  };

  const handleRadioButtonChange = (item) => {
    setSelectedItemId(item._id);
  };

  const fetchsavedData = async () => {
    try {
      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId");

      const savedresponse = await axios.get(
        `${process.env.REACT_APP_URL}/user/prepopulate_userdata/${userid}`,
        { headers: h }
      );

      if (savedresponse.status === 200) {
        const latestDocuments = savedresponse.data.latestDocuments;

        const allTrades = latestDocuments.map((doc) => doc.trades);

        const flattenedTrades = allTrades.flat();
        console.log("flattenedTrades" , flattenedTrades)
        //  <---hlo am kushagra---->
        setSavedtradedatavalues(flattenedTrades);
       
        flattenedTrades.forEach((trade) => {
          const tradeid = trade.tradeid;

          // if (trade.investing_name !== null && trade.investing_name !== 'NaN') {
          //   setinvesting_name1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.investing_name
          //   }));
          // }
          setinvesting_name1((prev) => ({
            ...prev,
            [tradeid]: trade.investing_name !== null && trade.investing_name !== "NaN"
                ? trade.investing_name
                : "",
          }));

          // if (trade.trading_view !== null && trade.trading_view !== 'NaN') {
          //   settrading_view1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.trading_view
          //   }));
          // }
          settrading_view1((prev) => ({
            ...prev,
            [tradeid]:
              trade.trading_view !== null && trade.trading_view !== "NaN"
                ? trade.trading_view
                : "",
          }));

          // if (trade.trade_grade !== null && trade.trade_grade !== 'NaN') {
          //   settrade_grade1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.trade_grade
          //   }));
          // }
          settrade_grade1((prev) => ({
            ...prev,
            [tradeid]:
              trade.trade_grade !== null && trade.trade_grade !== "NaN"
                ? trade.trade_grade
                : "",
          }));
          // if (trade.trade !== null && trade.trade !== 'NaN') {
          //   settrade1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.trade
          //   }));
          // }
          settrade1((prev) => ({
            ...prev,
            [tradeid]:
              trade.trade !== null && trade.trade !== "NaN" ? trade.trade : "",
          }));

          // if (trade.trading_economics !== null && trade.trading_economics !== 'NaN') {
          //   settrading_economics1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.trading_economics
          //   }));
          // }
          settrading_economics1((prev) => ({
            ...prev,
            [tradeid]:
              trade.trading_economics !== null &&
              trade.trading_economics !== "NaN"
                ? trade.trading_economics
                : "",
          }));

          // if (trade.movement_currency !== null && trade.movement_currency !== 'NaN') {
          //   setmovement_currency1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.movement_currency
          //   }));
          // }
          setmovement_currency1((prev) => ({
            ...prev,
            [tradeid]:
              trade.movement_currency !== null &&
              trade.movement_currency !== "NaN"
                ? trade.movement_currency
                : "",
          }));

          // if (trade.movement_correlation !== null && trade.movement_correlation !== 'NaN') {
          //   setmovement_correlation1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.movement_correlation
          //   }));
          // }
          setmovement_correlation1((prev) => ({
            ...prev,
            [tradeid]:
              trade.movement_correlation !== null &&
              trade.movement_correlation !== "NaN"
                ? trade.movement_correlation
                : "",
          }));

          // if (trade.flat_currency !== null && trade.flat_currency !== 'NaN') {
          //   setflat_currency1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.flat_currency
          //   }));
          // }
          setflat_currency1((prev) => ({
            ...prev,
            [tradeid]:
              trade.flat_currency !== null && trade.flat_currency !== "NaN"
                ? trade.flat_currency
                : "",
          }));

          // if (trade.flat_correlation !== null && trade.flat_correlation !== 'NaN') {
          //   setflat_correlation1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.flat_correlation
          //   }));
          // }
          setflat_correlation1((prev) => ({
            ...prev,
            [tradeid]:
              trade.flat_correlation !== null &&
              trade.flat_correlation !== "NaN"
                ? trade.flat_correlation
                : "",
          }));

          // if (trade.comments !== null && trade.comments !== 'NaN') {
          //   setcomments1((prev) => ({
          //     ...prev,
          //     [tradeid]: trade.comments
          //   }));
          // }
          setcomments1((prev) => ({
            ...prev,
            [tradeid]:
              trade.comments !== null && trade.comments !== "NaN"
                ? trade.comments
                : "",
          }));

          if (trade.checkednull !== null) {
            setCheckednull((prev) => ({
              ...prev,
              [tradeid]: true,
            }));
          }
        });
      }

      if (savedresponse.status === 404) {
        toast.error("No data found in history.", { position: "top-right" });
        // setLoading_startpage(false)
        return;
      }
    } catch (error) {
      // setLoading_startpage(false)
      console.error("Error fetching saved data:", error);
    }
  };

  useEffect(() => {
    fetchsavedData();
  }, []);

  useEffect(() => {
    // Transform `maindata` array into an object where keys are `_id`s
    const dataObject = maindata.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});

    // Update the state once with the full transformed object
    setmaintradedata(dataObject);
  }, [maindata]); // Re-run this only if `maindata` changes

  const sendSelected_tradevalues = async () => {
    setLoading(true);
    try {
      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId");

      // Combine separate states into `allItems` array, only if maintradedata[tradeid] exists
      const allItems = Object.keys(trade1).map((tradeid) => {
          const tradeData = maintradedata[tradeid];
          if (!tradeData) {
            console.warn(`No maintradedata found for tradeid: ${tradeid}`);
            return null; // Skip this entry if no maintradedata found
          }

          //  else {
          console.log("hlo amd here in checked item ,------------->");
          return {
            tradeid,
            date: tradeData.date || null,
            time: tradeData.time || null,
            country: tradeData.country || null,
            event: tradeData.event || null,
            currency: tradeData.currency || null,
            previous: tradeData.previous || null,
            estimate: tradeData.estimate || null,
            actual: tradeData.actual || null,
            impact: tradeData.impact || null,
            changePercentage: tradeData.changePercentage || null,
            trade: trade1[tradeid] || null,
            trade_grade: trade1[tradeid] === "No" ? null : trade_grade1[tradeid] || null,
            investing_name:
              trade1[tradeid] === "No"
                ? null
                : investing_name1[tradeid] || null,
            trading_view:
              trade1[tradeid] === "No" ? null : trading_view1[tradeid] || null,

            trading_economics:
              trade1[tradeid] === "No"
                ? null
                : trading_economics1[tradeid] || null,
            movement_currency:
              trade1[tradeid] === "No"
                ? null
                : movement_currency1[tradeid] || null,
            movement_correlation:
              trade1[tradeid] === "No"
                ? null
                : movement_correlation1[tradeid] || null,
            flat_currency:
              trade1[tradeid] === "No" ? null : flat_currency1[tradeid] || null,
            flat_correlation:
              trade1[tradeid] === "No"
                ? null
                : flat_correlation1[tradeid] || null,
            comments: comments1[tradeid] || null,
            // flag: trade1[tradeid] || trade_grade1[tradeid] ||  investing_name1[tradeid] || trading_view1[tradeid] || trading_economics1[tradeid] || movement_currency1[tradeid] || movement_correlation1[tradeid] || flat_currency1[tradeid] || comments1[tradeid] || flat_correlation1[tradeid]? "non-null" : "null"
            flag: checkednull[tradeid] ? "non-null" : "null",
            // };
          };
        })
        .filter((item) => item !== null);
      

      // Send consolidated data
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/selectedtrade`,
        { allItems, userid },
        { headers: { "Content-Type": "application/json", ...h } }
      );

      // if (!isModalOpen) {

      // }

      toast.success("Data has been saved successfully!", {
        position: "top-right",
      });
     
      setLoading(false);

      setIsModalOpenforedit(false);

      fetchsavedData();
    } catch (error) {
      setLoading(false);
      setIsModalOpenforedit(false);
    }
  };

  const handleFinalSubmission = async () => {
    setRepopulateloader(true);
    try {
      const userid = localStorage.getItem("userregisterId");

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/final_modalsubmissionvalue`,
        { modalFinalSelection, userid, modal_selectedItemId }
      );

      // console.log("modal_selectedItemId-------->" , modal_selectedItemId)
      // console.log("table item id--------->" , modalFinalSelection)
      fetchsavedData();
      // closeModal();
      setRepopulateloader(false);
    } catch (error) {
      console.error("error", error);
      setRepopulateloader(false);
    }
  };

  const handleModalYes = () => {
    removePair(currtradeid);
    console.log("currtradeid", currtradeid);
    setCheckednull((prev) => ({
      ...prev,
      [currtradeid]: false,
    }));
  };
  const handleModalNo = () => {
    setModalshow(false); // Close modal without any changes
   
  };

  // <-----new code ashi---------->

 
  const options1 = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const trade_grades = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "NaN", label: "NaN" },
  ];

  

  const movement_correlation_option = [
    { value: "Positive", label: "Positive" },
    { value: "Negative", label: "Negative" },
    { value: "N.A", label: "N.A" },
  ];



  const flat_correlation_option = [
    { value: "Positive", label: "Positive" },
    { value: "Negative", label: "Negative" },
    { value: "N.A", label: "N.A" },
  ];

  // <-------------utc + 30 minutes------------->
  const currentTime = new Date();
  const timePlus30Min = new Date(currentTime.getTime() + 30 * 60 * 1000);
  const formatTime = (date) => {
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };
  const timeString = formatTime(timePlus30Min);
  const todayDate = new Date().toISOString().split("T")[0];
  const formattedDate = todayDate.replace(/-/g, ".");

  const getCurrencyOptions = (country) => {
    switch (country) {
      case "UK":
        return [
          { value: "GBP/USD", label: "GBP/USD" },
          { value: "GBP/JPY", label: "GBP/JPY" },
          { value: "GBP/NZD", label: "GBP/NZD" },
          { value: "EUR/GBP", label: "EUR/GBP" },
          { value: "GBP/CHF", label: "GBP/CHF" },
          { value: "GBP/AUD", label: "GBP/AUD" },
          { value: "GBP/CAD", label: "GBP/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "US":
        return [
          { value: "GBP/USD", label: "GBP/USD" },
          { value: "USD/JPY", label: "USD/JPY" },
          { value: "NZD/USD", label: "NZD/USD" },
          { value: "EUR/USD", label: "EUR/USD" },
          { value: "USD/CHF", label: "USD/CHF" },
          { value: "AUD/USD", label: "AUD/USD" },
          { value: "USD/CAD", label: "USD/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "JP":
        return [
          { value: "USD/JPY", label: "USD/JPY" },
          { value: "GBP/JPY", label: "GBP/JPY" },
          { value: "NZD/JPY", label: "NZD/JPY" },
          { value: "EUR/JPY", label: "EUR/JPY" },
          { value: "CHF/JPY", label: "CHF/JPY" },
          { value: "AUD/JPY", label: "AUD/JPY" },
          { value: "CAD/JPY", label: "CAD/JPY" },
          { value: "N.A", label: "N.A" },
        ];
      case "CA":
        return [
          { value: "USD/CAD", label: "USD/CAD" },
          { value: "CAD/JPY", label: "CAD/JPY" },
          { value: "NZD/CAD", label: "NZD/CAD" },
          { value: "EUR/CAD", label: "EUR/CAD" },
          { value: "CAD/CHF", label: "CAD/CHF" },
          { value: "AUD/CAD", label: "AUD/CAD" },
          { value: "GBP/CAD", label: "GBP/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "CH":
        return [
          { value: "USD/CHF", label: "USD/CHF" },
          { value: "CHF/JPY", label: "CHF/JPY" },
          { value: "NZD/CHF", label: "NZD/CHF" },
          { value: "EUR/CHF", label: "EUR/CHF" },
          { value: "GBP/CHF", label: "GBP/CHF" },
          { value: "AUD/CHF", label: "AUD/CHF" },
          { value: "CAD/CHF", label: "CAD/CHF" },
          { value: "N.A", label: "N.A" },
        ];
      case "EU":
      case "DE":
      case "IT":
      case "FR":
        return [
          { value: "EUR/USD", label: "EUR/USD" },
          { value: "EUR/JPY", label: "EUR/JPY" },
          { value: "EUR/NZD", label: "EUR/NZD" },
          { value: "EUR/GBP", label: "EUR/GBP" },
          { value: "EUR/CHF", label: "EUR/CHF" },
          { value: "EUR/AUD", label: "EUR/AUD" },
          { value: "EUR/CAD", label: "EUR/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "AU":
        return [
          { value: "AUD/USD", label: "AUD/USD" },
          { value: "AUD/JPY", label: "AUD/JPY" },
          { value: "AUD/NZD", label: "AUD/NZD" },
          { value: "EUR/AUD", label: "EUR/AUD" },
          { value: "AUD/CHF", label: "AUD/CHF" },
          { value: "GBP/AUD", label: "GBP/AUD" },
          { value: "AUD/CAD", label: "AUD/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "NZ":
        return [
          { value: "NZD/USD", label: "NZD/USD" },
          { value: "NZD/JPY", label: "NZD/JPY" },
          { value: "GBP/NZD", label: "GBP/NZD" },
          { value: "EUR/NZD", label: "EUR/NZD" },
          { value: "NZD/CHF", label: "NZD/CHF" },
          { value: "AUD/NZD", label: "AUD/NZD" },
          { value: "NZD/CAD", label: "NZD/CAD" },
          { value: "N.A", label: "N.A" },
        ];

      default:
        return [];
    }
  };

  const getflat_CurrencyOptions = (country) => {
    switch (country) {
      case "UK":
        return [
          { value: "GBP/USD", label: "GBP/USD" },
          { value: "GBP/JPY", label: "GBP/JPY" },
          { value: "GBP/NZD", label: "GBP/NZD" },
          { value: "EUR/GBP", label: "EUR/GBP" },
          { value: "GBP/CHF", label: "GBP/CHF" },
          { value: "GBP/AUD", label: "GBP/AUD" },
          { value: "GBP/CAD", label: "GBP/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "US":
        return [
          { value: "GBP/USD", label: "GBP/USD" },
          { value: "USD/JPY", label: "USD/JPY" },
          { value: "NZD/USD", label: "NZD/USD" },
          { value: "EUR/USD", label: "EUR/USD" },
          { value: "USD/CHF", label: "USD/CHF" },
          { value: "AUD/USD", label: "AUD/USD" },
          { value: "USD/CAD", label: "USD/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "JP":
        return [
          { value: "USD/JPY", label: "USD/JPY" },
          { value: "GBP/JPY", label: "GBP/JPY" },
          { value: "NZD/JPY", label: "NZD/JPY" },
          { value: "EUR/JPY", label: "EUR/JPY" },
          { value: "CHF/JPY", label: "CHF/JPY" },
          { value: "AUD/JPY", label: "AUD/JPY" },
          { value: "CAD/JPY", label: "CAD/JPY" },
          { value: "N.A", label: "N.A" },
        ];
      case "CA":
        return [
          { value: "USD/CAD", label: "USD/CAD" },
          { value: "CAD/JPY", label: "CAD/JPY" },
          { value: "NZD/CAD", label: "NZD/CAD" },
          { value: "EUR/CAD", label: "EUR/CAD" },
          { value: "CAD/CHF", label: "CAD/CHF" },
          { value: "AUD/CAD", label: "AUD/CAD" },
          { value: "GBP/CAD", label: "GBP/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "CH":
        return [
          { value: "USD/CHF", label: "USD/CHF" },
          { value: "CHF/JPY", label: "CHF/JPY" },
          { value: "NZD/CHF", label: "NZD/CHF" },
          { value: "EUR/CHF", label: "EUR/CHF" },
          { value: "GBP/CHF", label: "GBP/CHF" },
          { value: "AUD/CHF", label: "AUD/CHF" },
          { value: "CAD/CHF", label: "CAD/CHF" },
          { value: "N.A", label: "N.A" },
        ];
      case "EU":
      case "DE":
      case "IT":
      case "FR":
        return [
          { value: "EUR/USD", label: "EUR/USD" },
          { value: "EUR/JPY", label: "EUR/JPY" },
          { value: "EUR/NZD", label: "EUR/NZD" },
          { value: "EUR/GBP", label: "EUR/GBP" },
          { value: "EUR/CHF", label: "EUR/CHF" },
          { value: "EUR/AUD", label: "EUR/AUD" },
          { value: "EUR/CAD", label: "EUR/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "AU":
        return [
          { value: "AUD/USD", label: "AUD/USD" },
          { value: "AUD/JPY", label: "AUD/JPY" },
          { value: "AUD/NZD", label: "AUD/NZD" },
          { value: "EUR/AUD", label: "EUR/AUD" },
          { value: "AUD/CHF", label: "AUD/CHF" },
          { value: "GBP/AUD", label: "GBP/AUD" },
          { value: "AUD/CAD", label: "AUD/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      case "NZ":
        return [
          { value: "NZD/USD", label: "NZD/USD" },
          { value: "NZD/JPY", label: "NZD/JPY" },
          { value: "GBP/NZD", label: "GBP/NZD" },
          { value: "EUR/NZD", label: "EUR/NZD" },
          { value: "NZD/CHF", label: "NZD/CHF" },
          { value: "AUD/NZD", label: "AUD/NZD" },
          { value: "NZD/CAD", label: "NZD/CAD" },
          { value: "N.A", label: "N.A" },
        ];
      // Add other cases here for more currencies
      default:
        return [];
    }
  };

  // <---------get data which is already stored in db ------------->

  const gethighlightesdata = async () => {
    const userid = localStorage.getItem("userregisterId");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/user/highlighted_usertrade/${userid}`
      );
      // console.log("response" , response)
      const olddata = response.data.matchedEvents.map((event) => event._doc); // Adjust according to your response structure

      setMatcheduserdata(olddata);
      seteyeLoader(false);
    } catch (error) {
      console.log("error", error);
      seteyeLoader(false);
    }
  };

  useEffect(() => {
    gethighlightesdata();
  }, []);

  const openEditmodal = (item) => {
    fetchsavedData();
    // console.log("item._id ashi-->", item._id);
    setgetEditdata(item);
    // console.log("item here ashi" , item)
    
    setIsModalOpenforedit(true);
  };
  const openHistoryModal = async (item) => {
    // console.log("item" , item)
    try {
      setLoading2(true);
      const userid = localStorage.getItem("userregisterId");
      const encodedItem = encodeURIComponent(JSON.stringify(item));
      const savedresponse = await axios.get(
        `${process.env.REACT_APP_URL}/user/historymodaluser_last4data/${userid}?item=${encodedItem}`
      );

      // console.log("savedresponse" , savedresponse)
      if (
        savedresponse.status === 200 &&
        savedresponse.data.filteredTrades.length > 0
      ) {
        const latestDocuments = savedresponse.data.filteredTrades;

        const modalDatas = latestDocuments.map((trade) => ({
          date: trade.date,
          time: trade.time,
          country: trade.country,
          currency: trade.currency,
          event: trade.event,
          trade: trade.trade,
          trade_grade: trade.trade_grade,
          investing_name: trade.investing_name,
          trading_view: trade.trading_view,
          trading_economics: trade.trading_economics,
          movement_currency: trade.movement_currency,
          movement_correlation: trade.movement_correlation,
          flat_currency: trade.flat_currency,
          flat_correlation: trade.flat_correlation,
          comments: trade.comments,
          prediction: trade.prediction,
          trade_log: trade.trade_log,
          trade_amount: trade.trade_amount,
          trade_time: trade.trade_time,
          trade_comments: trade.trade_comments,
          _id: trade._id,
        }));

        setModalData(modalDatas);
        setIsModalOpen(true);
        setLoading2(false);
      } else {
        setModalData([]);
        toast.error("No data found in history.", { position: "top-right" });
        setLoading2(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setModalData([]);
      setLoading2(false);
    } finally {
      setLoading2(false); // End loading
    }
  };

  const openHistoryModalinedit_btn = async (item) => {
    // console.log("item" , item)
    try {
      setLoading2(true);
      const userid = localStorage.getItem("userregisterId");
      const encodedItem = encodeURIComponent(JSON.stringify(item));
      const savedresponse = await axios.get(
        `${process.env.REACT_APP_URL}/user/historymodaluser_last4data/${userid}?item=${encodedItem}`
      );

      // console.log("savedresponse" , savedresponse)
      if (
        savedresponse.status === 200 &&
        savedresponse.data.filteredTrades.length > 0
      ) {
        const latestDocuments = savedresponse.data.filteredTrades;

        const modalData = latestDocuments.map((trade) => ({
          date: trade.date,
          time: trade.time,
          country: trade.country,
          currency: trade.currency,
          event: trade.event,
          trade: trade.trade,
          trade_grade: trade.trade_grade,
          investing_name: trade.investing_name,
          trading_view: trade.trading_view,
          trading_economics: trade.trading_economics,
          movement_currency: trade.movement_currency,
          movement_correlation: trade.movement_correlation,
          flat_currency: trade.flat_currency,
          flat_correlation: trade.flat_correlation,
          comments: trade.comments,
          prediction: trade.prediction,
          trade_log: trade.trade_log,
          trade_amount: trade.trade_amount,
          trade_time: trade.trade_time,
          trade_comments: trade.trade_comments,
          _id: trade._id,
        }));

        console.log("modalData---------->" , modalData)
        setModalData(modalData);
        // setIsModalOpen(true);
        setLoading2(false);
      } else {
        setModalData([]);
        toast.error("No data found in history.", { position: "top-right" });
        setLoading2(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setModalData([]);
      setLoading2(false);
    } finally {
      setLoading2(false); // End loading
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalData([]);
  };

  const closeModalforedit = () => {
    setIsModalOpenforedit(false);
  };

  const handleInvestingNameChange = (tradeid, event) => {
    setinvesting_name1((prev) => ({
      ...prev,
      [tradeid]: event.target.value, // Update the specific tradeid
    }));
  };

  const handleTradeGradeChange = (tradeid, val) => {
    // console.log("tradeid" , tradeid)
    // console.log("event.target.value" , val)
    settrade_grade1((prev) => ({
      ...prev,
      [tradeid]: val, // Update the specific tradeid
    }));
  };

  const handleTradeChange = (tradeid, val) => {
    // console.log("tradeid" , tradeid)
    // console.log("event.target.value" , val)
    settrade1((prev) => ({
      ...prev,
      [tradeid]: val, // Update the specific tradeid
    }));
  };
  const handleTradingViewChange = (tradeid, event) => {
    // trading_view[tradeid] = event.target.value;
    settrading_view1((prev) => ({
      ...prev,
      [tradeid]: event.target.value, // Update the specific tradeid
    }));
  };

  const handleTradingEconomicsChange = (tradeid, event) => {
    // trading_view[tradeid] = event.target.value;
    settrading_economics1((prev) => ({
      ...prev,
      [tradeid]: event.target.value, // Update the specific tradeid
    }));
  };
  const handleCommentsChange = (tradeid, event) => {
    // trading_view[tradeid] = event.target.value;
    setcomments1((prev) => ({
      ...prev,
      [tradeid]: event.target.value, // Update the specific tradeid
    }));
  };

  const handleUncheckChange = (tradeid, val) => {
    if (checkednull[tradeid]) {
      setModalshow(true);
      setcurrtradeid(tradeid);
    } else {
      setModalshow(false);
      setCheckednull((prev) => ({
        ...prev,
        [tradeid]: true,
      }));
      console.log("checkednull[tradeid]", checkednull[tradeid], tradeid);

      console.log("val", val);
      // console.log("tradeid" , tradeid)
      // console.log("event.target.value" , val)
    }
  };

  const handleMovementcurrencyChange = (tradeid, val) => {
    // console.log("tradeid" , tradeid)
    // console.log("event.target.value" , val)
    setmovement_currency1((prev) => ({
      ...prev,
      [tradeid]: val, // Update the specific tradeid
    }));
  };

  const handleMovementCorrelationChange = (tradeid, val) => {
    // console.log("tradeid" , tradeid)
    // console.log("event.target.value" , val)
    setmovement_correlation1((prev) => ({
      ...prev,
      [tradeid]: val, // Update the specific tradeid
    }));
  };

  const handleFlatcurrencyChange = (tradeid, val) => {
    // console.log("tradeid" , tradeid)
    // console.log("event.target.value" , val)
    setflat_currency1((prev) => ({
      ...prev,
      [tradeid]: val, // Update the specific tradeid
    }));
  };

  const handleFlatCorrelationChange = (tradeid, val) => {
    console.log("tradeid", tradeid);
    console.log("event.target.value", val);
    setflat_correlation1((prev) => ({
      ...prev,
      [tradeid]: val, // Update the specific tradeid
    }));
  };

 


  
  return (
    <div
      className={` ${styles.maintableuser} max-w-[100%] w-[89%] lg:w-[84%] xl:w-[89%]
        fixed ml-0 sm:ml-0 md:ml-0 lg:ml-[6%] xl:ml-0 overflow-auto top-[61%] sm:top-[61%] md:top-[52%] lg:top-[37%] xl:top-[27%]`}
    >
      {/* <div className={`flex justify-end items-center mb-1 mt-[38%] sm:mt-[30%] md:mt-[8%] lg:mt-10 xl:mt-0 ${styles.tableheader}`} >
                <h1 className="cursor-pointer font-medium" title="Click here to save your trade" onClick={sendSelected_tradevalues}>Save Final Changes</h1>
                <FiCheckCircle className="cursor-pointer" title="Save your data" />
    </div> */}

      <div className="overflow-auto">
        <div className="overflow-x-auto overflow-y-auto  max-h-[46vh] sm:max-h-[46vh] md:max-h-[48vh] lg:max-h-[62vh] xl:max-h-[72vh]">
          {maindata.length === 0   ? (
            <div
              className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl`}
            >
              Sorry!! Data for this day is not available.
            </div>
          ) : (
            <>
           
              <table
                className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}
              >
                <thead
                  key={2}
                  className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]"
                >
                  <tr key={1} className="h-[96px]">
                    <th className="sticky top-0 py-2 px-4">S. No.</th>
                    <th className="sticky top-0 py-2 px-4">Date</th>
                    <th className="sticky top-0 py-2 px-4">Time(GMT)</th>
                    <th className="sticky top-0 py-2 px-4">Country</th>
                    <th className="sticky top-0 py-2 px-4">Event</th>
                    <th className="sticky top-0 py-2 px-4">Currency</th>
                    <th className="sticky top-0 py-2 px-4">Previous</th>
                    <th className="sticky top-0 py-2 px-4">Estimate</th>
                    <th className="sticky top-0 py-2 px-4">Actual</th>
                    <th className="sticky top-0 py-2 px-4">Impact</th>
                    <th className="sticky top-0 py-2 px-4">Change</th>
                    <th className="sticky top-0 py-2 px-4">
                      Change Percentage
                    </th>
                    <th className="sticky top-0 py-2 px-4">View</th>
                    {/* <th className="sticky top-0 py-2 px-4">Bookmark</th> */}
                    <th className="sticky top-0 py-2 px-4">Add data</th>
                    <th className="sticky top-0 py-2 px-4">Trade</th>
                    <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                    <th className="sticky top-0 py-2 px-4">
                      Investing.com Var name
                    </th>
                    <th className="sticky top-0 py-2 px-4">
                      Trading view Var name
                    </th>
                    <th className="sticky top-0 py-2 px-4">
                      Trading Economics Var name
                    </th>
                    <th className="sticky top-0 py-2 px-4">
                      Movement (Currency)
                    </th>
                    <th className="sticky top-0 py-2 px-4">
                      Movement (Correlation)
                    </th>
                    <th className="sticky top-0 py-2 px-4">Flat (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">
                      Flat (Correlation)
                    </th>
                    <th className="sticky top-0 py-2 px-4">Comments</th>


                  </tr>
                </thead>
                <tbody className="bg-[#F6F6F6]  z-0">
                  {maindata
                    .filter(
                      (item) =>
                      
                        (selectedCountry.length > 0
                          ? selectedCountry.includes(item.country)
                          : true) &&
                       
                        (selectedImpacts.length > 0
                          ? selectedImpacts.includes(item.impact)
                          : true)
                    )
                    .map((item, index) => {
                      {
                        
                      }

                      if (
                        hideEstimate &&
                        (item.estimate === null || item.estimate === "")
                      ) {
                        return null;
                      }
                      

                      const itemDate = item.date;
                      const itemTime = item.time;
                      const isDateToday = itemDate === formattedDate;
                      const isDateInPast = itemDate < formattedDate;
                      const isTimeWithinThreshold = itemTime <= timeString;
                      const shouldDisable =
                        clickedon === "yesterday" ||
                        isDateInPast ||
                        (isDateToday && isTimeWithinThreshold);
                      const isSelected = savedtradedatavalues.some(
                        (selectedItem) => selectedItem.tradeid === item._id
                      );
                    

                      return (
                        <>
                          <tr
                            key={item._id}
                            className={` text-center border-y-[12px] border-solid border-[#F6F6F6]  ${
                              isSelected
                                ? "bg-[#e4ebf9] hover:bg-[#8bbafa33]"
                                : "bg-white hover:bg-[#aecaf133]"
                            }`}
                            onClick={() => handleRowClick(item)}
                          >
                          
                            <td className="py-1 px-2 min-w-[80px]">
                              {index + 1}.
                            </td>
                            <td className="py-1 px-1">{item.date}<br/> {item._id}</td>
                            {/* <td className="py-1 px-1">{item.time}</td> */}
                            <td className="py-1 px-1">{item.time.slice(0, 5)}</td>
                            
                            <td className="py-1 px-1">{item.country}</td>

                            <td
                              className={`py-1 min-w-[180px] px-1 ml-[-5px] cursor-pointer ${
                                matcheduserdata.some(
                                  (event) =>
                                    event.event === item.event &&
                                    event.country === item.country
                                )
                                  ? "text-yellow-500"
                                  : "text-blue-500"
                              }`}
                              onClick={() => handleEventClick(item)}
                            >
                              {" "}
                              {item.event}{" "}
                            </td>

                            <td className="py-1 px-2">{item.currency}</td>
                            <td className="py-1 px-2">{item.previous}</td>
                            <td className="py-1 px-2">{item.estimate}</td>
                            <td className="py-1 px-2">{item.actual}</td>
                            <td className="py-1 px-2">{item.impact}</td>
                            <td className="py-1 px-2">{item.change}</td>
                            <td className="py-1 px-2">
                              {item.changePercentage}
                            </td>

                            <td
                              className={`py-1 px-1 min-w-[86px] pl-[1%] ${
                                shouldDisable
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              }`}
                            >
                              {matcheduserdata.some(
                                (event) =>
                                  event.event === item.event &&
                                  event.country === item.country
                              ) ? (
                                <IoIosEye
                                  className={` text-gray-500 ${
                                    shouldDisable
                                      ? "cursor-not-allowed text-gray-300"
                                      : "cursor-pointer"
                                  }`}
                                 
                                  onClick={() => {
                                    if (!shouldDisable) {
                                   
                                      openHistoryModal(item);
                                    }
                                  }}
                                />
                              ) : (
                                <td></td>
                              )}
                            </td>
                           
                        
                      

                            <td
                              className={`py-1 px-1 min-w-[86px] pl-[1%] cursor-pointer ${
                                shouldDisable
                                  ? "text-gray-400 cursor-not-allowed"
                                  : "text-blue-500"
                              }`}

                              onClick={() =>
                                (!shouldDisable && openEditmodal(item)) ||
                                openHistoryModalinedit_btn(item)
                              }  style={{
                                pointerEvents: shouldDisable
                                  ? "none"
                                  : "auto",
                              }} >
                                {
                                  shouldDisable ? <img
                                  src={editbtndisable}
                                  alt="editbtn"
                                  className="h-[20px] w-[20px]"
                                /> : <img
                                  src={editbtn}
                                  alt="editbtn"
                                  className="h-[20px] w-[20px]"
                                />
                                }
                                
                              {/* <MdEdit
                                onClick={() =>
                                  (!shouldDisable && openEditmodal(item)) ||
                                  openHistoryModalinedit_btn(item)
                                }
                                className={`${
                                  shouldDisable
                                    ? "text-gray-400 cursor-not-allowed"
                                    : "text-blue-500"
                                }`}
                                style={{
                                  pointerEvents: shouldDisable
                                    ? "none"
                                    : "auto",
                                }}
                              /> */}
                            </td>
                            <>
                              <td className="py-1 px-4 min-w-[140px] ">
                                <span className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[150px] flex items-center justify-center">
                                  {trade1[item._id]}
                                </span>
                              </td>

                              <td className="py-1 px-4 min-w-[140px]">
                                <span className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[150px] flex items-center justify-center">
                                  {trade_grade1[item._id]}
                                </span>
                              </td>

                              <td className="py-1 px-4 min-w-[140px]">
                                <span
                                  className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[210px] flex items-center justify-center"
                                  title={investing_name1[item._id] || ""}
                                >
                                  {/* {investing_name1[item._id] || ''}  */}
                                  {(() => {
                                    const investing_nam =
                                      investing_name1[item._id] || "";
                                    return investing_nam.length > 19
                                      ? investing_nam.slice(0, 19) + "..."
                                      : investing_nam;
                                  })()}
                                </span>
                              </td>

                              <td className="py-1 px-4 min-w-[140px]">
                                <span
                                  className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[210px] flex items-center justify-center"
                                  title={trading_view1[item._id] || ""}
                                >
                                  {/* {trading_view1[item._id] || ''}  */}
                                  {(() => {
                                    const trading_v =
                                      trading_view1[item._id] || "";
                                    return trading_v.length > 19
                                      ? trading_v.slice(0, 19) + "..."
                                      : trading_v;
                                  })()}
                                </span>
                              </td>

                              <td className="py-1 px-4 min-w-[140px]">
                                <span
                                  className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[210px] flex items-center justify-center"
                                  title={trading_economics1[item._id] || ""}
                                >
                                  {/* {trading_economics1[item._id] || ''} */}
                                  {(() => {
                                    const trading_eco =
                                      trading_economics1[item._id] || "";
                                    return trading_eco.length > 19
                                      ? trading_eco.slice(0, 19) + "..."
                                      : trading_eco;
                                  })()}
                                </span>
                              </td>

                              <td className="py-1 px-4 min-w-[140px]">
                                <span className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[150px] flex items-center justify-center">
                                  {movement_currency1[item._id]}
                                </span>
                              </td>

                              <td className="py-1 px-4 min-w-[140px]">
                                <span className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[150px] flex items-center justify-center">
                                  {movement_correlation1[item._id]}
                                </span>
                              </td>

                              <td className="py-1 px-4 min-w-[140px]">
                                <span className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[150px] flex items-center justify-center">
                                  {flat_currency1[item._id]}
                                </span>
                              </td>

                              <td className="py-1 px-4 min-w-[140px]">
                                <span className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[150px] flex items-center justify-center">
                                  {flat_correlation1[item._id]}
                                </span>
                              </td>

                           
                              <td className="py-1 px-4 min-w-[140px]">
                                <span
                                  className=" p-2 min-h-[36px] rounded-[4px] border border-solid border-[#A0A0A0] border-opacity-[0.5] min-w-[170px] flex items-center justify-center"
                                  title={comments1[item._id] || ""}
                                >
                                  {/* {comments1[item._id] || ''} */}

                                  {(() => {
                                    const comment = comments1[item._id] || "";
                                    return comment.length > 19
                                      ? comment.slice(0, 19) + "..."
                                      : comment;
                                  })()}
                                </span>
                              </td>
                            </>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            

              {Loading || eyeLoader ? (
                <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
                  <LineWave
                    height="60"
                    width="60"
                    color="#111827"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    className=" w-10 h-10 flex items-center justify-center  mt-10 "
                  />
                </div>
              ) : (
                <div></div>
              )}

              {modalShow && (
                <div
                  id="popup-modal"
                  className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center bg-black bg-opacity-15 z-50"
                  onClick={() => setModalshow(false)}
                >
                  <div className="relative p-4 w-full max-w-md">
                    <div className="relative bg-white rounded-lg shadow">
                      <button
                        type="button"
                        className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md w-8 h-8 inline-flex justify-center items-center"
                        data-modal-hide="popup-modal"
                        onClick={() => setModalshow(false)}
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                      <div className="p-4 md:p-5 text-center">
                        <h3 className="mb-5 mt-5 text-lg font-normal text-black">
                          Are you sure you want to uncheck this item ? Data will
                          be deleted.
                        </h3>
                        <div className="flex items-center justify-center">
                          <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white font-bold bg-gray-700 mt-3 font-medium rounded-lg text-md inline-flex items-center justify-center px-3 py-2 w-[20%]"
                            onClick={handleModalYes}
                          >
                            Yes
                          </button>
                          <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="ml-4 text-white font-bold bg-gray-700 mt-3 font-medium rounded-lg text-md inline-flex items-center justify-center px-3 py-2 w-[20%]"
                            onClick={handleModalNo}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <------history view-------> */}
              {isModalOpen && (
                <div
                  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]"
                  onClick={closeModal}
                >
                  <div
                    className="bg-white rounded-lg p-4 w-[70%] md:w-[75%] relative  top-0 sm:top-0 md:top-0 lg:top-[2%] xl:top-0 "
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      onClick={closeModal}
                      className="absolute top-4 right-4 h-4 w-4 text-gray-600 hover:text-gray-900"
                      title="Close"
                    >
                      {/* &times;  */}
                      <img
                              src={crossicon}
                              alt="crossicon"
                              className="h-[12px] w-[12px]"
                            />
                    </button>
                    <h2 className="text-lg font-bold mb-4 text-[#151841]">
                    Trade History :
                    </h2>

                    <>
                      {/* <div className="flex items-end justify-end mb-4"> 
                            <h1 className="flex  justify-center text-white bg-[#5b6b8b] border border-solid border-[#5b6b8b] p-1 items-end cursor-pointer font-bold rounded-md " title="Click here to save your trade"     onClick={handleFinalSubmission} >Repopulate this entry    <FiCheckCircle className="ml-1 cursor-pointer" title="Save your data" onClick={handleFinalSubmission}/></h1>
                            </div> */}
                        <div className="overflow-auto">
                        <div className="overflow-x-auto overflow-y-auto  ">
                        <table className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}>
                          <thead  className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]">
                            <tr >
                              <th className=" sticky top-0 py-2 px-4">
                                S. No.
                              </th>
                              <th className=" sticky top-0 py-2 px-4">Date</th>
                              <th className=" sticky top-0 py-2 px-4">
                                Time(GMT)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Country
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Currency
                              </th>
                              <th className="sticky top-0 py-2 px-4">Event</th>
                              <th className="sticky top-0 py-2 px-4">Trade</th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade Grade
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Investing.com Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trading view Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trading Economics Var name
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Movement (Currency)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Movement (Correlation)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Flat (Currency)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Flat (Correlation)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Comments
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Prediction
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade log(profit/loss)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade Amount
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade in time(seconds)
                              </th>
                              <th className="sticky top-0 py-2 px-4">
                                Trade comment
                              </th>
                              {/* <th className="sticky top-0 py-2 px-4">
                                Select Trade
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="bg-[#F6F6F6]  z-0">
                            {modalData.length === 0 ? (
                              <div
                                className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl `}
                              >
                                Sorry!! No history available for this event .
                              </div>
                            ) : (
                              <>
                                {modalData
                                  .sort(
                                    (a, b) =>
                                      new Date(b.date) - new Date(a.date)
                                  )
                                  .map((item, index) => (
                                    <>
                                      <tr key={index} className="text-center border-y-[12px] border-solid border-[#F6F6F6] bg-white hover:bg-[#aecaf133]">
                                        {/* { console.log("modalData" , modalData)} */}
                                        <td className="py-2 px-4 min-w-[80px]">
                                          {index + 1}.
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.date}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.time}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.country}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.currency}
                                        </td>
                                        <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer min-w-[200px]">
                                          {item.event}
                                        </td>

                                        <td className="py-2 px-4">
                                          {item.trade}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.trade_grade}
                                        </td>
                                        <td className="py-2 px-4 min-w-[200px]">
                                          {item.investing_name}
                                        </td>
                                        <td className="py-2 px-4 min-w-[200px]">
                                          {item.trading_view}
                                        </td>
                                        <td className="py-2 px-4 min-w-[200px]">
                                          {item.trading_economics}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.movement_currency}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.movement_correlation}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.flat_currency}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.flat_correlation}
                                        </td>
                                        <td className="py-2 px-4 min-w-[200px]">
                                          {item.comments}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.prediction}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.trade_log}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.trade_amount}
                                        </td>
                                        <td className="py-2 px-4">
                                          {item.trade_time}
                                        </td>
                                        <td className="py-2 px-4 min-w-[200px]">
                                          {item.trade_comments}
                                        </td>

                                       
                                      </tr>
                                    </>
                                  ))}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {isModalOpenforedit && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]"
          onClick={closeModalforedit}
        >
          <div
            className="flex relative justify-center items-center flex-col bg-white w-[86%] mt-[10%] h-[75vh] overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            {repopulateloader ? (
              <div className="absolute bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
                <LineWave
                  height="60"
                  width="60"
                  color="#111827"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  className=" w-10 h-10 flex items-center justify-center  mt-10 "
                />
              </div>
            ) : (
              <div></div>
            )}

            <div className="overflow-y-auto bg-white rounded-lg w-[90%] relative  top-[24%] sm:top-[24%] md:top-[4%] lg:top-[4%] xl:top-0 ">
              <button
                onClick={closeModalforedit}
                className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                title="Close"
              >
                {/* &times; Cross icon */}
                <img
                              src={crossicon}
                              alt="crossicon"
                              className="h-[12px] w-[12px]"
                            />

              </button>
             
              <h2 className="text-[28px] font-bold mb-4 text-[#151841] mt-4">
                    Trade History 
                    </h2>

            {modalData.length === 0 ? (
              <>
                <h1 className="text-lg font-bold">
                  {" "}
                  Sorry!! No history trades available for this event .
                </h1>
              </>
            ) : (
              <>
                <div className="w-[100%]">
                  <div className="flex items-end justify-end mb-4">
                    <h1
                      className="rounded-[8px] flex  justify-center text-white bg-[#151841] border border-solid border-[#5b6b8b] p-2 items-end cursor-pointer font-bold "
                      title="Click here to save your trade"
                      onClick={handleFinalSubmission}
                    >
                      Repopulate this Entry
                      
                    </h1>
                  </div>
                  <div className="overflow-auto">
                  <div className="overflow-x-auto overflow-y-auto ">
                  <table className={` ${styles.tableuser} min-w-full  rounded overflow-x-scroll m-auto text-[16px] font-semibold`}>
                  <thead  className="bg-[#d0dcf4]   text-[#170E32]  sticky top-0 z-10 text-[16px] font-semibold border-b-[12px] border-solid border-[#F6F6F6] rounded-[8px]">
                        <tr>
                          <th className=" sticky top-0 py-2 px-4">S. No.</th>
                          <th className=" sticky top-0 py-2 px-4">Date</th>
                          <th className=" sticky top-0 py-2 px-4">Time(GMT)</th>
                          <th className="sticky top-0 py-2 px-4">Country</th>
                          <th className="sticky top-0 py-2 px-4">Currency</th>
                          <th className="sticky top-0 py-2 px-4">Event</th>
                          <th className="sticky top-0 py-2 px-4">Trade</th>
                          <th className="sticky top-0 py-2 px-4">
                            Trade Grade
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Investing.com Var name
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Trading view Var name
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Trading Economics Var name
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Movement (Currency)
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Movement (Correlation)
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Flat (Currency)
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Flat (Correlation)
                          </th>
                          <th className="sticky top-0 py-2 px-4">Comments</th>
                          <th className="sticky top-0 py-2 px-4">Prediction</th>
                          <th className="sticky top-0 py-2 px-4">
                            Trade log(profit/loss)
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Trade Amount
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Trade in time(seconds)
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Trade comment
                          </th>
                          <th className="sticky top-0 py-2 px-4">
                            Select Trade
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-[#F6F6F6]  z-0">
                        {/* {
                                  modalData.length === 0 ? (
                                    <div
                                      className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl `}
                                    >

                                      <div className="w-[100vw] flex justify-center items-center">
                                     <h1 > Sorry!! No history available for this event  .</h1></div>
                                    </div>
                                  ) : (<> */}
                        {modalData
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((item, index) => (
                            <>
                              <tr key={index} className="text-center border-y-[12px] border-solid border-[#F6F6F6] bg-white hover:bg-[#aecaf133]">
                                {/* { console.log("modalData" , modalData)} */}
                                <td className="py-2 px-4 min-w-[80px]">
                                  {index + 1}.
                                </td>
                                <td className="py-2 px-4">{item.date}<br/>{item._id}</td>
                                <td className="py-2 px-4">{item.time.slice(0, 5)}</td>
                                <td className="py-2 px-4">{item.country}</td>
                                <td className="py-2 px-4">{item.currency}</td>
                                <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer min-w-[200px]">
                                  {item.event}
                                </td>

                                <td className="py-2 px-4">{item.trade}</td>
                                <td className="py-2 px-4">
                                  {item.trade_grade}
                                </td>
                                <td className="py-2 px-4 min-w-[200px]">
                                  {item.investing_name}
                                </td>
                                <td className="py-2 px-4 min-w-[200px]">
                                  {item.trading_view}
                                </td>
                                <td className="py-2 px-4 min-w-[200px]">
                                  {item.trading_economics}
                                </td>
                                <td className="py-2 px-4">
                                  {item.movement_currency}
                                </td>
                                <td className="py-2 px-4">
                                  {item.movement_correlation}
                                </td>
                                <td className="py-2 px-4">
                                  {item.flat_currency}
                                </td>
                                <td className="py-2 px-4">
                                  {item.flat_correlation}
                                </td>
                                <td className="py-2 px-4 min-w-[350px]">
                                  {item.comments}
                                </td>
                                <td className="py-2 px-4">{item.prediction}</td>
                                <td className="py-2 px-4">{item.trade_log}</td>
                                <td className="py-2 px-4">
                                  {item.trade_amount}
                                </td>
                                <td className="py-2 px-4">{item.trade_time}</td>
                                <td className="py-2 px-4 min-w-[200px]">
                                  {item.trade_comments}
                                </td>

                                <td className="py-2 px-4 flex items-center justify-center">
                                  <input
                                    type="radio"
                                    name="itemSelection"
                                    className="mt-10"
                                    onChange={() =>
                                      handleRadioButtonChange(item)
                                    } // Set selected item
                                    checked={modal_selectedItemId === item._id}
                                    // Update checked state
                                  />
                                </td>
                              </tr>
                            </>
                          ))}
                        {/* </>)

                                } */}
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </>
            )}
              <>
                {/* <div className="flex items-end justify-end"> 
                              <h1 className="items-end cursor-pointer font-bold" title="Click here to save your trade"     onClick={sendSelected_tradevalues} >final this data:</h1></div> */}
                <div className="flex items-end justify-between mb-4 mt-[12%] sm:mt-[12%] md:mt-[6%] lg:mt-[6%] xl:mt-[6%]">
                <h2 className="text-[28px] font-bold  text-[#151841]">
                    Fill Data -
                    </h2>
                 
                </div>
                {/* <div className="overflow-x-auto">
                  <table className=" min-w-full border rounded overflow-x-scroll m-auto min-h-[30%] ">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className=" sticky top-0 py-2 px-4">Date</th>
                        <th className=" sticky top-0 py-2 px-4">Time(GMT)</th>
                        <th className="sticky top-0 py-2 px-4">Country</th>
                        <th className="sticky top-0 py-2 px-4">Currency</th>
                        <th className="sticky top-0 py-2 px-4">Event</th>
                        <th className="sticky top-0 py-2 px-4">Bookmark</th>
                        <th className="sticky top-0 py-2 px-4">Trade</th>
                        <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                        <th className="sticky top-0 py-2 px-4">
                          Investing.com Var name
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Trading view Var name
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Trading Economics Var name
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Movement (Currency)
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Movement (Correlation)
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Flat (Currency)
                        </th>
                        <th className="sticky top-0 py-2 px-4">
                          Flat (Correlation)
                        </th>
                        <th className="sticky top-0 py-2 px-4">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {geteditdata && (
                          <tr className="text-center">
                            <td className="py-2 px-4">{geteditdata.date}</td>
                            <td className="py-2 px-4">{geteditdata.time}</td>
                            <td className="py-2 px-4">{geteditdata.country}</td>
                            <td className="py-2 px-4">
                              {geteditdata.currency}
                            </td>
                            <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer min-w-[180px]">
                              {geteditdata.event}
                            </td>
                            <td className="py-2 px-4 flex items-center justify-center">
                              <input
                                type="checkbox"
                                item={geteditdata}
                                className="mt-[20%]"
                                onChange={() =>
                                  handleUncheckChange(
                                    geteditdata._id,
                                    checkednull[geteditdata._id]
                                  )
                                }
                                checked={checkednull[geteditdata._id]}
                                // disabled={shouldDisable}
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[140px]">
                              <Select
                                options={options1}
                                value={options1.find(
                                  (option) =>
                                    option.value === trade1[geteditdata._id]
                                )}
                                onChange={(option) =>
                                  handleTradeChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                                isDisabled={!checkednull[geteditdata._id]}
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[140px]">
                              <Select
                                options={trade_grades}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? trade_grade1[geteditdata._id] === ""
                                    : trade_grades.find(
                                        (option) =>
                                          option.value ===
                                          trade_grade1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleTradeGradeChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[212px]">
                              <input
                                type="text"
                                name="investing_name"
                                id="investing_name"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? ""
                                    : investing_name1[geteditdata._id] || ""
                                }
                                onInput={(event) =>
                                  handleInvestingNameChange(
                                    geteditdata._id,
                                    event
                                  )
                                }
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[212px]">
                              <input
                                type="text"
                                name="trading_view"
                                id="trading_view"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? ""
                                    : trading_view1[geteditdata._id] || ""
                                }
                                onInput={(event) =>
                                  handleTradingViewChange(
                                    geteditdata._id,
                                    event
                                  )
                                }
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[212px]">
                              <input
                                type="text"
                                name="trading_economics"
                                id="trading_economics"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? ""
                                    : trading_economics1[geteditdata._id] || ""
                                }
                                onInput={(event) =>
                                  handleTradingEconomicsChange(
                                    geteditdata._id,
                                    event
                                  )
                                }
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[140px]">
                              <Select
                                options={getCurrencyOptions(
                                  geteditdata.country
                                )}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? movement_currency1[geteditdata._id] === ""
                                    : getCurrencyOptions(
                                        geteditdata.country
                                      ).find(
                                        (option) =>
                                          option.value ===
                                          movement_currency1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleMovementcurrencyChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[140px]">
                              <Select
                                options={movement_correlation_option}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? movement_correlation1[geteditdata._id] ===
                                      ""
                                    : movement_correlation_option.find(
                                        (option) =>
                                          option.value ===
                                          movement_correlation1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleMovementCorrelationChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[140px]">
                              <Select
                                options={getflat_CurrencyOptions(
                                  geteditdata.country
                                )}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? flat_currency1[geteditdata._id] === ""
                                    : getflat_CurrencyOptions(
                                        geteditdata.country
                                      ).find(
                                        (option) =>
                                          option.value ===
                                          flat_currency1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleFlatcurrencyChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[140px]">
                              <Select
                                options={flat_correlation_option}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? flat_correlation1[geteditdata._id] === ""
                                    : flat_correlation_option.find(
                                        (option) =>
                                          option.value ===
                                          flat_correlation1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleFlatCorrelationChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              />
                            </td>

                            <td className="py-2 px-4 min-w-[140px]">
                              <input
                                type="text"
                                name="comments"
                                id="comments"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id]
                                }
                                value={comments1[geteditdata._id] || ""}
                                onInput={(event) =>
                                  handleCommentsChange(geteditdata._id, event)
                                }
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    </tbody>
                  </table>
                </div> */}
      {geteditdata && (
        <div className="overflow-auto">
       <div className="p-3 border-2 border-opacity-[35%] border-[#A0A0A0] rounded-[13px] overflow-y-auto overflow-x-auto px-[4%] ">
                 <div className="flex justify-between items-center mt-2 flex-col lg:flex-row">
                 <div className="text-[#170E32] text-[18px] font-bold "> Date : {geteditdata.date}</div>
                 <div className="text-[#170E32] text-[18px] font-bold"> Time : {geteditdata.time.slice(0, 5)}</div>
                 <div className="text-[#170E32] text-[18px] font-bold"> Country : {geteditdata.country}</div>
                 <div className="text-[#170E32] text-[18px] font-bold max-w-[300px]"> Event : {geteditdata.event}</div>
                  </div>
                
                  <div className="flex justify-center lg:justify-between items-center mt-5 w-full lg:w-[64%] flex-col lg:flex-row">
                
                 <div className="text-[#170E32] text-[18px] font-bold"> Currency : {geteditdata.currency}</div>
                 <div className="text-[#170E32] text-[18px] font-bold"> Impact : {geteditdata.impact}</div>
                
                  </div>

               
             <hr className="mt-5 bg-black m-0 p-0"  />
                <div className="flex justify-between flex-col lg:flex-row mt-7 w-[100%]">
                <div  className="text-[#170E32]  text-[18px] font-bold flex justify-start lg:justify-center items-center w-[222px]">
                     Bookmark :  <input
                                type="checkbox"
                                item={geteditdata}
                                className="mt-0 ml-4"
                                onChange={() =>
                                  handleUncheckChange(
                                    geteditdata._id,
                                    checkednull[geteditdata._id]
                                  )
                                }
                                checked={checkednull[geteditdata._id]}
                               
                              />
                              </div>
                <div className="flex flex-col pl-0 lg:pl-2 w-[230px]" >
                      <h1 className="text-[#170E32] text-[18px] font-bold">Trade:</h1>
                      <div className="w-[222px]">
                        <Select
                                options={options1}
                                value={options1.find(
                                  (option) =>
                                    option.value === trade1[geteditdata._id]
                                )}
                                onChange={(option) =>
                                  handleTradeChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                                isDisabled={!checkednull[geteditdata._id]}
                              />
                              </div>

                  </div>
                  <div className="flex flex-col w-[265px]">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Trade Grade:</h1>
                      <div className="w-[222px]">
                      <Select
                                options={trade_grades}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? trade_grade1[geteditdata._id] === ""
                                    : trade_grades.find(
                                        (option) =>
                                          option.value ===
                                          trade_grade1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleTradeGradeChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              />
                              </div>

                  </div>
                 
                </div>
                <div className="flex justify-between mt-7 flex-col lg:flex-row">
                <div className="flex flex-col">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Investing.com Var name:</h1>
                      <div className="w-[222px]">
                      <input
                                type="text"
                                name="investing_name"
                                id="investing_name"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? ""
                                    : investing_name1[geteditdata._id] || ""
                                }
                                onInput={(event) =>
                                  handleInvestingNameChange(
                                    geteditdata._id,
                                    event
                                  )
                                }
                              />
                              </div>

                  </div>
                  <div className="flex flex-col pl-0 lg:pl-2 ">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Trading view Var name:</h1>
                      <div className="w-[222px]">
                      <input
                                type="text"
                                name="trading_view"
                                id="trading_view"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? ""
                                    : trading_view1[geteditdata._id] || ""
                                }
                                onInput={(event) =>
                                  handleTradingViewChange(
                                    geteditdata._id,
                                    event
                                  )
                                }
                              />
                              </div>

                  </div>
                  <div className="flex flex-col">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Trading Economics Var name:</h1>
                      <div className="w-[222px]">
                      <input
                                type="text"
                                name="trading_economics"
                                id="trading_economics"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? ""
                                    : trading_economics1[geteditdata._id] || ""
                                }
                                onInput={(event) =>
                                  handleTradingEconomicsChange(
                                    geteditdata._id,
                                    event
                                  )
                                }
                              />
                              </div>

                  </div>
                 
                </div>
                <div className="flex justify-between mt-0 xl:mt-7 w-[100%] flex-col lg:flex-row">
                <div className="flex flex-col  w-[222px]">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Movement (Currency):</h1>
                      <div className="w-[222px]">
                      <Select
                                options={getCurrencyOptions(
                                  geteditdata.country
                                )}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? movement_currency1[geteditdata._id] === ""
                                    : getCurrencyOptions(
                                        geteditdata.country
                                      ).find(
                                        (option) =>
                                          option.value ===
                                          movement_currency1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleMovementcurrencyChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              /></div>

                  </div>
                  <div className="flex flex-col mt-0 xl:mt-5 pl-0 lg:pl-2  w-[230px]">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Movement (Correlation):</h1>
                      <div className="w-[222px]">
                      <Select
                                options={movement_correlation_option}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? movement_correlation1[geteditdata._id] ===
                                      ""
                                    : movement_correlation_option.find(
                                        (option) =>
                                          option.value ===
                                          movement_correlation1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleMovementCorrelationChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              />
                              </div>

                  </div>
                  <div className=" flex-col invisible w-[265px]">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Trading Economics Var name:</h1>
                      <div className="w-[222px]">
                      <input
                                type="text"
                                name="trading_economics"
                                id="trading_economics"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? ""
                                    : trading_economics1[geteditdata._id] || ""
                                }
                                onInput={(event) =>
                                  handleTradingEconomicsChange(
                                    geteditdata._id,
                                    event
                                  )
                                }
                              />
                              </div>

                  </div>
                 
                </div>
                <div className="flex justify-between mt-0 xl:mt-7 w-[100%] flex-col lg:flex-row">
                <div className="flex flex-col  w-[222px]">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Flat (Currency):</h1>
                      <div className="w-[222px]">
                      <Select
                                options={getflat_CurrencyOptions(
                                  geteditdata.country
                                )}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? flat_currency1[geteditdata._id] === ""
                                    : getflat_CurrencyOptions(
                                        geteditdata.country
                                      ).find(
                                        (option) =>
                                          option.value ===
                                          flat_currency1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleFlatcurrencyChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              /></div>

                  </div>
                  <div className="flex flex-col mt-0 xl:mt-5 pl-0 lg:pl-2 w-[230px]">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Flat (Correlation):</h1>
                      <div className="w-[222px]">
                      <Select
                                options={flat_correlation_option}
                                isDisabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? flat_correlation1[geteditdata._id] === ""
                                    : flat_correlation_option.find(
                                        (option) =>
                                          option.value ===
                                          flat_correlation1[geteditdata._id]
                                      )
                                }
                                onChange={(option) =>
                                  handleFlatCorrelationChange(
                                    geteditdata._id,
                                    option.value
                                  )
                                }
                              />
                              </div>

                  </div>
                  <div className="invisible flex-col w-[265px]">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Trading Economics Var name:</h1>
                      <div className="w-[222px]">
                      <input
                                type="text"
                                name="trading_economics"
                                id="trading_economics"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id] ||
                                  trade1[geteditdata._id] === "No"
                                }
                                value={
                                  trade1[geteditdata._id] === "No"
                                    ? ""
                                    : trading_economics1[geteditdata._id] || ""
                                }
                                onInput={(event) =>
                                  handleTradingEconomicsChange(
                                    geteditdata._id,
                                    event
                                  )
                                }
                              />
                              </div>

                  </div>
                 
                </div>
                <div className="flex flex-col mt-7 mb-6">
                      <h1 className="text-[#170E32] text-[18px] font-bold">Comments:</h1>
                      <div>
                      <input
                                type="text"
                                name="comments"
                                id="comments"
                                className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                                disabled={
                                  !checkednull[geteditdata._id] ||
                                  !trade1[geteditdata._id]
                                }
                                value={comments1[geteditdata._id] || ""}
                                onInput={(event) =>
                                  handleCommentsChange(geteditdata._id, event)
                                }
                              /></div>

                  </div>
                
                 </div>

                 <div className="flex items-end justify-end mb-4 mt-6 mr-3">
               
                  <h1
                     className="rounded-[8px] flex  justify-center text-white bg-[#151841] border border-solid border-[#5b6b8b] py-2 px-6 items-end cursor-pointer font-bold "
                    title="Click here to save your trade"
                    onClick={sendSelected_tradevalues}
                  >
                    Save Data
                   
                  </h1>
                </div>
  </div>
               )
               }
               
              

              
              </>
            </div>

           
          </div>
        </div>
      )}


     
    </div>
  );
};

export default UserTable;
