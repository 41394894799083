// import dayjs from "dayjs";
// import React, { useEffect, useState, useMemo, useRef } from "react";
// import { fetchStockData } from "./services";
// import formatStockData from "./utils";
// import ReactApexChart from "react-apexcharts";
// import { Bars } from "react-loader-spinner";

// const Candle = () => {
//   const [stockData, setStockData] = useState([]);
//   const [countryname, setcountryname] = useState("");
//   const [loading, setLoading] = useState(true);
//   const chartRefs = useRef([]);

//   const fetchCandleData = async () => {
//     try {
//       let graphdataarray = localStorage.getItem("4GraphDataobjects");

//       if (graphdataarray) {
//         graphdataarray = JSON.parse(graphdataarray);

//         if (graphdataarray.length > 0) {
//           const lastItem = graphdataarray[graphdataarray.length - 1];
//           const country = lastItem.country;
//           setcountryname(country);
//           let extractedDatesAndTimes = [];

//           lastItem.four_graph_values.forEach((graphItem) => {
//             const { date, time } = graphItem;
//             if (
//               !extractedDatesAndTimes.some(
//                 (item) => item.date === date && item.time === time
//               )
//             ) {
//               extractedDatesAndTimes.push({ date, time, country });
//             }
//           });

//           const data = await fetchStockData(extractedDatesAndTimes);
//           setStockData(data);
//           setLoading(false);
//         } else {
//           console.log("No data found in localStorage for key '4GraphDataobjects'");
//           setLoading(false);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching stock data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchCandleData();
//   }, []);

//   const findHighLow = (dataArray) => {
//     let highestHigh = -Infinity;
//     let lowestLow = Infinity;

//     dataArray.forEach(item => {
//       if (item && typeof item.High === 'number' && typeof item.Low === 'number') {
//         highestHigh = Math.max(highestHigh, item.High);
//         lowestLow = Math.min(lowestLow, item.Low);
//       }
//     });

//     return { highestHigh, lowestLow };
//   };

//   const seriesData = useMemo(() => {
//     return stockData.map((item) => {
//       const { highestHigh, lowestLow } = findHighLow(item.data);
//       return {
//         model: item.model,
//         data: formatStockData(item.data),
//         highestHigh,
//         lowestLow
//       };
//     });
//   }, [stockData]);

//   const basicOptions = {
//     chart: {
//       type: "candlestick",
//       zoom: {
//         enabled: true,
//       },
//     },
//     xaxis: {
//       type: "category",
//     },
//     yaxis: {
//       tooltip: {
//         enabled: true,
//       },
//     },
//   };
//   // useEffect(() => {
//   //   if (!loading && stockData.length > 0) {
//   //     // Iterate over each object in stockData
//   //     stockData.forEach((item, index) => {
//   //       const dateGroups = {}; // Initialize an empty object for grouping

//   //       // Group "Open" values by date within the current object
//   //       item.data.forEach(point => {
//   //         const date = point.date; // Ensure this matches your data structure
//   //         if (!dateGroups[date]) {
//   //           dateGroups[date] = []; // Initialize an array for the date if it doesn't exist
//   //         }
//   //         dateGroups[date].push(point.Open); // Push the "Open" value into the corresponding date array
//   //       });

//   //       // Calculate the average "Open" value for each date
//   //       const averagesByDate = Object.keys(dateGroups).map(date => {
//   //         const openValues = dateGroups[date];
//   //         const sum = openValues.reduce((acc, Open) => acc + Open, 0);
//   //         const average = sum / openValues.length;
//   //         return { date, average };
//   //       });

//   //       // Log the averages for the current object
//   //       console.log(`Averages for object ${index + 1}:`, averagesByDate);
//   //     });
//   //   }
//   // }, [loading, stockData]);

//   useEffect(() => {
//     if (!loading && stockData.length > 0) {
//       // Iterate over each object in stockData
//       stockData.forEach((item, index) => {
//         const dateGroups = {}; // Initialize an empty object for grouping

//         // Group values (Open, High, Low, Close) by date within the current object
//         item.data.forEach(point => {
//           const date = point.date; // Ensure this matches your data structure
//           if (!dateGroups[date]) {
//             dateGroups[date] = []; // Initialize an array for the date if it doesn't exist
//           }
//           dateGroups[date].push(point); // Push the entire point into the corresponding date array
//         });

//         // Calculate the average "Open" value for each date
//         const averagesByDate = Object.keys(dateGroups).map(date => {
//           const openValues = dateGroups[date].map(point => point.Open);
//           const highValues = dateGroups[date].map(point => point.High);
//           const lowValues = dateGroups[date].map(point => point.Low);
//           const closeValues = dateGroups[date].map(point => point.Close);

//           const sum = arr => arr.reduce((acc, val) => acc + val, 0);
//           const average = arr => sum(arr) / arr.length;

//           const openAverage = average(openValues);
//           const highAverage = average(highValues);
//           const lowAverage = average(lowValues);
//           const closeAverage = average(closeValues);
//           // console.log("openAverage" , openAverage)
//           // Calculate (value - average) / average for each value in the group
//           const calculations = dateGroups[date].map(point => {
//             return {
//               ...point,
//               openDeviation: (point.Open - openAverage) / openAverage,
//               highDeviation: (point.High - openAverage) / openAverage,
//               lowDeviation:  (point.Low - openAverage) / openAverage,
//               closeDeviation:(point.Close - openAverage) / openAverage
//             };
//           });

//           return { date, calculations };
//         });

//         // Log the calculations for the current object
//         console.log(`Calculations for object ${index + 1}:`, averagesByDate);
//       });
//     }
//   }, [loading, stockData]);

//   return (
//     <>
//       <div>
//         <h1 className="font-semibold text-start m-5">
//           Here are the charts of country:
//           <span className="font-bold"> {countryname}</span>
//         </h1>
//       </div>
//       <div className="container mx-auto p-4">
//         {loading ? (
//           <div>
//             <div className="font-semibold m-6 font-xl text-center flex justify-center items-center h-[50%]">
//               Wait for charts .....
//             </div>
//             <div className="flex items-center justify-center">
//               <Bars
//                 height="80"
//                 width="80"
//                 color="#94A3B8"
//                 ariaLabel="bars-loading"
//                 visible={true}
//                 className="loader"
//               />
//             </div>
//           </div>
//         ) : (
//           <div className="flex flex-wrap -mx-2">
//             {seriesData.map((series, index) => (
//               <div key={index} className="w-full md:w-1/2 p-2">
//                 <div className="border rounded shadow p-4">
//                   <h2 className="text-center mb-4">{series.model}</h2>
//                   <ReactApexChart
//                     series={[{ data: series.data }]}
//                     options={{
//                       ...basicOptions,
//                       yaxis: {
//                         min: series.lowestLow,
//                         max: series.highestHigh,
//                          forceNiceScale: true, // Ensures better scaling without too much empty space
//                         tickAmount: 10,  // You can try reducing this if there's too much space
//                         tooltip: {
//                           enabled: true,
//                         },

//                         labels: {
//                           formatter: function (val) {
//                             return val.toFixed(5); // Adjust precision as needed
//                           }
//                         }
//                       },
//                     }}
//                     type="candlestick"
//                     height={800}
//                     ref={(el) => (chartRefs.current[index] = el)}
//                   />
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default Candle;

import dayjs from "dayjs";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { fetchStockData } from "./services";
import formatStockData from "./utils";
import ReactApexChart from "react-apexcharts";
import { Bars } from "react-loader-spinner";

const Candle = () => {
  const [stockData, setStockData] = useState([]);
  const [countryname, setcountryname] = useState("");
  const [eventname, seteventname] = useState("");
  const [loading, setLoading] = useState(true);
  const chartRefs = useRef([]);
  const [noDataAvailable, setNoDataAvailable] = useState(false); 
  const [fullScreenIndex, setFullScreenIndex] = useState(null);

  
  const fetchCandleData = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const eventName = decodeURIComponent(urlParams.get('eventName'));
      const eventcountry = decodeURIComponent(urlParams.get('eventcountry'));
      let graphdataarray = localStorage.getItem("4GraphDataobjects");

      if (graphdataarray) {
        graphdataarray = JSON.parse(graphdataarray);
        // console.log("graphdataarray" , graphdataarray)
        if (graphdataarray.length > 0) {

          const current_Item = graphdataarray.find(
            (item) => item.event === eventName && item.country === eventcountry
          );
          // const lastItem = graphdataarray[graphdataarray.length - 1];
          // console.log("current_Item" , current_Item)
          const country = current_Item.country;
          setcountryname(country);

          const event = current_Item.event;
          seteventname(event);

          let extractedDatesAndTimes = [];

          current_Item.four_graph_values.forEach((graphItem) => {
            const { date, time } = graphItem;
            // <------if same date and time is not available then push the data ------------>
            if (
              !extractedDatesAndTimes.some(
                (item) => item.date === date && item.time === time
              )
            ) {
              extractedDatesAndTimes.push({ date, time, country });
            }
            else {
              console.log("data is already available")
            }
            
          });
         
          const data = await fetchStockData(extractedDatesAndTimes);
          
          // setStockData(data);
         console.log("data------------->" , data)
         
           // Check for unsupported country error
        if (data.error === "Unsupported country") {
          console.log("No data available for this country");
          setStockData([]); // Clear any existing stock data
          setNoDataAvailable(true); 
          setLoading(false);
        } else {
          setStockData(data);
          // console.log("Fetched data:", data);
          setLoading(false);
        }
        } 
        
        else {
          console.log(
            "No data found in localStorage for key '4GraphDataobjects'"
          );
          setLoading(false);
          setNoDataAvailable(true); 
        }
      }

      else{
        console.log("No data available in local storage");
        setLoading(false);
        setNoDataAvailable(true); 
      }
    }
     catch (error) {
      console.error("Error fetching stock data:", error);
      setLoading(false);
      setNoDataAvailable(true); 
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCandleData();
  }, []);

  const findHighLow = (dataArray) => {
    let highestHigh = -Infinity;
    let lowestLow = Infinity;

    dataArray.forEach((item) => {
      if (
        item &&
        typeof item.High === "number" &&
        typeof item.Low === "number"
      ) {
        highestHigh = Math.max(highestHigh, item.High);
        lowestLow = Math.min(lowestLow, item.Low);
      }
    });

    return { highestHigh, lowestLow };
  };

  const seriesData = useMemo(() => {
    return stockData.map((item) => {
      const dateGroups = {};

      item.data.forEach((point) => {
        const date = point.date;
        if (!dateGroups[date]) {
          dateGroups[date] = [];
        }
        dateGroups[date].push(point);
        // console.log("dateGroups" , dateGroups[date])
      });


      // console.log("dateGroups" , dateGroups)
      // console.log("dateGroups[date]" , dateGroups[date])
      // Calculate averages and deviations
      const averagesByDate = Object.keys(dateGroups).map((date) => {
        const openValues = dateGroups[date].map((point) => point.Open);
        const highValues = dateGroups[date].map((point) => point.High);
        const lowValues = dateGroups[date].map((point) => point.Low);
        const closeValues = dateGroups[date].map((point) => point.Close);

        const sum = (arr) => arr.reduce((acc, val) => acc + val, 0);
        const average = (arr) => sum(arr) / arr.length;
      
        // console.log("openValues" , openValues[0])
        const openAverage = average(openValues);
        // const highAverage = average(highValues);
        // const lowAverage = average(lowValues);
        // const closeAverage = average(closeValues);
        console.log("openAverage:", openAverage);
        const calculations = dateGroups[date].map((point) => {

          return {
            ...point,
            openDeviation: (((point.Open - openValues[0]) / openValues[0])*100).toFixed(3),
            highDeviation: (((point.High - openValues[0]) / openValues[0])*100).toFixed(3),
            lowDeviation: (((point.Low - openValues[0])   / openValues[0])*100).toFixed(3),
            closeDeviation: (((point.Close - openValues[0]) / openValues[0])*100).toFixed(3),
          };
        });
       
        return { date, calculations };
      });

      // console.log(`Averages for object :`, averagesByDate)
      const flattenedData = averagesByDate.flatMap((item) => item.calculations);
      // console.log("flattenedData" , flattenedData)

      return {
        model: item.model,
        data: formatStockData(flattenedData),
        highestHigh: findHighLow(item.data).highestHigh,
        lowestLow: findHighLow(item.data).lowestLow,
        averagesByDate, 
      };
    });
  }, [stockData]);



  // let chartOptions = [];

  //     let allYValues;
  //     let defaultMax;
  //     let defaultMin;
  //     seriesData.forEach((currentItem) => {
  //       allYValues = currentItem.data.flatMap(point => point.y);
  //       defaultMin = Math.min(...allYValues) - 0.002;
  //       defaultMax = Math.max(...allYValues) + 0.002;
  //       console.log("seriesData" , seriesData)
  //       // console.log("defaultMin" ,defaultMin)
  //       // console.log("defaultMax" ,defaultMax)

  //       const chartOption = {
  //         chart: {
  //           type: "candlestick",
  //           zoom: {
  //             enabled: true,
  //           },
  //         },
          
  //         xaxis: {
  //           type: "category",
  //         },
  //         yaxis: {
  //           tooltip: {
  //             enabled: false,
  //           },
  //           labels: {
  //             formatter: function (val) {
  //               return val !== undefined ? val.toFixed(3) : "";
  //             },
  //           },
  //           min: defaultMin,
  //           max: defaultMax,
  //           tickAmount: 10,
  //         },
          
  //         annotations: {
  //           yaxis: [
  //             {
  //               y: 0.000,
  //               strokeDashArray: 0,
  //               borderColor: '#007bff', // blue color 
  //               borderWidth: 2,
  //               // borderDash: [], 
  //               // fillColor: '#FEB019',
  //               label: {
  //                 borderColor: '#00E396',
  //                 style: {
  //                   color: '#fff',
  //                   background: '#00E396'
  //                 },
  //                 // text: 'base line at 0.000'
  //               }
  //             }
  //           ],
      
  //           xaxis:[
  //             {x: date,
  //               strokeDashArray: 0,
  //               borderColor: '#007bff', // blue color 
  //               borderWidth: 2,
  //               // borderDash: [], 
  //               // fillColor: '#FEB019',
  //               label: {
  //                 borderColor: '#00E396',
  //                 style: {
  //                   color: '#fff',
  //                   background: '#00E396'
  //                 },
  //                 // text: 'base line at 0.000'
  //               }
  //             }
  //           ]

  //         }

  //       };
        
      
  //       chartOptions.push(chartOption);


  // });
  let chartOptions = [];

  let allYValues;
  let defaultMax;
  let defaultMin;

  seriesData.forEach((currentItem) => {
    // console.log("currentItem" , currentItem)
    allYValues = currentItem.data.flatMap(point => point.y);
    // console.log("allYValues" , allYValues)
    defaultMin = Math.min(...allYValues) - 0.002;
    defaultMax = Math.max(...allYValues) + 0.002;
    // console.log("seriesData" , seriesData)
    // console.log("defaultMin" ,defaultMin)
    // console.log("defaultMax" ,defaultMax)
  
    const chartOption = {
      chart: {
        type: "candlestick",
        zoom: {
          enabled: true,
        },
      },
      
      xaxis: {
        type: "category",
      },
      yaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: function (val) {
            return val !== undefined ? val.toFixed(3) : "";
          },
        },
        min: defaultMin,
        max: defaultMax,
        tickAmount: 10,
        tickValues: [0.000]
       
      },
      annotations: {
        yaxis: [
          {
            y: 0.000,
            
            strokeDashArray: 0,
            borderColor: '#007bff', // blue color 
            borderWidth: 2,
            // borderDash: [], 
            // fillColor: '#FEB019',
            label: {
              
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396'
              },
              text: '0.000'
            }
          }
        ],
        xaxis: [] // Initialize an empty xaxis annotation array
      }
    };
  
    const dates = [];
    currentItem.data.forEach((point, index) => {
      // const date = point.x.split(' ')[0];
      const date = point.x.split(',')[0].trim();
      const time = point.x.split(' ')[1]; 
      if (!dates.includes(date)) {
        dates.push(date);
        console.log("date---------------->" , date)
        chartOption.annotations.xaxis.push({
          x: point.x,
          borderColor: '#007bff', 
          borderWidth: 1,
          borderDash: [], 
          label: {
            position: 'top', 
            // offsetY: -10,
            offsetX : 22,         // position right align 
            borderColor: '#000',
            style: {
              color: '#fff',
              fontSize: '14px', 
              text:"bold",
              background: '#000'
            },
            text: `${date}`
          }
        });
        
      }
    });
    
    chartOptions.push(chartOption);
  });

  // console.log("seriesData" , seriesData)

  const handleGraphClick = (index) => {
    setFullScreenIndex(index);  // Set the clicked graph to full screen
  };

  const handleCloseFullScreen = () => {
    setFullScreenIndex(null);  // Reset to hide full-screen
  };

  return (
    <>
      <div className="flex flex-col">
        <h1 className="font-semibold text-start mx-5 mt-5">
        {/* Here are the charts of country: */}
        Candlestick charts for :
          <span className="font-bold mr-2"> {eventname}</span>  
         
       
        </h1>
        <h1 className="font-semibold text-start m-5">(Country :  <span className="font-bold"> {countryname}</span>)</h1>
      </div>
      <div className="font-bold justify-center items-center text-[20px] w-[100%] flex">{noDataAvailable && <p>No data available for this country.</p>}</div>
      <div className="max-w-[100%] mx-[4%] p-4">
        {/* {loading ? (
          <div>
            <div className="font-semibold m-6 font-xl text-center flex justify-center items-center ">
              Wait for charts .....
            </div>
            <div className="flex items-center justify-center">
              <Bars
                height="80"
                width="80"
                color="#94A3B8"
                ariaLabel="bars-loading"
                visible={true}
                className="loader"
              />
            </div>
          </div>
        ) : (
          fullScreenIndex === null ?(
            <div className="flex flex-wrap -mx-2">
            {seriesData.map((series, index) => (
            
              <div key={index} className="w-full md:w-1/2 p-2">
                <div className="border rounded shadow p-4">
                  <h2 className="text-center mb-4">{series.model}</h2>

                 
                  <ReactApexChart
                  series={[{ data: series.data }]}
                    options={chartOptions[index]}
                    // options={basicOptions}
                    type="candlestick"
                    height={800}
                    ref={(el) => (chartRefs.current[index] = el)}
                  />
                </div>
              </div>
            ))}
          </div>
          )
          :(
            <div className="fixed inset-0 bg-white z-50 flex flex-col items-center justify-center">
            <button
              className="absolute top-4 right-4 bg-red-500 text-white p-2 rounded"
              onClick={handleCloseFullScreen}
            >
              Close
            </button>
            <h2 className="text-center mb-4">{seriesData[fullScreenIndex].model}</h2>
            <ReactApexChart
              series={[{ data: seriesData[fullScreenIndex].data }]}
              options={chartOptions[fullScreenIndex]}
              type="candlestick"
              height={800}  // Fullscreen height
              ref={(el) => (chartRefs.current[fullScreenIndex] = el)}
            />
          </div>
          )
        
         
        )
        } */}
        {loading ? (
  <div>
    <div className="font-semibold m-6 font-xl text-center flex justify-center items-center">
      Wait for charts .....
    </div>
    <div className="flex items-center justify-center">
      <Bars
        height="80"
        width="80"
        color="#94A3B8"
        ariaLabel="bars-loading"
        visible={true}
        className="loader"
      />
    </div>
  </div>
) : (
  fullScreenIndex === null ? (
    <div className="flex flex-wrap -mx-2">
      {seriesData.map((series, index) => (
        <div key={index} className="w-full md:w-1/2 p-2">
          <div className="border rounded shadow p-4" onClick={() => handleGraphClick(index)}>
            <h2 className="text-center mb-4">{series.model}</h2>
            <ReactApexChart
              series={[{ data: series.data }]}
              options={chartOptions[index]}
              type="candlestick"
              height={800}
              ref={(el) => (chartRefs.current[index] = el)}
            />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="inset-0 bg-white z-50 flex flex-col items-center justify-center">
      <button
        className="absolute top-4 right-4 bg-red-500 text-white p-2 rounded"
        onClick={handleCloseFullScreen}
      >
        Close
      </button>
      <h2 className="text-center mb-4">{seriesData[fullScreenIndex].model}</h2>
      <div className="w-[100%] min-w-[100%]">

      <ReactApexChart
        series={[{ data: seriesData[fullScreenIndex].data }]}
        options={chartOptions[fullScreenIndex]}
        type="candlestick"
        height={800}  // Fullscreen height
        ref={(el) => (chartRefs.current[fullScreenIndex] = el)}
      />
      </div>
    </div>
  )
)}


      </div>
    </>
  );
};

export default Candle;

