import React, { useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";
import formatStockData from "../components/utils";
import { candleStickOptions } from "../components/constants";





// <---------------this code is for graphs , for now this is not in use ---------------->
const Eventdetails = () => {
  const [eventData, setEventData] = useState([]);
  const [eventinfo, setEventinfo] = useState([]);

  useEffect(() => {
    const clickedEventData = localStorage.getItem("clickedEventData");

    if (clickedEventData) {
      const parsedData = JSON.parse(clickedEventData);
      console.log("clickedEventData", clickedEventData);
      const flattenedData = parsedData.map((innerArray) => innerArray.flat());
      console.log(flattenedData, "flattenedData");
      setEventData(flattenedData);
    }

    const clickedEventinfo = localStorage.getItem("clickedEventinfo");
    if (clickedEventinfo) {
      const parsedData = JSON.parse(clickedEventinfo);
      console.log("clickedEventData", clickedEventinfo);
     
      console.log(parsedData, "parsedData");
      setEventinfo(parsedData);
    }
  }, []);

  return (
    <div className="flex gap-4 mt-6 mx-4 ">
      <div className="w-3/4">
   
       {eventData.map((data, index) => {
          const seriesData = formatStockData(data);
          return (
            <div key={index} className="mb-6">
              <ReactApexChart
                series={[{ data: seriesData }]}
                options={{
                  ...candleStickOptions,
                  
                }}
                type="candlestick"
                height={350}
                width={700}
                className=""
              />
            </div>
          );
        })}
      </div>
      <div className="w-1/4  mt-20 mb-10">
  {eventinfo && eventinfo.length > 0 ? (
    eventinfo.map((info, index) => (
      <div key={index} className="flex justify-center items-center mb-24 mt-4">
        <table className="table shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] shadow-lg ml-[-70%] ">
          <tbody>
            <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
              <td className="px-6 py-3">Date</td>
              <td className="px-6 py-3">{info.date}</td>
            </tr>
            <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
              <td className="px-6 py-3">Time</td>
              <td className="px-6 py-3">{info.time}</td>
            </tr>
            <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
              <td className="px-6 py-3">Country</td>
              <td className="px-6 py-3">{info.country}</td>
            </tr>
            <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
              <td className="px-6 py-3">Currency</td>
              <td className="px-6 py-3">{info.currency}</td>
            </tr>
            <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
              <td className="px-6 py-3">Event</td>
              <td className="px-6 py-3">{info.event}</td>
            </tr>
          </tbody>
        </table>
      </div>
    ))
  ) : (
    <div>No event information available</div>
  )}
</div>

    </div>
  );
};

export default Eventdetails;
